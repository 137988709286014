.sin_fondo{
    display: none;
}

.fondo_azul{
        height: 624px;
        width: 100%;
        background: #00aeef;
        position: absolute;
        top: 1749px;
}