/* Estilos para modelo #2 */

.toggle_button_container_model_one {
  width: 100%;
  background-color: #fafbff;
  padding: 8px 0 0 0;
  border: 1px solid #eaedfa;
  border-radius: 4px 0 0 4px;
}

.toggle_button_container_model_one i {
  margin-right: 10px;
}

.toggle_button_container_model_one,
.filters_selected_container_model_one,
.search_button_container_model_one,
.filter_container_outside_model_one,
.filter_container_emisora_seleccionada {
  height: 60px;
}

.search_button_container_model_one {
  width: 100%;
  padding: 8px 0 0 0;
  border-top: 1px solid #eaedfa;
  border-right: 1px solid #eaedfa;
  border-bottom: 1px solid #eaedfa;
  border-radius: 0 4px 4px 0;
  background-color: #fafbff;
}

.toggle_button_container_model_one button,
.search_button_container_model_one button {
  color: #00aeef;
  outline: none;
  box-shadow: none !important;
}

.filters_selected_container_model_one {
  border-top: 1px solid #eaedfa;
  border-right: 1px solid #eaedfa;
  border-bottom: 1px solid #eaedfa;
}

.no_col_padding_model_one {
  padding: 0;
}

.filter_container_outside_model_one,
.filter_container_emisora_seleccionada {
  width: 100%;
  overflow-y: none;
  overflow-x: auto;
  white-space: nowrap;
  color: #00aeef;
  padding-top: 15px;
}

.filters_container_model_one {
  width: 100%;
  background-color: #fafbff;
  border-left: 1px solid #eaedfa;
  border-right: 1px solid #eaedfa;
  border-bottom: 1px solid #eaedfa;
  border-radius: 0 0 4px 4px;
}

.filter_container_model_one,
.filter_emisora_seleccionada {
  display: inline;
  padding: 5px 7px 7px 10px;
  margin: 6px 5px 0;
  border: 1px solid #eaedfa;
  border-radius: 50px;
}

.filter_container_model_one span,
.filter_emisora_seleccionada span {
  color: #00aeef;
}

.search_input_container_model_one input {
  padding-left: 20px;
  height: 58px;
  border: 0;
  outline: none;
  box-shadow: none !important;
  font-size: 14px !important;
}

.filters_section_model_one {
  padding: 30px 0 20px 0;
}

.no_borders_filter_button_model_one {
  border-bottom-left-radius: 0;
}

.no_border_search_button_model_one {
  border-bottom-right-radius: 0;
}

.filter_icon_button_model_one {
  margin-left: 8px;
  cursor: pointer;
}

.filter_text_model_one {
  font-size: 14px;
}

.hidden {
  display: none;
}

.button_model_one {
  border: 1px solid #eaedfa;
  color: #00aeef;
  width: 90% !important;
  margin: 0 auto;
}

.button_model_one i {
  margin-right: 10px;
}

.autoCompleteContainer {
  position: relative;
  bottom: 15px;
  z-index: 5000 !important;
}

.autoCompleteContainer li:hover{
  cursor: pointer;
}

.autoCompleteContainer li:first-child {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.filterContainer {
  padding: 8px;
  margin-right: 5px;
  border: 1px solid #eaedfa;
  border-radius: 50px;
}

.filterContainer span {
  color: #00aeef;
}

.filterIconButton {
  margin-left: 10px;
  cursor: pointer;
}

.filterSelectedContainer,
.selectedOptionContainer{
  height: 65px;
}


.selectedOptionContainer {
  width: 100%;
  overflow-y: none !important;
  overflow-x: auto;
  white-space: nowrap;
  color: #00aeef;
  padding: 18px 0 0 15px;
}