.main_title {
  font-size: 42px;
}

.main_subtitle {
  font-size: 30px;
  margin: 20px 0 40px 0;
}

.main_subtitle h2 {
  display: inline;
  margin-left: 20px;
}

.slash {
  font-size: 30px;
  font-weight: bold;
  color: #00aeef;
}

.text_container {
  text-align: justify;
}

.imgContainer {
  margin-top: 30px;
}

.link{
  color: #00aeef;
font-weight: bold;

}