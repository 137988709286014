.blur {
    filter: blur(2px);
    width: 100%;
    top: 0;
    user-select: none;
    pointer-events: none;
}

.imgContainer {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    margin: auto;
    width: 85%;
    height: fit-content;
    vertical-align: middle;
    display: flex;
}

.center {
    width: 100%;
    height: 100%;
}

.imagen,
.mensaje {
    float: left;
    width: 50%;
    padding: 20px;
}

.imagen{
    padding-top: 60px;
}

.mensaje {
    height: min-content;
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    right: 0;
}


/* // Medium devices (tablets, less than 992px) */
@media (max-width: 1199.98px) {
    .imgContainer {
        width: 90%;
    }

    .imagen img {
        width: 100%;
    }


}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
    .imagen {
        text-align: center;
        width: 100%;
    }
    .imagen img{
        width: 80%;
    }
    .mensaje {
        position: relative;
        float: left;
        width: 100%;
    }

}