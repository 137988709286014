.imgBackground{
    background-image: url("../../../assets/images/reporteoBack.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 200px;
    background-position: center;
}


@media (max-width: 1199.98px) {
    .imgBackground {
        background-position-x: center;
        background-position-y: bottom;
    }
  }
