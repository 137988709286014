.color {
	color: #3581c2;
}

.buttonContainer button {
	border-radius: 50px;
	background-color: #3581c2;
	color: #fff;
}

.buttonContainer button i {
	margin-left: 30px;
}

.captchaContainer iframe {
	font-family: 'Montserrat', sans-serif !important;
    font-size: 14px;
    font-weight: 600 !important;
}

.input,
.captchaContainer,
.buttonContainer,
.copyright {
	margin: 5px;
}

.div_error {
	margin-bottom: 15px;
	width: 100%;
}
.error {
	color: #ffffff;
	font-weight: bold;
}
.enviado {
	font-weight: bold;
	color: #ffffff;
}

.buttonContainer button:disabled {
	border-radius: 50px;
	background-color: rgb(179, 179, 179);
	color: #fff;
}

.leftTexto {
    float: left;
    margin-right: 5px;
}
.link {
	color: #fff;
}

.link:hover {
	text-decoration: underline;
}

.link:active {
	transform: scale(0.9);
}
