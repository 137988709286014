.tabsContainer {
    border: 1px solid #eaedfa;
    border-radius: 4px;
    background-color: #ffffff;
    height: 64px;
    padding: 24px 21px 22px 26px;

  }

  .titulo_25pct {
    display: inline-block;
    width: 25%;
  }
  .titulo_50pct {
    display: inline-block;
    width: 50%;
  }
  
  .titulo_75pct{
    display: inline-block;
    width: 75%;
  }


