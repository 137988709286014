
.textos_html ol, .textos_html ul {
    list-style: inherit;
    margin-top: 10px;
    padding-left: 35px;
  }
  
  .textos_html ul li {
    list-style: inherit;
    margin-top: 10px;
  }
  .textos_html ol li {
    list-style: auto;
    margin-top: 10px;
  }

  .divImagen{
    float: left;
    width: 125px;
    height: 125px;
    background-size: contain;
}

