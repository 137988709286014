.imagen {
	width: 25%;
	height: 270px;
	float: left;
}

.imagen a,
.imagen a img {
	position: relative;
	height: 100%;
	width: 145%;
	left: -20px;
}

.imagen a img {
	background-size: cover;
}
.fondoDegradado {
	position: absolute;
	height: 270px;
	left: -20px;
	/* background: aqua; */
	width: 100%;
	top: -256px;
	background: #03114352;
	z-index: 1;
}

.tituloImagen {
	position: absolute;
	height: 270px;
	left: 0;
	/* background: aqua; */
	width: 100%;
	top: -256px;
	text-align: center;
	z-index: 2;
}

.tituloImagen span {
	position: absolute;
	top: 0;
	bottom: 0;
	margin-top: auto;
	margin-bottom: auto;
	height: 90px;
	left: -61px;
	right: 0;
	margin-left: auto;
	width: min-content;
	width: -moz-min-content;
	margin-right: auto;
	text-transform: uppercase;
}

.imagen1 img,
.imagen1 .fondoDegradado {
	clip-path: polygon(0% 0, 100% 0%, 69% 100%, 0% 100%);
}
.imagen2 img,
.imagen2 .fondoDegradado {
	clip-path: polygon(31% 0, 100% 0%, 69% 100%, 0% 100%);
}
.imagen3 img,
.imagen3 .fondoDegradado {
	clip-path: polygon(31% 0, 100% 0%, 69% 100%, 0% 100%);
}
.imagen4 img,
.imagen4 .fondoDegradado {
	clip-path: polygon(31% 0, 100% 0%, 100% 100%, 0% 100%);
}

/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .imagen {
		width: 100% !important;
	}

	.imagen1 img,
	.imagen1 .fondoDegradado,
	.imagen3 img,
	.imagen3 .fondoDegradado {
		/* clip-path: polygon(0% 0, 100% 0%, 69% 100%, 0% 100%); */
		clip-path: inherit !important;
		width: 100% !important;
		left: 0;
	}
	.imagen2 img,
	.imagen2 .fondoDegradado,
	.imagen4 img,
	.imagen4 .fondoDegradado {
		/* clip-path: polygon(31% 0, 100% 0%, 100% 100%, 0% 100%); */
		clip-path: inherit !important;
		width: 100% !important;
		left: 0;
	}

	.imagen a,
	.imagen a img {
		position: relative;
		height: 100%;
		width: 100% !important;
		left: 0px;
	}
	.tituloImagen span {
		left: 0;
	}
}

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
	.imagen {
		width: 50%;
	}

	.imagen1 img,
	.imagen1 .fondoDegradado,
	.imagen3 img,
	.imagen3 .fondoDegradado {
		/* clip-path: polygon(0% 0, 100% 0%, 69% 100%, 0% 100%); */
		clip-path: inherit !important;
		width: 100% !important;
		left: 0;
	}
	.imagen2 img,
	.imagen2 .fondoDegradado,
	.imagen4 img,
	.imagen4 .fondoDegradado {
		/* clip-path: polygon(31% 0, 100% 0%, 100% 100%, 0% 100%); */
		clip-path: inherit !important;
		width: 100% !important;
		left: 0;
	}

	.imagen a,
	.imagen a img {
		position: relative;
		height: 100%;
		width: 100% !important;
		left: 0px;
	}
	.tituloImagen span {
		left: 0;
	}
}

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
}
