.black{
    color: #696A6D;
}

.white{
    color: #fff;
}

.btnRS{
    background: #00aeef;
    width: 15px;
    height: 100px;
    position: absolute;
    right: -14px;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 0px 5px 5px 0px;
    display: none;
}
.btnRS:hover{
    background: #0090ba;
}
.btnRS:active{
    background: #00aeef;
}

.arrow{
    color: #FFF;
    width: 10px;
    height: 18px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 1px;
}
.contenedor_social{
    background: #4B4E53BA;
    position: fixed;
    z-index: 1019;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    /* height: fit-content; */
    /* height: -moz-fit-content; */
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 10px;
    height:325px;
    border-radius: 0px 5px 5px 0px;
}

.esconder{
    transform: translateX(-65px);
	transition: all 0.5s ease-out;
}

.mostrar{
	transition: all 0.5s ease-out;
}
.botones_list{
    /* float: right; */
}

.no_padding{
    padding: 0 !important;
}


.icon {
	position:relative;
	text-align:center;
	width:0px !important;
	height:0px !important;
	padding:19px;
	border-top-right-radius: 	19px;
	border-top-left-radius: 	19px;
	border-bottom-right-radius: 19px;
	border-bottom-left-radius: 	19px; 
	-moz-border-radius: 		19px 19px 19px 19px;
	-webkit-border-radius: 		19px 19px 19px 19px;
	-khtml-border-radius: 		19px 19px 19px 19px; 	
    color:#FFFFFF;
    display: grid;
}
.icon i {
	font-size:18px;
	position:absolute;
	left:9px;
	top:10px;
}
.icon.social {
	float: inherit;;
	margin:10px 5px 10px 5px;
	cursor:pointer;
    color:#FFFFFF;
    border:1px solid #ffffff;
	transition: 0.5s;
	-moz-transition: 0.5s;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s; 	
}
.icon.social:hover {
    color:#FFFFFF;
    border:1px solid #ffffff;
	transition: 0.5s;
	-moz-transition: 0.5s;
	-webkit-transition: 0.5s;
	-o-transition: 0.5s;
	
}
.icon.social.fb i {
	left:13px;
	top:10px;
}
.icon.social.fb:hover {
background: #3b5998;
}

.icon.social.fb:hover i {
    color:#FFFFFF;

}
.icon.social.tw i {
	left:10px;
}
.icon.social.tw:hover {
    background: #55acee;
    }
    .icon.social.tw:hover i {
        color:#FFFFFF;

    }
.icon.social.in i {
	left:11px;
}
.icon.social.in:hover {
    background: #007bb5;
    }
    .icon.social.in:hover i {
        color:#FFFFFF;

    }
.icon.social.youtube i {
    left:9px;
	top:10px;
    
}
.icon.social.youtube:hover i {
    color:#FFFFFF;

}
.icon.social.youtube:hover {
    background: #bb0000;
    }
.icon.social.insta i {
	left:11px;
}
.icon.social.insta:hover {
    background: #125688;
    }
    .icon.social.insta:hover i {
        color:#FFFFFF;

    }
.icon.social.mail i {
	left:9px;
}
.icon.social.mail:hover {
    background: #dd4b39;
    }
    .icon.social.mail:hover i {
        color:#FFFFFF;

    }
    .icon.black{
        border: 1px solid #EAEDFA;
    }


    /* // Small devices (landscape phones, 576px and up) */
@media (max-width: 767.99px) {
    .btnRS{
        display: inherit;
    }
}
    
