.item_container{
    border: 1px solid #EAEDFA;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 20px;
}

.img_container img{
    width: 120px;
    height: 120px;
}

.footer_container{
    background-color: #00ADEF;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.footer_container p{
    font-size: 16px;
    color: #fff;
    margin-bottom: 0;
    padding: 5px;
}
.main_subtitle h2{
    display: inline;
    margin-left: 20px;
}

.slash{
    font-size: 30px;
    font-weight: bold;
    color: #00aeef;
}

.casaActiva{
    border: 1px solid #00aeef;
}