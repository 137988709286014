.imgContainer{
    border: 1px solid #00AEEF;
    padding: 10px 0 10px 17px;
    border-radius: 50%;
    position: relative;
    right: 15px;
}

.textSection{
    margin: 50px 0;
}

.imgContainer i{
    color: #00AEEF;
    font-size: 23px;
}

.phone,
.email{
    color: #00AEEF
}