.modal {
	z-index: -500;
	width: 100%;
	transition: all 0.3s ease-out;
	background-color: #00aeef;
	padding: 30px 40px;
	border-radius: 4px;
	position: absolute;
}

.hidden {
	transform: translateY(-100vh);
	opacity: 0;
}

.show {
	z-index: 1024;
	position: fixed;
	opacity: 1;
	width: 80%;
	position: fixed;
	left: 6%;
	top: 8%;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	max-height: 80%;
    overflow: auto;
    border: 1px solid #b9b9b9;
}

.close_button_container {
	position: relative;
	top: 8px;
}

.close_button_container i {
	font-size: 20px;
	color: #fff;
	cursor: pointer;
	z-index: 500;
}

.modal_header {
	position: relative;
	bottom: 20px;
	left: 20px;
}

.modal_body input {
}
