@media (max-width: 9999px) { 
    .contenedor {
		height: 420px;
	}
 }
@media (max-width: 1900px) {
	.contenedor {
		height: 400px;
	}
}
@media (max-width: 1800px) {
	.contenedor {
		height: 370px;
	}
}
@media (max-width: 1700px) {
	.contenedor {
		height: 320px;
	}
}
@media (max-width: 1200px) {
	.contenedor {
		height: 280px;
        padding-top:10px
	}
}
@media (max-width: 600px) {
	.contenedor {
		height: 250px;
	}
}