.backBlue {
  background: #092a45;
}

.esgExperienceBackground, .esgExperienceBackgroundGenero {
  background-image: url("../../../assets/images/fondoMundo.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.esgExperienceBackgroundGenero {
  background-image: url("../../../assets/images/equidad.jpg");
}
.backgroundFilter{
  height: 100%;
  width: 100%;
  background: #262626c4;
  position: absolute;
}

.esgContainer :global(.hojaBlanca) {
  position: absolute;
  top: -22px;
}
.esgContainer :global(.hojaBlanca):before {
  content: "";
  background-image: url("../../../assets/icons/sustentable/hoja-blanca.png");
  background-size: 100% 100%;
  display: inline-block;

  height: 60px;
  width: 60px;

  position: relative;
  /* top: 5px; */
}
.esgContainer :global(.check):before {
  content: "";
  background-image: url("../../../assets/icons/quiz.png");
  background-size: 100% 100%;
  display: inline-block;
  height: 60px;
  width: 60px;
  position: relative;
}

.esgContainer :global(.tituloSg) {
  padding: 0 !important;
}
.contenedorBotones :global(.Box__boxContainer__10Ud0) {
  background-color: #FFF;
}
.contenedorBotones{
  padding: 15px;
  z-index: 1;
}
.esgContainer :global(.cuadroEsg),
.esgContainer :global(.cuadroEsgDescanso) {
  padding: 30px;
  padding-top: 50px;
  min-height: 400px;
  background: #092a45;
  background-size: cover;
}
.esgContainer :global(.textoCuadro) {
  height: 100%;
  width: 100%;
  padding: 50px;
  padding-top: 15px;
}

.esgContainer :global(.colorAzul) {
  color: #00aced !important;
}
.esgContainer :global(.verticalSpan) {
  display: inline-block;
  vertical-align: middle;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  height: fit-content;
}

.esgContainer :global(.cuadroEsgDescanso) {
  height: 500px;
}
.esgContainer :global(.transparente) {
  background-color: transparent !important;
}
.esgContainer :global(.verde) {
  background-color: #176937 !important;
}


.esgContainer ol, .esgContainer ul {
  list-style: inherit;
}