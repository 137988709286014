/* Estilos para modelo #2 */

.toggle_button_container_model_one {
	width: 100%;
	background-color: #fafbff;
	padding: 8px 0 0 0;
	border: 1px solid #eaedfa;
	border-radius: 4px 0 0 4px;
}

.toggle_button_container_model_one i {
	margin-right: 10px;
}

.toggle_button_container_model_one,
.filters_selected_container_model_one,
.search_button_container_model_one,
.filter_container_outside_model_one {
	height: 60px;
}

.search_button_container_model_one {
	width: 100%;
	padding: 8px 0 0 0;
	border-top: 1px solid #eaedfa;
	border-right: 1px solid #eaedfa;
	border-bottom: 1px solid #eaedfa;
	border-radius: 0 4px 4px 0;
	background-color: #fafbff;
}

.toggle_button_container_model_one button,
.search_button_container_model_one button {
	color: #00aeef;
	outline: none;
	box-shadow: none !important;
}

.filters_selected_container_model_one {
	border-top: 1px solid #eaedfa;
	border-right: 1px solid #eaedfa;
	border-bottom: 1px solid #eaedfa;
}

.no_col_padding_model_one {
	padding: 0;
}

.filter_container_outside_model_one {
	width: 100%;
	overflow-y: none;
	overflow-x: auto;
	white-space: nowrap;
	color: #00aeef;
	padding-top: 15px;
}

.filters_container_model_one {
	width: 100%;
	background-color: #fafbff;
	border-left: 1px solid #eaedfa;
	border-right: 1px solid #eaedfa;
	border-bottom: 1px solid #eaedfa;
	border-radius: 0 0 4px 4px;
}

.filter_container_model_one {
	display: inline;
	padding: 5px 7px 7px 10px;
	margin: 6px 5px 0;
	border: 1px solid #eaedfa;
	border-radius: 50px;
}

.filter_container_model_one span {
	color: #00aeef;
}

.search_input_container_model_one input {
	padding-left: 20px;
	height: 58px;
	border: 0;
	outline: none;
	box-shadow: none !important;
}

.filters_section_model_one {
	padding: 30px 0 20px 0;
}

.no_borders_filter_button_model_one {
	border-bottom-left-radius: 0;
}

.no_border_search_button_model_one {
	border-bottom-right-radius: 0;
}

.filter_icon_button_model_one {
	margin-left: 8px;
	cursor: pointer;
	height: 15px;
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
}

.filter_text_model_one {
	font-size: 13px;
}

.hidden {
	display: none;
}

.button_model_one {
	border: 1px solid #eaedfa;
	color: #00aeef;
	width: 90% !important;
	margin: 0 auto;
}

.button_model_one i {
	margin-right: 10px;
}

.autoCompleteContainer {
	position: relative;
	bottom: 15px;
	z-index: 5000 !important;
}

.autoCompleteContainer li:hover {
	cursor: pointer;
}

.autoCompleteContainer li:first-child {
	border-top: 0;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.filterContainer {
	padding: 8px;
	margin-right: 5px;
	border: 1px solid #eaedfa;
	border-radius: 50px;
}

.filterContainer span {
	color: #00aeef;
}

.filterIconButton {
	margin-left: 10px;
	cursor: pointer;
}

.filterSelectedContainer,
.selectedOptionContainer {
	height: 65px;
}

.selectedOptionContainer {
	width: 100%;
	overflow-y: none !important;
	overflow-x: auto;
	white-space: nowrap;
	color: #00aeef;
	padding: 18px 0 0 15px;
}
.errorContainer p {
	color: #e22929;
}

.button_model_one:disabled {
	background: #fafbff;
	color: rgb(79, 79, 79);
}

.padding_izquierdo {
	padding-left: 20px !important;
}

.fechaInicio,
.fechaFin {
	outline: none;
	box-shadow: none !important;
	border: 1px solid #eaedfa;
	border-radius: 4px;
	padding: 6px;
}

.fechaInicio {
	border-right: 1px solid #eaedfa;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.fechaFin {
	border-left: 1px solid #eaedfa;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}


.separacionRadios{
    margin-right: 50px;
    float: left;
}

.top{
	width: 112px;
	height: 48px;
	margin-right: 10px;
	margin-bottom: 10px;
	margin-top: 10px;
	text-align: center;
	float: left;
	border-radius: 6px;
}

.top_fecha {
	background: #4A4F54;
  }
:global(.top_1) {
	background: #00aeef;
  }
  :global(.top_2) {
	background: #194CD6;
  }
  :global(.top_3) {
	background: #EF0053;
  }
  :global(.top_4) {
	background: #6187ED;
  }
  :global(.top_5) {
	background:  #072E3C;
  }
  :global(.top_6) {
	background: #af05e8;
  }
  :global(.top_7) {
	background: #f26900;
  }
  :global(.top_8) {
	background: #226022;
  }
  :global(.top_9) {
	background: #ee06ff;
  }
  :global(.top_10) {
	background: #49ba03;
  }

  :global(.top_11) {
	background: #ff0000;
  }

  :global(.top_12) {
	background: #ddca22;
  }

  :global(.top_13) {
	background: #1f4474;
  }

  :global(.top_14) {
	background: #804925;
  }

  :global(.top_15) {
	background: #ac3880;
  }

  :global(.top_16) {
	background: #62d488;
  }

  :global(.top_17) {
	background: #fa8888;
  }

  :global(.top_18) {
	background: #7303db;
  }

  :global(.top_19) {
	background: #ba033a;
  }

  :global(.top_20) {
	background: #636e72;
  }

  :global(.top_21) {
	background: #225702;
  }

  :global(.top_22) {
	background: #585003;
  }

  :global(.top_23) {
	background: #03e9fa;
  }

  :global(.top_24) {
	background: #6829fa;
  }

  :global(.top_25) {
	background: #ff4181;
  }

  :global(.top_26) {
	background: #263258;
  }

  :global(.top_27) {
	background: #fd7d06;
  }

  :global(.top_28) {
	background: #18b933;
  }

  :global(.top_29) {
	background: #f4f813;
  }

  :global(.top_30) {
	background: #e279dd;
  }

  :global(.top_31) {
	background: #21fca8;
  }

  :global(.top_32) {
	background: #756da1;
  }

  :global(.top_33) {
	background: #dda863;
  }

  :global(.top_34) {
	background: #35581f;
  }

  :global(.top_35) {
	background: #476369;
  }


  .vertical_div{
    width: 112px;
    display: table;
  min-height: 48px;
}

.vertical{
    display: table-cell;
    vertical-align: middle;
	line-height: normal;
	position: relative;
	padding: 2px;
}

.derecha_close{
	position: absolute;
	right: 13px;
}