.hidden {
    display: none;
}

.claveContainer {
    border: 1px solid #eaedfa;
    border-radius: 4px;
}

.textContainer {
    padding: 18px 10px 0 15px;
}

.buttonContainer {
    padding: 18px 10px 0 25px;
}

.textContainer,
.buttonContainer {
    height: 60px;
}

.buttonContainer {
    background-color: #fafbff;
    color: #00aeef;
    border-left: 1px solid #eaedfa;
}

.buttonContainer a {
    color: #00aeef;
    text-decoration: none;
}

.buttonContainer a,
.buttonContainer span {
    height: 60px !important;
    width: 100%;
}

.buttonContainer span:hover {
    cursor: pointer;
}

.buttonContainer span i {
    margin-left: 10px;
    font-size: 14px;
}

.tabsContainer {
    border: 1px solid #eaedfa;
    border-radius: 4px;
    background-color: #ffffff;
    padding: 24px 21px 22px 26px;
}

.vertical_div {
    display: table;
    height: 100%;
    width: 100%;
}

.vertical_content {
    display: table-cell;
    vertical-align: middle;
    line-height: normal;
}