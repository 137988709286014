.TVChartContainer {
  height: 400px;
}

.btn {
  background: #FFFFFF;
  color: #00aeef !important;
  border: 1px solid #EAEDFA;
  border-radius: 6px;
  height: 48px;
  width: 48px;
  font-size: 16px;
  line-height: 16.69px;
  font-style: normal;
  font-weight: bold;
  white-space: nowrap;
  margin-right: 15px;
  float: left;
  cursor: pointer;
}

.btnActivo{
  background: #00aeef;
  color: #ffffff !important;
  border: 1px solid #EAEDFA;
  border-radius: 6px;
  height: 48px;
  width: 48px;
  font-size: 16px;
  line-height: 16.69px;
  font-style: normal;
  font-weight: bold;
  white-space: nowrap;
  margin-right: 15px;
  float: left;
}

.btn div, .btnActivo div{
  width: fit-content;
  width: -moz-fit-content;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
}

.btn:hover{
  background: #00aeef;
  color: #ffffff !important;
  border: 1px solid #EAEDFA;
  transform: scale(1.2);
}

.btnActivo:hover{
  background: #FFFFFF;
  color: #00aeef !important;
  border: 1px solid #EAEDFA;
}

.btn:active{
  background: #FFFFFF;
  color: #00aeef !important;
  border: 1px solid #EAEDFA;
}