.relativo {
    position: relative;
}

.fondo_cuadricula1 {
    height: 595px;
    width: 768px;
    background-image: url("../../../assets/images/a.svg");
    background-position-x: center !important;
    position: relative;
    border-radius: 6px;
    background-size: contain;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    -ms-background-size: contain;
    background-size: contain;
    border: 1px solid #ffffff;
    padding-left: 0;
    left: -10px;
    background-repeat: no-repeat !important;
    background-position-y: center !important;
}

.fondo_cuadricula2 {
    height: 450.2px;
    width: 100%;
    background-image: url("../../../assets/images/a.svg");
    background-position-x: 29.5157px !important;
    background-size: contain;
    position: relative;
    border-radius: 6px;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    -ms-background-size: contain;
    background-size: contain;
    border: 1px solid #ffffff;
    padding-left: 0;
    left: -10px;
    /* background-attachment: fixed !important; */
}

.fondo_cuadricula3 {
    height: 474px;
    width: 514px;
    background-image: url("../../../assets/images/a.svg");
    background-position-x: 16.5157px;
    background-size: contain;
    position: relative;
    border-radius: 6px;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    -ms-background-size: contain;
    background-size: contain;
    padding-left: 0;
    left: -10px;
    /* background-attachment: fixed !important; */
}

.fondo_cuadricula4 {
    height: 474px;
    width: 768px;
    background-image: url("../../../assets/images/a.svg");
    background-position-x: center !important;
    position: relative;
    border-radius: 6px;
    background-size: contain;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    -ms-background-size: contain;
    background-size: contain;
    border: 1px solid #ffffff;
    padding-left: 0;
    left: -10px;
    background-repeat: no-repeat !important;
    background-position-y: center !important;
}

.fondo_cuadricula3 > div{
    width: 50% !important;
}

.fondo_cuadricula_tipo_2 {
    height: 474px;
    width: 768px;
    background-image: url("../../../assets/images/a.svg");
    background-position-x: center !important;
    background-size: contain;
    position: relative;
    border-radius: 6px;
    -ms-background-size: contain;
    background-size: 491px;
    background-position-x: 47%;
    background-repeat: no-repeat;
    border: 1px solid #ffffff;
    background-attachment: initial;
}

.fondo_cuadricula2 {
    /* height: 100%; */
    width: 768px;
    border: 0px;
}

.sinFondo{
    background-image: none !important;
}
.cuadriculaSubtitulo > div{

}
/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.99px) {
    .fondo_cuadricula2 {
        width: 100%;
        height: fit-content;
        background-repeat: round;
    }
    .fondo_cuadricula1 {
        width: 100%;
        height: fit-content;
        background-repeat: round;
    }
    .fondo_cuadricula4, .fondo_cuadricula3 {
        width: 100%;
        height: fit-content;
        background-repeat: round;
    }
    .fondo_cuadricula_tipo_2 {
        width: 100%;
        background-position-x: center !important;
    }
    .fondo_cuadricula3 > div, .fondo_cuadricula3{
        width: 100% !important;
    }
    .cuadriculaSubtitulo > div{
        height: 180px !important;
    }
    .fondo_cuadricula3 > div{
        height: 130px;
    }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.99px) {
    .fondo_cuadricula2 {
        width: 100%;
        height: fit-content;
        background-repeat: round;
    }
    .fondo_cuadricula1, .fondo_cuadricula3  {
        width: 100%;
        height: fit-content;
        background-repeat: round;
    }
    .fondo_cuadricula4 {
        width: 100%;
        height: fit-content;
        background-repeat: round;
    }
    .fondo_cuadricula_tipo_2 {
        width: 100%;
        background-position-x: center !important;
    }
    .fondo_cuadricula3 > div, .fondo_cuadricula3{
        width: 100% !important;
    }
    .cuadriculaSubtitulo > div{
        height: 182px !important;
    }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.99px) {
    
    .fondo_cuadricula1 {
        background-position-x: center !important;
        background-repeat: no-repeat;
        background-position-y: center;
        width: 100%;
    }
    .fondo_cuadricula4, .fondo_cuadricula3  {
        background-position-x: center !important;
        background-repeat: no-repeat;
        background-position-y: center;
        width: 100%;
        height: 470px;
    }
    
    
    .fondo_cuadricula2 {
        height: 100%;
        width: 100%;
        background-repeat: round;
        background-position-x: center !important;
    }
    .fondo_cuadricula_tipo_2 {
        width: 100%;
        background-position-x: center !important;
    }
    .fondo_cuadricula3 > div, .fondo_cuadricula3{
        width: 100% !important;
    }
    .cuadriculaSubtitulo > div{
        height: 195px !important;
    }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.99px) {
    .fondo_cuadricula2 {
        height: 100%;
        width: 768px;
        /* background-repeat: round; */
        background-position-x: center !important;
    }
    
    .fondo_cuadricula1 {
        height: 661px;
        width: 768px;
        background-position-x: center !important;
    }
    .fondo_cuadricula4 {
        height: 474px;
        width: 768px;
        background-position-x: center !important;
    }
    
    .cuadriculaSubtitulo > div{
        height: 255px !important;
    }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .cuadriculaSubtitulo > div{
        height: 250px !important;
    }
}
