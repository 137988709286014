.divImagen {
  width: 100%;
  height: 150px;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -ms-background-size: cover;
  overflow: hidden;
  background-position: center;
}

.contenedor {
  background-color: #fff;
  min-height: 350px;
  width: 100%;
  cursor: pointer;
  position: relative;
}

.sinImagen {
  background: #e2e2e2;
  background-size: 105%;
  background-position-x: 72px;
  background-position-y: 50px;
  background-repeat: no-repeat;
}

.contenedor:hover {
  transform: scale(1.1);
}
.contenedor:hover .boton span {
  color: #00aeef !important;
}

.contenedor .divImagen.wide {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
}

.contenedor .divImagen.tall {
  width: 100%;
  max-height: 100%;
  max-width: 100%;
}

.descripcion,
.titulo {
  padding-top: 10px;
}
.fecha {
  margin-top: 5px;
}
.descripcion span {
  text-align: justify;
}

.boton {
  position: absolute;
  bottom: -25px;
  right: 0;
}
.boton span {
}
