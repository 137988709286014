.tabsContainer {
    border: 1px solid #eaedfa;
    border-radius: 4px;
    background-color: #ffffff;
    height: 64px;
    padding: 24px 21px 22px 26px;

  }

.footer{
    background-color: #00AEEF;
    height: 512px;
    padding: 1.7rem 1.7rem;
}

.encabezado_footer {
    border-radius: 4px;
/*     padding: 24px 21px 22px 26px; */
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
    width: 100%;
}

.titulo_footer {
    border-radius: 4px;
    /* padding: 24px 21px 22px 26px; */
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
    width: 100%;
}
.titulo_body {
  border-radius: 4px;
  /* padding: 24px 21px 22px 26px; */
  color: #ffffff;
  width: 100%;
}

.table_footer {
    width: 100%;
}

.tr_footer {
    border-radius: 4px;
    /* padding: 24px 21px 22px 26px; */
    color: #ffffff;
    width: 100%;
}
.tr_header {
  border-radius: 4px;
  /* padding: 24px 21px 22px 26px; */
  color: #ffffff;
  width: 100%;
}

.titulo_25pct {
    display: inline-block;
    width: 25%;
  }
  .titulo_50pct {
    display: inline-block;
    width: 50%;
  }
  
  .titulo_75pct{
    display: inline-block;
    width: 75%;
  }

  .titulo_40pct {
    display: inline-block;
    width: 40%;
  }
  .titulo_60pct {
    display: inline-block;
    width: 60%;
  }

  .titulo_ZIP {
    color: gray;
  }
  .titulo {
    color: #00aeef;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    width: 100%;
    background-color: #FAFBFF;
  }

.tr_footer:hover{
  background-color: #3581C2;
}

.margin_left{
  margin-left: 30px;;
}


.vertical_div{
    position: relative;
    display: table;
	height:100px;
}

.vertical{
    display: table-cell;
	vertical-align: middle;
}
.vertical_img{
    display: table-cell;
    vertical-align: middle;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
}

.card_header {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  height: 64px;
  padding: .75rem 1.25rem;

}

.card_header2 {
  background: #FAFBFF;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  height: 64px;
 
}