.productoContainer {
  border: 1px solid;
  min-height: 300px;
  height: 100%;
  max-width: 300px;
  border-radius: 6px;
  margin: auto;
  margin-bottom: 20px;
}

.type2 .productoContainer{
  background: #FFF;
  border-radius: 10px;
}
.margin_curso{
  margin-bottom: 30px;
}

.imagenContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imagenContainer img {
  width: 100%;
  border-radius: 6px 6px 0 0;
}

.detailContainer {
  padding: 6px;
  padding-bottom: 10px;
}

.detail {
  margin-top: 10px;
  text-align: left;
  margin-bottom: 50px;
}

.ribbon {
  position: absolute;
  left: 11px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}
.type2 .ribbon{
  left: 50px;

}

.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 104px;
  display: block;
  background: #00aeef;
  box-shadow: 0 3px 5px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 20px;
  left: -22px;
}
.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid #002e72;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #002e72;
}
.ribbon span::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid #002e72;
  border-bottom: 3px solid transparent;
  border-top: 3px solid #002e72;
}

.cart {
  position: absolute;
  right: 10px;
}

.cart i {
  font-size: 30px;
  color: #00aeef;
}

.cart:hover i {
  transform: scale(1.5);
}

.containerFooter {
  min-height: 75px;
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.priceItm {
  position: absolute;
  right: 45px;
}
.copy {
  cursor: copy;
}
.pointer {
  cursor: pointer;
}
.pointer:hover {
  transform: scale(1.2);
}
.pointer:active {
  cursor: grabbing;
  transform: scale(1);
}
.footer{
  position: relative;
  width: 275px;
}

.disabled, .disabled i {
  transform: scale(1) !important;
  color: #9b9b9b !important;
  cursor: inherit !important;
  top: 10px !important;
}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 1299.98px) {
  .type2 .ribbon{
    left: 12%;
  }
}
/* // Medium devices (tablets, less than 992px) */
@media (max-width: 1249.98px) {
  .type2 .ribbon{
    left: 10%;
  }
}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 1199.98px) {
  .type2 .ribbon{
    left: 10px;
  }
}
/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
}
/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {

  .ribbon,  .type2 .ribbon{
    position: relative;
    left: -5px;
  }

  .containerFooter{
    width: 60%;
    right: 85px;
  }
}
/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .containerFooter{
    width: 275px;
    right: inherit;
    padding-left: 15px;
  }
  .footer{
    width: 100%;
  }

}
