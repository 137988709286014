.fondo {
	height: unset;
	height: -moz-max-content;
	/* height: -webkit-max-content; */
	min-height: 440px;
	background: #4b4e53f2;
	position: relative;
	width: 100%;
	z-index: 1;
	padding-top: 60px;
	display: inline-block;
}
.fondo2 {
	height: unset;
	height: -moz-max-content;
	/* height: -webkit-max-content; */
	background-size: cover;
	min-height: 440px;
	background: #4b4e53;
	position: absolute;
	width: 100%;
	z-index: 1;
	padding-top: 0;
	display: inline-block;
	position: absolute;
	width: 100%;
	height: 100%;
}
.body {
	/* margin-bottom: 200px; */
}

.fondo2 {
	height: min-content;
	height: -moz-min-content;
	background: #696a6d;
	position: relative;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	background-size: cover;
	/* padding-top: 45px; */
	/* padding-bottom: 45px; */
}

.row_margen_2 {
	margin-top: 34px;
	padding: 0;
	position: relative;
}
.sin_padding {
	padding: 0;
}

.img_cencor {
	position: relative;
	width: 188px;
}

.centrado {
	position: absolute;
	top: 5px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.derecha {
	position: absolute;
	float: right;
	right: 0;
}

.link {
	position: relative;
}
.link_blanco {
	color: #ffffff;
	white-space: nowrap;
	font-size: 12px;
	text-align: center;
}
.link_blanco2 {
	color: #ffffff;
	font-size: 12px;
	text-align: center;
}

.letras_cencor {
	color: #ffffff;
	white-space: nowrap;
	font-size: 10px;
	position: relative;
	left: 57px;
	top: 9px;
	font-family: montserrat;
}

:global(.row) {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: 0px;
	margin-left: 0px;
}

/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.99px) {
	.derecha {
		position: absolute;
		float: right;
		right: 0;
		bottom: -11px;
	}
	.fondo,
	.fondo2 {
		min-height: 240px;
		bottom: 0px;
	}
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.99px) {
	.derecha {
		position: relative;
		float: inherit;
		top: 0px;
		width: fit-content;
		right: 0;
		left: 0;
		margin-right: auto;
		margin-left: auto;
	}

	.fondo,
	.fondo2 {
		min-height: 240px;
		bottom: 0px;
	}
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.99px) {
	.fondo,
	.fondo2 {
		min-height: 240px;
		bottom: 0px;
    }
    .fondo2 {
		background-size: 834px;
		background-repeat: no-repeat;
		background-position-x: 130%;
	}
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.99px) {
	.derecha {
		position: relative;
		float: inherit;
		top: 0px;
		width: fit-content;
		right: 0;
		left: 0;
		margin-right: auto;
		margin-left: auto;
	}
	.fondo2 {
		background-size: 834px;
		background-repeat: no-repeat;
		background-position-x: 130%;
	}
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	.derecha {
		position: absolute;
		float: right;
		top: -55px;
		width: fit-content;
		right: 0;
		margin-left: auto;
	}

	
	.fondo2 {
		background-size: 834px;
		background-repeat: no-repeat;
		background-position-x: 130%;
	}
}
@media (min-width: 1400px) {
	.derecha {
		position: absolute;
		float: right;
		top: -55px;
		width: fit-content;
		right: 0;
		margin-left: auto;
	}

	.body {
		/* margin-bottom: 150px; */
	}
	.fondo2 {
		background-size: 834px;
		background-repeat: no-repeat;
		background-position-x: 100%;
	}
}
