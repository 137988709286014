.btnBackDiv{
    margin-bottom: 10px;
    user-select: none;
    cursor: pointer;
    width: 100px;
    color: #00aeef;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.btnBackDiv i{
    margin-right: 5px;
}

.btnBackDiv:hover, .btnBackDiv i:hover{
   color: #212529 ;
   transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}


.btnBackDiv:active, .btnBackDiv i:active{
    text-decoration: none;
    text-decoration-color: #00aeef;
    color: #00aeef;
    transition: color .0s ease-in-out,background-color .0s ease-in-out,border-color .0s ease-in-out,box-shadow .0s ease-in-out;
 }