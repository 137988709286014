.main_title {
  font-size: 42px;
}

.main_subtitle {
  font-size: 30px;
  margin: 20px 0 40px 0;
}

.main_subtitle h2 {
  display: inline;
  margin-left: 20px;
}

.text_container {
  text-align: justify;
}

.text_container,
.imgContainer {
  margin-top: 30px;
}

.video_container {
  width: 100%;
  /* height: 500px; */
}

.video_container iframe {
  width: 100%;
  height: 300px;
}
/* // Extra small devices (portrait phones, less than 576px) */
@media (min-width: 575.99px) {
  .video_container iframe {
    height: 300px;
  }
}
/* // Medium devices (tablets, less than 992px) */
@media (min-width: 991.99px) {
  .video_container iframe {
    height: 400px;
  }
}

/* // Small devices (landscape phones, less than 768px) */
@media (min-width: 767.99px) {
  .video_container iframe {
    height: 500px;
  }
}
