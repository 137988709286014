.margen {
  margin-right: 18px;
  width: 278px !important;
  min-height: 500px;
  height: auto;
}

.centrado {
  width: 225px !important;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.titulo {
  width: 100%;
  height: 125px;
  background: #ffffff;
  padding-left: 20px;
  border: 1px solid #EAEDFA;
  border-radius: 6px 6px 0px 0px;
  border-bottom: 1px solid #00aeef;
}

.imagen_titulo {
  width: 100%;
  height: 70px;
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  -ms-background-size: contain;
  background-size: contain;
  background-position-y: center;
  background-position-x: center;
}

.gris_medio {
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 20px;
}

.contenido_inversion {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background: #ffffff;
  height: 100%;
  min-height: 456px;
  border: 1px solid #EAEDFA;
  border-radius: 0px 0px 6px 6px;
}

.subtitulo {
  margin-right: 10px;
  width: 100%;
  min-width: 181px;
  float: left;
  display: inline-flex;
}



.col_flex {
  display: inline-block;
  border-bottom: 1px solid #EAEDFA;
  margin-top: 14px;
}

.col_flex div {
  margin-bottom: 10px;

}

.col_flex2 {
  display: inline-block;
  border-bottom: 0px solid #EAEDFA;
  margin-top: 14px;
}

.col_flex2 div {
  margin-bottom: 10px;

}

.dato_variable{
  max-width: 268px;
}


.hr_vacio {
  width: 100%;
  max-width: 43px;
  background-color: black;
  position: absolute;
  right: 0;
  margin-top: 11px;
}


.nota{
  /* white-space: nowrap; */
}