.main_subtitle{
    font-size: 30px;
    margin: 20px 0 40px 0;
}

.main_subtitle h2{
    display: inline;
    margin-left: 20px;
}

.slash{
    font-size: 30px;
    font-weight: bold;
    /* color: #00aeef; */
    color: #32cd32;
}

.text_container{
    text-align: justify;
}

.text_container_blue{
    text-align: justify;
    background-color: #00aeef;
    border-radius: 20px;
    padding: 10px;
}

.item_list li:first-child {
    border-left: 1px solid #eaedfa;
}

.list_consejo li:first-child,
.list_comite li:first-child  {
    border-left: 1px solid #eaedfa;
}

.margin{
    margin-top: 30px;
}

.margin_leaf{
    font-size: 30px;
    margin: 20px 0 40px 0;
    text-align: right;
}


