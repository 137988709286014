.fondo {
  background: #fafbff;
  margin-bottom: 80px;
  padding: 20px 0px 20px 20px;
}
.fondo2 {
  background: transparent;
  margin-bottom: 80px;
  padding: 20px 0px 20px 20px
}

.fondo3, .fondoCuotas{
  background: #00aeef;
  margin-bottom: 80px;
  padding: 20px 30px 20px 30px;
  width: inherit;
}

.fondoCuotas{
  border-radius: 0 0 10px 10px;
}

:global(.slick-dots) li {
  padding-top: inherit !important;
  border-bottom:inherit;
  border-right: inherit;
  border-left:inherit;

}


.fondo2 :global(.slick-arrow) {
  display: none !important;
}

/* .fondo2 :global(.slick-dots) {
  position: relative;
}  */