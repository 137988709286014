
.flex {
    display: flex;
}

.cuadroEnlace {
    width: 200px;
    height: 200px;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    float: left;
    margin: auto;
    border-radius: 10px;
    padding: 5px;
}

.cuadroEnlace:hover {
    text-decoration-color: #FFF;
}

.cuadroEnlace:active,
.imgContainer:active {
    cursor: grabbing;
}

.textCapitulos {
    font-size: 50px !important;
}



/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {

    .flex {
        display: block;
    }

    .cuadroEnlace {
        margin-right: 10px;
        margin-bottom: 10px;
    }
}
