.posturaContainer,
.vigenciaContainer {
  border: 1px solid #eaedfa;
  border-radius: 4px;
}

.headerContainer {
  padding: 10px 0 10px 20px;
}

.colorTitle {
  color: #00aeef;
}

.itemBody {
}

.itemHeader{
  margin-bottom: 10px;
}

.itemBody img {
  margin-bottom: 10px;
}

.itemFooter {
  width: 100px;
  margin: 0 auto;
  text-align: center;
  padding: 10px 0;
}

.footerBkg {
  background-color: #f5f6fc;
  min-height: 100px;
  position: relative;
}

.secondRes{
  position: absolute;
  top: 62px;
  left: 40px;
}

.itemFooter p:last-child {
  margin-bottom: 0;
}

.positivo,
.activo {
  color: #11d11f;
}

.negativo,
.inactivo {
  color: #e22929;
}

.info_casa_container {
  border: 1px solid #eaedfa;
  border-radius: 5px;
  margin: 10px 0;
}

.info_casa_container p {
  margin: 0;
}

.info_casa_container i {
  font-size: 12px;
}

.secction_1,
.secction_2 {
  padding: 10px;
}

.secction_2 {
  background-color: #fafbff;
}

.separator {
  background-color: #00adef;
  font-size: 2px;
  margin: 0;
}

.helpIcon {
  position: relative;
  left: 10px;
  z-index: 100;
}

.widthCol{
  width: 100%;
}

.borderTop{
  border-top: 3px solid #00adef;
}

@media only screen and (max-width: 980px) {
  .myCol{
    flex-basis: auto;
  }

  .mobilMargin{
    margin-top: 20px;
  }

  .borderBottom{
    border-bottom: 3px solid #00adef;
  }
}
