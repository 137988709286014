.tamano_550 {
	width: max-content;
	max-width: 630px;
	height: 90px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	top: 25%;
	/* background-image:url("../../../assets/images/comillas.PNG");  */
}

.fondo_comillas {
	background-size: contain;
	/* height: 158px; */
	background-repeat: no-repeat;
	position: relative;
}

.descarga_div {
	margin-top: 80px;
	width: 320px;
	height: 120px;
	border: 1px solid #eaedfa;
	padding-left: 26.14px;
	padding-right: 24px;
	padding-top: 33px;
	padding-bottom: 33px;
	border-radius: 6px;
}

.icono {
	font-size: 48px;
	color: #00aeef;
}

.icono2 {
	font-size: 40px;
	color: #00aeef;
}

.icono_div {
	float: left;
}

.descarga_div_text {
	float: left;
	margin-left: 10px;
	padding-top: 10px;
	padding-left: 5px;
}

.icono_download {
	padding-top: 5px;
	float: right;
}

.vertircal_div {
	display: table;
}

.vertical {
	display: table-cell;
	vertical-align: middle;
	line-height: normal;
}

.comillas_izq {
	position: relative;
    font-size: 60px;
    left: -10px;
    top: -20px;
	color: rgba(109, 207, 246, 25%);
}
.comillas_der {
	position: absolute;
    font-size: 60px;
    top: 49px;
    margin-left: 10px;
	color: rgba(109, 207, 246, 25%);
}
/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.99px) {
	.texto_comas {
		font-size: 26px;
	}
	.fondo_comillas {
		height: 158px;
	}
	.tamano_550 {
		width: max-content;
        max-width: 100%;
        font-size: 18px !important;
    }
    
    .comillas_izq {
        position: relative;
        font-size: 35px;
        left: -10px;
        top: 5px;
    }
    .comillas_der {
        position: absolute;
        font-size: 35px;
        top: 49px;
        margin-left: 10px;
    }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.99px) {
	.texto_comas {
		font-size: 26px;
	}
	.fondo_comillas {
		height: 230px;
	}
	.tamano_550 {
		width: max-content;
        max-width: 100%;
        font-size: 22px;
    }

    .comillas_izq {
        position: relative;
        font-size: 40px;
        left: -10px;
        top: 0px;
    }
    .comillas_der {
        position: absolute;
        font-size: 40px;
        top: 52px;
        margin-left: 10px;
    }
    
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.99px) {
	.texto_comas {
		font-size: 26px;
	}
	.fondo_comillas {
		height: 130px;
	}
	.tamano_550 {
		width: max-content;
		max-width: 100%;
	}
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.99px) {
	.texto_comas {
		font-size: 26px;
	}
	.fondo_comillas {
		background-size: cover;
	}
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	.fondo_comillas {
		background-size: cover;
		height: 120px;
	}
}
