.tabsContainer {
  border: 1px solid #eaedfa;
  border-radius: 6px;
  background-color: #ffffff;
  height: auto;
  width: 100%;

}

.myCol{
  background: #FFFFFF !important;
  border-right: 1px solid #EAEDFA;
  border-radius: 6px 0px 0px 6px;
  width: 104px;
  padding: 24px ;
  padding-top: 20px ;
}

.myCol2{
  background: #FAFBFF;
  /* border: 1px solid #EAEDFA; */
  border-left: 0px solid #EAEDFA;
}


.myCol2 > div{
  border: 0px solid #EAEDFA;
}


.myCol3{
  background: #FAFBFF;
  border: 1px solid #EAEDFA;
  border-left: 0px solid #EAEDFA;
  padding: 10px;
  padding-top: 17px;
}
