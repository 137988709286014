.main_title {
  font-size: 42px;
}

.main_subtitle {
  font-size: 30px;
  margin: 20px 0 40px 0;
}

.main_subtitle h2 {
  display: inline;
  margin-left: 20px;
}

.text_container {
  text-align: justify;
}

.text_container,
.imgContainer {
  margin-top: 30px;
}


.img{
  width: 192px;
}