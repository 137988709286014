.tabsContainer, .tabsContainerBonos {
    border: 1px solid #eaedfa;
    border-radius: 4px;
    background-color: #ffffff;
    text-align: center;
  }
.tabsContainer {
    height: 64px;
  }


.vertical{
    display: table-cell;
	vertical-align: middle;
}

.vertical_div {
  display: table;
  height: 100%;
  width: 100%;
}

.vertical_content {
    display: table-cell;
    vertical-align: middle;
    line-height: normal;
  }