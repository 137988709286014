.fondo_toggle{
    background: #4B4E53;
    height: max-content;
    height: -moz-max-content;
    min-height: 200px;
    border-radius: 25px;
}

.margen_toggle{
    padding: 10px;
}