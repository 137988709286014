.main_subtitle h2 {
  display: inline;
  margin-left: 20px;
}

.slash {
  font-size: 30px;
  font-weight: bold;
  color: #00aeef;
}

.my_btn {
  background: #00aeef;
  color: #ffffff !important;
  border-radius: 50px;
}

.my_btn i {
  margin: 0 10px;
}
