.accordion strong {
    font-weight: bold !important;
  }
  
  .accordion ul ul {
    margin-left: 20px;
  }
  
  .ul li {
    list-style-type: inherit;
    margin-bottom: 5px;
  }
  
  .ul,
  .ul_2 {
    list-style: inherit;
  }
  
  .ul_2 {
    margin-left: 35px;
  }
  .img_container {
    text-align: center;
  }
  .estructura_img {
    width: 35%;
    min-width: 280px;
  }
  
  
  .centerText {
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
  }
  
  .texto_html ol,
  .texto_html ul {
    list-style: revert;
    position: relative;
    left: 30px;
  }
  
  .red {
    color: rgb(185, 0, 0);
  }
  
  .divCirculos {
    position: relative;
    width: 100%;
  }
  .circulos {
    left: 0;
    right: 0;
    margin: auto;
    position: relative;
    width: 100%;
    max-width: 1000px;
  }
  
  .separador {
    position: relative;
    margin-top: 30px;
    color: #00aeef7d;
    width: 100%;
    border: 1px solid;
    border-style: dashed;
  }