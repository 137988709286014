/* Estilos para modelo #1*/
.toogleButtonContainer{
    width: 100%;
    background-color: #fafbff;
    border-right: 1px solid #eaedfa;
    border-radius: 4px 0 0 4px;
  }
  
  .toogleButtonContainer button{
    width: 100% !important;
    color: #00aeef;
    outline: none;
    box-shadow: none !important;
    height: 65px;
  }
  
  .toogleButtonContainer button i{
    margin-right: 10px;
  }
  .firstSectionFilterContainer{
    border: 1px solid #eaedfa;
    border-radius: 4px;
    padding: 0;
  }
.filterComponents,
.selectedFilters,
.showFilterButton {
  height: 64px;
}

.selectedFilters,
.showFilterButton {
  display: inline-block;
  border: 1px solid #eaedfa;
}

.selectedFilters {
  width: 560px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  position: relative;
  top: -15px;
}

.noBorderRigthSelectedFilters {
  border-bottom-right-radius: 0;
}

.showFilterButton {
  position: relative;
  bottom: 0px;
  width: 242px !important;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #eaedfa;
}

.noBorderLeftFilterButton {
  border-bottom-left-radius: 0;
}

.showFilterButton button {
  width: 100%;
  height: 100%;
  font-size: 20px;
  color: #00aeef;
}
.showFilterButton button:focus {
  outline: none;
  box-shadow: none !important;
}

.showFilterButton span i {
  margin-right: 5px;
}

.filtersContainer {
  padding: 20px;
  background-color: #fafbff;
  border: 1px solid #eaedfa;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.selectedFiltersContainer {
  position: relative;
  padding: 5px 15px;
  top: 15px;
}

.filterContainer {
  padding: 8px;
  margin-right: 5px;
  border: 1px solid #eaedfa;
  border-radius: 50px;
}

.filterContainer span {
  color: #00aeef;
}

.filterIconButton {
  margin-left: 10px;
  cursor: pointer;
}

.show {
  display: none;
}

.fields {
  margin: 20px 0;
}

.fecha_inicio,
.fecha_fin {
  outline: none;
  box-shadow: none !important;
}

.fecha_inicio {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.fecha_fin {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.button {
  border: 1px solid #eaedfa;
  color: #00aeef;
  width: 90% !important;
  margin: 0 auto;
}

.button i {
  margin-right: 10px;
}

.errorContainer p {
  color: #e22929;
}

/* Media Query */

@media screen and (max-width: 1190px) {
  .selectedFilters {
    width: 437px;
  }

  .showFilterButton {
    width: 230px !important;
  }

  .button {
    width: 100% !important;
    margin: 5px auto !important;
  }
}

@media only screen and (max-width: 980px) {
  .selectedFilters {
    width: 100%;
    border-radius: 4px 4px 0 0;
  }

  .showFilterButton {
    width: 100% !important;
    bottom: 15px;
    margin-bottom: 50px !important;
    border-radius: 0 !important;
    border: 1px solid #eaedfa;
  }

  .filtersContainer {
    position: relative;
    top: 45px;
  }

  .button {
    width: 100% !important;
    margin: 5px auto !important;
  }
}


.firstSectionFilterContainer{
    border: 1px solid #eaedfa;
    border-radius: 4px;
    padding: 0;
  }
  
  .noBorderRadius{
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .toogleButtonContainer{
    width: 100%;
    background-color: #fafbff;
    border-right: 1px solid #eaedfa;
    border-radius: 4px 0 0 4px;
  }
  
  .toogleButtonContainer button{
    width: 100% !important;
    color: #00aeef;
    outline: none;
    box-shadow: none !important;
    height: 65px;
  }
  
  .toogleButtonContainer button i{
    margin-right: 10px;
  }
  
  .secondSectionFilterContainer{
    border-bottom: 1px solid #eaedfa;
    border-left: 1px solid #eaedfa;
    border-right: 1px solid #eaedfa;
    border-radius: 0 0 4px 4px;
    background-color: #fafbff;
    padding: 10px 0;
  }
  
  .filterSections {
    padding: 20px 0;
  }
  
  .fechaInicio,
  .fechaFin {
    outline: none;
    box-shadow: none !important;
    border: 1px solid #eaedfa;
    border-radius: 4px;
    padding: 6px;
  }
  
  .fechaInicio {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .fechaFin {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  input[type="date"] {
    width: 100%;
  }
  
  .buttonModel {
    border: 1px solid #eaedfa;
    color: #00aeef;
    width: 90% !important;
    margin: 0 auto;
  }
  
  .buttonModel i {
    margin-right: 10px;
  }
  
  .hidden{
    display: none;
  }
  
  .buttonModel {
    border: 1px solid #eaedfa;
    color: #00aeef;
    width: 90% !important;
    margin: 0 auto;
  }
  
  .buttonModel i {
    margin-right: 10px;
  }
  
  .filterContainer {
    padding: 8px;
    margin-right: 5px;
    border: 1px solid #eaedfa;
    border-radius: 50px;
  }
  
  .filterContainer span {
    color: #00aeef;
    font-size: 14px;
  }
  
  .filterIconButton {
    margin-left: 10px;
    cursor: pointer;
  }
  
  .filterSelectedContainer,
  .filterContainerOutside{
    height: 65px;
  }
  
  .filterContainerOutside {
    width: 100%;
    overflow-y: none !important;
    overflow-x: auto;
    white-space: nowrap;
    color: #00aeef;
    padding: 19px 0 0 15px;
  }
  
  .errorContainer p {
    color: #e22929;
  }
  
  .myLabel{
      display: block;
  }

  .formCheckInput{
    position: relative;
    top: 13px;
    left: 18px;
    margin-right: 25px;
  }

  .model_list li {
    cursor: pointer;
  }
  
  .no_col_padding {
    padding: 0;
  }
  
  .no_margin {
    margin: 0;
  }

  .paddingLeft15{
      padding-left: 15px;
  }
  
  
  
  