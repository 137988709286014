.fondo {
  height: 100%;
  width: 100%;
  position: fixed;
  background: #ffffff;
  top: 0;
  left: 0;
  z-index: 10000;
  background-image:url("../../assets/images/va.svg"); 
  background-size: fixed;
  -webkit-background-size: fixed;
  -moz-background-size: fixed;
  -o-background-size: fixed;
  -ms-background-size: fixed;
  background-attachment: fixed !important;
  background-size: auto;
  background-color: floralwhite;
  background-position-x: center;
  background-position-y: center;
  background-size: 70px;
}
.fondo:before{
    position: relative;
    height: 100%;
    width: 100%;
    content: ' ';
    position: absolute;
    background: #ffffffe3;
    left: 0;
  }
.letras {
  font-size: 140px;
  z-index: 10001;
}

.numeros {
  font-size: 80px;
  z-index: 10001;
  /* float: right; */
}

.centrado {
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 10001;
  width: 50%;
  position: absolute;
}


.centrado h1, .centrado h2, .centrado h3, .centrado h4{
    text-align: center;
    margin-top: 20px;
}

.img{
  z-index: 10001;
  width: 70%;

}
.centrado_img {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.vertical_div{
    display: table;
}

.vertical{
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    height: 70%;
}

/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .letras {
        font-size: 60px;
      }
    .numeros{
        font-size: 100px;
    }
      .centrado{
          width: 80%;
      }

      .img{
          width: 100%;
      }
      .centrado h2{
          font-size:20px;
      }
      .centrado h3{
        font-size:15px;
    }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .letras {
        font-size: 92px;
      }
    .numeros{
        font-size: 120px;;
    }
      .centrado{
          width: 80%;
      }
      .img{
        width: 100%;
    }
    .centrado h2{
        font-size:25px;
    }
    .centrado h3{
      font-size:20px;
  }
 }

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
    
    .letras {
        font-size: 125px;
      }
    .numeros{
        font-size: 140px;;
    }
      .centrado{
          width: 80%;
      }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) { 
    .letras {
        font-size: 125px;
      }
    .numeros{
        font-size: 140px;;
    }
      .centrado{
          width: 80%;
      }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { 
    .letras {
        font-size: 150px;
      }
    .numeros{
        font-size: 140px;;
    }
      .centrado{
          width: 80%;
      }
}