.left{
    background-color: #dedede	;
    padding-top: 10px;
    height: 810px;
    border-right: 3px solid #00aeef;
    border-radius: 10px;
}

.right{
    background-color: #dedede;
    padding-top: 10px;
    height: 810px;
    border-left: 3px solid #00aeef;
    border-radius: 10px;
}

.row{
    background-color: #00aeef;
    padding: 10px;
    border-radius: 10px;
}

/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.99px) { 
    .left, .right{
        padding-left: 70px;
        padding-right: 70px ;
        margin-bottom: 20px;
    }
 }

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.99px) { 
    .left, .right{
        padding-left: 70px;
        padding-right: 70px ;
        margin-bottom: 20px;
    }
 }

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.99px) { 
    .left, .right{
        padding-left: 70px;
        padding-right: 70px ;
        margin-bottom: 20px;
    }
 }

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.99px) { 
    
 }
