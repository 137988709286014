.cuadro {
    background: #ffffffa3;
    width: 242px;
    height: 210px;
    border-radius: 6px;
    border: 1px solid #eaedfa;
    box-shadow: 0 0 0 13px #ffffff;
    padding-bottom: 10px;
    /* padding-top: 30px; */
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
}

.cuadro_azul {
    background: #00aeefd1;
}

.cuadro_azul span {
    color: #ffffff;
}
.cuadro_gris {
    background: #9095a4d1;
}
.cuadro_gris span {
    color: #ffffff;
}
.cuadro_div {
    width: 33.333%;
    height: 224px;
    border-radius: 0;
    float: left;
    box-shadow: 0 0 0 2px #ffffff;
    border-left: 6px solid #ffffff;
}

.tipo2.cuadro_div,
.tipo2.cuadro_div .cuadro {
    height: 100%;
}

.cuadro_1 {
    box-shadow: 0px 0px 0px 19px #ffffff;
    margin-right: 16px;
    margin-bottom: 16px;
}

.blanco_borde {
    background: #ffffff;
    box-shadow: 0 0 0 3px #ffffff;
    border: 1px solid #00aeef;
}
.sin_fondo {
    box-shadow: 0 0 0 13px #ffffff;
    /* background: #FFFFFF; */
}
.blanco_sin_borde {
    background: #ffffff;
    border: 0px solid #00aeef;
}
.blanco_sin_borde .contenido {
    display: none;
}

.contenido {
    position: relative;
}

.imagen {
    width: 80px;
    position: relative;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.icono {
    width: 50px;
    height: 50px;
}

.texto {
    text-align: left;
    position: relative;
    left: 0;
    right: 0;
    top: 5px;
    padding-left: 25px;
    padding-right: 25px;
}



.texto_tipo_normal {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    text-align: center !important;
}

.texto_tipo_titulo,
.texto_tipo_titulo > span {
    font-family: 'Nunito', sans-serif !important;
    font-size: 30px !important;
    line-height: 40px;
    text-align: center;
    font-weight: 500;
}

.texto_tipo_titulo_tipo2 {
    text-align: left;
    position: relative;
    left: 0;
    right: 0;
    top: 5px;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 16px !important;
    line-height: 24px;
    text-align: left;
    font-weight: 500;
    padding-left: 20px;
    padding-right: 20px;
}

.texto_tipo_titulo_tipo3 {
    text-align: left;
    position: relative;
    left: 0;
    right: 0;
    top: 5px;
    font-family: 'Nunito', sans-serif !important;
    font-size: 24px !important;
    line-height: 36px;
    text-align: center;
    font-weight: 500;
    padding-left: 20px;
    padding-right: 20px;
}

.texto_tipo_titulo_tipo4 {
    text-align: left;
    position: relative;
    left: 0;
    right: 0;
    top: 5px;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 30px !important;
    line-height: 40px;
    text-align: center;
    font-weight: 500;
    padding-left: 20px;
    padding-right: 20px;
}

.texto_tipo_titulo_tipo5 {
    text-align: left;
    position: relative;
    left: 0;
    right: 0;
    top: 10px;
    font-family: 'Montserrat', sans-serif !important;
    font-size: 16px !important;
    line-height: 24px;
    text-align: left;
    font-weight: 500;
    padding-left: 30px;
    padding-right: 30px;
}
.texto_tipo_titulo_tipo5 p {
    margin-bottom: 0px;
}

.icono_plus {
    font-size: 16px !important;
}

.no_mostrar {
    display: none;
}

.margen_texto {
    position: relative;
    /* top: 40px; */
}

.tipo2 .imagen {
    margin-top: 30px;
    margin-bottom: 50px;
}

.btn_enlace {
    position: relative;
    width: 242px;
    height: 50px;
    background: #00aeef;
    bottom: 51px;
    border-radius: 0 0 6px 6px;
    border: 1px;
    padding-left: 0px;
}



.btn_enlace:hover {
    background: #017aa7;
}

.btn_enlace:active {
    background: #00aeef;
}
.btn_enlace i {
    position: absolute;
    right: 20px;
    top: 0px;
    font-size: 20px;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    height: 15px;
    display: flex;
}

.btn_enlace span {
    position: absolute;
    top: 0;
    left: 20px;
    font-size: 16px;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    height: 15px;
    display: flex;
}

.cuadro {
    display: table;
}

.contenido {
    display: table-cell;
    vertical-align: middle;
    line-height: normal;
}

.contenido_arriba {
    display: block !important;
    line-height: normal;
    padding: 5px;
}
.contenido_justificado {
    position: relative;
    display: block;
    line-height: normal;
    top: 15px;
}

.justificada_top{
    top:15px !important;
}

.cuadro {
    width: 97%;
    height: 94%;
    padding-left: 2px;
    padding-right: 2px;
}

.texto_justificado {
    text-align: left !important;
    max-width: 225px;
    min-width: 125px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
}
.cuadro_gris {
    height: 210px;
    width: 99%;
    
}

.texto_tipo_subtitulo{
    text-align: left;
    position: relative;
    left: 0;
    right: 0;
    /* top: 10px; */
    font-family: 'Montserrat', sans-serif !important;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    font-weight: 500;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 5px;
    color: #ffffff;
}

.texto_tipo_normal_small {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    line-height: 22px;
    font-weight: 500;
    text-align: justify !important;
}

/* // Extra small devices (portrait phones, less than 576px) */
@media (min-width: 0px) and (max-width: 575.99px) {
    .cuadro_div {
        width: 100%;
        height: 168px;
        border-radius: 0;
        float: left;
        box-shadow: 0 0 0 2px #ffffff;
        border-left: 6px solid #ffffff;
    }
    .cuadro {
        width: 100%;
        height: 90%;
        padding-left: 2px;
        padding-right: 2px;
    }
    .quitar_cuadro_div {
        display: none;
    }
    .texto_tipo_titulo {
        font-size: 15px;
        line-height: 20px;
    }
    .texto_tipo_titulo,
    .texto_tipo_titulo > span {
        font-size: 10px !important;
    }
    
    .texto_tipo_normal {
        font-size: 10px;
        line-height: 15px;
    }
    .icono {
        width: 20px;
        height: inherit;
    }
    .tipo2.cuadro_div,
    .tipo2.cuadro_div .cuadro {
        height: 150px;
        box-shadow: 0 0 0 0px #ffffff;
        margin-bottom: 10px;
    }
    .tipo2 .imagen {
        margin-top: 15px;
        margin-bottom: 0px;
    }
    .texto_tipo_titulo_tipo2 {
        font-size: 10px !important;
        padding-left: 5px;
        padding-right: 5px;
    }
    
    .btn_enlace {
        width: 100%;
        height: 40px;
    }
    
    .texto_justificado{
        max-width: 90%;
        width: 100%;
        font-size: 10px;
        
    }
    .cuadro_gris {
        width: 100%;
        height: 90%;
    }
    .texto_tipo_subtitulo{
        font-size: 12px;
    }
}


/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.99px) {
    .cuadro_div {
        width: 100%;
        height: 150px;
        border-radius: 0;
        float: left;
        box-shadow: 0 0 0 2px #ffffff;
        border-left: 6px solid #ffffff;
    }
    .cuadro {
        width: 100%;
        height: 84%;
        padding-left: 2px;
        padding-right: 2px;
    }
    .quitar_cuadro_div {
        display: none;
    }
    .texto_tipo_titulo {
        font-size: 15px;
        line-height: 20px;
    }
    .texto_tipo_titulo,
    .texto_tipo_titulo > span {
        font-size: 20px !important;
    }
    
    .texto_tipo_normal {
        font-size: 10px;
        line-height: 15px;
    }
    .icono {
        width: 20px;
        height: inherit;
    }
    .tipo2.cuadro_div,
    .tipo2.cuadro_div .cuadro {
        height: 150px;
        box-shadow: 0 0 0 0px #ffffff;
        margin-bottom: 10px;
    }
    .tipo2 .imagen {
        margin-top: 15px;
        margin-bottom: 0px;
    }
    .texto_tipo_titulo_tipo2 {
        font-size: 10px !important;
        padding-left: 5px;
        padding-right: 5px;
    }
    .btn_enlace {
        width: 100%;
        height: 40px;
    }
    .texto_justificado{
        max-width: 90%;
        width: 100%;
        font-size: 10px;
    }
    .cuadro_gris {
        height: 170px;
    }
    .texto_tipo_subtitulo{
        font-size: 12px;
    }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.99px) {
    .cuadro_div {
        width: 100%;
        height: 150px;
        border-radius: 0;
        float: left;
        box-shadow: 0 0 0 2px #ffffff;
        border-left: 6px solid #ffffff;
    }
    .cuadro {
        width: 100%;
        height: 84%;
        padding-left: 2px;
        padding-right: 2px;
    }
    .quitar_cuadro_div {
        display: none;
    }
    .texto_tipo_titulo {
        font-size: 15px;
        line-height: 20px;
    }
    .texto_tipo_titulo,
    .texto_tipo_titulo > span {
        font-size: 20px !important;
    }
    
    .texto_tipo_normal {
        font-size: 10px;
        line-height: 15px;
    }
    .icono {
        width: 20px;
        height: inherit;
    }
    .quitar_cuadro_div {
        display: none;
    }
    .tipo2.cuadro_div,
    .tipo2.cuadro_div .cuadro {
        height: 150px;
        box-shadow: 0 0 0 0px #ffffff;
        margin-bottom: 10px;
    }
    .tipo2 .imagen {
        margin-top: 15px;
        margin-bottom: 0px;
    }
    .btn_enlace {
        width: 100%;
        height: 40px;
    }
    .texto_justificado{
        max-width: 90%;
        width: 100%;
        font-size: 12px;
    }
    .cuadro_gris {
        height: 170px;
    }
    .texto_tipo_subtitulo{
        font-size: 14px;
    }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.99px) {
    .texto_tipo_titulo {
        font-size: 18px;
        line-height: 20px;
    }
    .texto_justificado{
        max-width: 90%;
        width: 100%;
    }
    .cuadro_gris {
        height: 239px;
    }
    .texto_tipo_subtitulo{
        font-size: 14px;
    }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .cuadro_gris {
        height: 235px;
    }
}

