.container {
  position: absolute;
  right: 0px;
  float: right;
}

.container_2 {
  min-width: 380px;
  position: relative;
  margin-right: 10px;
  margin-top: 10px;
  height: max-content;
  width: max-content;
  background-color: rgb(226 226 226);
  padding: 5px;
  border-radius: 6px;
}

.table,
.table_last {
  color: #000;
  border-radius: 6px !important;
  margin-bottom: 0px;
}
.table,
.table_last,
.th,
.td {
  border: 1px solid #bfbfbf !important;
}

.th {
  font-weight: bold;
  text-align: center;
  border-top: 0px !important;
  color: #00aeef;
}

.td {
  text-align: center;
}

.button_mapa_calor {
  display: grid;
  text-decoration: none;
  position: relative;
  top: 10px;
  font-family: "Nunito", sans-serif;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
  padding: 10px 20px;
  border: none;
  color: white;
  border-radius: 7px;
  letter-spacing: 4px;
  font-weight: 700;
  text-transform: uppercase;
  transition: 0.5s;
  transition-property: box-shadow;
  background: #00aeef;
  box-shadow: 0 0 25px #00aeef;
}

.button_mapa_calor:hover {
  text-decoration: none;
  color: white;
  box-shadow: 0 0 5px #00aeef,
              0 0 20px #00aeef,
              0 0 40px #00aeef,
              0 0 80px #00aeef;
}

.container_button_mapa_calor {
  position: relative;
  z-index: 100;
  top: 10px;
  float: right;
  right: 10px;
  margin-right: 10px;
}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  .container {
    top: -33px;
  }
  .th {
    font-size: 12px !important;
  }
  .td {
    font-size: 12px !important;
  }
  .button_mapa_calor {
    font-size: 12px !important;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .container {
    right: 0;
  }

  .container_2 {
    width: 250px;
    min-width: 261px;
    float: left;
  }

  .th {
    font-size: 12px !important;
  }
  .td {
    font-size: 12px !important;
  }
  .container_button_mapa_calor {
    position: relative;
    z-index: 100;
    top: 10px;
    float: none;
    right: 0px;
    margin-right: 10px;
  }
}
/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 619.98px) {
  .container_2 {
    width: 215px;
    min-width: 215px;
    float: left;
  }
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .container_2 {
    float: left;
  }
}
