.animated > div {
  padding-top: 100px;
}
.transition {
  height: fit-content;
  min-height: 300px;
  padding-top: 50px;
}

.transition ol,
.transition ul {
  list-style: inherit;
  margin-top: 10px;
  padding-left: 35px;
}

.transition ul li {
  list-style: inherit;
  margin-top: 10px;
}
.transition ol li {
  list-style: auto;
  margin-top: 10px;
}

.imagen {
  position: fixed;
  height: 399px;
  width: 50%;
  right: 0;
  bottom: 1%;
  opacity: 0.03;
  background-size: cover;
}

.containerTitulo {
  position: relative;
}
.img {
  width: 50px;
  background-position: center;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.titulo {
  position: relative;
  left: 40px;
  top: 0;
  bottom: 0;
  margin: auto;
}

.imagenIcono {
  width: 70%;
}
@media (max-width: 575.98px) {
  .img {
    width: 35px;
  }
}
