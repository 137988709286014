.fondo{
    background: #fafbff;
    padding-top: 30px;
    z-index: 0;
    margin-top: 5px;
}

.containerDiv{
    width: 100%;
    position: relative;
    cursor: pointer;
}
.containerDiv:hover{
    transform: scale(1.1);
    background: #ffffff;
    z-index: 1;
}
.container{
    margin-bottom: 15px;
}

.thumbanil img{
    width: 100%;
}