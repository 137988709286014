.at_grid_item {
    position: relative;
    height: 162px;
    width: 139px;
    box-shadow: 0 0 0 1px #FFFFFF;
}

.at_grid_item_2 {
    position: relative;
    height: 213px !important;
    width: 183px !important;
    box-shadow: 0 0 0 1px #FFFFFF;
}

.at_grid_item_titulo {
    position: relative;
    height: 162px;
    width: 139px;
    background: #4B4E53;
    /* padding-top: 75px; */

}
.at_grid_item_titulo_2 {
    position: relative;
    height: 215px;
    width: 180px;
    margin-left: 54px;
    margin-right: 5px;
    background: #4B4E53;
    /* padding-top: 75px; */

}

.at_grid_item> .divImagen{
    opacity: inherit !important;

}

.at_grid_item>div {
    display: grid;
    height: 100%;
    left: 0;
    position: absolute;
    padding: 8px;
    top: 0;
    transition: all .23s ease;
    opacity: 0;
    width: 100%;
    background-size: cover;
    z-index: 1

}

.at_grid_item>div h5 {
    color: #00aeef;
    text-transform: inherit;
}

.at_grid_item>div h6 {
    color: #fff;
    text-transform: inherit;
}

.at_grid_item:hover>div {
    background: rgba(0,0,0,0.6);
    background-size: cover;
    opacity: inherit;
}
.at_grid_item>div {
    display: grid;
    height: 100%;
    left: 0;
    position: absolute;
    padding: 8px;
    top: 0;
    transition: all .23s ease;
    opacity: 0;
    width: 100%;
}

.imagen_persona{
    width: 100%;
}

.titulo_equipo{
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: 122px;
    text-align: center;
    color: #00aeef;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.texto_linkedin{
    background: transparent;
    border: 0 none;
    color: #fff;
    display: inline-block;
    padding: 0;
    margin: 0;
    margin-left: 0px;
    bottom: 0;
    overflow-wrap: anywhere;
}

.link_in{
    text-decoration: none;
    align-self: end;
}