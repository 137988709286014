.contenedor_foto {
	position: relative;
	width: 320px;
	padding: 0px;
	max-height: 470px;
	height: 470px;
	float: left;
}

.relativo {
	position: relative;
	padding-top: 30px;
}
.message {
	top: -50px;
	position: relative;
}
