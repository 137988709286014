.tabsContainer {
    border: 1px solid #eaedfa;
    border-radius: 4px;
    background-color: #ffffff;
    height: 64px;
    padding: 24px 21px 22px 26px;

  }
  
.card_header {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  min-height: 64px;
  padding: .75rem 1.25rem;

}

.card_header2 {
  background: #FAFBFF;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  min-height: 64px;
 padding-top: 10px;
 padding-bottom: 10px;
}

.titulo_ZIP {
  color: gray;
}