.icono{
    float: left;
    height: 46px;
    margin-right: 20px;
}

.texto_icono{
    position: relative;
    height: 46px;
    display: table;
    width: 403px;
}



.texto_icono span{
  display: table-cell;
  vertical-align: middle;
  line-height: normal;
}

.accordion{
    width: 100%;
}