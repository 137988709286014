.text_container,
text_container_blue {
  text-align: justify;
  padding: 10px;
  width: 100%;
}
.text_container_blue {
  background: #00aeef;
  border-radius: 10px 10px 0px 0px;
  padding-top: 15px;
}

.container_logo {
  height: 70px;
}

.logo {
  width: 60%;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.tabsContainer {
  position: relative;
  height: 150px;
}

.tabsContainer > div {
  position: absolute;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  width: max-content;
}

.centerBtns {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
}
.containerBtns {
  width: fit-content !important;
  width: -moz-fit-content !important;
  display: inline-flex;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.form_blue {
  background: #00aeef;
  padding: 30px;
  border-radius: 0 0 10px 10px;
}
.iframeContainer{
  position: relative;
  height: 425px;
}
.iframe {
  width: 70%;
  height: 425px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.tab{
  cursor: pointer;
}

.tabs_container, .tabs_container > div{
  width: 100%;
}

.tabs_container :global(.nav-tabs){
  border-bottom: 1px solid #00aeef !important;
  color: #9095a4 !important;
}

.tabs_container :global(.nav-link):hover {
  border-color: #9095a4 #9095a4 #00aeef !important;
}
.tabs_container :global(.active), .tabs_container :global(.active):hover {
  color: #00aeef !important;
  border-color: #00aeef #00aeef #fff !important;
}

@media (max-width: 1200px) {
  .iframeContainer, .iframe {
    height: 353px;
  }
}
/* Large devices (desktops, 992px and up) */
@media (max-width: 992px) {
  .tabsContainer > div {
    width: 55%;
  }

  .tabsContainer {
    height: 345px;
  }
  .logo {
    width: 80%;
  }
  .container_logo {
    height: 65px;
  }
  .iframeContainer, .iframe {
    height: 260px;
  }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (max-width: 768px) {
  .tabsContainer > div {
    width: 75%;
  }

  .tabsContainer {
    height: 345px;
  }
  .logo {
    width: 85%;
  }
  .container_logo {
    height: 50px;
  }
  .iframeContainer, .iframe {
    width: 100%;
  }
  .iframe {
    height: 270px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .tabsContainer > div {
    width: 85%;
  }

  .tabsContainer {
    height: 345px;
  }
  .logo {
    width: 90%;
  }
  .container_logo {
    height: 45px;
  }

  .iframeContainer, .iframe {
    height: 274px;
  }
}

/* Small devices (landscape phones, 500px and up) */
@media (max-width: 500px) {
  .tabsContainer > div {
    width: 100%;
  }

  .tabsContainer {
    height: 345px;
  }
  .container_logo {
    height: 45px;
  }
  .iframe {
    height: 236px;
  }
}

/* Small devices (landscape phones, 419px and up) */
@media (max-width: 419px) {
  .tabsContainer > div {
    width: 100%;
  }

  .tabsContainer {
    height: 700px;
  }
  .container_logo {
    height: 45px;
  }
  .iframe {
    height: 216px;
  }
}
