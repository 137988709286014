.card_header {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  height: 64px;
  padding: 0.75rem 1.25rem;
}

.card_header2 {
  background: #fafbff;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  height: 64px;
}

.altura {
  height: 64px;
  width: 100%;
}

.tabsContainer {
  border: 1px solid #eaedfa;
  border-radius: 4px;
  background-color: #ffffff;
  height: 64px;
  padding: 24px 21px 22px 26px;
}

.footer {
  background-color: #00aeef;
  height: 512px;
  padding: 1.7rem 1.7rem;
}

.encabezado_footer {
  border-radius: 4px;
  padding: 24px 21px 22px 26px;
  color: #ffffff;
  border-bottom: 3px solid #ffffff;
  width: 100%;
}

.titulo_footer {
  border-radius: 4px;
  /* padding: 24px 21px 22px 26px; */
  color: #ffffff;
  border-bottom: 3px solid #ffffff;
  width: 100%;
}

.table_footer {
  width: 100%;
}

.tr_footer {
  border-radius: 4px;
  /* padding: 24px 21px 22px 26px; */
  color: #ffffff;
  width: 100%;
}

.titulo_25pct {
  display: inline-block;
  width: 25%;
}
.titulo_40pct {
  display: inline-block;
  width: 40%;
}
.titulo_50pct {
  width: 50%;
  display: inline-block;
}
.titulo_60pct {
  display: inline-block;
  width: 60%;
}

.titulo_75pct {
  display: inline-block;
  width: 75%;
}

.titulo_ZIP {
  color: gray;
}

.titulo {
  color: #00aeef;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  width: 100%;
  background-color: #fafbff;
}
.btn_link {
  text-align: left;
  color: #f26522;
  text-decoration: none;
  background-color: #ffffff;
}
