.fondo{
    height: fit-content;
    width: 100%;
    background: #00aeef;
    padding-top: 60px;
    padding-right: 15px;
}




@media (max-width: 575.99px) {
.fondo{
    height: fit-content;
background: #00aeef;
padding-top: 30px;
padding-right: 0px;
}
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.99px) {
    .fondo{
        height: fit-content;
    background: #00aeef;
    padding-top: 30px;
    padding-right: 0px;
    }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.99px) {
    .fondo{
        height: fit-content;
    background: #00aeef;
    padding-top: 30px;
    padding-right: 10px;
    }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.99px) {
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}