.main_subtitle{
    font-size: 30px;
    margin: 20px 0 40px 0;
}

.main_subtitle h2{
    display: inline;
    margin-left: 20px;
}

.slash{
    font-size: 30px;
    font-weight: bold;
    color: #00aeef;
}

.text_container{
    text-align: justify;
}

.item_list li:first-child {
    border-left: 1px solid #eaedfa;
}

.list_consejo li:first-child,
.list_comite li:first-child  {
    border-left: 1px solid #eaedfa;
}

.margin{
    margin-bottom: 30px;
}

