.divImagen {
    width: 120px;
    height: 120px;
    background-size: contain;
}

.tituloContainer,
.divImagen {
    float: left;
}

.imgContainer {
    cursor: pointer;
}

.tituloContainer {
    width: calc(100% - 140px);
    height: 100%;
    position: relative;
    margin-left: 20px;
}

.vertical {
    width: 100%;
    height: min-content;

    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
}



.titulo {
    width: 90%;
}


.iconTitulo {
    float: right;
    font-size: 30px;
}


.imagenInicial {
    width: 100%;
    height: 100%;
    max-width: 400px;
    max-height: 400px;
    min-width: 320px;
    min-height: 320px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: top;
    position: absolute;
    z-index: 0;
}

.title {
    position: relative;
    width: 40%;
    margin-top: 200px;
}

.container {
    z-index: 1;
}

/* // Large devices (desktops, less than 1600px) */
@media (max-width: 1664.98px) {

    .title {
        left: 130px;
    }
}

/* // Large devices (desktops, less than 1600px) */
@media (max-width: 1424.98px) {

    .title {
        left: 180px;
    }
}

/* // Large devices (desktops, less than 1600px) */
@media (max-width: 1324.98px) {

    .title {
        left: 220px;
    }
}

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
    .title {
        left: 25%;
        width: 50%;

    }

}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
    .title {
        width: 55%;

    }
}

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
    .title {
        width: 80%;
    }

    .titulo {
        font-size: 18px;
    }

    .rowObjetivos {
        margin-top: 50px;
    }

    .rowObjetivos .texto_container {
        margin-top: 50px;
    }
}

/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .imagenInicial {
        width: 100%;
        height: 100%;
        max-width: 200px;
        max-height: 200px;
    }

    .title {
        width: 100%;
    }

    .titulo {
        font-size: 16px;
    }
}