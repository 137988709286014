.fondo1 {
	border: 1px solid #eaedfa;
	width: 225px !important;
	height: 319px;
	/* background-image: url("../../../assets/images/fondo_carousel/azul.png"); */
	background-size: cover;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	position: relative;
}

.diseno3 .fondo1 {
	border: 1px solid #eaedfa;
	width: 225px !important;
	height: 319px;
	/* background-image: url(../../../assets/images/fondo_carousel/azul.png); */
	background-size: 149%;
	background-position-x: -76px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	position: relative;
}

.diseno4 .fondo1 {
	border: 1px solid #eaedfa;
	width: 225px !important;
	height: 319px;
	/* background-image: url(../../../assets/images/fondo_carousel/azul.png); */
	background-size: 149%;
	background-position-x: -76px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	position: relative;
}

.diseno6 .fondo1 {
	border: 1px solid #eaedfa;
	width: 225px !important;
	height: 319px;
	/* background-image: url(../../../assets/images/fondo_carousel/azul.png); */
	background-size: 149%;
	background-position-x: -53px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	position: relative;
}
.margen {
	width: 278px !important;
	min-height: 500px;
	height: auto;
	padding-bottom: 20px;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
	position: relative;
}

.centrado {
	width: 225px !important;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
}

.contenedor_fecha {
	margin-top: 20px;
}
.contenedor_descripcion {
	margin-top: 2px;
}
.contenedor_descarga {
	margin-top: 10px;
	cursor: pointer;
}
.contenedor_descarga:hover span {
	color: #005675 !important;
	transition: all 0.2s;
}

.icono{
	color: #005675;
	position: relative;
	margin-top: 15px;
}

.contenedor_descarga:hover .icono:before {
	background: #005675 !important;
	transition: all 0.2s;
}

.contenedor_descarga:active span {
	color: #00afef62 !important;
}

.contenedor_descarga:active .icono:before {
	background: #00afef62 !important;
}

.diseno1 .titulo {
	width: fit-content;
	max-width: 160px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	position: absolute;
	top: 130px;
	text-align: justify;
}

.diseno1 .titulo_slider {
	width: fit-content;
	width: -moz-fit-content;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	color: #ffffff;
	position: absolute;
	bottom: 10px;
}

.diseno2 .titulo {
	width: fit-content;
	max-width: 160px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	position: absolute;
	top: 185px;
	text-align: justify;
}

.diseno2 .titulo_slider {
	width: 110px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	color: #ffffff;
	position: absolute;
	bottom: 130px;
}

.diseno3 .titulo {
	width: fit-content;
	max-width: 160px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	position: absolute;
	top: 130px;
}

.diseno3 .titulo_slider {
	width: 110px;
	margin-left: 30px;
	margin-right: auto;
	text-align: left;
	color: #ffffff;
	position: absolute;
	bottom: 30px;
}

.diseno4 .titulo {
	width: fit-content;
	max-width: 160px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	position: absolute;
	top: 85px;
}

.diseno4 .titulo_slider {
	width: 110px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	color: #ffffff;
	position: absolute;
	top: 30px;
}

.diseno5 .titulo {
	width: fit-content;
	max-width: 160px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	position: absolute;
	top: 130px;
}

.diseno5 .titulo_slider {
	width: 110px;
	right: 0;
	margin-left: auto;
	margin-right: 30px;
	text-align: right;
	color: #ffffff;
	position: absolute;
	bottom: 10px;
}

.diseno6 .titulo {
	width: fit-content;
	max-width: 160px;
	left: 0;
	margin-left: 30px;
	margin-right: auto;
	position: absolute;
	top: 190px;
	text-align: left;
}

.diseno6 .titulo_slider {
	width: 110px;
	left: 0;
	right: 0;
	margin-left: 30px;
	margin-right: auto;
	text-align: left;
	color: #ffffff;
	position: absolute;
	top: 130px;
}

.esconde {
	display: none;
}
