.contenedor_cuadro {
    width: 240px;
    height: 224px;
    border-radius: 0;
    float: left;
    box-shadow: 0 0 0 2px #ffffff;
    border-left: 6px solid #ffffff;
  }

.cuadro {
    background: #ffffffa3;
    width: 220px;
    height: 210px;
    border-radius: 6px;
    border: 1px solid #eaedfa;
    box-shadow: 0 0 0 13px #ffffff;
    padding-bottom: 10px;
    /* padding-top: 30px; */
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    display: table;
  }

  .contenedor_cuadro {
    background-image: url("../../../../assets/images/a.svg");
    background-position-x: -35px;
    position: relative;
    border-radius: 6px;
    background-size: contain;
    -ms-background-size: contain;
    background-size: 290px;
    padding-left: 0;
    left: -10px;
    background-repeat: no-repeat !important;
    background-position-y: -37px;
    cursor: pointer;
  }



  .contenedor_cuadro:hover .cuadro{
    background: #00a2caa3;
  }
  .contenedor_cuadro:hover .texto{
    color: #FFFFFF;
  }
  .contenedor_cuadro:active {
    transform: scale(0.9) 
  }

  .texto {
    text-align: center;
    position: relative;
    left: 0;
    right: 0;
    top: 5px;
  }
  
  .texto_justificado {
    text-align: center !important;
  }

  .contenido {
    display: table-cell;
    vertical-align: middle;
    line-height: normal;
  }

  .contenedor_primero{
      width: 100%;
      position: relative;
  }
  .btn_derecha{
      position: relative;
      right: 0;
      float: left;
  }

  .icon{
      margin-right:20px; 
      width: 60px;
  }

  i.icon{
      font-size: 40px;
      width: max-content;
      width: -moz-max-content;
      color: #00aeef;
  }
  .contenedor_icon{
      position: relative;
      top: 15px;
      cursor: pointer;
  }
  .contenedor_icon i{
    cursor: pointer;
}

.fondo{
  height: fit-content;
  width: 100%;
  background: #00aeef;
  padding-top: 60px;
  padding-right: 15px;
}

.marginContainer{
  margin-top: 100px;
  margin-bottom: 100px;
}

.my_btn_one {
  background: #fff;
  color: #00aeef !important;
  border-radius: 50px;
  margin: 15px 0;
  border-color: #00aeef;
}

.my_btn_one i {
  margin: 0 10px;
}

.dot {
  color: #00aeef;
  padding: 3px 15px 5px 5px;
  font-size: 14px;
  border-radius: 50px;
  border: 1px solid #00AEEF;
}

.dot i{
  margin: 0 10px;
}

.alza {
  color: #16c622;
}

.baja {
  color: #e22929;
}

.cambio {
  color: #ff620f;
}

.ticketContainer{
  position: relative;
  display: inline-block !important;
  margin: 0 5px;
  width: 200px;
  padding: 5px;
  border: 1px solid #EAEDFA;
  border-radius: 3px;
}

.itemCambioContainer{
  position: relative;
  display: inline-block !important;
  margin: 0 5px;
  width: 200px;
  padding: 5px;
  border: 1px solid #EAEDFA;
  border-radius: 3px;
  min-height: 125px;
}

.itemCambioContainer .cambioAsk,
.itemCambioContainer .cambioBid{
  position: relative;
  top: 25px;
} 


.mainInfoContainer,
.mainTicketInfoContainer,
.mainCambioInfoContainer{
  padding-left: 15px;
}

.titleColor{
  color: #00AEEF;
}

.chartInfoContainer,
.chartTicketInfoContainer,
.chartCambioInfoContainer{
  position: absolute;
  top: 0;
  z-index: 500;
  left: 1px;
  opacity: 0;
  padding: 2px;
  /* width: 100% !important; */
  width: 345px;
}

.chartInfoContainer:hover,
.chartTicketInfoContainer:hover,
.chartCambioInfoContainer:hover{
  opacity: 1;
  transition: all .5s ease-out;
}

.indiceContainer{
  margin-bottom: 20px;
}
.mercadoContainer,
.indiceContainer{
  padding: 10px 15px;
  border: 1px solid #EAEDFA;
  border-radius: 3px;
  width: 350px;
}

.mercadoContainer p{
  margin-bottom: 0;
}

.optionsContainer{
  margin: 70px 0;
}

.alza{
  color: #16C622;
}

.baja{
  color: #FF0000;
}

/* Media Query */
@media screen and (max-width: 1190px) {
  .myCol{
    flex-basis: auto;
  }

  .ticketContainer,
  .itemCambioContainer{
    margin: 3px;
  }
}

@media screen and (max-width: 980px) {
  .center{
    text-align: center;
  }
  .optionsContainer button{
    width: 95%;
    margin: 0 auto;
    padding: 10px 0;
    margin: 5px 0;
  }
}

.fondoContacto{
  background: #00AEEF;
  padding: 30px;
  padding-top: 25px;
  padding-bottom: 25px;
}