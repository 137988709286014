.tabsContainer {
  border: 1px solid #eaedfa;
  border-radius: 4px;
  background-color: #ffffff;
  height: 64px;
  padding: 24px 21px 22px 26px;

}

.card_header {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  height: 64px;
  padding: .75rem 1.25rem;

}

.card_header2 {
  background: #FAFBFF;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  height: 64px;
 
}

.altura {
  height: 64px;
  width: 100%;
}

.tabsContainer {
    border: 1px solid #eaedfa;
    border-radius: 4px;
    background-color: #ffffff;
    height: 64px;
    padding: 24px 21px 22px 26px;

  }

.footer{
    background-color: #00AEEF;
    height: 512px;
    padding: 1.7rem 1.7rem;
}

.footer:hover{
  background-color: #FFE5B4;
  height: 512px;
  padding: 1.7rem 1.7rem;
}

.encabezado_footer {
    border-radius: 4px;
    padding: 24px 21px 22px 26px;
    color: #ffffff;
    border-bottom: 3px solid #ffffff;
    width: 100%;
}

.titulo_footer {
    border-radius: 4px;
    /* padding: 24px 21px 22px 26px; */
    color: #ffffff;
    border-bottom: 3px solid #ffffff;
    width: 100%;
}

.table_footer {
    width: 100%;
}

.tr_footer {
    border-radius: 4px;
    /* padding: 24px 21px 22px 26px; */
    color: #ffffff;
    width: 100%;
}
.encabezado{
  background-color: #00AEEF;

}

.encabezado_sustentable{
  /* background-color: #00aeef; */
  background-color: #FAFBFF;
  border-color: #00cc4e;
  border-width: 5px;
  /* color: #ffffff; */
  border-radius: 10px;
  border-style: solid;

}

.titulo {
  color: #00aeef;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  width: 100%;
  background-color: #FAFBFF;
}
.btn_link {
  text-align: left;
  color: #00aeef;
  text-decoration: none;
  background-color: #FAFBFF;
}
.card{
  width: 18rem;
  background-color: #ffffff;
  border-color: #ffffff;
  border-width: 5px;
  border-radius: 10px;
}

.padding{
padding: 15px 15px 0px 15px;
}

.card_sustentable{
  width: 18rem;
  background-color: #ffffff;
  border-color: #ffffff;
  border-width: 5px;
  border-radius: 10px;
}
.card_body{
  background-color: #696a6d;
  color: #ffffff;
  border-radius: 10px;
} 

.card_body:hover{
  /* background-color : #ff9e5f; */
  color: #ffffff;
  border-radius: 10px;
 } 

.card:hover{
    border-radius: 25px;
    border-color: #ff9e5f;
    border-width: 5px;
    /* background-color: #00aeef; */
  background-color : #ff9e5f;

    color: #ffffff;
}

.card_sustentable:hover{
  border-radius: 10px;
  border-color: #00cc4e;
  border-width: 5px;
  /* background-color: #00aeef; */
  background-color : #00cc4e;
   /* #008F39; */

  color: #ffffff;
}

.card_body_sustentable{
background-color: #696a6d;
color: #ffffff;
border-radius: 5px;
}
.card_header_gray{
background-color: #696a6d;
color: #ffffff;
} 

.content_gray{
background-color: #696a6d;
color: #ffffff;
border-right: 15px solid #00aeef;
border-top:15px solid transparent;
}

.content_blue{
background-color: #00aeef;
color: #ffffff;
border-right: 15px solid #696a6d;
border-top:15px solid transparent;
}

.content_blue_border_right{
border-right: 15px solid #00aeef;
border-top:15px solid transparent;
}


.content_peach{
background-color: #ff9e5f;
color: #ffffff;
}

.content_peach_border_right{
border-right: 15px solid #ff9e5f;
border-top:15px solid transparent;
}

.content_green{
background-color: #00cc4e; 
color: #ffffff;
}

.content_green_border_right{
border-right: 15px solid #00cc4e;
border-top:15px solid transparent;
}

.button:hover{
  /* background-color: #ff7f4f; */
  color: #ffffff;
}
.button{
align-self: center;
}

.border_radius5{
  border-radius: 10px;
}

.leaf{
color: #ffffff;

}
.leaf:hover{
color: #00cc4e;

}
