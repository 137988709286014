
 .card_header {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.125) !important;
    height: 64px;
    padding: .75rem 1.25rem;
  
  }
  
  .card_header2 {
    background: #FAFBFF;
    border: 1px solid rgba(0, 0, 0, 0.125) !important;
    height: 64px;
   
  }

  .titulo_40pct {
    display: inline-block;
    width: 40%;
  }
  .titulo_60pct {
    display: inline-block;
    width: 60%;
  }
   
  .titulo_75pct{
    display: inline-block;
    width: 75%;
  }

  .titulo_ZIP {
    color: gray;
  }

  .titulo {
    color: #00aeef;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    width: 100%;
    background-color: #FAFBFF;
  }