.card_body{
    width: 100%;
}

.titulo, .letra{
    float: left;
}

.titulo{
    max-width: 700px;
    position: relative;
    top: 18px;
    left: 5px;
}

.img{
    background-repeat: no-repeat;
    background-size: contain;
    width: 98%;
}


.cuadroSustentable{
    position: relative;
    margin: auto;
    width: 260px;
    height: 300px;
    padding: 5px;
    padding-left: 10px;
    transform: skewX(-10deg);
}

.gradient{
    background: rgb(44,38,161);
    background: linear-gradient(90deg, #025b81 0%, #009ee2 10%, rgba(218,249,255,1) 100%);
    width: 3px;
    height: 295px;
    float: left;
    border-radius: 10px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: -5px;
    filter: blur(5px);
}

.iconHand{
    color: #FFF;
    font-size: 25px;
}

.icon, .icon2, .icon3{
    width: 45px;
    height: 40px;
    border-radius: 2px;
    vertical-align: middle;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.icon {
    background-color: #009ee2;

}
.icon2 {
    background-color: #176937;

}
.icon3 {
    background-color: #51ad32;

}