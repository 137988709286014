
.cien {
    width: 100%;
    position: relative;
    margin-bottom: 50px;
    height: fit-content !important;
    height: -moz-fit-content !important;
    max-height: inherit !important;
    min-height: 350px;

}

.cien:first-child {
    height: fit-content !important;
    height: -moz-fit-content !important;
    max-height: inherit !important;
}

.cien:hover,.cien:first-child:hover {
    height: fit-content !important;
    height: -moz-fit-content !important;
    max-height: inherit !important;
    filter: inherit !important;
}

.cien:last-child .flechaAbajo, .cien:last-child .linea{
    display: none
}



.left {
  border: 0px;
  border-right: 2px solid #00aeef;
  border-style: dotted;
}

.left .botonLinkSuma {
  position: absolute;
  top: -10px;
  right: -25px;
  height: 47px;
  width: 47px;
  background-color: #fff;
  border-radius: 50%;
  z-index: 11;
}

.left .botonLinkSuma:hover,
.left .botonLinkSuma:focus {
    background-color: #FFF;
  border: 1px solid #fff !important;
  box-shadow: 0 0 0 0px !important;
  transform: scale(0.9);
}

.left .botonLinkSuma:active {
  border: 1px solid #fff !important;
  box-shadow: 0 0 0 0px !important;
  transform: scale(1);
}

.left .btnSuma {
    position: relative;
    left: -1px;
    top: -8px;
    z-index: 10;
}

.left .flechaAbajo {
  position: absolute;
  bottom: -10px;
  right: -10px;
}
.right {
    border: 0px;
  }
  
  .right .botonLinkSuma {
    position: absolute;
    top: -10px;
    left: -25px;
    height: 47px;
    width: 47px;
    background-color: #FFF;
    border-radius: 50%;
    z-index: 11;
  }
  
  .right .botonLinkSuma:hover,
  .right .botonLinkSuma:focus {
    background-color: #FFF;
    border: 1px solid #fff !important;
    box-shadow: 0 0 0 0px !important;
    transform: scale(0.9);
  }
  
  .right .botonLinkSuma:active {
    border: 1px solid #fff !important;
    box-shadow: 0 0 0 0px !important;
    transform: scale(1);
  }
  
  .right .btnSuma {
    position: relative;
    left: 0px;
    top: -8px;
    z-index: 10;
  }
  
  .right .flechaAbajo {
    position: absolute;
    bottom: -10px;
    left: -10px;
  }


  .cien:last-child .left{
    border-right: 0px solid #00aeef;

}

.contenido{
    margin-top: 100px;
    width: 100%;
}

.card{
    background-color: #FAFBFF !important;
    border: 1px solid #EAEDFA !important;
    margin-bottom: 20px;
    width: 100%;
}

.left .tituloStep{
    position: absolute;
    top: 0;
    right: 40px;
}

.right .tituloStep{
    position: absolute;
    top: 0;
    left: 40px;
}

.imagenIpo {
    width: 50%;
    position: relative;
    float: right;
    margin-right: 20px;
    margin-left: 20px;
  }

  .right .imagenIpo {
    width: 50%;
    position: relative;
    float: left;
  }


.linea{
    min-height: 40px;
    width: 0px;
    border: 1px solid #00aeef;
    border-style: dotted;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;
    position: absolute;
    bottom: -80px;
    
}


.left_md{
 display: none;
}

.left_lg{

}


.texto_titulo_contenido{
    margin-left: 15px;
  }
  .texto_contenido{
    margin-top:15px;
    text-align: left !important;
}

.texto_contenido p{
    margin-top:15px;
    margin-bottom:15px;
    font-family: montserrat;
    font-style: normal;
    font-size: 16px;

}

.texto_contenido ul li {
    list-style: none;
    font-size: 16px;
    font-family: montserrat;
    font-style: normal;
  }
.texto_contenido ul li:before {
    font-family: "Font Awesome\ 5 Free";
    content: "\f058";
    color: #00aeef;
    margin-right: 10px;
  }

  .minimoLinea{
    min-height: 100px !important;
    margin-bottom: 27px;
    width: 80% !important;
  }

  .imagen_minimo{
    min-width: 141px !important;
  }

  /* responsivo */
  /* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.99px) { 
  .minimoLinea{
    min-height: 100px !important;
    margin-bottom: 27px;
    width: 100% !important;
    min-width: 100%;
  }
 }

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.99px) { 
    .left {
        border: 0px;
        border-right: 0px solid #00aeef;
        border-style: inherit;
      }

      .left .imagenIpo, .right .imagenIpo{
          width: 50%;
          float: inherit;
      }
      .botonLinkSuma{
        position: absolute !important;
        left: 0 !important;
        right: 0 !important;
        margin-right: auto !important;
        margin-left: auto !important;
        background: #fff;
        background-color: #fff !important;
        top: inherit !important;
        bottom: -50px;
      }
      .btnSuma{
        left: 0px !important;
        top: 0px !important;
      }

      .left, .right{
          text-align: center;
      }

      .left .flechaAbajo, .right .flechaAbajo{
        position: absolute !important;
        left: 0 !important;
        right: 0 !important;
        margin-right: auto !important;
        margin-left: auto !important;
        bottom: -90px;
      }

      .tituloStep{
        position: absolute !important;
        top: -25px !important;
        left: 0 !important;
        right: 0 !important;
      }

      .left_md{
        display: inherit;
       }
       
       .left_lg{
       display: none
       }

       .cien {
        margin-bottom: 80px;
    }
 }

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.99px) { 
    
 }

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.99px) { 
    
 }