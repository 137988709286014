
.carta_box {
    width: 250px;
    height: 250px;
    perspective: 1000px;
    cursor: pointer;
    float: left;
    margin: auto;
    margin-top: 20px;
  }
  
  .carta_box:hover .carta {
    transform: rotateY(180deg);
  }
  
  .carta {
    transform-style: preserve-3d;
    transition: all 1s linear;
  }
  
  .cara {
    position: absolute;
    backface-visibility: hidden;
    border-radius: 16px;
    width: 250px;
    height: 250px;
  }
  
  .cara span {
    padding: 15px;
    height: min-content;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  
  .cara.detras {
    transform: rotateY(180deg);
  }
  

  .color1 {
    background: #009ee2;
  }
  .color2 {
    background: #009aa8;
  }
  .color3 {
    background: #00893b;
  }
  .color4 {
    background: #aec90b;
  }