.contenedor{
    height: 400px;
    width: 100%;
    position: relative;
}

.margenes{
    width: 43%;
    height: 460px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    position: absolute;
}

.logo{
    width: 250px;
}

.vertical_div{
    position: relative;
    display: table;
	height:100%;
}

.vertical{
    display: table-cell;
	vertical-align: middle;
}
.vertical_img{
    display: table-cell;
    vertical-align: middle;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
}

/* // Extra small devices (portrait phones, less than 576px) */
@media (min-width: 575.99px) { 
    .contenedor{
        height: 400px;
    }
    .logo{
        width: 350px;
    }
 }

/* // Small devices (landscape phones, less than 768px) */
@media (min-width: 767.99px) { 
    .contenedor{
        height: 400px;
    }
    .logo{
        width: 100%;
    }
 }

/* // Medium devices (tablets, less than 992px) */
@media (min-width: 991.99px) { 
    .contenedor{
        height: 500px;
    }
 }

/* // Large devices (desktops, less than 1200px) */
@media (min-width: 1199.99px) { 
    .contenedor{
        height: 650px;
    }
 }

 @media (min-width: 1499.99px) { 
    .contenedor{
        height: 700px;
    }
 }