.ticker {
	padding-top: 0px;
	padding-bottom: 3px;
	background: #e2e2e2;
	width: 85%;
	position:absolute;
	min-height: 45px;
}

.search{
	float: left;
    cursor: pointer;
    -webkit-user-select: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-khtml-user-select: none;
	-ms-user-select: none;
    position: relative;
    width: 100%;
}
.search_container {
	padding: 4px;
	background: #e2e2e2;
	width: 15%;
	position:absolute;
	right: 0;
	min-height: 47px;
}
.inputSearch{
	width: 100%;
	float: left;
	height: 37px;
	pointer-events:none;
	border: 2px solid #00aeef;
}
.iconSearch{
	position: absolute;
    right: 10px;
    top: 8px;
    font-size: 18px !important;

}
.idioma {
	float: left;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-khtml-user-select: none;
	-ms-user-select: none;
}

.idioma:hover {
	color: #ffffff;
	outline: none;
	text-decoration: underline;
}

/* .activo{
    font-weight: bold !important;
  } */

/* .activo_sub{
    text-decoration: underline;
   } */

.idioma:active {
	color: #ffffff;
	font-weight: bold;
}

.pipe {
	-webkit-user-select: none;
	-moz-user-select: none;
	-khtml-user-select: none;
	-ms-user-select: none;
	user-select: none;
	float: left;
	margin-left: 5px;
	margin-right: 5px;
	position: relative;
	bottom: 4px;
	cursor: none !important;
	font-size: 27px;
}
.contenedor_idioma {
	width: fit-content;
	width: -moz-fit-content;
	position: relative;
	margin-right: auto;
	margin-left: auto;
	left: 0;
	right: 0;
	top: 0px;
}

  .search:hover i{
	transform: scale(1.2);
  }
  .search:active i{
	transform: scale(1);
  }

:global(.activeLenguage) span {
	color: #ffffff !important;
	font-weight: bold;
}

.nav_menu {
	transition: top 0.3s;
}
/* =========================== */

@import 'https://p.typekit.net/p.css?s=1&k=vba3erx&ht=tk&f=1789.1791.1795&a=504152&app=typekit&e=css';

.icon {
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.primary_nav__links {
	margin-bottom: 0px !important;
}
.grid-guides {
	pointer-events: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.25;
	z-index: 1000;
	display: none;
}

.grid-guides__container {
	height: 100%;
}

.grid-guides__container::after {
	clear: both;
	content: '';
	display: block;
}

@media only screen and (min-width: 48rem) {
	.grid-guides__container {
		margin-left: 0.875rem;
		margin-right: 0.875rem;
	}
}

@media only screen and (min-width: 1289px) {
	.grid-guides__container {
		margin-left: 4.375rem;
		margin-right: 4.375rem;
	}
}

@media (min-width: 108.75rem) {
	.grid-guides__container {
		width: 100rem;
		max-width: 100rem;
		margin-left: auto;
		margin-right: auto;
	}
}

.grid-guides__row {
	width: calc(20% - 1.875rem);
	float: left;
	margin-left: 1.5625rem;
	height: 100%;
	background: #0090ba;
}

.grid-guides__row:nth-child(n + 6) {
	display: none;
}

@media only screen and (min-width: 48rem) {
	.grid-guides__row {
		width: calc(8.33333% - 1.35417rem);
		float: left;
		margin-left: 1.25rem;
	}

	.grid-guides__row:nth-child(n) {
		display: block;
	}

	.grid-guides__row:nth-child(n + 13) {
		display: none;
	}
}

@media only screen and (min-width: 1289px) {
	.grid-guides__row {
		width: calc(4.16667% - 1.30208rem);
		float: left;
		margin-left: 1.25rem;
	}

	.grid-guides__row:nth-child(n) {
		display: block;
	}
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}

body {
	line-height: 1;
}

ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

button {
	border: 0;
	padding: 0;
	background: none;
	cursor: pointer;
}

html {
	box-sizing: border-box;
}

*,
::before,
::after {
	box-sizing: inherit;
}

body {
	font-weight: 300;
	letter-spacing: 0.03em;
	-webkit-font-smoothing: antialiased;
	overflow-x: hidden;
}

body::after {
	content: 'mobile';
	display: none;
}

@media only screen and (min-width: 48rem) {
	body::after {
		content: 'tablet';
	}
}

@media only screen and (min-width: 1289px) {
	body::after {
		content: 'desktop';
	}
}

body main {
	display: block;
}

body #optoutbanner {
	height: 4.4375rem;
	overflow: hidden;
	display: block;
	position: relative;
	z-index: 9;
}

body #optoutbanner .banner__close {
	top: 1.5rem;
}

body #optoutbanner .banner__title {
	font-size: 0.875rem;
	margin-top: -0.125rem;
	margin-bottom: 0.25rem;
}

@media only screen and (min-width: 1289px) {
	body #optoutbanner .banner__title {
		font-size: 1.175rem;
		margin-top: 0;
		margin-bottom: 0;
	}
}

body.overlay-open,
body.card-overlay-open {
	overflow: hidden;
	height: 100vh;
	width: 100vw;
}

body.overlay-open .sub_nav,
body.card-overlay-open .sub_nav {
	opacity: 0;
	visibility: hidden;
}

body .jumpnav {
	font-weight: 500;
	position: absolute;
	height: 3.75rem;
	width: auto;
	border-radius: 0.125rem;
	background: #0090ba;
	display: flex;
	align-items: center;
	box-shadow: 0 0 0.625rem 0 rgba(0, 0, 0, 0.2);
	pointer-events: auto;
	margin: 0;
	font-size: 0.875rem;
	letter-spacing: 0.14em;
	text-transform: uppercase;
	color: #fff;
	padding: 0 1.4375rem;
	top: -3.75rem;
	left: 0;
	opacity: 0;
	transition: top 0.25s, opacity 0.25s;
	z-index: 1000;
}

body .jumpnav:focus {
	opacity: 1;
	top: 0;
}

body .sliding-popup-bottom {
	background: rgba(0, 0, 0, 0.9) !important;
	width: 100%;
	overflow: hidden;
	display: block;
}

body .sliding-popup-bottom .eu-cookie-compliance-content {
	display: flex;
	flex-direction: column;
	max-width: 100%;
	padding: 0.9375rem;
	color: #fff;
}

body .sliding-popup-bottom .eu-cookie-compliance-content .eu-cookie-compliance-message {
	margin: 0.625rem auto;
	max-width: 100%;
}

body .sliding-popup-bottom .eu-cookie-compliance-content .eu-cookie-compliance-message h2 {
	font-weight: 500;
	font-size: 1.125rem;
	line-height: 1.78;
	letter-spacing: 0.04em;
	text-align: center;
	margin-bottom: 0.625rem;
	text-transform: uppercase;
}

body .sliding-popup-bottom .eu-cookie-compliance-content .eu-cookie-compliance-message p {
	font-weight: 400;
	max-width: 90%;
	margin: 0 auto;
	text-align: center;
	font-size: 1rem;
	line-height: 1.5;
	letter-spacing: 0.04em;
	display: block;
	color: #fff;
}

body .sliding-popup-bottom .eu-cookie-compliance-content .eu-cookie-compliance-message a {
	color: #0090ba;
}

body .sliding-popup-bottom .eu-cookie-compliance-content .eu-cookie-compliance-buttons {
	margin: 0 auto;
}

body .sliding-popup-bottom .eu-cookie-compliance-content .eu-cookie-compliance-buttons button {
	border-radius: 0.125rem;
	color: #fff;
	padding: 0 0.625rem;
	cursor: pointer;
	margin: 0 0.625rem 0.3125rem;
	text-decoration: none;
	line-height: 1.625rem;
	font-size: 0.8125rem;
}

body .sliding-popup-bottom .eu-cookie-compliance-content .eu-cookie-compliance-buttons button.agree-button {
	font-weight: 500;
	background: #0090ba;
	display: flex;
	align-items: center;
	height: 3.75rem;
	box-shadow: 0 0 0.625rem 0 rgba(0, 0, 0, 0.2);
	pointer-events: auto;
	margin: 0;
	font-size: 0.875rem;
	letter-spacing: 0.14em;
	text-transform: uppercase;
	transition: color 0.2s;
	color: #fff;
	padding: 0 1.4375rem;
	border-radius: 0;
}

body .sliding-popup-bottom .eu-cookie-compliance-content .eu-cookie-compliance-buttons button.agree-button:hover,
body .sliding-popup-bottom .eu-cookie-compliance-content .eu-cookie-compliance-buttons button.agree-button:focus {
	color: rgba(255, 255, 255, 0.75);
}

body .sliding-popup-bottom .eu-cookie-compliance-content .eu-cookie-compliance-buttons button.decline-button {
	background-color: #666;
}

body.no-scroll {
	overflow: hidden;
}

a {
	color: inherit;
	text-decoration: none;
}

a:focus,
a:hover {
	text-decoration: underline;
}

::-ms-clear {
	display: none;
}

:focus {
	outline: none !important;
}

.disclaimer__container {
	width: 100%;
	display: block;
	overflow: hidden;
	max-width: 62.5rem;
	padding: 1.875rem;
	margin: 0 auto;
}

.disclaimer__container h2,
.disclaimer__container h3,
.disclaimer__container h4,
.disclaimer__container h5,
.disclaimer__container h6 {
	font-weight: 600;
	font-size: 1.25rem;
	letter-spacing: 0.05em;
	line-height: 1.75;
	margin-bottom: 0.625rem;
}

@media only screen and (min-width: 48rem) {
	.disclaimer__container h2,
	.disclaimer__container h3,
	.disclaimer__container h4,
	.disclaimer__container h5,
	.disclaimer__container h6 {
		font-size: 1.375rem;
	}
}

@media only screen and (min-width: 1289px) {
	.disclaimer__container h2,
	.disclaimer__container h3,
	.disclaimer__container h4,
	.disclaimer__container h5,
	.disclaimer__container h6 {
		font-size: 1.5rem;
	}
}

.disclaimer__container p,
.disclaimer__container ol,
.disclaimer__container ul {
	font-weight: 300;
	font-size: 0.875rem;
	line-height: 2.14;
	letter-spacing: 0.03em;
	margin-bottom: 1.875rem;
	color: #000;
	font-size: 0.75rem;
	line-height: 1.2;
	margin-bottom: 0.625rem;
}

@media only screen and (min-width: 48rem) {
	.disclaimer__container p,
	.disclaimer__container ol,
	.disclaimer__container ul {
		font-size: 1rem;
		margin-bottom: 2.1875rem;
	}
}

@media only screen and (min-width: 1289px) {
	.disclaimer__container p,
	.disclaimer__container ol,
	.disclaimer__container ul {
		font-size: 1.125rem;
	}
}

@media only screen and (min-width: 48rem) {
	.disclaimer__container p,
	.disclaimer__container ol,
	.disclaimer__container ul {
		font-size: 0.75rem;
		line-height: 1.2;
		margin-bottom: 0.625rem;
	}
}

@media only screen and (min-width: 1289px) {
	.disclaimer__container p,
	.disclaimer__container ol,
	.disclaimer__container ul {
		font-size: 0.75rem;
		line-height: 1.2;
		margin-bottom: 0.625rem;
	}
}

.disclaimer__container p li,
.disclaimer__container ol li,
.disclaimer__container ul li {
	position: relative;
	padding-left: 2.0625rem;
	margin-bottom: 0.625rem;
}

.disclaimer__container p li::after,
.disclaimer__container ol li::after,
.disclaimer__container ul li::after {
	display: block;
	content: '';
	clear: both;
}

@media only screen and (min-width: 48rem) {
	.disclaimer__container p li,
	.disclaimer__container ol li,
	.disclaimer__container ul li {
		margin-bottom: 0.625rem;
		padding-left: 2.6875rem;
	}
}

@media only screen and (min-width: 1289px) {
	.disclaimer__container p li,
	.disclaimer__container ol li,
	.disclaimer__container ul li {
		padding-left: 0;
		padding-right: 2.0625rem;
		left: 2.0625rem;
		margin-bottom: 0.625rem;
	}
}

.disclaimer__container p li::before,
.disclaimer__container ol li::before,
.disclaimer__container ul li::before {
	float: left;
	margin-left: -2.0625rem;
	color: #0090ba;
}

@media only screen and (min-width: 48rem) {
	.disclaimer__container p li::before,
	.disclaimer__container ol li::before,
	.disclaimer__container ul li::before {
		margin-left: -2.6875rem;
	}
}

@media only screen and (min-width: 1289px) {
	.disclaimer__container p li::before,
	.disclaimer__container ol li::before,
	.disclaimer__container ul li::before {
		margin-left: -2.0625rem;
	}
}

@media only screen and (min-width: 48rem) {
	.disclaimer__container p li::after,
	.disclaimer__container ol li::after,
	.disclaimer__container ul li::after {
		display: none;
	}
}

.disclaimer__container p li:last-child,
.disclaimer__container ol li:last-child,
.disclaimer__container ul li:last-child {
	margin-bottom: 0;
}

.disclaimer__container p li a,
.disclaimer__container ol li a,
.disclaimer__container ul li a {
	color: #00bfd5;
}

.disclaimer__container p a,
.disclaimer__container ol a,
.disclaimer__container ul a {
	color: #00bfd5;
	text-decoration: none;
}

.disclaimer__container p a:hover,
.disclaimer__container p a:focus,
.disclaimer__container ol a:hover,
.disclaimer__container ol a:focus,
.disclaimer__container ul a:hover,
.disclaimer__container ul a:focus {
	text-decoration: underline;
}

.disclaimer__container p u,
.disclaimer__container ol u,
.disclaimer__container ul u {
	text-decoration: underline;
}

.disclaimer__container p em,
.disclaimer__container ol em,
.disclaimer__container ul em {
	font-style: italic;
}

.disclaimer__container p strong,
.disclaimer__container ol strong,
.disclaimer__container ul strong {
	font-weight: 500;
}

.disclaimer__container ol {
	counter-reset: item;
}

.disclaimer__container ol ol {
	counter-reset: item;
}

.disclaimer__container ol li::before {
	font-weight: 500;
	content: counter(item, decimal-leading-zero);
	counter-increment: item;
}

.disclaimer__container > ul > li::before {
	content: 'â€¢';
}

@media only screen and (min-width: 48rem) {
	.disclaimer__container > ul .pullquote,
	.disclaimer__container > ol .pullquote {
		margin-left: 0;
		margin-bottom: 1em;
	}
}

.disclaimer__container sup {
	font-size: 0.8em;
	vertical-align: super;
}

.disclaimer__container sub {
	font-size: 0.8em;
	vertical-align: sub;
}

.body__right-rail .inside-container {
	top: 6.25rem;
	position: -webkit-sticky;
	position: sticky;
	float: left;
	width: 100%;
}

#block-tabs {
	margin-top: 5rem;
}

.page {
	position: relative;
}

.page__main {
	background-color: #fff;
}

.with-primary_nav .page__main {
	padding-top: 4.375rem;
}

@media only screen and (min-width: 1289px) {
	.with-primary_nav .page__main {
		padding-top: 5rem;
	}
}

@media only screen and (min-width: 1289px) {
	.with-primary_nav .latest-news + .page__main {
		padding-top: 0;
	}
}

.with-sub_nav .page__main {
	padding-top: 2.5625rem;
}

@media only screen and (min-width: 48rem) {
	.with-sub_nav .page__main {
		padding-top: 2.5625rem;
	}
}

@media only screen and (min-width: 1289px) {
	.with-sub_nav .page__main {
		padding-top: 6.875rem;
	}
}

.page__main .dialog-off-canvas-main-canvas {
	position: relative;
}

.overlay-open .page__main {
	pointer-events: none;
}

#tfa-entry-form {
	margin-top: 6.25rem;
}

.usabilla_live_button_container {
	transition: -webkit-transform 0.3s ease;
	transition: transform 0.3s ease;
	transition: transform 0.3s ease, -webkit-transform 0.3s ease;
	will-change: transform;
}

.overlay-open .usabilla_live_button_container {
	display: none;
}

@media only screen and (min-width: 1289px) {
	.overlay-open .usabilla_live_button_container {
		-webkit-transform: none;
		transform: none;
	}
}

.header-group {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 100;
}

.with-header-ads .header-group {
	top: -7.5rem;
}

.with-header-ads--loaded .header-group {
	top: 0;
}

.page-node-type-homepage .header-group {
	margin-bottom: -5rem;
}

.page-node-type-homepage #optoutbanner + a + noscript + .dialog-off-canvas-main-canvas .header-group {
	padding-top: 5rem;
	margin-bottom: -10rem;
}

.path-frontpage.page-node-type-homepage .header-group {
	margin-bottom: -10rem;
	padding-top: 5rem;
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.primary_nav {
	min-height: 4.375rem;
	z-index: 1025;
	top: 0;
	will-change: transform;
}

.overlay-open .primary_nav {
	background-color: #fff;
	position: fixed;
}

.page-node-type-homepage .primary_nav + .page__main {
	padding-top: 0;
}

.overlay-open #optoutbanner:not([style]) + a + noscript + .dialog-off-canvas-main-canvas .primary_nav {
	position: fixed;
	width: 100%;
}

@media only screen and (min-width: 1289px) {
	.primary_nav {
		min-height: 5rem;
	}

	.page-node-type-homepage .primary_nav {
		margin-bottom: -5rem;
	}

	.primary_nav + .page__main {
		padding-top: 5rem;
	}
}

.primary_nav_sticky {
	position: -webkit-sticky;
	position: sticky;
	width: 100%;
	top: 0;
}

.nav--hidden .primary_nav {
	-webkit-transform: translateY(-100%);
	transform: translateY(-100%);
}

.primary_nav__content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 4.375rem;
	padding: 1.125rem 1.5625rem;
	box-shadow: 0 0 0.625rem 0 rgba(0, 0, 0, 0.1);
	background-color: #00aeef;
	position: relative;
	z-index: 102;
	transition: background-color 0.25s ease;
}

.primary_nav__hamburger {
	font-size: 1rem;
	color: #0090ba;
	padding: 1.25rem;
	margin-left: -1.25rem;
}

.overlay-open .primary_nav--transparent .primary_nav__hamburger {
	color: #0090ba;
}

.primary_nav__logo {
	flex-shrink: 0;
	position: relative;
}

.primary_nav__logo img {
	max-width: 100%;
	max-height: 100%;
	/* width: 150px; */
	width: 7.8125rem;
	/* height:2.1875rem; */
	transition: -webkit-transform 0.2s ease-out;
	transition: transform 0.2s ease-out;
	transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
	-webkit-transform-origin: left center;
	transform-origin: left center;
}

@media only screen and (min-width: 1298px) {
	.primary_nav__hamburger {
		display: none;
	}
	.primary_nav__content {
		height: 5rem;
		padding: 0;
		justify-content: flex-start;
	}
	.primary_nav__logo img {
		/* display: none; */
		padding-left: 32px;
		/* width:10.9375rem; */
		/* height:2.75rem  */
	}
	.primary_nav[data-mega-menu-disabled='TRUE'] .primary_nav__logo {
		position: absolute;
		left: 2.1875rem;
	}

	.primary_nav[data-mega-menu-disabled='TRUE'] .primary_nav__content {
		justify-content: center;
	}
}





.primary_nav__logo_normal {
	opacity: 1;
}

.page-node-type-homepage .primary_nav--transparent .primary_nav__logo_normal {
	opacity: 0;
}

.page-node-type-homepage.overlay-open .primary_nav--transparent .primary_nav__logo_normal {
	opacity: 1;
}

.primary_nav__logo-opaque {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
}

.primary_nav--transparent .primary_nav__logo-opaque {
	opacity: 1;
}

.overlay-open .primary_nav--transparent .primary_nav__logo-opaque {
	opacity: 0;
}

.primary_nav__links {
	position: absolute;
	top: 4.375rem;
	left: 0;
	width: 100%;
	height: calc(110vh - 0rem) ;
	background: #fff;
	padding: 0;
	overflow-x: auto;
	overflow-y: auto !important;
	transition: visibility 0.2s, opacity 0.2s ease-out;
	-webkit-overflow-scrolling: touch;
}

.primary_nav__links[aria-hidden='true'] {
	opacity: 0;
	visibility: hidden;
}

.primary_nav__links::before {
	content: '';
	width: 100%;
	height: 0.8125rem;
	box-shadow: 0 0.25rem 0.625rem -0.1875rem rgba(0, 0, 0, 0.1);
	position: absolute;
	top: -0.8125rem;
	left: 0;
}

@media only screen and (min-width: 1298px) {
	.primary_nav__links {
		position: static;
		height: auto;
		width: auto;
		background: none;
		padding: 0;
		transition: none;
		display: flex;
		align-items: center;
		top: auto;
		left: auto;
		margin-left: 2.1875rem;
		margin-right: 115px;
	}

	.primary_nav__links[aria-hidden='true'] {
		-webkit-transform: none;
		transform: none;
		opacity: 1;
		overflow: auto;
		visibility: visible;
	}
}

.primary_nav__link {
	font-weight: 500;
	font-size: 0.875rem;
	color: #0090ba;
	letter-spacing: 2px;
	text-transform: uppercase;
	border-bottom: 0.125rem solid #fff;
	transition: opacity 0.1s 0.2s, -webkit-transform 0.3s 0.2s ease-out;
	transition: transform 0.3s 0.2s ease-out, opacity 0.1s 0.2s;
	transition: transform 0.3s 0.2s ease-out, opacity 0.1s 0.2s, -webkit-transform 0.3s 0.2s ease-out;
	background-color: rgba(105, 106, 109, 1);
	position: relative;
	padding: 0;
}

.primary_nav__link--active {
	text-decoration: underline;
}

.primary_nav__link[data-type='login'] {
	background-color: #fff;
}

.primary_nav__link[aria-hidden='true'] {
	display: none;
}

.primary_nav__links[aria-hidden='true'] .primary_nav__link {
	-webkit-transform: translateY(-15%);
	transform: translateY(-15%);
	opacity: 0;
}

@media only screen and (min-width: 1298px) {
	.primary_nav__link {
		flex-shrink: 0;
		padding: 0;
		border: 0;
		transition: none;
		margin: 0;
		background-color: transparent;
		position: static;
	}

	.primary_nav__link[data-type='login'] {
		display: none;
	}

	.primary_nav__links[aria-hidden='true'] .primary_nav__link {
		-webkit-transform: none;
		transform: none;
		opacity: 1;
	}
}

.primary_nav__anchor {
	display: block;
	width: 100%;
	padding: 0 0 0 1.5625rem;
	line-height: 3.125rem;
	transition: all 0.25s ease;
	text-decoration: none;
	pointer-events: none;
	justify-content: center !important;
}

.primary_nav__anchor:hover {
	text-decoration: none;
}

.primary_nav__anchor[data-mega-menu-enabled='false'] {
	pointer-events: auto;
}

@media only screen and (min-width: 48rem) {
	.primary_nav__anchor {
		line-height: 4.6875rem;
		padding-left: 2.1875rem;
	}
}

@media only screen and (min-width: 1298px) {
	.primary_nav__anchor {
		pointer-events: auto;
		padding: 0 10px;
		width: auto;
		display: flex;
		justify-content: left;
		height: 80px;
	}

	.primary_nav__anchor:focus,
	.primary_nav__link:hover .primary_nav__anchor {
		background-color: #0090ba;
		color: #fff;
	}

	.primary_nav__link > :global(.active) {
		background-color: #0090ba;
		color: #fff;
	}
}

.primary_nav__expand {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 3.125rem;
}

@media only screen and (min-width: 48rem) {
	.primary_nav__expand {
		height: 4.6875rem;
	}
}

@media only screen and (min-width: 1298px) {
	.primary_nav__expand {
		opacity: 0;
		pointer-events: none;
	}
}

.primary_nav__expand_caret {
	color: #0090ba;
	font-size: 0.6875rem;
	float: right;
	margin-right: 1.5625rem;
	transition: -webkit-transform 0.15s ease;
	transition: transform 0.15s ease;
	transition: transform 0.15s ease, -webkit-transform 0.15s ease;
}
.primary_nav__expand_caret2 {
	color: #0090ba;
	font-size: 0.6875rem;
	float: right;
	margin-right: 1.5625rem;
	transition: -webkit-transform 0.15s ease;
	transition: transform 0.15s ease;
	transition: transform 0.15s ease, -webkit-transform 0.15s ease;
}
.primary_nav__expand_caret::before {
	right: 24px;
	position: absolute;
	top: 32px;
	color: #fff;
}

.primary_nav__expand[data-enabled='true'] .primary_nav__expand_caret {
	-webkit-transform: scale(-1);
	transform: scale(-1);
}

.primary_nav__expand_caret2 {
	color: #ffffff;
}

.primary_nav__expand[data-enabled='true'] .primary_nav__expand_caret2 {
	transform: rotate(90deg);
	color: #ffffff;
}

@media only screen and (min-width: 48rem) {
	.primary_nav__expand_caret {
		font-size: 0.875rem;
		margin-right: 3.125rem;
	}
	.primary_nav__expand_caret2 {
		font-size: 0.875rem;
		margin-right: 3.125rem;
	}
}

.primary_nav__anchor[data-mega-menu-enabled='true'] .primary_nav__anchor_text {
	margin-right: 0px;
	margin-left: 5px;
}

.primary_nav__anchor_caret {
	color: #0090ba;
	font-size: 0.75rem;
	display: none;
	transition: -webkit-transform 0.15s ease;
	transition: transform 0.15s ease;
	transition: transform 0.15s ease, -webkit-transform 0.15s ease;
}

.primary_nav__anchor_caret::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤£';
}

.primary_nav__link:hover .primary_nav__anchor_caret {
	-webkit-transform: scale(-1);
	transform: scale(-1);
	color: #fff;
}

.primary_nav__cta {
	color: #0090ba;
	font-weight: 400;
	text-decoration: none;
	margin-bottom: 1.875rem;
}

@media only screen and (min-width: 1298px) {
	.primary_nav__anchor_caret {
		display: block;
	}

	.primary_nav__cta {
		font-size: 0.875rem;
		letter-spacing: 2px;
		position: absolute;
		margin-bottom: 0;
		bottom: 0;
	}
}

.primary_nav__cta_text {
	float: left;
}

.primary_nav__cta-arrow {
	float: left;
	clear: both;
	font-size: 0.6875rem;
	padding-top: 0.3125rem;
}

.primary_nav__cta-arrow::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤Ÿ';
}

.primary_nav__mega {
	background-color: #fffffff7;
	padding: 1.25rem 1.5625rem;
}

.primary_nav__mega[aria-hidden='true'] {
	display: none;
}

@media only screen and (min-width: 48rem) {
	.primary_nav__mega {
		padding: 2.1875rem 2.5rem;
		display: flex;
		justify-content: space-between;
		height: fit-content !important;
		height: -moz-fit-content !important;
	}
}

@media only screen and (min-width: 1298px) {
	.primary_nav__mega {
		justify-content: flex-start;
		position: absolute;
		top: 4.7rem;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		padding: 1.875rem;
		opacity: 0;
		pointer-events: none;
		-webkit-transform: translateX(-0.625rem);
		transform: translateX(-0.625rem);
		transition: all 0.35s ease-out;
		box-shadow: 0 0.5625rem 0.625rem 0 rgba(0, 0, 0, 0.2);
		width: 100%;
		max-width: 75%;
		height: fit-content !important;
		min-height: 150px;
	}

	.primary_nav__mega[aria-hidden='true'] {
		display: flex;
	}

	.primary_nav__mega:hover,
	.primary_nav__mega[aria-hidden='false'],
	.primary_nav__link:hover .primary_nav__mega {
		opacity: 1;
		pointer-events: auto;
		-webkit-transform: translateX(0);
		transform: translateX(0);
		transition: all 0.2s ease;
	}

	.nav--hidden .primary_nav__link:hover .primary_nav__mega {
		opacity: 0;
		pointer-events: none;
		-webkit-transform: translateX(-0.625rem);
		transform: translateX(-0.625rem);
		transition: all 0.35s ease-out;
	}
}

@media only screen and (min-width: 48rem) {
	.primary_nav__mega_list {
		width: 100%;
	}

	.primary_nav__mega[data-featured-content='false'] .primary_nav__mega_list {
		display: flex;
		flex-flow: column nowrap;
		width: 100%;
	}
}

@media only screen and (min-width: 1289px) {
	.primary_nav__mega_list {
		width: calc(100% - 15.4375rem);
		max-width: 100%;
		display: inline-block;
		position: relative;
		z-index: 1;
		/* margin-right: 1.25rem; */
		width: 100% !important;
	}

	.primary_nav__mega[data-featured-content='false'] .primary_nav__mega_list {
		flex-flow: row nowrap;
	}
}

.primary_nav__mega_column {
	display: flex;
	flex-direction: column;
	border-bottom: 0 solid #e1e1e6;
	padding-bottom: 1.25rem;
	margin-bottom: 5px;
	float: left;
}
.primary_nav__mega_column:last-child {
	border-bottom: none;
}

@media only screen and (min-width: 48rem) {
	.primary_nav__mega[data-featured-content='false'] .primary_nav__mega_column {
		flex-basis: 33%;
		min-width: 48vw;
	}
}

@media only screen and (min-width: 1289px) {
	.primary_nav__mega_column {
		min-width: 14.0625rem;
		max-width: 14.5625rem;
		/* width: 33%; */
		border-bottom: 0;
		border-right: 0 solid #e1e1e6;
		padding: 0 10px;
		padding-top: 10px;
		margin-bottom: 10px;
	}
	.primary_nav__mega_column_ultimo {
		min-width: 10rem;
		max-width: 10rem;
		width: 33%;
		border-bottom: 0;
		border-right: 0 solid #e1e1e6;
		padding: 0 10px;
		padding-top: 10px;
		margin-bottom: 10px;
	}

	.alto_maximo {
		min-height: 220px;
	}

	.primary_nav__mega_column:first-child {
		position: relative;
		padding-bottom: 0.125rem;
	}

	.primary_nav__mega_column:last-child {
		/* border-right:none */
	}

	.primary_nav__mega[data-featured-content='false'] .primary_nav__mega_column {
		min-width: 14.0625rem;
	}
}

.primary_nav__mega-subnav {
	margin-bottom: 1.25rem;
}

.primary_nav__mega-subnav-item {
	margin-bottom: 1.0625rem;
}

.primary_nav__mega-subnav-item:last-child {
	margin-bottom: 0;
}

@media only screen and (min-width: 1289px) {
	.primary_nav__mega-subnav-item {
		margin-bottom: 1.25rem;
	}
}

.primary_nav__mega-subnav_link {
	font-weight: 300;
	color: #000;
	font-size: 1.25rem;
	line-height: 1.75rem;
	letter-spacing: 0.83px;
	text-transform: none;
	-webkit-text-decoration-color: transparent;
	text-decoration-color: transparent;
	transition: -webkit-text-decoration-color 0.3s ease-in;
	transition: text-decoration-color 0.3s ease-in;
	transition: text-decoration-color 0.3s ease-in, -webkit-text-decoration-color 0.3s ease-in;
}

.primary_nav__mega-subnav_link:hover {
	-webkit-text-decoration-color: #000;
	text-decoration-color: #000;
}

@media only screen and (min-width: 1289px) {
	.primary_nav__mega-subnav_link {
		font-size: 0.875rem;
		letter-spacing: 0.58px;
		line-height: 1.5;
	}
}

.primary_nav__mega_links-item {
	margin-bottom: 1.5625rem;
}

@media only screen and (min-width: 1289px) {
	.primary_nav__mega_links-item {
		margin-bottom: 0.9375rem;
	}
}

.primary_nav__mega_links_link {
	font-weight: 400;
	font-size: 1.125rem;
	line-height: 1.75rem;
	letter-spacing: 0.75px;
	text-transform: none;
	color: #0090ba;
	-webkit-text-decoration-color: transparent;
	text-decoration-color: transparent;
	transition: -webkit-text-decoration-color 0.3s ease-in;
	transition: text-decoration-color 0.3s ease-in;
	transition: text-decoration-color 0.3s ease-in, -webkit-text-decoration-color 0.3s ease-in;
}

.primary_nav__mega_links_link:hover {
	-webkit-text-decoration-color: #0090ba;
	text-decoration-color: #0090ba;
}

@media only screen and (min-width: 1289px) {
	.primary_nav__mega_links_link {
		font-size: 0.75rem;
		letter-spacing: 0.5px;
		line-height: 1.4;
	}
}

.primary_nav__featured {
	display: none;
}

[data-featured-content='false'] .primary_nav__featured {
	display: none;
}

@media only screen and (min-width: 48rem) {
	.primary_nav__featured {
		display: block;
		width: calc(60% - 5rem);
	}
}

@media only screen and (min-width: 1289px) {
	.primary_nav__featured {
		width: 15.4375rem;
		position: relative;
		z-index: 0;
	}
}

.primary_nav__featured-item-eyebrow {
	display: inline-block;
	margin-bottom: 0.625rem;
}

.primary_nav__featured-item-eyebrow .eyebrow {
	font-weight: 400;
	font-size: 0.75rem;
	text-transform: uppercase;
	letter-spacing: 0.16em;
	color: #fff;
	padding: 0.5rem 0.625rem 0.4375rem;
	background: #000;
	display: inline-block;
}

.primary_nav__featured-item-figure {
	margin-bottom: 0.625rem;
}

.primary_nav__featured-item-title {
	font-weight: 300;
	font-size: 1.125rem;
	line-height: 1.56;
	letter-spacing: 0.75px;
	color: #2b2b2b;
	text-transform: none;
}

.primary_nav__header {
	font-weight: 400;
	color: #a7a9ac;
	font-size: 0.75rem;
	line-height: 0.9375rem;
	letter-spacing: 2px;
	margin-bottom: 1.25rem;
}

@media only screen and (min-width: 1289px) {
	.primary_nav__header {
		font-size: 0.75rem;
		letter-spacing: 2px;
	}

	.primary_nav__header:not(:first-child) {
		margin-top: 0.625rem;
	}
}

.primary_nav__header_link {
	position: relative;
	display: inline-block;
	padding-right: 1.25rem;
	width: 100%;
}

.primary_nav__header :global(.active) span{
	text-decoration: underline !important;
}

.second_nav__header_link {
	position: relative;
	display: inline-block;
	padding-right: 1.25rem;
	width: 100%;
}

.subsub {
	text-transform: none;
}
.subsub_links {
	padding-left: 15px;
}

.primary_nav__header_link_caret {
	font-size: 0.4375rem;
	color: #0090ba;
	position: absolute;
	right: 0;
	top: 6px;
	font-size: 10px;
	/* -webkit-transform:rotateZ(-90deg) scale(0.7);
transform:rotateZ(-90deg) scale(0.7) */
}
.second_nav__header_link_caret {
	font-size: 0.4375rem;
	color: #0090ba;
	position: absolute;
	right: 0;
	top: 6px;
	font-size: 10px;
	/* -webkit-transform:rotateZ(-90deg) scale(0.7);
transform:rotateZ(-90deg) scale(0.7) */
}

.primary_nav__header_link_caret::before {
}

.primary_nav__mobile-login,
.primary_nav__mobile-account {
	font-weight: 500;
	font-size: 0.875rem;
	color: #0090ba;
	letter-spacing: 2px;
	text-transform: uppercase;
	padding: 0;
	margin-left: 1.5625rem;
	line-height: 3.125rem;
	border-bottom: 0.125rem solid #0090ba;
	display: inline-block;
	width: calc(100% - 3.125rem);
}

@media only screen and (min-width: 48rem) {
	.primary_nav__mobile-login,
	.primary_nav__mobile-account {
		line-height: 4.6875rem;
		margin-left: 2.1875rem;
	}
}

@media only screen and (min-width: 1289px) {
	.primary_nav__mobile-login,
	.primary_nav__mobile-account {
		display: none !important;
	}
}

.primary_nav__right {
	display: flex;
	align-items: center;
	margin: 0.3125rem 0;
}

@media only screen and (min-width: 1298px) {
	.primary_nav__right {
		flex-shrink: 0;
		position: absolute;
		right: 0;
		width: 125px;
	}
}

.primary_nav__login {
	font-weight: 500;
	font-size: 0.875rem;
	color: #0090ba;
	letter-spacing: 2px;
	text-transform: uppercase;
	border-bottom: 0.125rem solid #fff;
	transition: opacity 0.1s 0.2s, -webkit-transform 0.3s 0.2s ease-out;
	transition: transform 0.3s 0.2s ease-out, opacity 0.1s 0.2s;
	transition: transform 0.3s 0.2s ease-out, opacity 0.1s 0.2s, -webkit-transform 0.3s 0.2s ease-out;
	background-color: #f9f9f9;
	position: relative;
	margin: 0;
	padding: 0 1.875rem;
	display: none;
}

.primary_nav__login--active {
	text-decoration: underline;
}

@media only screen and (min-width: 1289px) {
	.primary_nav__login {
		flex-shrink: 0;
		padding: 0;
		border: 0;
		transition: none;
		margin: 0 2.1875rem;
		background-color: transparent;
		position: static;
		display: block;
	}
}

.primary_nav__account {
	font-size: 1.25rem;
	color: #0090ba;
	display: none;
}

.primary_nav__account::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤œ';
}

@media only screen and (min-width: 1289px) {
	.primary_nav__account {
		display: block;
		padding: 0 1.875rem;
	}
}

.primary_nav__solution-contact {
	font-weight: 500;
	font-size: 0.75rem;
	letter-spacing: 0.17em;
	text-transform: uppercase;
	color: #fff;
	height: 1.875rem;
	padding: 0 0.9375rem;
	background: #0090ba;
	display: none;
}

@media only screen and (min-width: 1289px) {
	.primary_nav__solution-contact {
		margin-right: 1.875rem;
	}
}

.primary_nav--transparent {
	width: 100%;
}

.primary_nav--transparent .primary_nav__content {
	background: none;
	box-shadow: none;
}

.primary_nav--transparent .primary_nav__hamburger,
.primary_nav--transparent .primary_nav__link,
.primary_nav--transparent .primary_nav__search,
.primary_nav--transparent .primary_nav__login,
.primary_nav--transparent .primary_nav__account {
	color: #fff;
}

.primary_nav--transparent .primary_nav__search {
	border-left-color: rgba(255, 255, 255, 0.35);
}

.overlay-open .primary_nav--transparent .primary_nav__search {
	color: #0090ba;
}

.primary_nav--transparent .primary_nav__links[aria-hidden='false'] .primary_nav__link {
	color: #0090ba;
}

@media only screen and (min-width: 1289px) {
	.primary_nav--transparent .primary_nav__links[aria-hidden='false'] .primary_nav__link {
		color: #fff;
	}
}

.primary_nav--transparent + .page__admin {
	padding-top: 0 !important;
}

.primary_nav--transparent ~ .page__main #block-tabs {
	position: absolute;
	top: 5rem;
	z-index: 10;
	margin-top: 1.25rem;
	margin-left: 1.5625rem;
}

@media only screen and (min-width: 1289px) {
	.primary_nav--transparent ~ .page__main #block-tabs {
		top: 6.25rem;
		margin-left: 5.625rem;
	}
}

.primary_nav--transparent ~ .page__main #block-tabs .tabs {
	padding: 0;
	margin: 0;
}

.primary_nav--transparent ~ .page__main #block-tabs a {
	color: #fff;
}

.toolbar-horizontal.toolbar-tray-open .primary_nav--transparent ~ .page__main #block-tabs {
	margin-top: 2.5rem;
}

.primary_nav--solution.primary_nav--secondary {
	min-height: 3.125rem;
}

.primary_nav--solution.primary_nav--secondary .primary_nav__content {
	height: 3.125rem;
}

.primary_nav--solution.primary_nav--secondary .primary_nav__logo {
	display: flex;
	align-items: center;
}

.primary_nav--solution.primary_nav--secondary .primary_nav__logo img {
	-webkit-transform: scale(0.85);
	transform: scale(0.85);
}

.primary_nav--solution.primary_nav--secondary .primary_nav__hamburger,
.primary_nav--solution.primary_nav--secondary .primary_nav__links,
.primary_nav--solution.primary_nav--secondary .primary_nav__right {
	display: none;
}

.primary_nav--solution.primary_nav--secondary .primary_nav__solution-contact {
	display: block;
}

.page-node-type-homepage.user-logged-in .primary_nav .primary_nav__links[aria-hidden='false'] .primary_nav__link {
	color: #0090ba;
}

@media only screen and (min-width: 1289px) {
	.page-node-type-homepage.user-logged-in .primary_nav .primary_nav__links[aria-hidden='false'] .primary_nav__link {
		color: #0090ba;
	}
}

.page-node-type-homepage.user-logged-in
	.primary_nav.primary_nav--transparent
	.primary_nav__links[aria-hidden='false']
	.primary_nav__link {
	color: #0090ba;
}

@media only screen and (min-width: 1289px) {
	.page-node-type-homepage.user-logged-in
		.primary_nav.primary_nav--transparent
		.primary_nav__links[aria-hidden='false']
		.primary_nav__link {
		color: #fff;
	}
}

body.user-logged-in .primary_nav--transparent .primary_nav__hamburger,
body.user-logged-in .primary_nav--transparent .primary_nav__link,
body.user-logged-in .primary_nav--transparent .primary_nav__login,
body.user-logged-in .primary_nav--transparent .primary_nav__account {
	color: #fff;
}

body.user-logged-in .primary_nav--transparent .primary_nav__search {
	border-left-color: #e1e1e6;
	color: #fff;
}

body.user-logged-in.overlay-open .primary_nav--transparent .primary_nav__hamburger,
body.user-logged-in.overlay-open .primary_nav--transparent .primary_nav__link,
body.user-logged-in.overlay-open .primary_nav--transparent .primary_nav__login,
body.user-logged-in.overlay-open .primary_nav--transparent .primary_nav__account {
	color: #0090ba;
}

body.user-logged-in.overlay-open .primary_nav--transparent .primary_nav__search {
	border-left-color: #e1e1e6;
	color: #0090ba;
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.footer__form-submit,
.footer__link-primary,
.footer__link-secondary {
	font-weight: 500;
	font-size: 0.875rem;
	letter-spacing: 0.14em;
	text-transform: uppercase;
	height: 2.5rem;
	display: flex;
	align-items: center;
}

.footer {
	background: #000;
	padding: 2.5rem 0 4.375rem;
}

@media only screen and (min-width: 48rem) {
	.footer {
		padding-bottom: 5.625rem;
	}
}

@media only screen and (min-width: 1289px) {
	.footer {
		padding-top: 3.75rem;
		padding-bottom: 6.875rem;
	}
}

.footer__content::after {
	clear: both;
	content: '';
	display: block;
}

@media only screen and (min-width: 48rem) {
	.footer__content {
		margin-left: 0.875rem;
		margin-right: 0.875rem;
	}
}

@media only screen and (min-width: 1289px) {
	.footer__content {
		margin-left: 4.375rem;
		margin-right: 4.375rem;
	}
}

@media (min-width: 108.75rem) {
	.footer__content {
		width: 100rem;
		max-width: 100rem;
		margin-left: auto;
		margin-right: auto;
	}
}

@media only screen and (min-width: 48rem) {
	.footer__content {
		position: relative;
	}
}

.footer__form {
	width: calc(100% - 3.125rem);
	float: left;
	margin-left: 1.5625rem;
	margin-bottom: 1.875rem;
}

@media only screen and (min-width: 48rem) {
	.footer__form {
		width: calc(100% - 2.5rem);
		float: left;
		margin-left: 1.25rem;
		margin-bottom: 2.5rem;
		position: relative;
	}
}

@media only screen and (min-width: 1289px) {
	.footer__form {
		width: calc(100% - 2.5rem);
		float: left;
		margin-left: 1.25rem;
		margin-bottom: 2.5rem;
	}
}

.footer__form::after {
	content: '';
	display: block;
	width: calc(100% + 3.125rem);
	height: 0.125rem;
	background: #2b2b2b;
	margin: 0 -1.5625rem;
}

@media only screen and (min-width: 48rem) {
	.footer__form::after {
		content: none;
	}
}

@media only screen and (min-width: 1289px) {
	.footer__form::after {
		content: '';
		width: 100%;
		margin: 0;
	}
}

.footer__form-header {
	font-weight: 300;
	font-size: 1.875rem;
	letter-spacing: 0.04em;
	color: #fff;
	margin-bottom: 1.875rem;
}

@media only screen and (min-width: 48rem) {
	.footer__form-header {
		font-size: 3.75rem;
		line-height: 3.4375rem;
		margin-bottom: 2.5rem;
	}
}

@media only screen and (min-width: 1289px) {
	.footer__form-header {
		width: 48%;
		max-width: 33.125rem;
		float: left;
	}
}

.footer__form-header::after {
	content: '';
	display: inline-block;
	width: 2.5rem;
	height: 0.125rem;
	background: #fdb913;
	margin-left: 0.625rem;
}

@media only screen and (min-width: 48rem) {
	.footer__form-header::after {
		width: 5rem;
	}
}

@media only screen and (min-width: 1289px) {
	.footer__fieldset {
		margin-left: -1.25rem;
		margin-right: -1.25rem;
		width: calc(100% + 2.5rem);
	}
}

.footer__form-container {
	font-weight: 400;
	font-size: 1.125rem;
	line-height: 1.78;
	letter-spacing: 0.04em;
	display: block;
	color: #fff;
	margin-bottom: 1.875rem;
}

@media only screen and (min-width: 1289px) {
	.footer__form-container {
		float: right;
		width: 48%;
		max-width: 32.1875rem;
	}
}

.footer__form-input,
.footer__form-success {
	font-weight: 400;
	font-size: 0.875rem;
	letter-spacing: 0.07em;
	width: 100%;
	margin-bottom: 1.25rem;
	border: 0;
	border-bottom: 0.125rem solid #2b2b2b;
	background: #000;
	color: gray;
	padding-bottom: 1.25rem;
}

@media only screen and (min-width: 48rem) {
	.footer__form-input,
	.footer__form-success {
		font-size: 1.125rem;
		padding-right: 9.375rem;
		margin-bottom: 0;
	}
}

@media only screen and (min-width: 1289px) {
	.footer__form-input,
	.footer__form-success {
		width: calc(45.83333% - 1.82292rem);
		float: left;
		margin-left: 1.25rem;
		margin-left: calc(54.16667% - 1.92708rem + 2.5rem);
		margin-bottom: 3.75rem;
	}
}

.footer__form-submit {
	color: #0090ba;
	background: none;
	height: auto;
	border: none;
	padding: 0;
	margin-bottom: 2.5rem;
}

@media only screen and (min-width: 48rem) {
	.footer__form-submit {
		position: absolute;
		bottom: 0;
		right: 0;
		margin-bottom: 1.375rem;
	}
}

@media only screen and (min-width: 1289px) {
	.footer__form-submit {
		margin-right: 1.25rem;
		margin-bottom: 5.125rem;
	}
}

.footer__form-success {
	text-align: center;
	padding-right: 0;
	display: none;
	border-bottom: 0;
}

.footer__form-success-check {
	color: #0090ba;
}

.footer__form-success-check::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤';
}

.footer__links {
	width: calc(100% - 3.125rem);
	float: left;
	margin-left: 1.5625rem;
}

@media only screen and (min-width: 48rem) {
	.footer__links {
		width: calc(100% - 2.5rem);
		float: left;
		margin-left: 1.25rem;
		display: flex;
		margin-bottom: 3.75rem;
	}
}

@media only screen and (min-width: 1289px) {
	.footer__links {
		width: calc(66.66667% - 2.08333rem);
		float: left;
		margin-left: 1.25rem;
		margin-bottom: 2.5rem;
	}
}

.footer__links-primary {
	margin-bottom: 1.25rem;
}

@media only screen and (min-width: 48rem) {
	.footer__links-primary {
		width: 75%;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 0;
	}
}

.footer__link-primary {
	color: #0090ba;
}

@media only screen and (min-width: 48rem) {
	.footer__link-primary {
		flex-basis: 50%;
	}
}

.footer__links-secondary {
	margin-bottom: 1.875rem;
}

@media only screen and (min-width: 48rem) {
	.footer__links-secondary {
		width: 25%;
		margin-bottom: 0;
	}
}

.footer__link-secondary {
	color: gray;
}

.footer__links-social {
	width: calc(100% - 3.125rem);
	float: left;
	margin-left: 1.5625rem;
	display: flex;
	margin-bottom: 1.875rem;
}

@media only screen and (min-width: 48rem) {
	.footer__links-social {
		width: calc(41.66667% - 1.77083rem);
		float: left;
		margin-left: 1.25rem;
		margin-left: calc(58.33333% - 1.97917rem + 2.5rem);
		justify-content: flex-end;
	}
}

@media only screen and (min-width: 1289px) {
	.footer__links-social {
		width: calc(50% - 1.875rem);
		float: left;
		margin-left: 1.25rem;
		margin-left: calc(0% - 1.25rem + 2.5rem);
		justify-content: flex-start;
	}
}

.footer__link-social {
	font-size: 1.125rem;
	color: #fff;
	margin-right: 2.5rem;
}

.footer__link-social:last-child {
	margin-right: 0;
}

.footer__link-social a {
	transition: opacity 0.2s;
}

.footer__link-social a:hover {
	text-decoration: none;
	opacity: 0.5;
}

.footer__link-social a[title*='Facebook']::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤‰';
}

.footer__link-social a[title*='Twitter']::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤';
}

.footer__link-social a[title*='LinkedIn']::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤‹';
}

.footer__link-social a[title*='Instagram']::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤Š';
}

.footer__link-social a[title*='Pinterest']::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤Œ';
}

.footer__logo {
	width: calc(100% - 3.125rem);
	float: left;
	margin-left: 1.5625rem;
	margin-bottom: 1.875rem;
}

@media only screen and (min-width: 48rem) {
	.footer__logo {
		position: absolute;
		left: 0;
		bottom: 0.625rem;
		width: 17.8125rem;
		margin: 0 0 0 1.25rem;
	}
}

@media only screen and (min-width: 1289px) {
	.footer__logo {
		left: auto;
		right: 1.25rem;
		bottom: 6.875rem;
	}
}

@media only screen and (min-width: 48rem) {
	.footer__logo img {
		width: 100%;
	}
}

.footer__legal {
	width: calc(100% - 3.125rem);
	float: left;
	margin-left: 1.5625rem;
	font-weight: 400;
	font-size: 0.75rem;
	color: gray;
}

@media only screen and (min-width: 48rem) {
	.footer__legal {
		width: calc(41.66667% - 1.77083rem);
		float: left;
		margin-left: 1.25rem;
		margin-left: calc(58.33333% - 1.97917rem + 2.5rem);
		text-align: right;
	}
}

@media only screen and (min-width: 1289px) {
	.footer__legal {
		width: calc(50% - 1.875rem);
		float: left;
		margin-left: 1.25rem;
		margin-left: calc(0% - 1.25rem + 2.5rem);
		font-size: 0.875rem;
	}
}

.footer .submit-button {
	color: #fff;
	background: none;
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.tabs {
	display: flex;
	padding: 0.3em 0.2em;
	position: relative;
	z-index: 2;
	margin: 0.6em;
}

.tabs li {
	padding: 0.9em 0.6em;
	font-size: 0.75rem;
	line-height: 1.2;
	letter-spacing: 0.04em;
	border: 0.0625rem solid;
	border-color: #ddd;
	border-right: none;
	font-weight: 400;
}

.tabs li:first-child {
	padding-left: 1.2em;
}

.tabs li:last-child {
	border-right: 0.0625rem solid #ddd;
	padding-right: 1.2em;
}

.tabs li a {
	color: #2b2b2b;
}

.tabs li a:hover {
	color: #e36f1e;
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.messages {
	font-weight: 400;
	padding: 1.25rem;
	color: inherit;
	word-wrap: break-word;
	overflow-wrap: break-word;
}

.messages a {
	color: #cc2a00;
}

.messages + .messages {
	margin-top: 1.538em;
}

[dir='rtl'] .messages {
	background-position: right 0.625rem top 1.0625rem;
	text-align: right;
}

.messages__content {
	background: no-repeat 0 center;
}

.messages--status {
	font-weight: 500;
	background: #f9f9f9;
	color: #3fcc6f;
}

.messages--status .messages__content {
	background-image: url(/core/misc/icons/73b355/check.svg);
}

.messages--warning {
	background-color: #fcf1d4;
}

.messages--warning .messages__content {
	background-image: url(/core/misc/icons/e29700/warning.svg);
}

.messages--error {
	background-color: #f9e6eb;
	overflow-y: auto;
}

.messages--error .messages__content {
	background-image: url(/core/misc/icons/e32700/error.svg);
}

.messages--error .error {
	color: inherit;
}

.messages__list {
	list-style: none;
	padding: 0;
	margin: 0;
}

.messages__item {
	margin-left: 1.5rem;
}

.messages__item + .messages__item {
	margin-top: 0.769em;
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.layout::after {
	clear: both;
	content: '';
	display: block;
}

@media only screen and (min-width: 48rem) {
	.layout {
		margin-left: 0.875rem;
		margin-right: 0.875rem;
	}
}

@media only screen and (min-width: 1289px) {
	.layout {
		margin-left: 4.375rem;
		margin-right: 4.375rem;
	}
}

@media (min-width: 108.75rem) {
	.layout {
		width: 100rem;
		max-width: 100rem;
		margin-left: auto;
		margin-right: auto;
	}
}

.layout--2-col .layout--left-rail {
	display: none;
}

.layout--2-col .layout--main {
	width: calc(100% - 3.125rem);
	float: left;
	margin-left: 1.5625rem;
	z-index: 1;
}

@media only screen and (min-width: 48rem) {
	.layout--2-col .layout--main {
		width: calc(100% - 2.5rem);
		float: left;
		margin-left: 1.25rem;
	}
}

@media only screen and (min-width: 1289px) {
	.layout--2-col .layout--main {
		width: calc(66.66667% - 2.08333rem);
		float: left;
		margin-left: 1.25rem;
		max-width: 52.0625rem;
	}
}

.layout--2-col .layout--right-rail {
	width: calc(100% - 3.125rem);
	float: left;
	margin-left: 1.5625rem;
}

@media only screen and (min-width: 48rem) {
	.layout--2-col .layout--right-rail {
		width: calc(100% - 2.5rem);
		float: left;
		margin-left: 1.25rem;
	}
}

@media only screen and (min-width: 1289px) {
	.layout--2-col .layout--right-rail {
		width: calc(25% - 1.5625rem);
		float: left;
		margin-left: 1.25rem;
		margin-left: calc(16.66667% - 1.45833rem + 2.5rem);
	}
}

.layout--2-col-small .layout--left-rail {
	display: none;
}

.layout--2-col-small .layout--main {
	width: calc(100% - 3.125rem);
	float: left;
	margin-left: 1.5625rem;
	z-index: 1;
}

@media only screen and (min-width: 48rem) {
	.layout--2-col-small .layout--main {
		width: calc(100% - 2.5rem);
		float: left;
		margin-left: 1.25rem;
	}
}

@media only screen and (min-width: 1289px) {
	.layout--2-col-small .layout--main {
		width: calc(62.5% - 2.03125rem);
		float: left;
		margin-left: 1.25rem;
		margin-left: calc(4.16667% - 1.30208rem + 2.5rem);
	}
}

.layout--2-col-small .layout--right-rail {
	width: calc(100% - 3.125rem);
	float: left;
	margin-left: 1.5625rem;
}

@media only screen and (min-width: 48rem) {
	.layout--2-col-small .layout--right-rail {
		width: calc(100% - 2.5rem);
		float: left;
		margin-left: 1.25rem;
	}
}

@media only screen and (min-width: 1289px) {
	.layout--2-col-small .layout--right-rail {
		width: calc(25% - 1.5625rem);
		float: left;
		margin-left: 1.25rem;
		margin-left: calc(16.66667% - 1.45833rem + 2.5rem);
	}
}

.layout--3-col .layout--left-rail {
	width: calc(100% - 3.125rem);
	float: left;
	margin-left: 1.5625rem;
}

@media only screen and (min-width: 48rem) {
	.layout--3-col .layout--left-rail {
		width: calc(100% - 2.5rem);
		float: left;
		margin-left: 1.25rem;
	}
}

@media only screen and (min-width: 1289px) {
	.layout--3-col .layout--left-rail {
		width: calc(12.5% - 1.40625rem);
		float: left;
		margin-left: 1.25rem;
	}
}

.layout--3-col .layout--main {
	width: calc(100% - 3.125rem);
	float: left;
	margin-left: 1.5625rem;
	z-index: 1;
}

@media only screen and (min-width: 48rem) {
	.layout--3-col .layout--main {
		width: calc(100% - 2.5rem);
		float: left;
		margin-left: 1.25rem;
	}
}

@media only screen and (min-width: 1289px) {
	.layout--3-col .layout--main {
		width: calc(62.5% - 2.03125rem);
		float: left;
		margin-left: 1.25rem;
	}
}

.layout--3-col .layout--right-rail {
	width: calc(100% - 3.125rem);
	float: left;
	margin-left: 1.5625rem;
}

@media only screen and (min-width: 48rem) {
	.layout--3-col .layout--right-rail {
		width: calc(100% - 2.5rem);
		float: left;
		margin-left: 1.25rem;
	}
}

@media only screen and (min-width: 1289px) {
	.layout--3-col .layout--right-rail {
		width: calc(20.83333% - 1.51042rem);
		float: left;
		margin-left: 1.25rem;
		margin-left: calc(4.16667% - 1.30208rem + 2.5rem);
	}
}

.layout--full .layout--left-rail {
	display: none;
}

.layout--full .layout--main {
	width: calc(100% - 3.125rem);
	float: left;
	margin-left: 1.5625rem;
	z-index: 1;
	position: relative;
	z-index: 1;
}

@media only screen and (min-width: 48rem) {
	.layout--full .layout--main {
		width: calc(100% - 2.5rem);
		float: left;
		margin-left: 1.25rem;
	}
}

@media only screen and (min-width: 1289px) {
	.layout--full .layout--main {
		width: calc(100% - 2.5rem);
		float: left;
		margin-left: 1.25rem;
	}
}

.layout--full .layout--right-rail {
	display: none;
}

.layout--2-col-centered .layout--left-rail {
	width: calc(100% - 3.125rem);
	float: left;
	margin-left: 1.5625rem;
}

@media only screen and (min-width: 48rem) {
	.layout--2-col-centered .layout--left-rail {
		width: calc(100% - 2.5rem);
		float: left;
		margin-left: 1.25rem;
	}
}

@media only screen and (min-width: 1289px) {
	.layout--2-col-centered .layout--left-rail {
		width: calc(8.33333% - 1.35417rem);
		float: left;
		margin-left: 1.25rem;
		margin-left: calc(8.33333% - 1.35417rem + 2.5rem);
	}
}

.layout--2-col-centered .layout--main {
	width: calc(100% - 3.125rem);
	float: left;
	margin-left: 1.5625rem;
	z-index: 1;
}

@media only screen and (min-width: 48rem) {
	.layout--2-col-centered .layout--main {
		width: calc(100% - 2.5rem);
		float: left;
		margin-left: 1.25rem;
	}
}

@media only screen and (min-width: 1289px) {
	.layout--2-col-centered .layout--main {
		width: calc(66.66667% - 2.08333rem);
		float: left;
		margin-left: 1.25rem;
		max-width: 52.0625rem;
	}
}

.layout--2-col-centered .layout--right-rail {
	display: none;
}

.layout--2-col-large .layout--left-rail {
	display: none;
}

.layout--2-col-large .layout--main {
	width: calc(100% - 3.125rem);
	float: left;
	margin-left: 1.5625rem;
	z-index: 1;
}

@media only screen and (min-width: 48rem) {
	.layout--2-col-large .layout--main {
		width: calc(100% - 2.5rem);
		float: left;
		margin-left: 1.25rem;
	}
}

@media only screen and (min-width: 1289px) {
	.layout--2-col-large .layout--main {
		width: calc(66.66667% - 2.08333rem);
		float: left;
		margin-left: 1.25rem;
	}
}

.layout--2-col-large .layout--right-rail {
	width: calc(100% - 3.125rem);
	float: left;
	margin-left: 1.5625rem;
}

@media only screen and (min-width: 48rem) {
	.layout--2-col-large .layout--right-rail {
		width: calc(100% - 2.5rem);
		float: left;
		margin-left: 1.25rem;
	}
}

@media only screen and (min-width: 1289px) {
	.layout--2-col-large .layout--right-rail {
		width: calc(25% - 1.5625rem);
		float: left;
		margin-left: 1.25rem;
		margin-left: calc(8.33333% - 1.35417rem + 2.5rem);
	}
}

@media only screen and (min-width: 1289px) {
	.layout--2-col-large {
		display: flex;
	}
}

.layout--main > * {
	margin-bottom: 3.125rem;
}

@media only screen and (min-width: 48rem) {
	.layout--main > * {
		margin-bottom: 3.75rem;
	}
}

@media only screen and (min-width: 1289px) {
	.layout--main > * {
		margin-bottom: 5rem;
	}
}

.layout--right-rail {
	margin-bottom: 3.125rem;
	z-index: 1;
}

.layout--right-rail > * {
	margin-bottom: 1.25rem;
	padding-bottom: 1.25rem;
}

@media only screen and (min-width: 48rem) {
	.layout--right-rail {
		margin-bottom: 3.75rem;
		display: flex;
		flex-wrap: wrap;
	}

	.layout--right-rail > * {
		width: 50%;
	}
}

@media only screen and (min-width: 1289px) {
	.layout--right-rail {
		margin-bottom: 5rem;
		flex-direction: column;
	}

	.layout--right-rail > * {
		width: 100%;
	}

	.layout--right-rail > :not(:last-child) {
		position: relative;
		top: unset;
	}

	.layout--right-rail > :not(:last-child)::after {
		content: '';
		background-color: rgba(167, 169, 172, 0.45);
		height: 0.125rem;
		left: 1.25rem;
		right: 1.25rem;
		bottom: 0;
		width: auto;
		top: auto;
		position: absolute;
	}
}

.layout--right-rail--hidden {
	opacity: 0;
}

.layout--1-col-centered .layout--left-rail {
	display: none;
}

.layout--1-col-centered .layout--main {
	width: calc(100% - 3.125rem);
	float: left;
	margin-left: 1.5625rem;
	z-index: 1;
}

@media only screen and (min-width: 48rem) {
	.layout--1-col-centered .layout--main {
		width: calc(100% - 2.5rem);
		float: left;
		margin-left: 1.25rem;
	}
}

@media only screen and (min-width: 1289px) {
	.layout--1-col-centered .layout--main {
		width: calc(66.66667% - 2.08333rem);
		float: left;
		margin-left: 1.25rem;
		max-width: 52.0625rem;
	}
}

@media only screen and (min-width: 1289px) {
	.layout--1-col-centered .layout--main {
		margin-left: auto;
		margin-right: auto;
		float: none;
	}
}

.layout--1-col-centered .layout--right-rail {
	display: none;
}

.toolbar-icon-brightcove-video-settings:before,
.toolbar-icon-entity-media-type-collection:before {
	background-image: url(/core/themes/stable/images/core/icons/787878/wrench.svg);
}

.glide {
	position: relative;
	width: 100%;
	box-sizing: border-box;
}

.glide * {
	box-sizing: inherit;
}

.glide__track {
	overflow: hidden;
}

.glide__slides {
	position: relative;
	width: 100%;
	list-style: none;
	backface-visibility: hidden;
	transform-style: preserve-3d;
	touch-action: pan-Y;
	overflow: hidden;
	padding: 0;
	white-space: nowrap;
	display: flex;
	flex-wrap: nowrap;
	will-change: transform;
}

.glide__slides--dragging {
	user-select: none;
}

.glide__slide {
	width: 100%;
	height: 100%;
	flex-shrink: 0;
	white-space: normal;
	user-select: none;
	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: transparent;
}

.glide__slide a {
	user-select: none;
	-webkit-user-drag: none;
	-moz-user-select: none;
	-ms-user-select: none;
}

.glide__arrows {
	-webkit-touch-callout: none;
	user-select: none;
}

.glide__bullets {
	-webkit-touch-callout: none;
	user-select: none;
}

.glide--rtl {
	direction: rtl;
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.watchlist__button_text,
.watchlist__add,
.watchlist__portfolio,
.watchlist__edit,
.watchlist-add-overlay__back {
	font-weight: 500;
	font-size: 0.875rem;
	letter-spacing: 0.14em;
	text-transform: uppercase;
	transition: opacity 0.2s;
}

.watchlist__button_text:hover,
.watchlist__add:hover,
.watchlist__portfolio:hover,
.watchlist__edit:hover,
.watchlist-add-overlay__back:hover,
.watchlist__button_text:focus,
.watchlist__add:focus,
.watchlist__portfolio:focus,
.watchlist__edit:focus,
.watchlist-add-overlay__back:focus {
	opacity: 0.75;
}

.watchlist__close,
.watchlist__empty-close,
.watchlist-add-overlay__close,
.watchlist-add-overlay__unavailable-close {
	font-size: 1rem;
	color: #fff;
	position: absolute;
	padding: 0.625rem;
	top: 1rem;
	right: -0.625rem;
}

.watchlist__close::before,
.watchlist__empty-close::before,
.watchlist-add-overlay__close::before,
.watchlist-add-overlay__unavailable-close::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤‡';
}

@media only screen and (min-width: 1289px) {
	.watchlist__close,
	.watchlist__empty-close,
	.watchlist-add-overlay__close,
	.watchlist-add-overlay__unavailable-close {
		display: none;
	}
}

.watchlist {
	position: fixed;
	bottom: 0;
	right: 0;
	z-index: 101;
	pointer-events: none;
}

@media only screen and (min-width: 1289px) {
	.watchlist {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		width: 100%;
		transition: -webkit-transform 0.2s;
		transition: transform 0.2s;
		transition: transform 0.2s, -webkit-transform 0.2s;
		-webkit-transform: translateY(9.5rem);
		transform: translateY(9.5rem);
	}
}

.watchlist--hidden {
	display: none;
}

.watchlist__button {
	background: #0090ba;
	display: flex;
	align-items: center;
	height: 3.75rem;
	box-shadow: 0 0 0.625rem 0 rgba(0, 0, 0, 0.2);
	pointer-events: auto;
	margin: 0;
}

.watchlist__button-icon {
	color: #fff;
	opacity: 0.35;
	margin: 0 0.625rem 0 1.4375rem;
}

.watchlist__button-icon::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤ˆ';
}

.watchlist__button_text {
	color: #fff;
	margin-right: 1.4375rem;
}

.watchlist__button-toggle {
	height: 3.75rem;
	width: 3.75rem;
	color: #fff;
	background: rgba(0, 0, 0, 0.2);
	display: flex;
	align-items: center;
	justify-content: center;
}

.watchlist__button-toggle::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤£';
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
	transition: -webkit-transform 0.2s;
	transition: transform 0.2s;
	transition: transform 0.2s, -webkit-transform 0.2s;
}

.watchlist__content {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	padding: 0 1.5625rem;
	background: #2b2b2b;
	overflow: auto;
	opacity: 1;
	transition: opacity 0.2s;
	pointer-events: auto;
}

@media only screen and (min-width: 48rem) {
	.watchlist__content {
		padding: 0 2.125rem;
	}
}

@media only screen and (min-width: 1289px) {
	.watchlist__content {
		display: flex;
		position: relative;
		padding: 0;
		height: 9.5rem;
		box-shadow: 0 0 0.625rem 0 rgba(0, 0, 0, 0.1);
		overflow: visible;
	}
}

.watchlist__content[aria-hidden='true'] {
	pointer-events: none;
	opacity: 0;
}

.watchlist__content-header {
	position: relative;
	padding: 1.875rem 0;
	border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.5);
}

@media only screen and (min-width: 1289px) {
	.watchlist__content-header {
		padding: 2.8125rem 2.5rem;
		flex: 0 0 15rem;
		max-width: 15rem;
		border-right: 0.0625rem solid rgba(255, 255, 255, 0.5);
		border-bottom: none;
	}
}

.watchlist__add {
	display: flex;
	align-items: center;
	color: #0090ba;
	margin-bottom: 1.875rem;
}

.watchlist__add::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤';
	font-size: 0.625rem;
	margin-right: 0.625rem;
}

.watchlist__portfolio,
.watchlist__edit {
	color: #0090ba;
}

.watchlist__edit {
	position: absolute;
	bottom: -2.25rem;
	right: 0;
}

@media only screen and (min-width: 48rem) {
	.watchlist__edit {
		bottom: -2.875rem;
	}
}

@media only screen and (min-width: 1289px) {
	.watchlist__edit {
		top: 1.25rem;
		bottom: auto;
		right: auto;
		left: 16.25rem;
	}
}

.watchlist__symbols {
	padding-top: 3.125rem;
}

@media only screen and (min-width: 48rem) {
	.watchlist__symbols {
		padding-top: 4.375rem;
	}
}

@media only screen and (min-width: 1289px) {
	.watchlist__symbols {
		width: calc(100% - 15rem);
		padding-top: 3.125rem;
	}
}

@media only screen and (min-width: 1289px) {
	.watchlist__track.glide__track {
		border-top: 0.0625rem solid rgba(255, 255, 255, 0.5);
		padding: 1.25rem 1.25rem 0;
	}
}

@media only screen and (min-width: 1289px) {
	.watchlist__content--editing .watchlist__track.glide__track {
		border-top: none;
		padding-top: 0;
		overflow: auto;
	}
}

.watchlist__slides.glide__slides {
	flex-wrap: wrap;
}

@media only screen and (min-width: 1289px) {
	.watchlist__slides.glide__slides {
		flex-wrap: nowrap;
		padding-bottom: 0.625rem;
	}
}

.watchlist__content--editing .watchlist__slides.glide__slides {
	will-change: auto;
}

@media only screen and (min-width: 1289px) {
	.watchlist__content--editing .watchlist__slides.glide__slides {
		overflow: auto;
	}
}

.watchlist__slide.glide__slide {
	flex-basis: calc(50% - 0.625rem);
	font-size: 0.75rem;
	letter-spacing: 0.04em;
	color: #fff;
	position: relative;
	margin-bottom: 2.5rem;
	margin-right: 0.625rem;
	transition: top 0.2s;
	z-index: 1;
	padding: 1.75rem 0.625rem 0.625rem 1.5625rem;
	margin-top: -1.75rem;
}

@media only screen and (min-width: 48rem) {
	.watchlist__slide.glide__slide {
		flex-basis: calc(25% - 0.625rem);
		margin-bottom: 3.75rem;
	}
}

@media only screen and (min-width: 1289px) {
	.watchlist__slide.glide__slide {
		flex-basis: 10rem;
		margin-bottom: 0;
	}
}

.watchlist__slide.glide__slide.draggable-mirror {
	z-index: 102;
	background: #000;
	padding: 1.75rem 0.625rem 0.625rem 1.5625rem;
	list-style: none;
	box-shadow: 0 0.3125rem 0.625rem 0.125rem rgba(0, 0, 0, 0.35);
}

.watchlist__slide.glide__slide.draggable-mirror::before {
	top: 1.625rem;
	left: 0.4375rem;
}

.watchlist__content--editing .watchlist__slide.glide__slide {
	background: #000;
	min-height: 5.75rem;
	cursor: pointer;
	margin-top: 0;
}

.watchlist__content--editing .watchlist__slide.glide__slide:not(.draggable--over):hover .watchlist__slide-overlay {
	opacity: 1;
}

.watchlist__slide.draggable-source--is-dragging {
	border: 0.125rem solid #0090ba;
	opacity: 0.2;
	pointer-events: none;
}

.watchlist__slide--add.glide__slide {
	padding: 0;
	margin-top: 0;
}

.watchlist__slide--add.glide__slide::before {
	content: none;
}

.watchlist__slide--add.glide__slide button {
	color: #0090ba;
	border: 0.0625rem dashed #0090ba;
	border-radius: 0.25rem;
	height: 3.625rem;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 7.5rem;
	transition: opacity 0.2s;
	cursor: pointer;
}

.watchlist__slide--add.glide__slide button::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤';
}

.watchlist__slide--add.glide__slide button:hover,
.watchlist__slide--add.glide__slide button:focus {
	opacity: 0.75;
}

.watchlist__slide--add.glide__slide button::before {
	font-size: 0.75rem;
	color: #0090ba;
	position: static;
}

.watchlist__content--editing .watchlist__slide--add.glide__slide {
	display: none;
}

.watchlist__slide--decrease.glide__slide .watchlist__slide-icon::before {
	color: #fd6e70;
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}

.watchlist__slide--decrease.glide__slide .watchlist__slide-change,
.watchlist__slide--decrease.glide__slide .watchlist__slide-change-percent {
	color: #fd6e70;
	text-decoration: none;
}

.watchlist__slide--clone.glide__slide {
	display: none;
}

.watchlist__slide-icon::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤¢';
	color: #3fcc6f;
	position: absolute;
	font-size: 2.8125rem;
	top: 1.625rem;
	left: 0.4375rem;
}

.watchlist__slide-name {
	font-weight: 500;
	text-transform: uppercase;
	margin-bottom: 0.5625rem;
}

.watchlist__slide-price {
	font-weight: 400;
	margin-top: 0.3125rem;
}

.watchlist__slide-change-container {
	margin-top: 0.3125rem;
}

.watchlist__slide_link:hover {
	text-decoration: none;
}

.watchlist__slide_link:hover span {
	text-decoration: underline;
}

.watchlist__slide-change,
.watchlist__slide-change-percent {
	font-weight: 500;
	color: #3fcc6f;
}

.watchlist__slide-change + .watchlist__slide-change,
.watchlist__slide-change-percent + .watchlist__slide-change,
.watchlist__slide-change + .watchlist__slide-change-percent,
.watchlist__slide-change-percent + .watchlist__slide-change-percent {
	margin-left: 0.625rem;
}

.watchlist__slide-remove {
	color: #0090ba;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
	padding: 0.625rem;
	display: none;
}

.watchlist__slide-remove::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤‡';
	pointer-events: none;
}

.watchlist__content--editing .watchlist__slide-remove {
	display: block;
}

.watchlist__slide-overlay {
	font-size: 1.875rem;
	color: #0090ba;
	pointer-events: none;
	opacity: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.9);
	transition: opacity 0.2s;
}

.watchlist__slide-overlay::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤§';
}

.watchlist__empty {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #2b2b2b;
}

@media only screen and (min-width: 1289px) {
	.watchlist__empty {
		flex-direction: row;
	}
}

.watchlist__empty[aria-hidden='true'] {
	display: none;
}

.watchlist__empty-close {
	right: 0.9375rem;
}

.watchlist__empty_text {
	font-weight: 400;
	font-size: 0.875rem;
	letter-spacing: 0.07em;
	color: #fff;
}

@media only screen and (min-width: 1289px) {
	.watchlist__empty_text {
		margin-right: 0.625rem;
	}
}

.watchlist__empty-add {
	font-weight: 500;
	font-size: 0.875rem;
	letter-spacing: 0.14em;
	text-transform: uppercase;
	color: #0090ba;
	margin-top: 0.625rem;
}

.watchlist__empty-add::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤';
}

@media only screen and (min-width: 1289px) {
	.watchlist__empty-add {
		margin-top: 0;
	}
}

.watchlist__empty-add::before {
	font-size: 0.625rem;
	margin-right: 0.625rem;
}

.watchlist__loader {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.75);
}

.watchlist__loader[aria-hidden='true'] {
	display: none;
}

.watchlist .carousel-controls {
	display: none;
}

@media only screen and (min-width: 1289px) {
	.watchlist .carousel-controls {
		display: flex;
		position: absolute;
		top: -2rem;
		right: 2.1875rem;
	}
}

.watchlist .carousel-controls__count {
	display: none;
}

.watchlist .carousel-controls__prev,
.watchlist .carousel-controls__next {
	background: #2b2b2b;
}

@media only screen and (min-width: 1289px) {
	.watchlist--open {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

.watchlist--open .watchlist__button-toggle::before {
	-webkit-transform: rotate(0);
	transform: rotate(0);
}

.watchlist-add-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 0 1.5625rem;
	z-index: 102;
	background: #2b2b2b;
	pointer-events: none;
	overflow: auto;
	opacity: 0;
	transition: opacity 0.2s;
}

@media only screen and (min-width: 48rem) {
	.watchlist-add-overlay {
		padding: 0 2.125rem;
	}
}

@media only screen and (min-width: 1289px) {
	.watchlist-add-overlay {
		background: rgba(255, 255, 255, 0.85);
		bottom: 9.5rem;
		z-index: 100;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}

.watchlist-add-overlay[aria-hidden='false'] {
	opacity: 1;
	pointer-events: auto;
}

.watchlist-add-overlay__content {
	position: relative;
}

@media only screen and (min-width: 1289px) {
	.watchlist-add-overlay__content {
		width: 35.3125rem;
		height: 29.6875rem;
		padding: 0 2.125rem;
		background: #2b2b2b;
	}
}

.watchlist-add-overlay__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.5);
	height: 4.375rem;
}

@media only screen and (min-width: 1289px) {
	.watchlist-add-overlay__header {
		height: auto;
		border: 0;
	}
}

.watchlist-add-overlay__back {
	color: #0090ba;
	padding: 0.625rem 0;
}

.watchlist-add-overlay__back::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤¤';
}

@media only screen and (min-width: 1289px) {
	.watchlist-add-overlay__back {
		font-size: 1.25rem;
		position: absolute;
		top: 0.625rem;
		right: 1.25rem;
	}

	.watchlist-add-overlay__back::before {
		speak: none;
		font-style: normal;
		font-weight: 400;
		font-variant: normal;
		text-transform: none;
		line-height: 1;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		content: 'î¤‡';
	}
}

.watchlist-add-overlay__back::before {
	display: inline-block;
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
	margin-right: 0.5625rem;
}

@media only screen and (min-width: 1289px) {
	.watchlist-add-overlay__back::before {
		color: #fff;
	}
}

@media only screen and (min-width: 1289px) {
	.watchlist-add-overlay__back_text {
		display: none;
	}
}

.watchlist-add-overlay__close {
	position: static;
	margin-right: -0.625rem;
}

@media only screen and (min-width: 1289px) {
	.watchlist-add-overlay__close {
		display: none;
	}
}

.watchlist-add-overlay__form {
	padding-top: 1.875rem;
	color: #fff;
}

@media only screen and (min-width: 1289px) {
	.watchlist-add-overlay__form {
		padding-top: 2.5rem;
	}
}

.watchlist-add-overlay__label {
	font-weight: 400;
	font-size: 0.875rem;
	letter-spacing: 0.07em;
	margin-bottom: 0.625rem;
}

.watchlist-add-overlay__input-wrapper {
	position: relative;
	margin-top: 0.9375rem;
}

.watchlist-add-overlay__input-wrapper::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤ƒ';
	font-size: 1.5rem;
	position: absolute;
	top: 0.5rem;
	right: 0.625rem;
	pointer-events: none;
}

.watchlist-add-overlay__input {
	font-weight: 300;
	font-size: 0.875rem;
	letter-spacing: 0.04em;
	color: #fff;
	background: rgba(167, 169, 172, 0.2);
	border: 0;
	width: 100%;
	height: 2.5rem;
	padding: 0 3.125rem 0 0.625rem;
}

@media only screen and (min-width: 1289px) {
	.watchlist-add-overlay__input {
		font-size: 1.125rem;
	}
}

.watchlist-add-overlay__results {
	font-weight: 400;
	font-size: 0.875rem;
	letter-spacing: 0.04em;
	line-height: 1.29em;
	color: #fff;
	margin-top: 2.5rem;
	position: relative;
}

.watchlist-add-overlay__results-header {
	letter-spacing: 0.17em;
	color: rgba(255, 255, 255, 0.6);
	text-transform: uppercase;
	margin-bottom: 0.625rem;
}

@media only screen and (min-width: 1289px) {
	.watchlist-add-overlay__results-items {
		height: 16.875rem;
		overflow: auto;
	}
}

.watchlist-add-overlay__results-item {
	height: 3.375rem;
	border-bottom: 0.0625rem solid #a7a9ac;
	display: flex;
	align-items: center;
}

.watchlist-add-overlay__results-item:last-child {
	border: 0;
}

.watchlist-add-overlay__results-item--clone {
	display: none;
}

.watchlist-add-overlay__results-item .autocomplete__symbol,
.watchlist-add-overlay__results-item .autocomplete__divider {
	display: none;
}

.watchlist-add-overlay__symbol {
	flex: 0 0 3.75rem;
	margin-right: 0.625rem;
}

.watchlist-add-overlay__name {
	flex-grow: 1;
	flex-shrink: 1;
	margin-right: 0.625rem;
	font-size: 0.75rem;
}

@media only screen and (min-width: 48rem) {
	.watchlist-add-overlay__name {
		font-size: 0.875rem;
	}
}

@media only screen and (min-width: 1289px) {
	.watchlist-add-overlay__name {
		font-size: 0.75rem;
	}
}

.watchlist-add-overlay__add {
	font-size: 0.875rem;
	color: #0090ba;
	height: 100%;
	flex: 0 0 3.3125rem;
}

.watchlist-add-overlay__add::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤';
}

.watchlist-add-overlay__add--added::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤';
}

.watchlist-add-overlay__unavailable {
	font-weight: 300;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.8);
	color: #fff;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.3s, visibility 0.3s;
	z-index: 1;
}

.watchlist-add-overlay__unavailable-close {
	display: block;
	position: absolute;
	top: 1rem;
	right: -0.625rem;
}

@media only screen and (min-width: 1289px) {
	.watchlist-add-overlay__unavailable-close {
		top: 0.8125rem;
		right: 1.3125rem;
	}
}

.watchlist-add-overlay--unavailable .watchlist-add-overlay__unavailable {
	opacity: 1;
	visibility: visible;
}

.watchlist-add-overlay__loader {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.75);
	display: flex;
	align-items: center;
	justify-content: center;
}

.watchlist-add-overlay__loader[aria-hidden='true'] {
	display: none;
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.svg-loader {
	position: relative;
}

.svg-loader .rect-mask__first {
	-webkit-transform: scaleY(0);
	transform: scaleY(0);
	-webkit-animation: reveal-01 2s ease-in infinite;
	animation: reveal-01 2s ease-in infinite;
	-webkit-transform-origin: bottom center;
	transform-origin: bottom center;
}

.svg-loader .rect-mask__second {
	-webkit-transform: scaleY(0);
	transform: scaleY(0);
	-webkit-animation: reveal-02 2s ease-in infinite;
	animation: reveal-02 2s ease-in infinite;
	-webkit-transform-origin: top left;
	transform-origin: top left;
}

.svg-loader .rect-mask__third {
	-webkit-transform: scaleY(0);
	transform: scaleY(0);
	-webkit-animation: reveal-03 2s ease-in infinite;
	animation: reveal-03 2s ease-in infinite;
	-webkit-transform-origin: bottom;
	transform-origin: bottom;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.svg-loader svg {
		-webkit-animation: pulse 2s ease-in-out infinite;
		animation: pulse 2s ease-in-out infinite;
	}
}

@-webkit-keyframes reveal-01 {
	0% {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
	}

	10% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	20% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	30% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	40% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	50% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	60% {
		-webkit-transform-origin: top;
		transform-origin: top;
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
	}

	70% {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
	}

	100% {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
	}
}

@keyframes reveal-01 {
	0% {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
	}

	10% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	20% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	30% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	40% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	50% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	60% {
		-webkit-transform-origin: top;
		transform-origin: top;
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
	}

	70% {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
	}

	100% {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
	}
}

@-webkit-keyframes reveal-02 {
	0% {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
		-webkit-transform-origin: top;
		transform-origin: top;
	}

	10% {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
	}

	20% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	30% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	40% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	50% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	60% {
		-webkit-transform-origin: bottom;
		transform-origin: bottom;
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	70% {
		-webkit-transform-origin: bottom;
		transform-origin: bottom;
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
	}

	80% {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
	}

	100% {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
	}
}

@keyframes reveal-02 {
	0% {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
		-webkit-transform-origin: top;
		transform-origin: top;
	}

	10% {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
	}

	20% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	30% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	40% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	50% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	60% {
		-webkit-transform-origin: bottom;
		transform-origin: bottom;
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	70% {
		-webkit-transform-origin: bottom;
		transform-origin: bottom;
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
	}

	80% {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
	}

	100% {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
	}
}

@-webkit-keyframes reveal-03 {
	0% {
		-webkit-transform-origin: bottom;
		transform-origin: bottom;
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
	}

	10% {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
	}

	20% {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
	}

	30% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	40% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	50% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	60% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	70% {
		-webkit-transform-origin: top;
		transform-origin: top;
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	80% {
		-webkit-transform-origin: top;
		transform-origin: top;
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
	}

	100% {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
	}
}

@keyframes reveal-03 {
	0% {
		-webkit-transform-origin: bottom;
		transform-origin: bottom;
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
	}

	10% {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
	}

	20% {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
	}

	30% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	40% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	50% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	60% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	70% {
		-webkit-transform-origin: top;
		transform-origin: top;
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
	}

	80% {
		-webkit-transform-origin: top;
		transform-origin: top;
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
	}

	100% {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
	}
}

@-webkit-keyframes pulse {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes pulse {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.carousel-controls {
	display: flex;
	justify-content: center;
}

.carousel-controls__prev .arrow-cta,
.carousel-controls__next .arrow-cta {
	will-change: transform;
}

.carousel-controls__prev .arrow-cta:disabled,
.carousel-controls__next .arrow-cta:disabled {
	pointer-events: none;
	cursor: auto;
	opacity: 0.35;
}

.carousel-controls__prev {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
	-webkit-transform-origin: center center;
	transform-origin: center center;
	background: #fff;
	display: flex;
	z-index: 1;
}

.carousel-controls__next {
	background: #fff;
	display: flex;
	z-index: 1;
}

.carousel-controls__count {
	font-weight: 400;
	font-size: 0.875rem;
	letter-spacing: 0.14em;
	color: #0090ba;
	background: #fff;
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.arrow-cta__icon {
	font-size: 0.5625rem;
	position: relative;
	display: flex;
	align-items: center;
}

.arrow-cta__icon::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤Ÿ';
}

.arrow-cta__icon::after {
	content: '\e91f';
	display: block;
	width: 0;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	color: rgba(255, 255, 255, 0.5);
	-webkit-transform-origin: left center;
	transform-origin: left center;
	transition: width 0.15s linear;
	overflow: hidden;
}

.arrow-cta {
	font-weight: 400;
	font-size: 0.875rem;
	letter-spacing: 0.14em;
	text-transform: uppercase;
	color: #0090ba;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
}

@media only screen and (min-width: 48rem) {
	.arrow-cta {
		flex-grow: 1;
		justify-content: flex-end;
	}
}

.arrow-cta:hover,
.arrow-cta:focus {
	text-decoration: none;
}

.no-touchevents .arrow-cta:hover .arrow-cta__icon::after,
.no-touchevents .arrow-cta:focus .arrow-cta__icon::after {
	width: 40%;
}

.arrow-cta span {
	display: block;
	position: relative;
	margin-right: 0.625rem;
}

.arrow-cta__text {
	font-size: 0.75rem;
}

.arrow-cta--blue {
	display: flex;
	align-items: center;
	position: relative;
	color: #fff;
}

.arrow-cta--blue::before {
	content: '';
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: #0090ba;
}

.arrow-cta--blue .arrow-cta__icon::before {
	display: none;
}

@media only screen and (min-width: 48rem) {
	.arrow-cta--blue .arrow-cta__icon::before {
		display: block;
		background-color: #0090ba;
	}
}

.arrow-cta--blue .arrow-cta__icon::after {
	display: none;
}

@media only screen and (min-width: 48rem) {
	.arrow-cta--blue .arrow-cta__icon::after {
		display: block;
		background-color: #0090ba;
	}
}

.arrow-cta--blue-and-white {
	color: #fff;
	text-transform: capitalize;
}

.arrow-cta--blue-and-white .arrow-cta__icon::before {
	color: #0090ba;
}

.arrow-cta--blue-and-white .arrow-cta__icon::after {
	color: #00539b;
}

.arrow-cta--blue-on-white {
	color: #fff;
	text-transform: capitalize;
}

.arrow-cta--blue-on-white .arrow-cta__icon::before {
	color: #fff;
}

.arrow-cta--blue-on-white .arrow-cta__icon::after {
	color: #00bfd5;
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.footer__form input[type='checkbox'],
.footer__form input[type='radio'] {
	opacity: 0;
}

.footer__form input[name='elq_hp'],
.footer__form div[name='elq_hp'] {
	display: none;
}

.footer__form .submit-button {
	border: 0.125rem solid #fff;
	margin: 0.625rem 0 0 !important;
	font-size: 0.875rem !important;
	width: 18.75rem !important;
	height: 3.75rem !important;
}

.footer__form .checkbox {
	cursor: pointer;
}

.footer__form .checkbox span {
	position: relative;
	margin-top: 0.625rem;
	margin-bottom: 0.625rem;
	display: inline-block;
}

.footer__form .checkbox span::before {
	content: '';
	display: inline-block;
	height: 0.875rem;
	width: 0.875rem;
	border: 0.0625rem solid;
	position: absolute;
	top: 0;
	left: -1.25rem;
}

.footer__form .checkbox span::after {
	content: '';
	display: inline-block;
	height: 0.5rem;
	width: 0.6875rem;
	border-left: 0.125rem solid;
	border-bottom: 0.125rem solid;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	position: absolute;
	top: 0;
	left: -1.125rem;
}

.footer__form .checkbox input[type='checkbox'] + span::after {
	content: none;
}

.footer__form .checkbox input[type='checkbox']:checked + span::after {
	content: '';
}

.footer__form .checkbox-span {
	cursor: pointer;
}

.footer__form .checkbox-span span {
	position: relative;
	display: inline-block;
}

.footer__form .checkbox-span span input {
	display: block;
	margin-top: -0.4375rem;
}

.footer__form .checkbox-span span label {
	cursor: pointer;
	float: left;
	margin-left: 1.25rem;
	margin-bottom: 0.625rem;
	line-height: 1.4;
}

.footer__form .checkbox-span span label::before {
	content: '';
	display: inline-block;
	height: 0.875rem;
	width: 0.875rem;
	border: 0.0625rem solid;
	position: absolute;
	top: 0.625rem;
	left: -0.125rem;
	cursor: pointer;
}

.footer__form .checkbox-span span label::after {
	content: '';
	display: inline-block;
	height: 0.5rem;
	width: 0.6875rem;
	border-left: 0.125rem solid;
	border-bottom: 0.125rem solid;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	position: absolute;
	top: 0.625rem;
	left: 0;
}

.footer__form .checkbox-span input[type='checkbox'] + label::after {
	content: none;
}

.footer__form .checkbox-span input[type='checkbox']:checked + label::after {
	content: '';
}

.footer__form.form .eloqua-form label {
	line-height: 1.4;
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.form .eloqua-form {
	width: 100%;
}

.form .eloqua-form .field-p {
	position: relative;
	margin-top: 1.875rem;
}

.form .eloqua-form label {
	font-weight: 400;
	font-size: 0.75rem;
	letter-spacing: 0.16em;
	line-height: 1.4;
	text-transform: uppercase;
	display: block;
}

.form .eloqua-form input[type='text'] {
	color: #fff;
	font-size: 0.875rem;
	width: 100%;
	height: 1.875rem;
	background-color: transparent;
	border: none;
	border-bottom: 0.125rem solid rgba(255, 255, 255, 0.25);
}

.form .eloqua-form input[type='submit'] {
	font-weight: 500;
	line-height: 1;
	letter-spacing: 0.14em;
	border: 0.125rem solid #fff;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: color 0.15s linear, background 0.15s linear;
	cursor: pointer;
}

.form .eloqua-form input[type='submit']:hover,
.form .eloqua-form input[type='submit']:focus {
	color: #0090ba;
	background-color: #fff;
}

@media only screen and (min-width: 48rem) {
	.form .eloqua-form input[type='submit'] {
		width: 18.75rem;
		margin: 1.0625rem auto 0;
	}
}

@media only screen and (min-width: 1289px) {
	.form .eloqua-form input[type='submit'] {
		margin: 2.5rem 0 0;
	}
}

.form .eloqua-form textarea {
	font-weight: 500;
	color: #fff;
	font-size: 0.875rem;
	width: 100%;
	height: 5rem;
	margin-top: 0.5rem;
	background-color: transparent;
	border: 0.125rem solid rgba(255, 255, 255, 0.25);
}

.form .eloqua-form select {
	background: transparent;
	border: none;
	border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.25);
	border-radius: 0;
	color: #fff;
	height: 1.875rem;
	font-size: 0.875rem;
	width: 100%;
	margin-top: 0.5rem;
	-webkit-appearance: none;
}

.form .eloqua-form select option {
	color: #000;
}

.form .eloqua-form select[name='multipleSelectMenu'] option {
	color: #fff;
	font-size: 0.875rem;
}

.form .eloqua-form input[type='text'].LV_invalid_field,
.form .eloqua-form textarea.LV_invalid_field,
.form .eloqua-form select.LV_invalid_field {
	border-color: #fd6e70;
}

.form .eloqua-form input[type='text'].LV_valid_field,
.form .eloqua-form textarea.LV_valid_field,
.form .eloqua-form select.LV_valid_field {
	outline: none;
}

.form .LV_validation_message {
	font-weight: 400;
	font-size: 0.6875rem;
	line-height: 1.2;
	text-transform: uppercase;
	display: block;
	margin: 0.5rem 0 0;
	position: absolute;
}

.form .LV_validation_message.LV_invalid {
	color: #fd6e70;
}

.form .LV_validation_message.LV_valid {
	display: none;
}

.form__success-msg {
	position: relative;
	text-align: center;
	padding: 3.125rem 1.5625rem;
}

@media only screen and (min-width: 48rem) {
	.form__success-msg {
		padding: 3.75rem 1.75rem;
	}
}

@media only screen and (min-width: 1289px) {
	.form__success-msg {
		padding: 3.125rem 2.1875rem;
	}
}

.form__success-msg__icon {
	margin-bottom: 1.25rem;
}

.form__success-msg__icon::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤';
	color: #0090ba;
	font-size: 1.75rem;
}

.form__success-msg__title {
	font-weight: 300;
	font-size: 1.875rem;
	letter-spacing: 0.04em;
	line-height: 1.33;
}

@media only screen and (min-width: 48rem) {
	.form__success-msg__title {
		font-size: 2.5rem;
	}
}

@media only screen and (min-width: 1289px) {
	.form__success-msg__title {
		font-size: 3.125rem;
	}
}

.form__success-msg__description {
	font-weight: 300;
	font-size: 0.875rem;
	letter-spacing: 0.04em;
	line-height: 1.7;
	opacity: 0.8;
	margin-top: 0.625rem;
}

@media only screen and (min-width: 48rem) {
	.form__success-msg__description {
		font-size: 1rem;
	}
}

@media only screen and (min-width: 1289px) {
	.form__success-msg__description {
		font-size: 1.5rem;
	}
}

.form--white .eloqua-form input[type='submit'] {
	color: #fff;
	background-color: transparent;
}

.form--white .eloqua-form input[type='submit']:hover,
.form--white .eloqua-form input[type='submit']:focus {
	color: #0090ba;
	background-color: #fff;
}

.form--blue .eloqua-form input[type='submit'] {
	color: #0090ba;
	background-color: #fff;
	border: 0.125rem solid #0090ba;
}

.form--blue .eloqua-form input[type='submit']:hover,
.form--blue .eloqua-form input[type='submit']:focus {
	color: #fff;
	background-color: #0090ba;
}

.about_contact .form--white .eloqua-form input[type='text'] {
	border-bottom: 0.125rem solid #e7e7e8;
	color: gray;
}

.about_contact .form--white .eloqua-form input[type='submit'] {
	color: #fff;
	background-color: #0090ba;
	border: 0.125rem solid #0090ba;
	margin-left: auto;
	margin-right: auto;
}

.about_contact .form--white .eloqua-form input[type='submit']:hover,
.about_contact .form--white .eloqua-form input[type='submit']:focus {
	color: #0090ba;
	background-color: #fff;
}

.about_contact .form--white .eloqua-form textarea {
	border: 0.125rem solid #e7e7e8;
	color: gray;
}

.about_contact .form--white .eloqua-form select {
	border-bottom: 0.0625rem solid #e7e7e8;
	color: gray;
}

.about_contact .form--white .eloqua-form select option {
	color: gray;
}

.about_contact .form--white .eloqua-form select[name='multipleSelectMenu'] option {
	color: gray;
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.homepage {
	position: relative;
}

.homepage .find-symbol {
	width: 100%;
	margin-left: 0;
	margin-right: 0;
}

.homepage .find-symbol__container {
	padding: 0;
}

.homepage .find-symbol__input {
	background: #e3e3e4;
	opacity: 0.5;
	color: #000;
	border: none;
	text-transform: none;
}

.homepage .find-symbol__input::-webkit-input-placeholder {
	color: #000 !important;
}

.homepage .find-symbol__input::-moz-placeholder {
	color: #000 !important;
}

.homepage .find-symbol__input::-ms-input-placeholder {
	color: #000 !important;
}

.homepage .find-symbol__input::placeholder {
	color: #000 !important;
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.loader-overlay {
	position: absolute;
	z-index: 90;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: center;
	visibility: visible;
	transition: opacity 0.4s, visibility 0.4s;
	-webkit-transition: opacity 0.4s, visibility 0.4s;
	padding: 1.25rem;
}

.loader-overlay:not(.loader-overlay--transparent)::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	display: block;
	background: #fff;
	opacity: 0.8;
	z-index: 0;
}

.loader-overlay .svg-loader {
	z-index: 101;
	width: 2.1875rem !important;
	visibility: visible;
	transition: opacity 0.4s, visibility 0.4s;
	-webkit-transition: opacity 0.4s, visibility 0.4s;
}

.loader-overlay .error-state {
	display: none;
}

.loaded > .loader-overlay {
	visibility: hidden;
	display: none;
}

.loaded > .loader-overlay .svg-loader {
	visibility: hidden;
	display: none;
}

.api-error > .loader-overlay .svg-loader {
	visibility: hidden;
	display: none;
}

.api-error > .loader-overlay .error-state {
	font-weight: 600;
	display: flex;
	color: #0090ba;
	z-index: 101;
	font-size: 1.125rem;
	position: relative;
	margin-left: 1.75rem;
	line-height: 1;
}

.api-error > .loader-overlay .error-state::after {
	content: '!';
	border: 0.125rem solid #0090ba;
	border-radius: 50%;
	width: 1.375rem;
	height: 1.375rem;
	top: -0.125rem;
	left: -1.75rem;
	position: absolute;
	line-height: 1;
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.module-header {
	font-weight: 300;
	font-size: 1.25rem;
	letter-spacing: 0.04em;
	margin-bottom: 1.25rem;
	line-height: 1.75rem;
}

@media only screen and (min-width: 48rem) {
	.module-header {
		font-size: 1.5rem;
	}
}

.module-header::before {
	content: '';
	display: block;
	width: 1.875rem;
	height: 0.125rem;
	background: #0090ba;
	margin-bottom: 0.4375rem;
}

.module-header__icon {
	margin-right: 0.5rem;
}

.module-header__icon[data-icon='news']::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤“';
}

.module-header__icon::before {
	background: none;
}

.series-video-player__bg,
.series-video-player__list::before,
.series-video-player__sidebar-wrap::before {
	background-blend-mode: normal, normal, overlay, overlay, normal, normal, normal, normal;
	background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
		linear-gradient(301deg, rgba(44, 34, 150, 0), #7b2297),
		linear-gradient(rgba(0, 82, 154, 0.95), rgba(0, 82, 154, 0.95)),
		linear-gradient(121deg, rgba(109, 180, 62, 0), #6db43e), linear-gradient(238deg, rgba(173, 32, 142, 0), #ad208e),
		linear-gradient(42deg, rgba(0, 190, 213, 0), rgba(0, 190, 213, 0.85) 85%, rgba(0, 190, 213, 0.87) 87%, #00bed5),
		linear-gradient(126deg, rgba(0, 190, 213, 0), #00bed5), linear-gradient(#594099, #594099);
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.series-video-player {
	margin-bottom: 3.125rem;
	position: relative;
	padding-bottom: 2.5rem;
	overflow: hidden;
}

@media only screen and (min-width: 48rem) {
	.series-video-player {
		margin-bottom: 3.75rem;
	}
}

@media only screen and (min-width: 1289px) {
	.series-video-player {
		margin-bottom: 5rem;
	}
}

.series-video-player__headline {
	position: relative;
	z-index: 1;
}

.series-video-player__headline .module-header {
	width: calc(100% - 3.125rem);
	float: left;
	margin-left: 1.5625rem;
	font-weight: 400;
}

.series-video-player__bg {
	position: absolute;
	top: 12.25rem;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 0;
}

.series-video-player__container {
	position: relative;
	z-index: 1;
}

.series-video-player__wrap {
	width: calc(100% - 3.125rem);
	float: left;
	margin-left: 1.5625rem;
}

.series-video-player__current {
	margin-bottom: 1.25rem;
}

.series-video-player__current-video {
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
	position: absolute;
}

.series-video-player__current-video[aria-hidden='false'] {
	visibility: visible;
	opacity: 1;
	pointer-events: auto;
	position: static;
}

.series-video-player__current-video video {
	max-width: 100%;
}

.series-video-player__current-video .video-js {
	width: 100%;
	height: auto;
	padding-top: 56.25%;
}

.series-video-player__list {
	position: relative;
}

.series-video-player__list::before {
	content: '';
	pointer-events: none;
	display: none;
}

.series-video-player__item {
	margin-bottom: 2.5rem;
	display: flex;
	align-items: flex-start;
	opacity: 0.5;
	transition: opacity 0.35s ease-out;
	width: 100%;
}

.series-video-player__item[aria-selected='true'] {
	transition: opacity 0.15s ease;
	pointer-events: none;
	opacity: 1;
}

.series-video-player__item:last-of-type {
	margin-bottom: 0;
}

.series-video-player__figure {
	flex-basis: 7.1875rem;
	width: 7.1875rem;
	margin-right: 1.5625rem;
	position: relative;
}

.series-video-player__figure::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	background-color: #000;
	opacity: 0.35;
}

.series-video-player__figure img {
	max-width: 100%;
	height: auto;
}

.series-video-player__playing-label {
	font-weight: 500;
	display: none;
	font-size: 0.875rem;
	letter-spacing: 2px;
	text-transform: uppercase;
	color: #fff;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	text-align: center;
	line-height: 1.4;
	z-index: 2;
}

.series-video-player__playing-label[aria-hidden='false'] {
	display: block;
}

.series-video-player__picture {
	display: block;
	position: relative;
	z-index: 0;
}

.series-video-player__img {
	max-width: 100%;
}

[data-selected='false'] .series-video-player__img {
	opacity: 0.35;
}

.series-video-player__info {
	flex-basis: calc(100% - 8.75rem);
	display: flex;
	flex-direction: column;
	text-align: left;
}

.series-video-player__title {
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 1.71;
	letter-spacing: 0.5px;
	color: #fff;
	margin-bottom: 0.4375rem;
}

.series-video-player__date {
	font-weight: 400;
	font-size: 0.625rem;
	line-height: 1.2;
	letter-spacing: 1.7px;
	opacity: 0.65;
	text-transform: uppercase;
	color: #fff;
}

.series-video-player__cta {
	padding-top: 1.875rem;
}

.series-video-player__cta_link {
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 1;
	letter-spacing: 2px;
	text-transform: uppercase;
	color: #fff;
	position: relative;
	transition: opacity 0.35s ease-out;
}

.series-video-player__cta_link:hover {
	transition: opacity 0.15s ease;
	opacity: 0.85;
	text-decoration: none;
}

.series-video-player__cta-arrow {
	font-size: 0.625rem;
	-webkit-transform: translate(100%, -50%);
	transform: translate(100%, -50%);
	position: absolute;
	right: -0.625rem;
	top: 48%;
	transition: -webkit-transform 0.35s ease-out;
	transition: transform 0.35s ease-out;
	transition: transform 0.35s ease-out, -webkit-transform 0.35s ease-out;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.series-video-player__cta-arrow::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤Ÿ';
}

.series-video-player__cta_link:hover .series-video-player__cta-arrow {
	transition: -webkit-transform 0.15s ease;
	transition: transform 0.15s ease;
	transition: transform 0.15s ease, -webkit-transform 0.15s ease;
	-webkit-transform: translate(115%, -50%);
	transform: translate(115%, -50%);
}

.series-video-player__sidebar-wrap::before {
	content: '';
	pointer-events: none;
	display: none;
}

.series-video-player__sidebar-title {
	font-weight: 300;
	font-size: 1.5rem;
	line-height: 1.33;
	letter-spacing: 1px;
	color: #fff;
	margin-bottom: 0.625rem;
	position: relative;
	z-index: 1;
}

.series-video-player__sidebar-description {
	font-weight: 300;
	font-size: 0.875rem;
	line-height: 2;
	letter-spacing: 1px;
	color: #fff;
	margin-bottom: 0.625rem;
	position: relative;
	z-index: 1;
}

.series-video-player__sidebar-date {
	font-weight: 300;
	color: #fff;
	font-size: 0.625rem;
	line-height: 1.2;
	letter-spacing: 1.7px;
	text-transform: uppercase;
	position: relative;
	z-index: 1;
}

.series-video-player .brightcove__template + .brightcove__template {
	display: none;
}

@media only screen and (min-width: 48rem) {
	.series-video-player {
		padding-bottom: 3.75rem;
	}

	.series-video-player__headline .module-header {
		width: calc(100% - 4.375rem);
		margin-left: 2.1875rem;
	}

	.series-video-player__wrap {
		width: calc(100% - 4.375rem);
		margin-left: 2.1875rem;
	}

	.series-video-player__bg {
		top: 20.9375rem;
	}

	.series-video-player__item {
		margin-bottom: 1.25rem;
	}

	.series-video-player__figure {
		flex-basis: 8.75rem;
		width: 8.75rem;
		margin-right: 1.25rem;
	}

	.series-video-player__info {
		flex-basis: calc(100% - 11.25rem);
	}

	.series-video-player__title {
		font-size: 1.125rem;
		letter-spacing: 0.7px;
	}
}

@media only screen and (min-width: 1289px) {
	.series-video-player {
		margin-left: 4.375rem;
		margin-right: 4.375rem;
	}

	.series-video-player__headline .module-header {
		width: 100%;
		float: none;
		margin-left: 0;
	}

	.series-video-player__wrap {
		display: flex;
		width: 100%;
		margin-left: 0;
		float: none;
	}

	.series-video-player__current {
		flex-basis: calc(68% - 1.25rem);
		margin-right: 1.25rem;
		position: relative;
		z-index: 1;
	}

	[data-variation='video'] .series-video-player__current {
		flex-basis: calc(85% - 1.25rem);
	}

	.series-video-player__list {
		z-index: 0;
		flex-basis: calc(30% - 1.25rem);
	}

	.series-video-player__list::before {
		display: block;
		position: absolute;
		z-index: 0;
		top: -2.5rem;
		left: -6.6875rem;
		right: -2.5rem;
		bottom: -2.5rem;
	}

	.series-video-player__item {
		position: relative;
		z-index: 1;
	}

	.series-video-player__bg {
		display: none;
	}

	.series-video-player__sidebar {
		width: 32.0625rem;
		position: relative;
	}

	.series-video-player__sidebar-wrap {
		position: relative;
		padding-right: 3.3125rem;
	}

	.series-video-player__sidebar-wrap::before {
		display: block;
		position: absolute;
		z-index: 0;
		top: -2.5rem;
		left: -6.6875rem;
		right: -2.5rem;
		bottom: -2.5rem;
	}

	.series-video-player__title {
		font-size: 0.875rem;
		letter-spacing: 0.5px;
	}
}

@media (min-width: 87.5rem) {
	.series-video-player {
		width: 100%;
		max-width: 78.75rem;
		margin-left: auto;
		margin-right: auto;
	}
}

@media (min-width: 108.75rem) {
	.series-video-player {
		width: 100rem;
		max-width: 100rem;
		margin-left: auto;
		margin-right: auto;
	}
}

.trending__item__bar::after {
	background-blend-mode: normal, overlay, overlay, normal, normal, normal, normal;
	background-image: linear-gradient(301deg, rgba(44, 34, 150, 0), #7b2297),
		linear-gradient(rgba(0, 82, 154, 0.95), rgba(0, 82, 154, 0.95)), linear-gradient(121deg, #3fcc6f, #3fcc6f),
		linear-gradient(238deg, rgba(173, 32, 142, 0), #ad208e),
		linear-gradient(42deg, rgba(0, 190, 213, 0), rgba(0, 190, 213, 0.85) 85%, rgba(0, 190, 213, 0.87) 87%, #00bed5),
		linear-gradient(126deg, rgba(0, 190, 213, 0), #00bed5), linear-gradient(#594099, #594099);
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.trending {
	position: relative;
	width: 100%;
	padding: 1.25rem 1.5625rem 1.875rem;
	background-color: #2b2b2b;
	color: #fff;
	display: block;
}

@media only screen and (min-width: 48rem) {
	.trending {
		padding: 1.25rem 2.125rem 1.875rem;
		width: 50%;
	}
}

@media only screen and (min-width: 1289px) {
	.trending {
		padding: 1.25rem 1.25rem 2.5rem;
		width: 100%;
	}
}

.body__right-rail .trending {
	margin-left: -1.5625rem;
	margin-right: -1.5625rem;
	width: calc(100% + 3.125rem);
}

@media only screen and (min-width: 48rem) {
	.body__right-rail .trending {
		width: calc(100% + 4.25rem);
		margin-left: -2.125rem;
		margin-right: -2.125rem;
	}
}

@media only screen and (min-width: 1289px) {
	.body__right-rail .trending {
		width: 100%;
		margin: 0;
	}
}

@media only screen and (min-width: 48rem) {
	.layout--news-and-insights-rail .trending {
		margin-left: -0.875rem;
		margin-right: -0.875rem;
		width: calc(100% + (0.875rem * 2));
	}
}

.trending__title {
	font-weight: 400;
	font-size: 0.875rem;
	letter-spacing: 0.1em;
	line-height: 1;
	text-transform: uppercase;
	display: flex;
	align-items: flex-start;
	margin-bottom: 1.25rem;
}

.trending__title::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤“';
}

.trending--topics .trending__title {
	margin-bottom: 1.875rem;
}

.trending__title::before {
	color: #0090ba;
	font-size: 0.75rem;
	margin-right: 0.5rem;
}

.trending__item {
	margin-top: 1.25rem;
}

.trending--topics .trending__item {
	margin-top: 1.875rem;
}

.trending__item:first-child {
	margin-top: 0;
}

.trending--topics .trending__item:first-child {
	margin-top: 0;
}

.trending__item__title {
	font-weight: 400;
	font-size: 0.75rem;
	letter-spacing: 0.04em;
	line-height: 1.67;
	display: block;
	margin-bottom: 0.3125rem;
}

.trending--topics .trending__item__title {
	margin-bottom: 0.625rem;
}

.trending--topics .trending__item__title .arrow-cta {
	font-weight: 300;
	color: #fff;
	font-size: 2.5rem;
	letter-spacing: 0.04em;
	line-height: 1.2;
	text-transform: none;
}

.trending--topics .trending__item__title .arrow-cta__icon::before {
	color: #0090ba;
}

.trending--topics .trending__item:nth-child(2) .trending__item__title .arrow-cta {
	font-size: 2.1875rem;
}

.trending--topics .trending__item:nth-child(3) .trending__item__title .arrow-cta {
	font-size: 1.875rem;
}

.trending--topics .trending__item:nth-child(4) .trending__item__title .arrow-cta {
	font-size: 1.5625rem;
}

.trending--related-articles .trending__item__title .arrow-cta {
	font-weight: 400;
	color: #fff;
	font-size: 0.75rem;
	text-transform: none;
}

.trending--related-articles .trending__item__title .arrow-cta__icon::before {
	color: #0090ba;
}

.trending__item__date {
	font-weight: 400;
	font-size: 0.625rem;
	letter-spacing: 0.17em;
	line-height: 1.2;
	color: gray;
	text-transform: uppercase;
	display: block;
	margin-bottom: 0.625rem;
}

.trending__item__bar {
	position: relative;
	display: block;
	width: 100%;
	height: 0.625rem;
	background-color: rgba(167, 169, 172, 0.3);
}

.trending__item__bar::after {
	content: '';
	position: absolute;
	height: 100%;
	width: 100%;
}

.trending__item:nth-child(2) .trending__item__bar::after {
	width: 90%;
}

.trending__item:nth-child(3) .trending__item__bar::after {
	width: 80%;
}

.trending__item:nth-child(4) .trending__item__bar::after {
	width: 70%;
}

.trending.latest-news {
	display: none;
}

@media only screen and (min-width: 1289px) {
	.trending.latest-news {
		display: block;
	}
}

@media only screen and (min-width: 48rem) {
	.trending.trending-articles {
		width: 100%;
	}
}

.news-insights--category .trending-articles {
	width: auto;
	margin-left: 0;
	margin-right: 0;
}

@media only screen and (min-width: 48rem) {
	.news-insights--category .trending-articles {
		flex-shrink: 0;
		width: calc(100% + 1.75rem);
		margin-left: -0.875rem;
	}
}

@media only screen and (min-width: 1289px) {
	.news-insights--category .trending-articles {
		flex-shrink: 0;
		width: 100%;
		margin-left: 0;
	}
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.tabbed-symbol-package-header {
	padding-bottom: 0.625rem;
	border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.1);
}

@media only screen and (min-width: 1289px) {
	.tabbed-symbol-package-header {
		padding-bottom: 1.25rem;
	}
}

@media only screen and (min-width: 1289px) {
	.tabbed-symbol-package-header {
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
	}
}

.tabbed-symbol-package-header__tabs {
	display: flex;
	flex-flow: row nowrap;
	overflow: auto;
	position: relative;
}

@media only screen and (min-width: 1289px) {
	.tabbed-symbol-package-header__tabs {
		display: flex;
		align-items: flex-end;
		order: 1;
	}
}

.tabbed-symbol-package-header__tabs::after {
	width: 25.33333%;
	background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #fff);
}

.tabbed-symbol-package-header__tabs li {
	flex-shrink: 0;
}

.tabbed-symbol-package-header__tabs li:not(:first-child) {
	margin-left: 0.625rem;
}

.tabbed-symbol-package-header__tab {
	font-weight: 400;
	display: block;
	padding: 0.625rem;
	font-size: 0.75rem;
	line-height: 1;
	letter-spacing: 0.14em;
	color: #0090ba;
	text-transform: uppercase;
}

.tabbed-symbol-package-header__tab--active {
	background-color: #0090ba;
	color: #fff;
}

.tabbed-symbol-package-header__title-wrapper {
	width: calc(100% - 3.125rem);
	float: left;
	margin-left: 1.5625rem;
	float: none;
	margin-top: 1.25rem;
}

@media only screen and (min-width: 48rem) {
	.tabbed-symbol-package-header__title-wrapper {
		width: auto;
		margin-top: 0;
		margin-left: 0;
	}
}

@media only screen and (min-width: 1289px) {
	.tabbed-symbol-package-header__title-wrapper {
		display: flex;
		align-items: flex-end;
	}
}

.tabbed-symbol-package-header__title {
	display: none;
}

@media only screen and (min-width: 1289px) {
	.tabbed-symbol-package-header__title {
		font-weight: 300;
		display: block;
		font-size: 2.5rem;
		line-height: 1;
		letter-spacing: 0.04em;
		margin-right: 0.625rem;
	}
}

.tabbed-symbol-package-header__title_text {
	display: none;
}

.tabbed-symbol-package-header__title_text--active {
	display: block;
}

.tabbed-symbol-package-header__timestamp {
	font-weight: 500;
	display: block;
	font-size: 0.625rem;
	line-height: 1.2;
	letter-spacing: 0.17em;
	text-transform: uppercase;
	margin-top: 1.25rem;
	color: gray;
}

@media only screen and (min-width: 1289px) {
	.tabbed-symbol-package-header__timestamp {
		margin-top: auto;
		margin-bottom: 0.3125rem;
	}
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.tabbed-symbol-package-panel {
	display: none;
}

.tabbed-symbol-package-panel--active {
	display: block;
}

.tabbed-symbol-package-panel__charts {
	margin-top: 1.25rem;
}

@media only screen and (min-width: 1289px) {
	.tabbed-symbol-package-panel__charts {
		display: flex;
		justify-content: space-between;
	}
}

.tabbed-symbol-package-panel__chart {
	display: block;
	position: relative;
	overflow: hidden;
}

@media only screen and (min-width: 1289px) {
	.tabbed-symbol-package-panel__chart {
		display: flex;
		flex-flow: row wrap;
		align-items: flex-end;
		width: 47.72727%;
	}
}

.tabbed-symbol-package-panel__chart:hover,
.tabbed-symbol-package-panel__chart:focus {
	text-decoration: none;
}

.tabbed-symbol-package-panel__chart:not(:first-child) {
	margin-top: 1.875rem;
}

@media only screen and (min-width: 1289px) {
	.tabbed-symbol-package-panel__chart:not(:first-child) {
		margin-top: 0;
	}
}

.tabbed-symbol-package-panel .symbol-graph {
	width: 100%;
}

.tabbed-symbol-package-panel .symbol-graph__content {
	margin-top: 1.25rem;
	width: 100%;
	height: 9.375rem;
}

.tabbed-symbol-package-panel__title {
	font-weight: 300;
	font-size: 1.75rem;
	line-height: 1.29;
	letter-spacing: 0.04em;
}

@media only screen and (min-width: 48rem) {
	.tabbed-symbol-package-panel__title {
		align-self: flex-start;
		font-size: 2.5rem;
	}
}

@media only screen and (min-width: 1289px) {
	.tabbed-symbol-package-panel__title {
		margin-bottom: auto;
	}
}

.tabbed-symbol-package-panel__ticker {
	font-weight: 400;
	width: 100%;
	position: relative;
	margin-top: 1.25rem;
	font-size: 1.125rem;
	line-height: 1;
	letter-spacing: 0.04em;
}

.tabbed-symbol-package-panel__ticker::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤¢';
	display: none;
	font-size: 2.75rem;
	position: absolute;
	top: 0;
	left: 0;
}

.tabbed-symbol-package-panel__ticker-change {
	margin-top: 0.625rem;
}

.tabbed-symbol-package-panel__ticker.js-tabbed-symbol-package-ticker--up {
	padding-left: 1.1875rem;
}

.tabbed-symbol-package-panel__ticker.js-tabbed-symbol-package-ticker--up::before {
	display: block;
	color: #3fcc6f;
}

.tabbed-symbol-package-panel__ticker.js-tabbed-symbol-package-ticker--up .tabbed-symbol-package-panel__ticker-change {
	color: #3fcc6f;
}

.tabbed-symbol-package-panel__ticker.js-tabbed-symbol-package-ticker--down {
	padding-left: 1.1875rem;
}

.tabbed-symbol-package-panel__ticker.js-tabbed-symbol-package-ticker--down::before {
	display: block;
	color: #fd6e70;
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}

.tabbed-symbol-package-panel__ticker.js-tabbed-symbol-package-ticker--down .tabbed-symbol-package-panel__ticker-change {
	color: #fd6e70;
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.tabbed-symbol-package-supplemental {
	margin-top: 0.625rem;
	padding-top: 0.625rem;
}

.tabbed-symbol-package-supplemental:not(:nth-child(2)) {
	border-top: 0.0625rem solid #a7a9ac;
}

.tabbed-symbol-package-supplemental__eyebrow {
	font-weight: 500;
	font-size: 0.625rem;
	line-height: 1.2;
	letter-spacing: 0.17em;
	margin-bottom: 1.875rem;
	text-transform: uppercase;
	color: gray;
}

.tabbed-symbol-package-supplemental__symbols {
	margin-left: -1.5625rem;
	margin-right: -1.5625rem;
	width: calc(100% + 3.125rem);
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
}

@media only screen and (min-width: 48rem) {
	.tabbed-symbol-package-supplemental__symbols {
		margin-left: -1.25rem;
		margin-right: -1.25rem;
		width: calc(100% + 2.5rem);
		justify-content: flex-start;
	}
}

@media only screen and (min-width: 1289px) {
	.tabbed-symbol-package-supplemental__symbols {
		width: 100%;
		margin: 0;
		align-items: stretch;
	}
}

.tabbed-symbol-package-supplemental .symbol-ticker {
	width: calc(40% - 2.1875rem);
	float: left;
	margin-left: 1.5625rem;
	flex-shrink: 0;
	margin-bottom: 1.25rem;
	position: relative;
}

@media only screen and (min-width: 48rem) {
	.tabbed-symbol-package-supplemental .symbol-ticker {
		width: calc(25% - 1.5625rem);
		float: left;
		margin-left: 1.25rem;
		margin-bottom: 1.875rem;
	}
}

@media only screen and (min-width: 1289px) {
	.tabbed-symbol-package-supplemental .symbol-ticker {
		width: 14.54545%;
		margin-left: 0;
		margin-bottom: 0;
		height: auto;
	}
}

.tabbed-symbol-package-supplemental .symbol-ticker:nth-child(even) {
	margin-right: 3.125rem;
}

@media only screen and (min-width: 48rem) {
	.tabbed-symbol-package-supplemental .symbol-ticker:nth-child(even) {
		margin-right: 0;
	}
}

@media only screen and (min-width: 1289px) {
	.tabbed-symbol-package-supplemental .symbol-ticker:not(:first-child) {
		margin-left: 6.81818%;
	}
}

.tabbed-symbol-package-supplemental .symbol-ticker__name {
	font-weight: 500;
	display: block;
	font-size: 0.75rem;
	line-height: 1;
	letter-spacing: 0.04em;
	text-transform: uppercase;
	color: #000;
	margin-bottom: auto;
}

@media only screen and (min-width: 1289px) {
	.tabbed-symbol-package-supplemental .symbol-ticker__change {
		white-space: nowrap;
	}
}

.tabbed-symbol-package__box {
	width: 100%;
}

@media only screen and (min-width: 1289px) {
	.tabbed-symbol-package__box {
		box-shadow: 0 0 0.625rem 0 rgba(0, 0, 0, 0.1);
		padding: 1.25rem 1.875rem;
	}
}

.tabbed-symbol-package .module-header {
	width: 100%;
}

@media only screen and (min-width: 48rem) {
	.tabbed-symbol-package .module-header {
		margin-bottom: 1.875rem;
	}
}

@media only screen and (min-width: 1289px) {
	.tabbed-symbol-package .module-header {
		margin-bottom: 1.25rem;
	}
}

.tabbed-symbol-package__panels {
	width: 100%;
}

@media only screen and (min-width: 48rem) {
	.tabbed-symbol-package__panels {
		width: auto;
		margin: 0;
	}
}

.tabbed-symbol-package .symbol-ticker__arrow {
	top: 0;
}

.tabbed-symbol-package .symbol-ticker__change {
	display: block;
	margin-top: 0.3125rem;
}

@media only screen and (min-width: 1289px) {
	.tabbed-symbol-package .symbol-ticker__change {
		display: inline;
		margin-top: 0;
	}
}

.tabbed-symbol-package .symbol-graph__timestamp {
	display: none !important;
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.symbol-ticker {
	position: relative;
	display: flex;
	flex-flow: column nowrap;
	align-content: flex-start;
	height: 3rem;
	font-size: 0.75rem;
	line-height: 1;
	margin-bottom: 0.125rem;
	padding-left: 0.9375rem;
}

.homepage-markets-sidebar .symbol-ticker {
	min-height: 3.125rem;
}

.tabbed-symbol-package-supplemental__symbols .symbol-ticker {
	min-height: 3.75rem;
}

.symbol-ticker__arrow {
	display: block;
	position: absolute;
	top: 0.3125rem;
	left: 0;
	font-size: 2.8125rem;
	margin-right: 0.3125rem;
	opacity: 0;
	transition: opacity 0.5s;
}

.symbol-ticker__arrow::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤¢';
	display: block;
}

.symbol-ticker:hover,
.symbol-ticker:focus {
	text-decoration: none;
}

.symbol-ticker--up .symbol-ticker__arrow {
	color: #3fcc6f;
	opacity: 1;
}

.symbol-ticker--up .symbol-ticker__change {
	color: #3fcc6f;
}

.symbol-ticker--down .symbol-ticker__arrow {
	color: #fd6e70;
	opacity: 1;
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}

.symbol-ticker--down .symbol-ticker__change {
	color: #fd6e70;
}

.symbol-ticker > :not(:first-child) {
	margin-top: 0.3125rem;
}

.symbol-ticker__timestamp {
	font-weight: 500;
	font-size: 0.625rem;
	line-height: 1.2;
	text-transform: uppercase;
	margin-top: 0.3125rem;
	margin-bottom: 0.3125rem;
	display: none;
	color: gray;
}

.symbol-ticker__symbol {
	font-weight: 500;
	max-width: 7.5rem;
}

.symbol-ticker__name {
	font-weight: 300;
	display: none;
	font-size: 1.25rem;
	line-height: 1.6;
	color: gray;
}

.symbol-ticker__value {
	font-weight: 400;
	font-size: 0.75rem;
}

.symbol-ticker__change {
	font-weight: 500;
	font-size: 0.75rem;
}

.symbol-ticker--large-symbol {
	height: auto;
	padding-left: 0;
}

.symbol-ticker--large-symbol .symbol-ticker__symbol {
	font-weight: 500;
	font-size: 0.75rem;
	margin-bottom: 1.25rem;
}

@media only screen and (min-width: 48rem) {
	.symbol-ticker--large-symbol .symbol-ticker__symbol {
		font-size: 1.125rem;
	}
}

@media only screen and (min-width: 1289px) {
	.symbol-ticker--large-symbol .symbol-ticker__symbol {
		font-weight: 300;
		font-size: 1.5rem;
	}
}

.symbol-ticker--large-symbol .symbol-ticker__symbol-data {
	font-weight: 400;
	font-size: 1.125rem;
	position: relative;
}

.symbol-ticker--large-symbol .symbol-ticker__symbol-data--up,
.symbol-ticker--large-symbol .symbol-ticker__symbol-data--down {
	padding-left: 1.5625rem;
}

.symbol-ticker--large-symbol .symbol-ticker__symbol-data--up .symbol-ticker__arrow,
.symbol-ticker--large-symbol .symbol-ticker__symbol-data--down .symbol-ticker__arrow {
	opacity: 1;
}

.symbol-ticker--large-symbol .symbol-ticker__symbol-data--up .symbol-ticker__arrow::before,
.symbol-ticker--large-symbol .symbol-ticker__symbol-data--down .symbol-ticker__arrow::before {
	font-size: 2.75rem;
}

.symbol-ticker--large-symbol .symbol-ticker__symbol-data--up .symbol-ticker__change--percent,
.symbol-ticker--large-symbol .symbol-ticker__symbol-data--up .symbol-ticker__change--points {
	color: #3fcc6f;
}

.symbol-ticker--large-symbol .symbol-ticker__symbol-data--up .symbol-ticker__arrow::before {
	color: #3fcc6f;
}

.symbol-ticker--large-symbol .symbol-ticker__symbol-data--down .symbol-ticker__change--percent,
.symbol-ticker--large-symbol .symbol-ticker__symbol-data--down .symbol-ticker__change--points {
	color: #fd6e70;
}

.symbol-ticker--large-symbol .symbol-ticker__symbol-data--down .symbol-ticker__arrow::before {
	color: #fd6e70;
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}

.symbol-ticker--large-symbol .symbol-ticker__value {
	display: block;
	line-height: 1.5;
}

.symbol-ticker--large-symbol.symbol-ticker--up,
.symbol-ticker--large-symbol.symbol-ticker--down {
	padding-left: 1.5625rem;
}

.symbol-ticker--large-symbol.symbol-ticker--up .symbol-ticker__arrow::before,
.symbol-ticker--large-symbol.symbol-ticker--down .symbol-ticker__arrow::before {
	font-size: 2.75rem;
}

.symbol-ticker--large-symbol.symbol-ticker--up .symbol-ticker__change--points,
.symbol-ticker--large-symbol.symbol-ticker--up .symbol-ticker__change--percent {
	color: #3fcc6f;
}

.symbol-ticker--large-symbol.symbol-ticker--up .symbol-ticker__symbol-data .symbol-ticker__arrow::before {
	color: #3fcc6f;
}

.symbol-ticker--large-symbol.symbol-ticker--down .symbol-ticker__change--points,
.symbol-ticker--large-symbol.symbol-ticker--down .symbol-ticker__change--percent {
	color: #fd6e70;
}

.symbol-ticker--large-symbol.symbol-ticker--down .symbol-ticker__symbol-data .symbol-ticker__arrow::before {
	color: #fd6e70;
}

.homepage-markets-sidebar__primary-symbol .symbol-ticker__symbol {
	max-width: 15.625rem;
}

.highcharts-container {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
	text-align: left;
	line-height: normal;
	z-index: 0;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	font-size: 12px;
}

.highcharts-root {
	display: block;
}

.highcharts-root text {
	stroke-width: 0;
}

.highcharts-strong {
	font-weight: 700;
}

.highcharts-emphasized {
	font-style: italic;
}

.highcharts-anchor {
	cursor: pointer;
}

.highcharts-background {
	fill: #fff;
}

.highcharts-plot-border,
.highcharts-plot-background {
	fill: none;
}

.highcharts-label-box {
	fill: none;
}

.highcharts-button-box {
	fill: inherit;
}

.highcharts-tracker-line {
	stroke-linejoin: round;
	stroke: rgba(192, 192, 192, 0.0001);
	stroke-width: 22;
	fill: none;
}

.highcharts-tracker-area {
	fill: rgba(192, 192, 192, 0.0001);
	stroke-width: 0;
}

.highcharts-title {
	fill: #333;
	font-size: 1.5em;
}

.highcharts-subtitle {
	fill: #666;
}

.highcharts-axis-line {
	fill: none;
	stroke: #ccd6eb;
}

.highcharts-yaxis .highcharts-axis-line {
	stroke-width: 0;
}

.highcharts-axis-title {
	fill: #666;
}

.highcharts-axis-labels {
	fill: #666;
	cursor: default;
	font-size: 0.9em;
}

.highcharts-grid-line {
	fill: none;
	stroke: #e6e6e6;
}

.highcharts-xaxis-grid .highcharts-grid-line {
	stroke-width: 0;
}

.highcharts-tick {
	stroke: #ccd6eb;
}

.highcharts-yaxis .highcharts-tick {
	stroke-width: 0;
}

.highcharts-minor-grid-line {
	stroke: #f2f2f2;
}

.highcharts-crosshair-thin {
	stroke-width: 1px;
	stroke: #ccc;
}

.highcharts-crosshair-category {
	stroke: #ccd6eb;
	stroke-opacity: 0.25;
}

.highcharts-credits {
	cursor: pointer;
	fill: #999;
	font-size: 0.7em;
	transition: fill 250ms, font-size 250ms;
}

.highcharts-credits:hover {
	fill: #000;
	font-size: 1em;
}

.highcharts-tooltip {
	cursor: default;
	pointer-events: none;
	white-space: nowrap;
	transition: stroke 150ms;
}

.highcharts-tooltip text {
	fill: #333;
}

.highcharts-tooltip .highcharts-header {
	font-size: 0.85em;
}

.highcharts-tooltip-box {
	stroke-width: 1px;
	fill: #f7f7f7;
	fill-opacity: 0.85;
}

.highcharts-tooltip-box .highcharts-label-box {
	fill: #f7f7f7;
	fill-opacity: 0.85;
}

.highcharts-selection-marker {
	fill: #335cad;
	fill-opacity: 0.25;
}

.highcharts-graph {
	fill: none;
	stroke-width: 2px;
	stroke-linecap: round;
	stroke-linejoin: round;
}

.highcharts-state-hover .highcharts-graph {
	stroke-width: 3;
}

.highcharts-point-inactive {
	opacity: 0.2;
	transition: opacity 50ms;
}

.highcharts-series-inactive {
	opacity: 0.2;
	transition: opacity 50ms;
}

.highcharts-state-hover path {
	transition: stroke-width 50ms;
}

.highcharts-state_normal path {
	transition: stroke-width 250ms;
}

g.highcharts-series,
.highcharts-point,
.highcharts-markers,
.highcharts-data-labels {
	transition: opacity 250ms;
}

.highcharts-legend-series-active g.highcharts-series:not(.highcharts-series-hover),
.highcharts-legend-point-active .highcharts-point:not(.highcharts-point-hover),
.highcharts-legend-series-active .highcharts-markers:not(.highcharts-series-hover),
.highcharts-legend-series-active .highcharts-data-labels:not(.highcharts-series-hover) {
	opacity: 0.2;
}

.highcharts-color-0 {
	fill: #7cb5ec;
	stroke: #7cb5ec;
}

.highcharts-color-1 {
	fill: #434348;
	stroke: #434348;
}

.highcharts-color-2 {
	fill: #90ed7d;
	stroke: #90ed7d;
}

.highcharts-color-3 {
	fill: #f7a35c;
	stroke: #f7a35c;
}

.highcharts-color-4 {
	fill: #8085e9;
	stroke: #8085e9;
}

.highcharts-color-5 {
	fill: #f15c80;
	stroke: #f15c80;
}

.highcharts-color-6 {
	fill: #e4d354;
	stroke: #e4d354;
}

.highcharts-color-7 {
	fill: #2b908f;
	stroke: #2b908f;
}

.highcharts-color-8 {
	fill: #f45b5b;
	stroke: #f45b5b;
}

.highcharts-color-9 {
	fill: #91e8e1;
	stroke: #91e8e1;
}

.highcharts-area {
	fill-opacity: 0.75;
	stroke-width: 0;
}

.highcharts-markers {
	stroke-width: 1px;
	stroke: #fff;
}

.highcharts-point {
	stroke-width: 1px;
}

.highcharts-dense-data .highcharts-point {
	stroke-width: 0;
}

.highcharts-data-label {
	font-size: 0.9em;
	font-weight: 700;
}

.highcharts-data-label-box {
	fill: none;
	stroke-width: 0;
}

.highcharts-data-label text,
text.highcharts-data-label {
	fill: #333;
}

.highcharts-data-label-connector {
	fill: none;
}

.highcharts-halo {
	fill-opacity: 0.25;
	stroke-width: 0;
}

.highcharts-series:not(.highcharts-pie-series) .highcharts-point-select,
.highcharts-markers .highcharts-point-select {
	fill: #ccc;
	stroke: #000;
}

.highcharts_column-series rect.highcharts-point {
	stroke: #fff;
}

.highcharts_column-series .highcharts-point {
	transition: fill-opacity 250ms;
}

.highcharts_column-series .highcharts-point-hover {
	fill-opacity: 0.75;
	transition: fill-opacity 50ms;
}

.highcharts-pie-series .highcharts-point {
	stroke-linejoin: round;
	stroke: #fff;
}

.highcharts-pie-series .highcharts-point-hover {
	fill-opacity: 0.75;
	transition: fill-opacity 50ms;
}

.highcharts-funnel-series .highcharts-point {
	stroke-linejoin: round;
	stroke: #fff;
}

.highcharts-funnel-series .highcharts-point-hover {
	fill-opacity: 0.75;
	transition: fill-opacity 50ms;
}

.highcharts-funnel-series .highcharts-point-select {
	fill: inherit;
	stroke: inherit;
}

.highcharts-pyramid-series .highcharts-point {
	stroke-linejoin: round;
	stroke: #fff;
}

.highcharts-pyramid-series .highcharts-point-hover {
	fill-opacity: 0.75;
	transition: fill-opacity 50ms;
}

.highcharts-pyramid-series .highcharts-point-select {
	fill: inherit;
	stroke: inherit;
}

.highcharts-solidgauge-series .highcharts-point {
	stroke-width: 0;
}

.highcharts-treemap-series .highcharts-point {
	stroke-width: 1px;
	stroke: #e6e6e6;
	transition: stroke 250ms, fill 250ms, fill-opacity 250ms;
}

.highcharts-treemap-series .highcharts-point-hover {
	stroke: #999;
	transition: stroke 25ms, fill 25ms, fill-opacity 25ms;
}

.highcharts-treemap-series .highcharts-above-level {
	display: none;
}

.highcharts-treemap-series .highcharts-internal-node {
	fill: none;
}

.highcharts-treemap-series .highcharts-internal-node-interactive {
	fill-opacity: 0.15;
	cursor: pointer;
}

.highcharts-treemap-series .highcharts-internal-node-interactive:hover {
	fill-opacity: 0.75;
}

.highcharts-vector-series .highcharts-point {
	fill: none;
	stroke-width: 2px;
}

.highcharts-windbarb-series .highcharts-point {
	fill: none;
	stroke-width: 2px;
}

.highcharts-legend-box {
	fill: none;
	stroke-width: 0;
}

.highcharts-legend-item > text {
	fill: #333;
	font-weight: 700;
	font-size: 1em;
	cursor: pointer;
	stroke-width: 0;
}

.highcharts-legend-item:hover text {
	fill: #000;
}

.highcharts-legend-item-hidden * {
	fill: #ccc !important;
	stroke: #ccc !important;
	transition: fill 250ms;
}

.highcharts-legend_nav-active {
	fill: #039;
	cursor: pointer;
}

.highcharts-legend_nav-inactive {
	fill: #ccc;
}

circle.highcharts-legend_nav-active,
circle.highcharts-legend_nav-inactive {
	fill: rgba(192, 192, 192, 0.0001);
}

.highcharts-legend-title-box {
	fill: none;
	stroke-width: 0;
}

.highcharts-bubble-legend-symbol {
	stroke-width: 2;
	fill-opacity: 0.5;
}

.highcharts-bubble-legend-connectors {
	stroke-width: 1;
}

.highcharts-bubble-legend-labels {
	fill: #333;
}

.highcharts-loading {
	position: absolute;
	background-color: #fff;
	opacity: 0.5;
	text-align: center;
	z-index: 10;
	transition: opacity 250ms;
}

.highcharts-loading-hidden {
	height: 0 !important;
	opacity: 0;
	overflow: hidden;
	transition: opacity 250ms, height 250ms step-end;
}

.highcharts-loading-inner {
	font-weight: 700;
	position: relative;
	top: 45%;
}

.highcharts-plot-band,
.highcharts-pane {
	fill: #000;
	fill-opacity: 0.05;
}

.highcharts-plot-line {
	fill: none;
	stroke: #999;
	stroke-width: 1px;
}

.highcharts-boxplot-box {
	fill: #fff;
}

.highcharts-boxplot-median {
	stroke-width: 2px;
}

.highcharts-bubble-series .highcharts-point {
	fill-opacity: 0.5;
}

.highcharts-errorbar-series .highcharts-point {
	stroke: #000;
}

.highcharts-gauge-series .highcharts-data-label-box {
	stroke: #ccc;
	stroke-width: 1px;
}

.highcharts-gauge-series .highcharts-dial {
	fill: #000;
	stroke-width: 0;
}

.highcharts-polygon-series .highcharts-graph {
	fill: inherit;
	stroke-width: 0;
}

.highcharts-waterfall-series .highcharts-graph {
	stroke: #333;
	stroke-dasharray: 1, 3;
}

.highcharts-sankey-series .highcharts-point {
	stroke-width: 0;
}

.highcharts-sankey-series .highcharts_link {
	transition: fill 250ms, fill-opacity 250ms;
	fill-opacity: 0.5;
}

.highcharts-sankey-series .highcharts-point-hover.highcharts_link {
	transition: fill 50ms, fill-opacity 50ms;
	fill-opacity: 1;
}

.highcharts-venn-series .highcharts-point {
	fill-opacity: 0.75;
	stroke: #ccc;
	transition: stroke 250ms, fill-opacity 250ms;
}

.highcharts-venn-series .highcharts-point-hover {
	fill-opacity: 1;
	stroke: #ccc;
}

.highcharts_navigator-mask-outside {
	fill-opacity: 0;
}

.highcharts_navigator-mask-inside {
	fill: #6685c2;
	fill-opacity: 0.25;
	cursor: ew-resize;
}

.highcharts_navigator-outline {
	stroke: #ccc;
	fill: none;
}

.highcharts_navigator-handle {
	stroke: #ccc;
	fill: #f2f2f2;
	cursor: ew-resize;
}

.highcharts_navigator-series {
	fill: #335cad;
	stroke: #335cad;
}

.highcharts_navigator-series .highcharts-graph {
	stroke-width: 1px;
}

.highcharts_navigator-series .highcharts-area {
	fill-opacity: 0.05;
}

.highcharts_navigator-xaxis .highcharts-axis-line {
	stroke-width: 0;
}

.highcharts_navigator-xaxis .highcharts-grid-line {
	stroke-width: 1px;
	stroke: #e6e6e6;
}

.highcharts_navigator-xaxis.highcharts-axis-labels {
	fill: #999;
}

.highcharts_navigator-yaxis .highcharts-grid-line {
	stroke-width: 0;
}

.highcharts-scrollbar-thumb {
	fill: #ccc;
	stroke: #ccc;
	stroke-width: 1px;
}

.highcharts-scrollbar-button {
	fill: #e6e6e6;
	stroke: #ccc;
	stroke-width: 1px;
}

.highcharts-scrollbar-arrow {
	fill: #666;
}

.highcharts-scrollbar-rifles {
	stroke: #666;
	stroke-width: 1px;
}

.highcharts-scrollbar-track {
	fill: #f2f2f2;
	stroke: #f2f2f2;
	stroke-width: 1px;
}

.highcharts-button {
	fill: #f7f7f7;
	stroke: #ccc;
	cursor: default;
	stroke-width: 1px;
	transition: fill 250ms;
}

.highcharts-button text {
	fill: #333;
}

.highcharts-button-hover {
	transition: fill 0;
	fill: #e6e6e6;
	stroke: #ccc;
}

.highcharts-button-hover text {
	fill: #333;
}

.highcharts-button-pressed {
	font-weight: 700;
	fill: #e6ebf5;
	stroke: #ccc;
}

.highcharts-button-pressed text {
	fill: #333;
	font-weight: 700;
}

.highcharts-button-disabled text {
	fill: #333;
}

.highcharts-range-selector-buttons .highcharts-button {
	stroke-width: 0;
}

.highcharts-range-label rect {
	fill: none;
}

.highcharts-range-label text {
	fill: #666;
}

.highcharts-range-input rect {
	fill: none;
}

.highcharts-range-input text {
	fill: #333;
}

.highcharts-range-input {
	stroke-width: 1px;
	stroke: #ccc;
}

input.highcharts-range-selector {
	position: absolute;
	border: 0;
	width: 1px;
	height: 1px;
	padding: 0;
	text-align: center;
	left: -9em;
}

.highcharts-crosshair-label text {
	fill: #fff;
	font-size: 1.1em;
}

.highcharts-crosshair-label .highcharts-label-box {
	fill: inherit;
}

.highcharts-candlestick-series .highcharts-point {
	stroke: #000;
	stroke-width: 1px;
}

.highcharts-candlestick-series .highcharts-point-up {
	fill: #fff;
}

.highcharts-ohlc-series .highcharts-point-hover {
	stroke-width: 3px;
}

.highcharts-flags-series .highcharts-point .highcharts-label-box {
	stroke: #999;
	fill: #fff;
	transition: fill 250ms;
}

.highcharts-flags-series .highcharts-point-hover .highcharts-label-box {
	stroke: #000;
	fill: #ccd6eb;
}

.highcharts-flags-series .highcharts-point text {
	fill: #000;
	font-size: 0.9em;
	font-weight: 700;
}

.highcharts-map-series .highcharts-point {
	transition: fill 500ms, fill-opacity 500ms, stroke-width 250ms;
	stroke: #ccc;
}

.highcharts-map-series .highcharts-point-hover {
	transition: fill 0ms, fill-opacity 0;
	fill-opacity: 0.5;
	stroke-width: 2px;
}

.highcharts-mapline-series .highcharts-point {
	fill: none;
}

.highcharts-heatmap-series .highcharts-point {
	stroke-width: 0;
}

.highcharts-map_navigation {
	font-size: 1.3em;
	font-weight: 700;
	text-align: center;
}

.highcharts-coloraxis {
	stroke-width: 0;
}

.highcharts-coloraxis-marker {
	fill: #999;
}

.highcharts-null-point {
	fill: #f7f7f7;
}

.highcharts-3d-frame {
	fill: transparent;
}

.highcharts-contextbutton {
	fill: #fff;
	stroke: none;
	stroke-linecap: round;
}

.highcharts-contextbutton:hover {
	fill: #e6e6e6;
	stroke: #e6e6e6;
}

.highcharts-button-symbol {
	stroke: #666;
	stroke-width: 3px;
}

.highcharts-menu {
	border: 1px solid #999;
	background: #fff;
	padding: 5px 0;
	box-shadow: 3px 3px 10px #888;
}

.highcharts-menu-item {
	padding: 0.5em 1em;
	background: none;
	color: #333;
	cursor: pointer;
	transition: background 250ms, color 250ms;
}

.highcharts-menu-item:hover {
	background: #335cad;
	color: #fff;
}

.highcharts-drilldown-point {
	cursor: pointer;
}

.highcharts-drilldown-data-label text,
text.highcharts-drilldown-data-label,
.highcharts-drilldown-axis-label {
	cursor: pointer;
	fill: #039;
	font-weight: 700;
	text-decoration: underline;
}

.highcharts-no-data text {
	font-weight: 700;
	font-size: 12px;
	fill: #666;
}

.highcharts-axis-resizer {
	cursor: ns-resize;
	stroke: #000;
	stroke-width: 2px;
}

.highcharts-bullet-target {
	stroke-width: 0;
}

.highcharts-lineargauge-target {
	stroke-width: 1px;
	stroke: #333;
}

.highcharts-lineargauge-target-line {
	stroke-width: 1px;
	stroke: #333;
}

.highcharts-annotation-label-box {
	stroke-width: 1px;
	stroke: #000;
	fill: #000;
	fill-opacity: 0.75;
}

.highcharts-annotation-label text {
	fill: #e6e6e6;
}

.highcharts-treegrid-node-collapsed,
.highcharts-treegrid-node-expanded {
	cursor: pointer;
}

.highcharts-point-connecting-path {
	fill: none;
}

.highcharts-grid-axis .highcharts-tick {
	stroke-width: 1px;
}

.highcharts-grid-axis .highcharts-axis-line {
	stroke-width: 1px;
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.symbol-graph {
	position: relative;
	min-height: 10.625rem;
}

.symbol-graph__timestamp {
	font-weight: 400;
	font-size: 0.625rem;
	letter-spacing: 0.17em;
	color: gray;
	margin-top: 1.25rem;
	text-transform: uppercase;
}

.symbol-graph .highcharts-graph {
	stroke-width: 0.5;
	stroke: #3fcc6f;
}

.symbol-graph .highcharts-root {
	font-weight: 300;
	font-size: 0.625rem;
	color: rgba(0, 0, 0, 0.3);
}

.symbol-graph .highcharts-series {
	stroke: transparent;
}

.symbol-graph .highcharts-grid-line {
	stroke: #e1e1e6;
	-webkit-transform: scaleX(1.5);
	transform: scaleX(1.5);
}

.symbol-graph .highcharts-axis-line {
	display: none;
}

.symbol-graph .highcharts-yaxis-labels {
	right: 0;
}

.symbol-graph .highcharts-yaxis-labels span {
	display: block;
	width: 3.125rem;
	left: auto !important;
	right: 0;
	text-align: right;
	padding-bottom: 0.125rem;
}

.symbol-graph--down .highcharts-graph {
	stroke: #fd6e70;
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.content-dashboard {
	margin-top: 3.75rem;
}

.content-dashboard__container::after {
	clear: both;
	content: '';
	display: block;
}

@media only screen and (min-width: 48rem) {
	.content-dashboard__container {
		margin-left: 0.875rem;
		margin-right: 0.875rem;
	}
}

@media only screen and (min-width: 1289px) {
	.content-dashboard__container {
		margin-left: 4.375rem;
		margin-right: 4.375rem;
	}
}

@media (min-width: 108.75rem) {
	.content-dashboard__container {
		width: 100rem;
		max-width: 100rem;
		margin-left: auto;
		margin-right: auto;
	}
}

.content-dashboard__section {
	width: calc(100% - 3.125rem);
	float: left;
	margin-left: 1.5625rem;
	margin-bottom: 3.75rem;
}

@media only screen and (min-width: 48rem) {
	.content-dashboard__section {
		width: calc(100% - 2.5rem);
		float: left;
		margin-left: 1.25rem;
	}
}

.content-dashboard__heading {
	font-weight: 400;
	display: flex;
	align-items: center;
	font-size: 0.75rem;
	line-height: 1.67;
	letter-spacing: 0.17em;
	text-transform: uppercase;
}

@media only screen and (min-width: 48rem) {
	.content-dashboard__heading {
		font-size: 0.875rem;
		line-height: 0.86;
		letter-spacing: 0.18em;
	}
}

.content-dashboard__heading::before {
	margin-right: 0.5rem;
	color: #00bfd5;
}

.content-dashboard__content {
	margin-left: -1.5625rem;
	margin-right: -1.5625rem;
	width: calc(100% + 3.125rem);
	position: relative;
	margin-top: 1.25rem;
}

@media only screen and (min-width: 48rem) {
	.content-dashboard__content {
		margin-left: -1.25rem;
		margin-right: -1.25rem;
		width: calc(100% + 2.5rem);
	}
}

@media only screen and (min-width: 1289px) {
	.content-dashboard__content {
		margin-left: -1.25rem;
		margin-right: -1.25rem;
		width: calc(100% + 2.5rem);
	}
}

.content-dashboard .market-calendar-promos {
	width: 100%;
	margin-left: 0;
}

@media only screen and (min-width: 48rem) {
	.content-dashboard .market-calendar-promos {
		display: flex;
		flex-wrap: wrap;
		align-items: stretch;
	}
}

@media only screen and (min-width: 1289px) {
	.content-dashboard .market-calendar-promos {
		width: calc(62.5% - 2.03125rem);
		float: left;
		margin-left: 1.25rem;
		justify-content: space-between;
	}
}

.content-dashboard .market-calendar-promos .module-header {
	font-weight: 400;
	width: calc(100% - 3.125rem);
	float: left;
	margin-left: 1.5625rem;
	font-size: 0.75rem;
	line-height: 1.67;
	letter-spacing: 0.17em;
	text-transform: uppercase;
}

@media only screen and (min-width: 48rem) {
	.content-dashboard .market-calendar-promos .module-header {
		width: calc(100% - 2.5rem);
		float: left;
		margin-left: 1.25rem;
		font-size: 0.875rem;
		line-height: 0.86;
		letter-spacing: 0.18em;
	}
}

@media only screen and (min-width: 1289px) {
	.content-dashboard .market-calendar-promos .module-header {
		width: 100%;
		margin-left: 0;
	}
}

.content-dashboard .market-calendar-promos .module-header::before {
	display: none;
}

.content-dashboard .market-calendar-promos .module-header__icon {
	color: #00bfd5;
}

.content-dashboard .market-calendar-promos .calendar-promo {
	width: calc(100% - 3.125rem);
	float: left;
	margin-left: 1.5625rem;
	min-height: 17.5rem;
	padding-bottom: 3.75rem;
}

@media only screen and (min-width: 48rem) {
	.content-dashboard .market-calendar-promos .calendar-promo {
		width: calc(50% - 1.875rem);
		float: left;
		margin-left: 1.25rem;
		min-height: 18.75rem;
		padding-bottom: 5rem;
	}
}

@media only screen and (min-width: 1289px) {
	.content-dashboard .market-calendar-promos .calendar-promo {
		width: calc((100% - 2.5rem) / 3);
		margin-left: 0;
		min-height: 12.625rem;
		padding-bottom: 1.25rem;
	}
}

@media only screen and (min-width: 1289px) {
	.content-dashboard-top-reads {
		width: calc(62.5% - 2.03125rem);
		float: left;
		margin-left: 1.25rem;
	}
}

.content-dashboard-top-reads .content-dashboard__heading-icon {
	margin-right: 0.5rem;
	color: #00bfd5;
}

.content-dashboard-top-reads .content-dashboard__heading-icon::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤“';
}

@media only screen and (min-width: 48rem) {
	.content-dashboard-top-reads .content-dashboard__content {
		display: flex;
		flex-flow: row wrap;
		align-items: stretch;
	}
}

.content-dashboard-top-reads__cards {
	display: flex;
	flex-flow: row wrap;
}

.content-dashboard-top-reads__card {
	width: calc(100% - 3.125rem);
	float: left;
	margin-left: 1.5625rem;
	display: block;
	flex-shrink: 0;
}

@media only screen and (min-width: 48rem) {
	.content-dashboard-top-reads__card {
		width: calc(50% - 1.875rem);
		float: left;
		margin-left: 1.25rem;
		flex-shrink: 0;
	}
}

@media only screen and (min-width: 1289px) {
	.content-dashboard-top-reads__card {
		width: calc(33.33333% - 1.66667rem);
		float: left;
		margin-left: 1.25rem;
	}
}

.content-dashboard-top-reads__card:hover {
	text-decoration: none;
}

.content-dashboard-top-reads__card:nth-child(n + 2) {
	margin-top: 1.25rem;
}

@media only screen and (min-width: 48rem) {
	.content-dashboard-top-reads__card:nth-child(2) {
		margin-top: 0;
	}
}

@media only screen and (min-width: 1289px) {
	.content-dashboard-top-reads__card:nth-child(3) {
		margin-top: 0;
	}
}

.content-dashboard-top-reads__image img {
	display: block;
	width: 100%;
	height: auto;
}

.content-dashboard-top-reads__text {
	margin-top: 0.625rem;
}

.content-dashboard-top-reads__title {
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 2;
	letter-spacing: 0.07em;
}

.content-dashboard-top-reads__date {
	font-weight: 500;
	margin-top: 0.3125rem;
	font-size: 0.625rem;
	line-height: 1.2;
	letter-spacing: 0.17em;
	text-transform: uppercase;
	color: gray;
}

@media only screen and (min-width: 1289px) {
	.content-dashboard-news {
		width: calc(29.16667% - 1.61458rem);
		float: left;
		margin-left: 1.25rem;
		margin-left: calc(8.33333% - 1.35417rem + 2.5rem);
	}
}

.content-dashboard-news .content-dashboard__heading-icon {
	margin-right: 0.5rem;
	color: #00bfd5;
}

.content-dashboard-news .content-dashboard__heading-icon::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤”';
}

.content-dashboard-news__list::after {
	display: block;
	content: '';
	clear: both;
}

.content-dashboard-news__list-container {
	min-height: 9.375rem;
	position: relative;
}

.content-dashboard-news__card {
	width: calc(100% - 3.125rem);
	float: left;
	margin-left: 1.5625rem;
}

@media only screen and (min-width: 48rem) {
	.content-dashboard-news__card {
		width: calc(100% - 2.5rem);
		float: left;
		margin-left: 1.25rem;
	}
}

.content-dashboard-news__card:hover {
	text-decoration: none;
}

.content-dashboard-news__card:not(:first-child) {
	margin-top: 1.25rem;
}

@media only screen and (min-width: 48rem) {
	.content-dashboard-news__card:not(:first-child) {
		margin-top: 1.875rem;
	}
}

@media only screen and (min-width: 1289px) {
	.content-dashboard-news__card:not(:first-child) {
		margin-top: 1.25rem;
	}
}

.content-dashboard-news__title {
	font-weight: 400;
	font-size: 0.75rem;
	line-height: 1.67;
	letter-spacing: 0.04em;
}

@media only screen and (min-width: 48rem) {
	.content-dashboard-news__title {
		font-size: 0.875rem;
	}
}

@media only screen and (min-width: 1289px) {
	.content-dashboard-news__title {
		font-size: 0.8125rem;
	}
}

.content-dashboard-news__date {
	font-weight: 500;
	margin-top: 0.3125rem;
	font-size: 0.6875rem;
	line-height: 1.2;
	letter-spacing: 0.17em;
	text-transform: uppercase;
	color: gray;
}

.content-dashboard-news .arrow-cta {
	display: none;
}

@media only screen and (min-width: 1289px) {
	.content-dashboard-news .arrow-cta {
		display: flex;
		margin-left: 1.25rem;
		margin-top: 1.25rem;
		justify-content: flex-start;
	}
}

@media only screen and (min-width: 1289px) {
	.content-dashboard-top-movers {
		width: calc(29.16667% - 1.61458rem);
		float: left;
		margin-left: 1.25rem;
		float: right;
		margin-right: 1.25rem;
	}
}

.content-dashboard-top-movers .content-dashboard__heading-icon {
	margin-right: 0.5rem;
	color: #00bfd5;
}

.content-dashboard-top-movers .content-dashboard__heading-icon::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤ˆ';
}

.content-dashboard-top-movers .content-dashboard__heading {
	display: inline;
}

.content-dashboard-top-movers__timestamp {
	width: calc(100% - 3.125rem);
	float: left;
	margin-left: 1.5625rem;
	font-weight: 500;
	float: none;
	font-size: 0.625rem;
	line-height: 1.2;
	letter-spacing: 0.17em;
	text-transform: uppercase;
	color: gray;
}

@media only screen and (min-width: 48rem) {
	.content-dashboard-top-movers__timestamp {
		width: calc(100% - 2.5rem);
		float: left;
		margin-left: 1.25rem;
		float: none;
	}
}

.content-dashboard-top-movers__symbols {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	margin-top: 1.375rem;
}

@media only screen and (min-width: 48rem) {
	.content-dashboard-top-movers__symbols {
		margin: 1.25rem 0 0;
		justify-content: flex-start;
	}
}

.content-dashboard-top-movers__symbol-template {
	display: none;
}

.content-dashboard-top-movers__loader-template {
	display: none;
}

.content-dashboard-top-movers .symbol-ticker {
	width: calc(40% - 2.1875rem);
	float: left;
	margin-left: 1.5625rem;
	margin-top: 1.25rem;
}

@media only screen and (min-width: 48rem) {
	.content-dashboard-top-movers .symbol-ticker {
		width: calc(25% - 1.5625rem);
		float: left;
		margin-left: 1.25rem;
		margin-top: 1.875rem;
	}
}

@media only screen and (min-width: 1289px) {
	.content-dashboard-top-movers .symbol-ticker {
		width: calc(42.85714% - 1.78571rem);
		float: left;
		margin-left: 1.25rem;
		margin-top: 0.9375rem;
	}
}

.content-dashboard-top-movers .symbol-ticker:hover,
.content-dashboard-top-movers .symbol-ticker:focus {
	text-decoration: none;
}

.content-dashboard-top-movers .symbol-ticker:nth-child(even) {
	margin-left: auto;
	margin-right: 3.125rem;
}

@media only screen and (min-width: 48rem) {
	.content-dashboard-top-movers .symbol-ticker:nth-child(even) {
		margin-left: 1.25rem;
		margin-right: 0;
	}
}

.content-dashboard-top-movers .symbol-ticker:first-child,
.content-dashboard-top-movers .symbol-ticker:nth-child(2) {
	margin-top: 0;
}

@media only screen and (min-width: 48rem) {
	.content-dashboard-top-movers .symbol-ticker:nth-child(3),
	.content-dashboard-top-movers .symbol-ticker:nth-child(4) {
		margin-top: 0;
	}
}

@media only screen and (min-width: 1289px) {
	.content-dashboard-top-movers .symbol-ticker:nth-child(3),
	.content-dashboard-top-movers .symbol-ticker:nth-child(4) {
		margin-top: 0.9375rem;
	}
}

.content-dashboard-top-movers .symbol-ticker__change {
	white-space: nowrap;
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.market-calendar-promos {
	width: 100%;
	display: block;
	overflow: hidden;
	margin-left: 1.25rem;
}

.market-calendar-promos .calendar-promo__events-container {
	position: relative;
	min-height: 6.25rem;
}

.market-calendar-promos .calendar-promo__events-container .loader-overlay::after {
	background: none;
}

.market-calendar-promos__container {
	display: flex;
	flex-wrap: wrap;
}

.market-calendar-promos__container .calendar-promo {
	width: calc(100% - 3.125rem);
	float: left;
	margin-left: 1.5625rem;
}

@media only screen and (min-width: 48rem) {
	.market-calendar-promos__container .calendar-promo {
		width: calc(50% - 1.875rem);
		float: left;
		margin-left: 1.25rem;
	}
}

@media only screen and (min-width: 1289px) {
	.market-calendar-promos__container .calendar-promo {
		width: calc(25% - 1.5625rem);
		float: left;
		margin-left: 1.25rem;
		padding-bottom: 1.25rem;
	}
}

@media only screen and (min-width: 1289px) {
	.market-calendar-promos__container .calendar-promo__event {
		padding-right: 0.625rem;
	}
}

.market-calendar-promos__container .calendar-promo__info {
	font-weight: 500;
	font-size: 0.875rem;
	line-height: 2;
	letter-spacing: 1px;
	margin-top: 1.25rem;
	margin-bottom: 0.625rem;
}

.market-calendar-promos__container .module-header {
	font-size: 1.5rem;
}

.layout--2-col-large .market-calendar-promos {
	width: 100%;
	margin-left: 0;
}

@media only screen and (min-width: 48rem) {
	.layout--2-col-large .market-calendar-promos {
		margin-left: -1.25rem;
		margin-right: -1.25rem;
		width: calc(100% + 2.5rem);
		display: flex;
		flex-wrap: wrap;
	}
}

@media only screen and (min-width: 48rem) {
	.layout--2-col-large .market-calendar-promos .module-header {
		width: calc(100% - 2.5rem);
		float: left;
		margin-left: 1.25rem;
	}
}

@media only screen and (min-width: 48rem) {
	.layout--2-col-large .market-calendar-promos .calendar-promo {
		width: calc(50% - 1.875rem);
		float: left;
		margin-left: 1.25rem;
	}
}

@media only screen and (min-width: 1289px) {
	.layout--2-col-large .market-calendar-promos .calendar-promo {
		width: calc(33.33333% - 1.66667rem);
		float: left;
		margin-left: 1.25rem;
	}
}

.layout--full .market-calendar-promos {
	width: 100%;
	margin-left: 0;
}

@media only screen and (min-width: 48rem) {
	.layout--full .market-calendar-promos {
		margin-left: -1.25rem;
		margin-right: -1.25rem;
		width: calc(100% + 2.5rem);
		display: flex;
		flex-wrap: wrap;
	}
}

@media only screen and (min-width: 48rem) {
	.layout--full .market-calendar-promos .module-header {
		width: calc(100% - 2.5rem);
		float: left;
		margin-left: 1.25rem;
	}
}

@media only screen and (min-width: 48rem) {
	.layout--full .market-calendar-promos .calendar-promo {
		width: calc(50% - 1.875rem);
		float: left;
		margin-left: 1.25rem;
	}
}

@media only screen and (min-width: 1289px) {
	.layout--full .market-calendar-promos .calendar-promo {
		width: calc(25% - 1.5625rem);
		float: left;
		margin-left: 1.25rem;
	}
}

.market-calendar-promos .cta_links-div {
	display: block;
	width: 100%;
}

@media only screen and (min-width: 1289px) {
	.market-calendar-promos .cta_links-div .arrow-cta {
		flex-grow: 1;
		justify-content: flex-start;
		margin-left: 1.875rem;
	}
}

.calendar-promo::before {
	background-blend-mode: normal, overlay, overlay, normal, normal, normal, normal;
	background-image: linear-gradient(301deg, rgba(44, 34, 150, 0), #7b2297),
		linear-gradient(rgba(0, 82, 154, 0.95), rgba(0, 82, 154, 0.95)), linear-gradient(121deg, #3fcc6f, #3fcc6f),
		linear-gradient(238deg, rgba(173, 32, 142, 0), #ad208e),
		linear-gradient(42deg, rgba(0, 190, 213, 0), rgba(0, 190, 213, 0.85) 85%, rgba(0, 190, 213, 0.87) 87%, #00bed5),
		linear-gradient(126deg, rgba(0, 190, 213, 0), #00bed5), linear-gradient(#594099, #594099);
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.calendar-promo {
	position: relative;
	margin-bottom: 1.25rem;
	padding: 1.25rem 0 4.625rem;
	flex-shrink: 0;
}

@media only screen and (min-width: 48rem) {
	.calendar-promo {
		padding: 1.25rem 0 5.875rem;
	}
}

.calendar-promo::before {
	content: '';
	position: absolute;
	top: 0;
	left: 1.25rem;
	right: 0;
	bottom: 0;
	opacity: 0.05;
	z-index: 1;
}

@media only screen and (min-width: 48rem) {
	.calendar-promo::before {
		left: 0.625rem;
	}
}

.calendar-promo > * {
	position: relative;
	z-index: 2;
}

.calendar-promo .arrow-cta {
	font-weight: 300;
	text-transform: none;
	font-size: 1.5rem;
	line-height: 1.5;
	letter-spacing: 0.04em;
	justify-content: flex-start;
}

.calendar-promo .arrow-cta__text {
	font-size: inherit;
}

.calendar-promo__count {
	font-weight: 500;
	margin-top: 0.625rem;
	font-size: 0.625rem;
	line-height: 1.2;
	letter-spacing: 0.17em;
	text-transform: uppercase;
	color: gray;
}

.calendar-promo__info {
	font-weight: 300;
	font-size: 0.875rem;
	line-height: 2;
	letter-spacing: 0.07em;
	margin-top: 1.25rem;
}

.calendar-promo__events {
	margin-top: 1.25rem;
}

.calendar-promo__event {
	font-weight: 500;
	display: flex;
	justify-content: space-between;
	font-size: 0.75rem;
	line-height: 1.75;
	margin-bottom: 1.0625rem;
	letter-spacing: 0.05em;
	padding-right: 1.5625rem;
}

.calendar-promo__event-name {
	color: #00bfd5;
	margin-right: 0.625rem;
}

.calendar-promo__event-info {
	flex-shrink: 0;
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.picture {
	display: block;
}

.picture img {
	width: 100%;
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.homepage-markets-sidebar {
	position: relative;
}

.homepage-markets-sidebar::after {
	clear: both;
	content: '';
	display: block;
}

@media only screen and (min-width: 48rem) {
	.homepage-markets-sidebar {
		margin-left: 0.875rem;
		margin-right: 0.875rem;
	}
}

@media only screen and (min-width: 1289px) {
	.homepage-markets-sidebar {
		margin-left: 4.375rem;
		margin-right: 4.375rem;
	}
}

@media (min-width: 108.75rem) {
	.homepage-markets-sidebar {
		width: 100rem;
		max-width: 100rem;
		margin-left: auto;
		margin-right: auto;
	}
}

@media only screen and (min-width: 1289px) {
	.homepage-markets-sidebar {
		position: absolute;
		top: 7.5rem;
		right: 0;
		width: 20rem;
		margin: 0;
		padding: 1.25rem 2.5rem 1.25rem 1.25rem;
		background-color: #fff;
		z-index: 10;
	}
}

.homepage-markets-sidebar__container {
	width: calc(100% - 3.125rem);
	float: left;
	margin-left: 1.5625rem;
}

@media only screen and (min-width: 48rem) {
	.homepage-markets-sidebar__container {
		width: calc(100% - 2.5rem);
		float: left;
		margin-left: 1.25rem;
	}
}

@media only screen and (min-width: 1289px) {
	.homepage-markets-sidebar__container {
		width: 100%;
		margin: 0;
		float: none;
	}
}

.homepage-markets-sidebar__symbol-lookup {
	margin-bottom: 1.25rem;
}

.homepage-markets-sidebar__symbol-lookup__input-container {
	position: relative;
}

.homepage-markets-sidebar__symbol-lookup__input-container::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤ƒ';
	position: absolute;
	right: 0.625rem;
	bottom: 0;
	top: 0;
	color: #0090ba;
	font-size: 1.4375rem;
	line-height: 2.5rem;
}

.homepage-markets-sidebar__symbol-lookup__input {
	font-weight: 400;
	width: 100%;
	height: 2.5rem;
	padding: 0 2.5rem 0 0.625rem;
	border: none;
	font-size: 0.875rem;
	letter-spacing: 0.04em;
	background-color: #e1e1e6;
}

.homepage-markets-sidebar__symbol-lookup__label {
	position: absolute;
	left: -625rem;
	top: auto;
	width: 0.0625rem;
	height: 0.0625rem;
	overflow: hidden;
}

.homepage-markets-sidebar .find-symbol {
	display: block;
	margin-bottom: 1.25rem;
}

.homepage-markets-sidebar .find-symbol__container {
	position: relative;
	width: 100%;
}

.homepage-markets-sidebar .find-symbol__container::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤ƒ';
	position: absolute;
	right: 0.625rem;
	bottom: 0;
	top: 0;
	color: #0090ba;
	font-size: 1.4375rem;
	line-height: 2.5rem;
}

.homepage-markets-sidebar .find-symbol__label {
	height: 0.0625rem;
	width: 0.0625rem;
	opacity: 0;
}

.homepage-markets-sidebar .find-symbol .autocomplete__input {
	font-weight: 400;
	width: 100%;
	height: 2.5rem;
	padding: 0 2.5rem 0 0.625rem;
	border: none;
	font-size: 0.875rem;
	letter-spacing: 0.04em;
	background-color: #e1e1e6;
	text-transform: none;
	color: #000;
}

.homepage-markets-sidebar .find-symbol .autocomplete__input::-webkit-input-placeholder {
	color: rgba(0, 0, 0, 0.5);
}

.homepage-markets-sidebar .find-symbol .autocomplete__input::-moz-placeholder {
	color: rgba(0, 0, 0, 0.5);
}

.homepage-markets-sidebar .find-symbol .autocomplete__input::-ms-input-placeholder {
	color: rgba(0, 0, 0, 0.5);
}

.homepage-markets-sidebar .find-symbol .autocomplete__input::placeholder {
	color: rgba(0, 0, 0, 0.5);
}

.homepage-markets-sidebar .find-symbol .autocomplete__option {
	font-size: 0.875rem;
}

.homepage-markets-sidebar .find-symbol__submit {
	position: absolute;
	right: 0.625rem;
	bottom: 0;
	top: 0;
	-webkit-transform: none;
	transform: none;
}

.homepage-markets-sidebar .find-symbol__submit::before {
	color: #0090ba;
	font-size: 1.4375rem;
	line-height: 2.5rem;
}

.homepage-markets-sidebar__timestamp {
	font-weight: 400;
	font-size: 0.6875rem;
	letter-spacing: 0.17em;
	color: gray;
	margin-top: 0.9375rem;
	text-transform: uppercase;
	margin-bottom: 0.9375rem;
}

.homepage-markets-sidebar__primary-symbol .symbol-ticker {
	position: relative;
	display: block;
	height: auto;
	overflow: hidden;
	margin-bottom: 0.625rem;
}

@media only screen and (min-width: 48rem) {
	.homepage-markets-sidebar__primary-symbol .symbol-ticker {
		font-size: 1.125rem;
		margin-bottom: 0.9375rem;
	}
}

@media only screen and (min-width: 1289px) {
	.homepage-markets-sidebar__primary-symbol .symbol-ticker {
		font-size: 0.8125rem;
		margin-bottom: 0.625rem;
	}
}

.homepage-markets-sidebar__primary-symbol .symbol-ticker .symbol-ticker__timestamp {
	display: block;
}

.homepage-markets-sidebar__primary-symbol .symbol-ticker__symbol {
	display: block;
}

.homepage-markets-sidebar__primary-symbol .symbol-ticker__value,
.homepage-markets-sidebar__primary-symbol .symbol-ticker__change {
	display: inline-block;
	margin-top: 0.625rem;
}

.homepage-markets-sidebar__primary-symbol .symbol-graph {
	min-height: 0;
	margin-bottom: 1.25rem;
}

@media only screen and (min-width: 48rem) {
	.homepage-markets-sidebar__primary-symbol .symbol-graph {
		margin-bottom: 1.875rem;
	}
}

@media only screen and (min-width: 1289px) {
	.homepage-markets-sidebar__primary-symbol .symbol-graph {
		margin-bottom: 1.25rem;
	}
}

.homepage-markets-sidebar__primary-symbol .symbol-graph__content {
	width: 100%;
	height: 6.875rem;
}

@media only screen and (min-width: 48rem) {
	.homepage-markets-sidebar__primary-symbol .symbol-graph__content {
		height: 8.125rem;
	}
}

@media only screen and (min-width: 1289px) {
	.homepage-markets-sidebar__primary-symbol .symbol-graph__content {
		height: 6.875rem;
	}
}

.homepage-markets-sidebar__secondary-symbols {
	display: flex;
	flex-flow: row wrap;
	margin-bottom: 1.875rem;
}

@media only screen and (min-width: 1289px) {
	.homepage-markets-sidebar__secondary-symbols {
		flex-wrap: nowrap;
	}

	.homepage-markets-sidebar__secondary-symbols .symbol-ticker {
		font-size: 0.8125rem;
	}
}

.homepage-markets-sidebar__secondary-symbols .symbol-ticker {
	margin-left: 1.25rem;
}

.homepage-markets-sidebar__secondary-symbols .symbol-ticker:first-child {
	margin-left: 0;
}

.homepage-markets-sidebar__secondary-symbols .symbol-ticker__change {
	white-space: nowrap;
}

@media only screen and (min-width: 1289px) {
	.homepage-markets-sidebar__secondary-symbols .symbol-ticker__symbol {
		min-height: 1.875rem;
	}
}

@media only screen and (min-width: 48rem) {
	.homepage-markets-sidebar .arrow-cta {
		justify-content: flex-start;
	}
}

@media only screen and (min-width: 1289px) {
	.homepage-markets-sidebar .arrow-cta {
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	.homepage-markets-sidebar .arrow-cta__icon {
		margin-top: 0.625rem;
	}
}

.find-symbol__container::before {
	background-blend-mode: normal, overlay, overlay, normal, normal, normal, normal;
	background-image: linear-gradient(301deg, rgba(44, 34, 150, 0), #7b2297),
		linear-gradient(rgba(0, 82, 154, 0.95), rgba(0, 82, 154, 0.95)), linear-gradient(121deg, #3fcc6f, #3fcc6f),
		linear-gradient(238deg, rgba(173, 32, 142, 0), #ad208e),
		linear-gradient(42deg, rgba(0, 190, 213, 0), rgba(0, 190, 213, 0.85) 85%, rgba(0, 190, 213, 0.87) 87%, #00bed5),
		linear-gradient(126deg, rgba(0, 190, 213, 0), #00bed5), linear-gradient(#594099, #594099);
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.main-expanded {
	z-index: 1000 !important;
}

.find-symbol {
	margin-left: -1.5625rem;
	margin-right: -1.5625rem;
	width: calc(100% + 3.125rem);
	float: none;
	display: none;
	margin-top: -3.125rem;
	position: relative;
	z-index: 99;
}

@media only screen and (min-width: 48rem) {
	.find-symbol {
		margin-left: -1.25rem;
		margin-right: -1.25rem;
		width: calc(100% + 2.5rem);
		float: none;
		margin-top: -3.0625rem;
	}
}

@media only screen and (min-width: 1289px) {
	.find-symbol {
		display: block;
		width: auto;
		margin-left: 0;
		margin-right: 0;
	}
}

.find-symbol__container {
	position: relative;
	padding: 0 2.1875rem 1.25rem;
}

@media only screen and (min-width: 48rem) {
	.find-symbol__container {
		width: calc(83.33333% - 2.29167rem);
		float: left;
		margin-left: 1.25rem;
		margin-left: calc(8.33333% - 1.35417rem + 2.5rem);
		padding: 0 0 1.25rem;
		float: none;
		display: flex;
		align-items: center;
	}
}

.find-symbol__container::before {
	content: '';
	display: none;
	position: absolute;
	top: 0.75rem;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
}

@media only screen and (min-width: 48rem) {
	.find-symbol__container::before {
		top: 2.625rem;
		left: -1.25rem;
		right: -1.25rem;
	}
}

.find-symbol__form {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	position: relative;
	padding: 0.625rem 0;
	z-index: 5;
}

@media only screen and (min-width: 1289px) {
	.find-symbol__form {
		width: calc(50% - 1.875rem);
		float: left;
		margin-left: 1.25rem;
		margin-left: calc(4.16667% - 1.30208rem + 2.5rem);
		flex-grow: 1;
	}
}

@media only screen and (min-width: 1289px) {
	.find-symbol__form-block {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		width: 100%;
	}
}

.find-symbol__label {
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 0.86;
	letter-spacing: 0.18em;
	color: #fff;
	text-transform: uppercase;
}

.find-symbol__screener_link {
	font-weight: 400;
	display: none;
	font-size: 0.875rem;
	line-height: 0.86;
	letter-spacing: 0.18em;
	color: #0090ba;
	text-transform: uppercase;
}

@media only screen and (min-width: 1289px) {
	.find-symbol__screener_link {
		display: inline;
		font-size: 0.875rem;
		position: absolute;
		top: 0;
		right: 0;
	}
}

.find-symbol__input-block {
	width: 100%;
	position: relative;
}

.find-symbol__input {
	font-weight: 400;
	font-size: 0.875rem;
	letter-spacing: 0.14em;
	width: 100%;
	border: 0.125rem solid #0090ba;
	padding: 1rem 1.25rem;
	text-transform: uppercase;
	color: #0090ba;
}

.find-symbol__input::-webkit-input-placeholder {
	color: #0090ba;
}

.find-symbol__input::-moz-placeholder {
	color: #0090ba;
}

.find-symbol__input::-ms-input-placeholder {
	color: #0090ba;
}

.find-symbol__input::placeholder {
	color: #0090ba;
}

.find-symbol__input-label {
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 0.86;
	letter-spacing: 2.5px;
	color: #fff;
	text-transform: uppercase;
	display: inline-block;
	margin-bottom: 0.625rem;
}

.find-symbol__input-label-light,
.find-symbol__input-label-minimal {
	width: 0.0625rem;
	height: 0.0625rem;
	float: left;
	opacity: 0;
}

.find-symbol__submit {
	font-size: 1.4375rem;
	position: absolute;
	top: 50%;
	right: 1.25rem;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	color: #0090ba;
}

.find-symbol__submit::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤ƒ';
}

.find-symbol__clear {
	display: none;
	font-size: 1.4375rem;
	position: absolute;
	top: 0.9375rem;
	right: 1.25rem;
	color: #0090ba;
}

.find-symbol__clear::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤ƒ';
}

.find-symbol__suggestions {
	font-weight: 400;
	visibility: hidden;
	width: 100%;
	position: absolute;
	top: calc(100% + 0.625rem);
	padding: 0.9375rem;
	background-color: #fff;
	box-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.3);
	z-index: 6;
}

.find-symbol__suggestions a {
	display: block;
	padding: 0.9375rem 0;
}

.find-symbol__suggestions a:focus {
	box-shadow: 0 0 0.375rem 0.125rem #0090ba;
	outline: 0;
}

.find-symbol__ads {
	display: flex;
	flex-flow: row nowrap;
	flex-shrink: 0;
}

@media only screen and (min-width: 1289px) {
	.find-symbol__ads:last-child {
		margin-left: calc(4.16667% - 1.30208rem + 2.5rem);
	}
}

.find-symbol__ad-slot:last-child {
	margin-left: 0.625rem;
}

.find-symbol__ad-slot img {
	display: block;
}

.find-symbol--dark {
	z-index: 2;
}

.find-symbol--dark .find-symbol__form {
	padding: 1.25rem;
	background-color: #2b2b2b;
	width: 100%;
}

@media only screen and (min-width: 1289px) {
	.find-symbol--dark .find-symbol__form {
		padding: 1.875rem 0 1.875rem 1.25rem;
		margin-left: 0;
	}
}

@media only screen and (min-width: 1289px) {
	.find-symbol--dark .find-symbol__container {
		width: 100%;
		margin-left: 0;
		background-color: #2b2b2b;
		padding-bottom: 0;
	}
}

.find-symbol--dark .find-symbol__form-block {
	flex-shrink: 1;
	width: 100%;
	margin-left: 0;
}

.find-symbol--dark .find-symbol__input {
	font-weight: 300;
	font-size: 1.125rem;
	line-height: 1.78;
	letter-spacing: 0.04em;
	padding: 0.25rem 0.625rem;
	background-color: #404041;
	border: none;
	color: #fff;
	text-transform: none;
	opacity: 0.5;
}

.find-symbol--dark .find-symbol__input::-webkit-input-placeholder {
	color: #fff;
	opacity: 0.5;
}

.find-symbol--dark .find-symbol__input::-moz-placeholder {
	color: #fff;
	opacity: 0.5;
}

.find-symbol--dark .find-symbol__input::-ms-input-placeholder {
	color: #fff;
	opacity: 0.5;
}

.find-symbol--dark .find-symbol__input::placeholder {
	color: #fff;
	opacity: 0.5;
}

.find-symbol--dark .find-symbol__submit {
	right: 0.625rem;
	top: auto;
	bottom: 0.3125rem;
	-webkit-transform: none;
	transform: none;
	color: #fff;
}

.find-symbol--dark .find-symbol__ads:first-child {
	margin-right: 4.375rem;
}

.find-symbol--dark .find-symbol__ads:last-child {
	margin-left: 1.875rem;
	margin-right: 1.875rem;
}

.find-symbol--filled.find-symbol--allow-reset .find-symbol__submit {
	display: none;
}

.find-symbol--filled.find-symbol--allow-reset .find-symbol__clear {
	display: block;
	font-size: 0.875rem;
	line-height: 1;
	letter-spacing: 0.14em;
}

.find-symbol--filled.find-symbol--allow-reset .find-symbol__clear::before {
	font-weight: 400;
	content: attr(data-clear-text);
	text-transform: uppercase;
}

.find-symbol--suggestions .find-symbol__suggestions {
	visibility: visible;
}

.find-symbol--minimal {
	margin-top: 0;
}

.find-symbol--minimal .find-symbol__container {
	margin: 0;
}

@media only screen and (min-width: 1289px) {
	.find-symbol--minimal .find-symbol__container {
		padding-bottom: 0;
	}
}

.find-symbol--minimal .find-symbol__form {
	width: 100%;
	margin: 0;
	padding: 0;
}

.find-symbol--minimal .find-symbol__form-block {
	margin: 0;
	width: 100%;
}

.find-symbol__ads_left {
	display: none;
}

.market-activity-landing .find-symbol__ads_left {
	display: inline-flex !important;
	flex-flow: row nowrap;
	flex-shrink: 0;
}

.market-activity-landing .find-symbol__ads_left .find-symbol__ad-slot {
	margin-left: 0.625rem;
}

.secondary-well__box::before {
	background-blend-mode: normal, overlay, overlay, normal, normal, normal, normal;
	background-image: linear-gradient(301deg, rgba(44, 34, 150, 0), #7b2297),
		linear-gradient(rgba(0, 82, 154, 0.95), rgba(0, 82, 154, 0.95)), linear-gradient(121deg, #3fcc6f, #3fcc6f),
		linear-gradient(238deg, rgba(173, 32, 142, 0), #ad208e),
		linear-gradient(42deg, rgba(0, 190, 213, 0), rgba(0, 190, 213, 0.85) 85%, rgba(0, 190, 213, 0.87) 87%, #00bed5),
		linear-gradient(126deg, rgba(0, 190, 213, 0), #00bed5), linear-gradient(#594099, #594099);
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.secondary-well {
	position: relative;
	margin-top: -3.125rem;
	margin-bottom: 1.875rem;
	z-index: 1;
}

@media only screen and (min-width: 48rem) {
	.secondary-well {
		margin-bottom: 2.5rem;
		margin-top: -3.75rem;
	}
}

@media only screen and (min-width: 1289px) {
	.secondary-well {
		margin-top: -4.0625rem;
	}
}

.secondary-well__container {
	padding-bottom: 0.625rem;
}

.secondary-well__container::after {
	clear: both;
	content: '';
	display: block;
}

@media only screen and (min-width: 48rem) {
	.secondary-well__container {
		margin-left: 0.875rem;
		margin-right: 0.875rem;
	}
}

@media only screen and (min-width: 1289px) {
	.secondary-well__container {
		margin-left: 4.375rem;
		margin-right: 4.375rem;
	}
}

@media (min-width: 108.75rem) {
	.secondary-well__container {
		width: 100rem;
		max-width: 100rem;
		margin-left: auto;
		margin-right: auto;
	}
}

.secondary-well__box {
	margin-left: 0.625rem;
	margin-right: 0.625rem;
	position: relative;
	background-color: #fff;
}

@media only screen and (min-width: 48rem) {
	.secondary-well__box {
		width: calc(100% - 2.5rem);
		float: left;
		margin-left: 1.25rem;
		margin-right: 0;
	}
}

.secondary-well__box::before {
	content: '';
	position: absolute;
	top: 3.125rem;
	left: -0.625rem;
	right: -0.625rem;
	bottom: -0.625rem;
	z-index: -1;
}

@media only screen and (min-width: 48rem) {
	.secondary-well__box::before {
		top: 3.75rem;
	}
}

@media only screen and (min-width: 1289px) {
	.secondary-well__box::before {
		top: 4.0625rem;
	}
}

.secondary-well__content {
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	padding: 0.9375rem 0.9375rem 1.25rem;
}

@media only screen and (min-width: 48rem) {
	.secondary-well__content {
		padding: 1.25rem 1.25rem 2.375rem;
	}
}

@media only screen and (min-width: 1289px) {
	.secondary-well__content {
		padding: 1.25rem 1.25rem 1.375rem;
	}
}

@media only screen and (min-width: 1289px) {
	.secondary-well__body {
		width: 100%;
	}
}

.secondary-well__thumbnail {
	width: calc(40% - 2.1875rem);
	float: left;
	margin-left: 1.5625rem;
	flex-shrink: 0;
	position: relative;
}

@media only screen and (min-width: 48rem) {
	.secondary-well__thumbnail {
		width: calc(33.33333% - 1.66667rem);
		float: left;
		margin-left: 1.25rem;
		margin-left: 2.5rem;
	}
}

@media only screen and (min-width: 1289px) {
	.secondary-well__thumbnail {
		width: calc(25% - 1.5625rem);
		float: left;
		margin-left: 1.25rem;
		margin-left: 2.5rem;
	}
}

.secondary-well__thumbnail picture::after,
.secondary-well__thumbnail .video-js::after {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: linear-gradient(to top, rgba(0, 0, 0, 0), #000);
}

@media only screen and (min-width: 1289px) {
	.secondary-well__thumbnail picture::after,
	.secondary-well__thumbnail .video-js::after {
		height: 50%;
	}
}

.secondary-well__thumbnail video {
	opacity: 0;
}

.secondary-well__thumbnail img {
	display: block;
	width: 100%;
}

.secondary-well__replay,
.secondary-well__play {
	color: #fff;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	font-size: 0;
	z-index: 1;
}

.secondary-well__replay::before,
.secondary-well__play::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤˜';
}

@media only screen and (min-width: 48rem) {
	.secondary-well__replay,
	.secondary-well__play {
		font-weight: 400;
		display: flex;
		align-items: center;
		font-size: 0.875rem;
		line-height: 0.86;
		letter-spacing: 0.18em;
		text-transform: uppercase;
		top: 0.625rem;
		left: 0.625rem;
		-webkit-transform: none;
		transform: none;
	}
}

.secondary-well__replay::before,
.secondary-well__play::before {
	font-size: 1.25rem;
}

@media only screen and (min-width: 48rem) {
	.secondary-well__replay::before,
	.secondary-well__play::before {
		margin-right: 0.625rem;
	}
}

@media only screen and (min-width: 1289px) {
	.secondary-well__replay::before,
	.secondary-well__play::before {
		margin-right: 1.25rem;
		font-size: 1.875rem;
	}
}

.secondary-well__replay {
	display: none;
}

.secondary-well[data-status='over'] .secondary-well__replay {
	display: flex;
}

.secondary-well__live {
	font-weight: 400;
	display: flex;
	align-items: center;
	font-size: 0.625rem;
	line-height: 1.2;
	letter-spacing: 0.18em;
	position: absolute;
	left: 0.375rem;
	top: 0.375rem;
	color: #fff;
	z-index: 1;
}

@media only screen and (min-width: 1289px) {
	.secondary-well__live {
		top: 1.25rem;
		left: 1.25rem;
		font-size: 0.875rem;
	}
}

.secondary-well__live::before {
	content: '';
	display: block;
	width: 0.375rem;
	height: 0.375rem;
	background-color: #3fcc6f;
	border-radius: 50%;
	margin-right: 0.3125rem;
}

@media only screen and (min-width: 1289px) {
	.secondary-well__live::before {
		width: 0.625rem;
		height: 0.625rem;
	}
}

.secondary-well[data-status='over'] .secondary-well__live,
.secondary-well[data-type='video'] .secondary-well__live {
	display: none;
}

.secondary-well__eyebrow {
	margin-bottom: 0.3125rem;
}

@media only screen and (min-width: 1289px) {
	.secondary-well__eyebrow {
		margin-bottom: 0.625rem;
	}
}

@media only screen and (min-width: 1289px) {
	.secondary-well__eyebrow {
		width: 100%;
	}
}

.secondary-well__label {
	font-weight: 400;
	font-size: 0.75rem;
	line-height: 1;
	letter-spacing: 0.17em;
	color: gray;
	text-transform: uppercase;
	margin-right: 0.625rem;
}

@media only screen and (min-width: 1289px) {
	.secondary-well__label {
		font-size: 0.875rem;
	}
}

.secondary-well .stock-ticker {
	display: inline-flex;
}

.secondary-well__text {
	font-weight: 300;
	font-size: 0.875rem;
	line-height: 1.71;
	letter-spacing: 0.04em;
}

.secondary-well__text:hover,
.secondary-well__text:focus {
	text-decoration: none;
}

@media only screen and (min-width: 48rem) {
	.secondary-well__text {
		font-size: 1.75rem;
	}
}

@media only screen and (min-width: 1289px) {
	.secondary-well__text {
		font-size: 2.5rem;
		margin-right: auto;
		margin-top: 1.625rem;
	}
}

.secondary-well__text strong {
	font-weight: 700;
}

.secondary-well__text em {
	font-style: italic;
}

.secondary-well__text u {
	font-style: underline;
}

@media only screen and (min-width: 1289px) {
	.secondary-well__title-block {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		margin-top: -1.625rem;
	}
}

.secondary-well .arrow-cta {
	margin-top: 1.25rem;
	flex-shrink: 0;
	justify-content: flex-start;
}

@media only screen and (min-width: 1289px) {
	.secondary-well .arrow-cta {
		margin-left: 4.25rem;
		margin-top: unset;
		justify-content: flex-end;
	}
}

.secondary-well .video-js {
	width: 100%;
	padding-top: 56.25%;
	height: 0;
}

.secondary-well .video-js video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 1;
}

.secondary-well .video-js :not(.vjs-tech) {
	display: none;
}

.secondary-well--video .secondary-well__content {
	padding: 0.9375rem 0;
	margin-left: -0.625rem;
	margin-right: -0.625rem;
}

@media only screen and (min-width: 48rem) {
	.secondary-well--video .secondary-well__content {
		margin-left: -1.25rem;
		margin-right: -1.25rem;
		width: calc(100% + 2.5rem);
		padding: 1.25rem 0 2.5rem;
	}
}

@media only screen and (min-width: 1289px) {
	.secondary-well--video .secondary-well__content {
		padding: 1.25rem 0 1.375rem;
	}
}

.secondary-well--video .secondary-well__body {
	width: calc(60% - 2.5rem);
	float: left;
	margin-left: 1.5625rem;
}

@media only screen and (min-width: 48rem) {
	.secondary-well--video .secondary-well__body {
		width: auto;
		flex-grow: 1;
		margin-left: 1.25rem;
		margin-right: 1.25rem;
	}
}

@media only screen and (min-width: 48rem) {
	.secondary-well--video .secondary-well__text {
		margin-right: 1.25rem;
	}
}

.main-well__bg--gradient-1::after {
	background-blend-mode: normal, overlay, overlay, normal, normal, normal, normal;
	background-image: linear-gradient(301deg, rgba(44, 34, 150, 0), #7b2297),
		linear-gradient(rgba(0, 82, 154, 0.95), rgba(0, 82, 154, 0.95)), linear-gradient(121deg, #3fcc6f, #3fcc6f),
		linear-gradient(238deg, rgba(173, 32, 142, 0), #ad208e),
		linear-gradient(42deg, rgba(0, 190, 213, 0), rgba(0, 190, 213, 0.85) 85%, rgba(0, 190, 213, 0.87) 87%, #00bed5),
		linear-gradient(126deg, rgba(0, 190, 213, 0), #00bed5), linear-gradient(#594099, #594099);
}

.main-well__bg--gradient-2::after {
	background-blend-mode: multiply, overlay, normal, overlay, overlay, normal, normal, normal;
	background-image: linear-gradient(rgba(173, 32, 142, 0.25), rgba(173, 32, 142, 0.25)),
		linear-gradient(rgba(173, 32, 142, 0.4), rgba(173, 32, 142, 0.4)),
		linear-gradient(141deg, #ff940c, rgba(255, 148, 12, 0)),
		linear-gradient(rgba(253, 187, 32, 0.7), rgba(253, 187, 32, 0.7)),
		linear-gradient(141deg, rgba(0, 190, 213, 0), #00bed5),
		linear-gradient(121deg, rgba(173, 32, 142, 0.5), #ad208e),
		linear-gradient(239deg, rgba(109, 180, 62, 0), #6db43e), linear-gradient(#fff, #fff);
}

.main-well__bg--gradient-3::after {
	background-image: linear-gradient(39deg, rgba(0, 82, 154, 0), rgba(0, 82, 154, 0.95)),
		linear-gradient(39deg, rgba(0, 195, 222, 0), #00bed5), linear-gradient(301deg, #3fcc6f, #3fcc6f),
		linear-gradient(#6db43e, #6db43e);
}

.main-well__bg--gradient-4::after {
	background-image: linear-gradient(219deg, rgba(173, 32, 142, 0), #ad208e),
		linear-gradient(121deg, rgba(253, 231, 32, 0), #fde720), linear-gradient(39deg, rgba(44, 34, 150, 0), #7b2297),
		linear-gradient(rgba(0, 82, 154, 0.95), rgba(0, 82, 154, 0.95));
}

.main-well__bg--gradient-1-dark::after {
	background-blend-mode: normal, normal, overlay, overlay, normal, normal, normal, normal;
	background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
		linear-gradient(301deg, rgba(44, 34, 150, 0), #7b2297),
		linear-gradient(rgba(0, 82, 154, 0.95), rgba(0, 82, 154, 0.95)),
		linear-gradient(121deg, rgba(109, 180, 62, 0), #6db43e), linear-gradient(238deg, rgba(173, 32, 142, 0), #ad208e),
		linear-gradient(42deg, rgba(0, 190, 213, 0), rgba(0, 190, 213, 0.85) 85%, rgba(0, 190, 213, 0.87) 87%, #00bed5),
		linear-gradient(126deg, rgba(0, 190, 213, 0), #00bed5), linear-gradient(#594099, #594099);
}

.main-well__bg--gradient-2-dark::after {
	background-blend-mode: normal, multiply, overlay, normal, overlay, overlay, normal, normal, normal;
	background-image: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)),
		linear-gradient(rgba(173, 32, 142, 0.25), rgba(173, 32, 142, 0.25)),
		linear-gradient(rgba(173, 32, 142, 0.4), rgba(173, 32, 142, 0.4)),
		linear-gradient(141deg, #ff940c, rgba(255, 148, 12, 0)),
		linear-gradient(rgba(253, 187, 32, 0.7), rgba(253, 187, 32, 0.7)),
		linear-gradient(141deg, rgba(0, 190, 213, 0), #00bed5),
		linear-gradient(121deg, rgba(173, 32, 142, 0.5), #ad208e),
		linear-gradient(239deg, rgba(109, 180, 62, 0), #6db43e), linear-gradient(#fff, #fff);
}

.main-well__bg--gradient-3-dark::after {
	background-image: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.35)),
		linear-gradient(39deg, rgba(0, 82, 154, 0), rgba(0, 82, 154, 0.95)),
		linear-gradient(39deg, rgba(0, 195, 222, 0), #00bed5), linear-gradient(301deg, rgba(109, 180, 62, 0), #6db43e),
		linear-gradient(#6db43e, #6db43e);
}

.main-well__bg--gradient-4-dark::after {
	background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
		linear-gradient(219deg, rgba(173, 32, 142, 0), #ad208e), linear-gradient(121deg, rgba(253, 187, 32, 0), #fdbb20),
		linear-gradient(39deg, rgba(89, 64, 153, 0), #594099),
		linear-gradient(rgba(0, 82, 154, 0.95), rgba(0, 82, 154, 0.95));
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.main-well {
	position: relative;
}

.main-well::before {
	content: '';
	display: block;
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	height: 62.5%;
	opacity: 0.5;
	background-image: linear-gradient(to bottom, #000, rgba(0, 0, 0, 0));
	z-index: 3;
}

.main-well::after {
	content: '';
	display: block;
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
	height: 27.70833%;
	opacity: 0.5;
	background-image: linear-gradient(to top, #000, rgba(0, 0, 0, 0));
	z-index: 3;
}

.main-well__cover {
	background-color: #00539b;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: block;
	margin: 0;
	padding: 0;
	z-index: 5;
	opacity: 1;
	visibility: visible;
	transition: opacity 0.4s, visibility 0.4s;
	-webkit-transition: opacity 0.4s, visibility 0.4s;
}

.main-well__bg {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.main-well__bg--gradient-1::after {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
}

.main-well__bg--gradient-1 > picture {
	opacity: 0.35;
	position: relative;
	z-index: 2;
	mix-blend-mode: soft-light;
}

.main-well__bg--gradient-1-dark::after {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
}

.main-well__bg--gradient-1-dark > picture {
	opacity: 0.35;
	position: relative;
	z-index: 2;
	mix-blend-mode: soft-light;
}

.main-well__bg--gradient-2::after {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
}

.main-well__bg--gradient-2 > picture {
	opacity: 0.35;
	position: relative;
	z-index: 2;
	mix-blend-mode: soft-light;
}

.main-well__bg--gradient-2-dark::after {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
}

.main-well__bg--gradient-2-dark > picture {
	opacity: 0.35;
	position: relative;
	z-index: 2;
	mix-blend-mode: soft-light;
}

.main-well__bg--gradient-3::after {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
}

.main-well__bg--gradient-3 > picture {
	opacity: 0.35;
	position: relative;
	z-index: 2;
	mix-blend-mode: soft-light;
}

.main-well__bg--gradient-3-dark::after {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
}

.main-well__bg--gradient-3-dark > picture {
	opacity: 0.35;
	position: relative;
	z-index: 2;
	mix-blend-mode: soft-light;
}

.main-well__bg--gradient-4::after {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
}

.main-well__bg--gradient-4 > picture {
	opacity: 0.35;
	position: relative;
	z-index: 2;
	mix-blend-mode: soft-light;
}

.main-well__bg--gradient-4-dark::after {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
}

.main-well__bg--gradient-4-dark > picture {
	opacity: 0.35;
	position: relative;
	z-index: 2;
	mix-blend-mode: soft-light;
}

.main-well__bg picture {
	display: block;
	height: 100%;
	width: 100%;
}

.main-well__bg picture img {
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}

.main-well__bg::before {
	content: '';
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	background-repeat: no-repeat;
	background-size: auto 130%;
	background-position: center 13%;
	opacity: 0.8;
	mix-blend-mode: soft-light;
	z-index: 2;
}

@media only screen and (min-width: 48rem) {
	.main-well__bg::before {
		background-position: center top;
	}
}

@media only screen and (min-width: 48rem) {
	.main-well__bg::before {
		background-position: center -20%;
	}
}

.main-well__bg_link {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 4;
}

.main-well__container {
	padding: 6.25rem 0 5rem;
	min-height: 30rem;
}

.main-well__container::after {
	clear: both;
	content: '';
	display: block;
}

@media only screen and (min-width: 48rem) {
	.main-well__container {
		margin-left: 0.875rem;
		margin-right: 0.875rem;
	}
}

@media only screen and (min-width: 1289px) {
	.main-well__container {
		margin-left: 4.375rem;
		margin-right: 4.375rem;
	}
}

@media (min-width: 108.75rem) {
	.main-well__container {
		width: 100rem;
		max-width: 100rem;
		margin-left: auto;
		margin-right: auto;
	}
}

@media only screen and (min-width: 48rem) {
	.main-well__container {
		padding: 8.125rem 0 7.5rem;
		min-height: 37.5rem;
	}
}

@media only screen and (min-width: 1289px) {
	.main-well__container {
		padding: 10.3125rem 0 8.5rem;
		min-height: 40.625rem;
	}
}

.main-well__content {
	width: calc(100% - 3.125rem);
	float: left;
	margin-left: 1.5625rem;
	position: relative;
	z-index: 4;
}

@media only screen and (min-width: 48rem) {
	.main-well__content {
		width: calc(100% - 2.5rem);
		float: left;
		margin-left: 1.25rem;
	}
}

@media only screen and (min-width: 1289px) {
	.main-well__content {
		width: calc(62.5% - 2.03125rem);
		float: left;
		margin-left: 1.25rem;
	}
}

.main-well__nasdaqlisted {
	font-weight: 300;
	color: #fff;
	vertical-align: top;
	margin-bottom: 0.625rem;
	font-weight: 600;
}

.main-well__nasdaqlisted span {
	font-weight: 300;
}

.main-well__nasdaqlisted img {
	height: 0.875rem;
}

.main-well__title {
	display: block;
	color: #fff;
}

.main-well__title:focus,
.main-well__title:hover {
	text-decoration: none;
}

.main-well__company {
	font-weight: 600;
	display: block;
	font-size: 3rem;
	letter-spacing: 0.042em;
}

@media only screen and (min-width: 48rem) {
	.main-well__company {
		font-size: 3.4375rem;
	}
}

@media only screen and (min-width: 48rem) {
	.main-well__company {
		font-size: 7.5rem;
	}
}

.main-well__headline {
	font-weight: 300;
	font-size: 2.25rem;
	line-height: 1.28;
	letter-spacing: 0.04em;
}

@media only screen and (min-width: 48rem) {
	.main-well__headline {
		font-size: 3.4375rem;
	}
}

@media only screen and (min-width: 1289px) {
	.main-well__headline {
		font-size: 3.75rem;
	}
}

.main-well__countdown {
	display: none;
}

.main-well__countdown .main-well__countdown-display {
	font-weight: 600;
	border-bottom: 0.125rem solid #fff;
}

.main-well__read-more {
	margin-top: 1.25rem;
}

@media only screen and (min-width: 48rem) {
	.main-well__read-more {
		margin-top: 1.875rem;
	}
}

.main-well__read-more .arrow-cta {
	justify-content: flex-start;
}

.main-well ~ .secondary-well {
	z-index: 10;
}

.main-well--with-ticker .main-well__nasdaqlisted {
	display: inline-flex;
	align-items: center;
	padding-right: 1.25rem;
	margin-right: 1.25rem;
	border-right: 0.0375rem solid rgba(255, 255, 255, 0.2);
}

.main-well--with-ticker .main-well__ticker {
	display: inline-block;
	position: relative;
	left: -0.125rem;
	top: -0.125rem;
}

.main-well[countdown-status='default'] .main-well__bg--default,
.main-well[countdown-status='default'] .main-well__bg_link--default,
.main-well[countdown-status='default'] .main-well__read-more--default,
.main-well[countdown-status='over'] .main-well__bg--default,
.main-well[countdown-status='over'] .main-well__bg_link--default,
.main-well[countdown-status='over'] .main-well__read-more--default {
	display: block;
}

.main-well[countdown-status='default'] .main-well__bg--live,
.main-well[countdown-status='default'] .main-well__bg_link--live,
.main-well[countdown-status='default'] .main-well__nasdaqlisted--live,
.main-well[countdown-status='default'] .main-well__ticker--live,
.main-well[countdown-status='default'] .main-well__read-more--live,
.main-well[countdown-status='over'] .main-well__bg--live,
.main-well[countdown-status='over'] .main-well__bg_link--live,
.main-well[countdown-status='over'] .main-well__nasdaqlisted--live,
.main-well[countdown-status='over'] .main-well__ticker--live,
.main-well[countdown-status='over'] .main-well__read-more--live {
	display: none;
}

.main-well[countdown-status='default'] .main-well__title--live,
.main-well[countdown-status='over'] .main-well__title--live {
	display: none;
}

.main-well[countdown-status='default'] .main-well__nasdaqlisted--default,
.main-well[countdown-status='over'] .main-well__nasdaqlisted--default {
	display: inline-flex;
}

.main-well[countdown-status='default'] .main-well__ticker--default,
.main-well[countdown-status='over'] .main-well__ticker--default {
	display: inline-block;
}

.main-well[countdown-status='countdown'] .main-well__bg--live,
.main-well[countdown-status='countdown'] .main-well__bg_link--live,
.main-well[countdown-status='countdown'] .main-well__read-more--live {
	display: block;
}

.main-well[countdown-status='countdown'] .main-well__nasdaqlisted--default {
	display: inline-flex;
}

.main-well[countdown-status='countdown'] .main-well__ticker--default {
	display: inline-block;
}

.main-well[countdown-status='countdown'] .main-well__title--default {
	display: none;
}

.main-well[countdown-status='countdown'] .main-well__bg--default,
.main-well[countdown-status='countdown'] .main-well__bg_link--default,
.main-well[countdown-status='countdown'] .main-well__nasdaqlisted--live,
.main-well[countdown-status='countdown'] .main-well__ticker--live,
.main-well[countdown-status='countdown'] .main-well__read-more--default {
	display: none;
}

.main-well[countdown-status='countdown'] .main-well__countdown {
	display: inline;
}

.main-well[countdown-status='countdown'] .main-well__headline {
	font-size: 1.875rem;
	line-height: 1.33;
	letter-spacing: 0.04em;
}

@media only screen and (min-width: 48rem) {
	.main-well[countdown-status='countdown'] .main-well__headline {
		font-size: 2.25rem;
	}
}

@media only screen and (min-width: 1289px) {
	.main-well[countdown-status='countdown'] .main-well__headline {
		font-size: 2.5rem;
	}
}

.main-well[countdown-status='live'] .main-well__title--default,
.main-well[countdown-status='live'] .main-well__bg--default,
.main-well[countdown-status='live'] .main-well__bg_link--default,
.main-well[countdown-status='live'] .main-well__nasdaqlisted--default,
.main-well[countdown-status='live'] .main-well__ticker--default,
.main-well[countdown-status='live'] .main-well__read-more--default {
	display: none;
}

.main-well[countdown-status='live'] .main-well__bg--live,
.main-well[countdown-status='live'] .main-well__bg_link--live,
.main-well[countdown-status='live'] .main-well__read-more--live {
	display: block;
}

.main-well[countdown-status='live'] .main-well__nasdaqlisted--live {
	display: inline-flex;
}

.main-well[countdown-status='live'] .main-well__ticker--live {
	display: inline-block;
}

.main-well[countdown-status='live']::before {
	height: 31.25%;
}

@media only screen and (min-width: 48rem) {
	.main-well[countdown-status='live']::before {
		height: 50%;
	}
}

@media only screen and (min-width: 1289px) {
	.main-well[countdown-status='live']::before {
		height: 46.15385%;
	}
}

.main-well[countdown-status='live']::after {
	height: 87.5%;
	opacity: 1;
}

@media only screen and (min-width: 48rem) {
	.main-well[countdown-status='live']::after {
		height: 66.66667%;
	}
}

@media only screen and (min-width: 1289px) {
	.main-well[countdown-status='live']::after {
		height: 61.53846%;
	}
}

.main-well[countdown-status='live'] .main-well__bg::before,
.main-well[countdown-status='live'] .main-well__bg::after {
	display: none;
}

.main-well[countdown-status='live'] picture {
	opacity: 0.8;
	mix-blend-mode: normal;
}

.main-well[countdown-status='live'] .main-well__container {
	display: flex;
	align-items: flex-end;
}

@media only screen and (min-width: 1289px) {
	.main-well[countdown-status='live'] .main-well__content {
		width: calc(79.16667% - 2.23958rem);
		float: left;
		margin-left: 1.25rem;
	}
}

.main-well[countdown-status='live'] .main-well__headline {
	font-size: 1.875rem;
	line-height: 1.33;
	letter-spacing: 0.04em;
}

@media only screen and (min-width: 48rem) {
	.main-well[countdown-status='live'] .main-well__headline {
		font-size: 2.25rem;
	}
}

@media only screen and (min-width: 1289px) {
	.main-well[countdown-status='live'] .main-well__headline {
		font-size: 2.5rem;
	}
}

.main-well[loaded='true'] .main-well__cover {
	opacity: 0;
	visibility: hidden;
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.stock-ticker {
	display: flex;
}

.stock-ticker .stock-ticker__name,
.stock-ticker .stock-ticker__name-icon,
.stock-ticker .stock-ticker__price,
.stock-ticker .stock-ticker__stats {
	font-size: 0.8125rem;
	letter-spacing: 0.04em;
	text-transform: uppercase;
}

.stock-ticker__name {
	font-weight: 500;
}

.stock-ticker--light .stock-ticker__name {
	color: #fff;
}

.stock-ticker__name-icon {
	font-weight: 500;
}

.stock-ticker__name-icon::before {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content: 'î¤¡';
	display: none;
}

.stock-ticker--up .stock-ticker__name-icon::before {
	color: #3fcc6f;
	display: inline-block;
	padding-right: 0.3125rem;
	padding-left: 0;
}

.stock-ticker--down .stock-ticker__name-icon::before {
	color: #fd6e70;
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
	display: inline-block;
	padding-right: 0;
	padding-left: 0.3125rem;
}

.stock-ticker__price {
	font-weight: 400;
	margin: 0 0.4375rem 0 0.5rem;
}

.stock-ticker--light .stock-ticker__price {
	color: #fff;
}

.stock-ticker__stats {
	font-weight: 500;
}

.stock-ticker--up .stock-ticker__stats {
	color: #3fcc6f;
}

.stock-ticker--down .stock-ticker__stats {
	color: #fd6e70;
}

.icon {
	speak: none;
	font-style: normal;
	font-weight: 400;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.header-ads {
	z-index: 101;
	background-color: #fff;
	width: 100%;
	align-items: center;
	justify-content: space-around;
	text-align: center;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
}

.header-ads::before {
	content: 'sticky';
	display: none;
}

.header-ads__left,
.header-ads__right {
	display: none;
}

.header-ads__center .ads__leaderboard {
	margin: 0;
}

.header-ads__center [class*='leaderboard'] {
	display: flex;
	min-height: 3.125rem;
	justify-content: center;
}

@media only screen and (min-width: 1289px) {
	.header-ads {
		display: flex;
		width: 100%;
		align-items: center;
		justify-content: space-around;
	}

	.header-ads__left,
	.header-ads__right {
		display: flex;
	}

	.header-ads__left .ads__header-ad-button-2-up,
	.header-ads__right .ads__header-ad-button-2-up {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	.header-ads__left .ads__header-ad-button-2-up__button,
	.header-ads__right .ads__header-ad-button-2-up__button {
		float: left;
	}
}

body.with-header-ads .header-ads {
	transition: top 1s;
	will-change: top;
}

@media only screen and (min-width: 48rem) {
	body.with-header-ads .header-ads {
		position: -webkit-sticky;
		position: sticky;
	}
}

body.with-header-ads .primary_nav_sticky,
body.with-header-ads .sub_nav,
body.with-header-ads .latest-news {
	transition: top 1s, -webkit-transform 0.3s ease-out;
	transition: transform 0.3s ease-out, top 1s;
	transition: transform 0.3s ease-out, top 1s, -webkit-transform 0.3s ease-out;
	will-change: transform, top;
}

body.with-header-ads:not(.with-header-ads--loaded) .header-ads {
	top: 0;
}

body.with-header-ads:not(.with-header-ads--loaded) .header-ads ~ * .latest-news {
	top: -13.75rem;
}

body.with-header-ads:not(.with-header-ads--loaded) .header-ads .ads__leaderboard [data-google-query-id] {
	display: block !important;
}

@media only screen and (min-width: 48rem) {
	body.with-header-ads:not(.with-header-ads--loaded) [class*='leaderboard'] {
		display: flex !important;
	}
}

/* @media screen and (min-width:83.5625rem) {
  .primary_nav__link {
  width:12.5rem
  }
  }
  
  @media  screen and (min-width:75rem) {
  .primary_nav__link {
  width:10rem
  }
  } */

@media only screen and (min-width: 48rem) {
	.primary_nav__mega_column {
		display: flex;
		flex-direction: column;
		border-bottom: 0 solid #e1e1e6;
		padding-bottom: 5px;
		margin-bottom: 10px;
		float: left;
		/* width: 25%; */
		min-height: 10px;
	}
}

@media only screen and (min-width: 1289px) {
	.primary_nav__mega_column {
		min-height: 50px;
	}
}
@media (max-width: 1298px) {
	.primary_nav__links {
		overflow-y: scroll !important;
		height: calc(110vh - 0rem);
	}
	
	.primary_nav__mega_column {
		display: flex;
		flex-direction: column;
		border-bottom: 0 solid #e1e1e6;
		padding-bottom: 1.25rem;
		margin-bottom: 0;
		float: inherit !important;
	}
}



@media (min-width: 1292.99px) {
	.primary_nav__links {
		width: 100% !important;
	}
	.primary_nav__link {
		width: 100%;
	}
}



.ticker :global(.slick-slide){
	width: intrinsic !important;           /* Safari/WebKit uses a non-standard name */
  width: -moz-max-content !important;    /* Firefox/Gecko */
  width: -webkit-max-content !important; /* Chrome */
	min-width: 267px !important;
}

/* // Large devices (desktops, less than 1200px) */
@media (min-width: 1298px) {
	.primary_nav__links {
		width: 80% !important;
	}
	.primary_nav__link {
		width: min-content;
		width: -moz-min-content
	}
}
@media (min-width: 1401.99px) {
	.primary_nav__links {
		width: 80% !important;
	}
	.primary_nav__link {
		width: calc(100% - 90%);
	}
}

@media (min-width: 1514.99px) {
	.primary_nav__links {
		width: 83% !important;
	}
	.primary_nav__link {
		width: calc(100% - 90%);
	}
}

@media (min-width: 1680.99px) {
	.primary_nav__links {
		width: 85% !important;
	}
	.primary_nav__link {
		width: calc(100% - 90%);
	}
}

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) { 
	
}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) { 
	.ticker{
		width: 70%;
	}
	.search_container{
		width: 30%;
	}
	
 }

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) { 
	.ticker{
		width: 70%;
	}
	.search_container{
		width: 30%;
	}
 }

/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
	.ticker{
		width: 100%;
	}
	.search_container{
		width: 100%;
		top: 107px;
	}
	
 }

