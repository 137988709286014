.item_list {
  border: 1px solid #eaedfa;
  margin-bottom: 20px;
  border-radius: 3px;
  padding-right: 0;
  padding-bottom: 0;

}

.marginList{
  margin-bottom: 20px; 
}

.paddingBottom{
  padding-bottom: 12px;
}

.img_container {
  padding: 5px;
}

.img_container img {
  width: 160px;
  height: 168px;
  border: 1px solid #eaedfa;
  border-radius: 3px;
}

.info_container {
  /* padding: 0 0 0 10px; */
}

.user p {
  font-size: 16px;
  color: #00aeef;
}

.user h1 {
  font-size: 24px;
}

.suplenteContainer{
  border-top-left-radius: 3px;
  padding: 10px;
  background-color: #eaedfa;
}

.separator {
  color: #00aeef;
  border-top: 2px solid;
}

.tituloSuplente{
  color: #00AEEF;
}

.suplenteInfo{
}
