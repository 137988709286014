.text_container{
    text-align: justify;
}

.text_container p {
    margin-bottom: 10px;
}
.text_container p span p {
    margin-top: 10px;
}

br{
    margin-bottom: 10px;
}