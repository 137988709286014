.showModel1 {
	/* z-index: 500 !important;
  transform: translateY(-50vh); */
	opacity: 1;
	z-index: 2000 !important;
}

.showModel2 {
	z-index: 500 !important;
	/* transform: translateY(-30vh); */
	opacity: 1;
	z-index: 2000 !important;
}

.hidden {
	transform: translateY(-100vh);
	opacity: 0;
}

.rssModalContainer {
	z-index: -500;
	width: 100%;
	transition: all 0.2s ease-out;
	background-color: #fff;
	padding: 30px;
	border-radius: 4px;
	position: absolute;
	top: 0;
}

.rssModalHeader span {
	font-size: 23px;
	color: #00aeef;
}

.rssModalHeader i {
	position: relative;
	top: 2px;
	font-size: 23px;
	color: #00aeef;
	cursor: pointer;
	margin-left: 10px;
}

.marginModaBodyForModel1 {
	margin: 90px;
	margin-bottom: 25px !important;
}

.marginModaBodyForModel2 {
	margin: 50px 90px 90px 90px;
}

.rssModalBody {
	min-height: 300px;
}

.bodySecondSection h1 {
	font-size: 40px;
	color: #000;
	text-align: center;
	margin: 20px 0 10px 0;
}

.urlContainer {
	margin: 50px 0;
	padding: 15px 10px;
	background-color: #fafbff;
	border-radius: 4px;
	overflow-x: auto !important;
	white-space: nowrap;
}

.button {
	border: 1px solid #eaedfa;
	color: #00aeef;
	width: 90% !important;
	margin: 0 auto;
}

.copyText {
	position: absolute;
	top: 40px;
	left: 20px;
	color: #00aeef;
}
.paddingMessage{
	padding-left: 15px;
	padding-right: 15px;
	margin-top: 0 !important;
}

.rssModalFooter h1 {
	font-size: 35px;
	color: #000;
}
.documentContainer {
	width: 100%;
	position: relative;
	height: 150px;
}
.documentContainer a {
	position: absolute;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	margin: 20px auto;
	width: 350px;
}

.firstSectionHeigth {
	min-height: 200px;
}

.bodyFirstSection span {
	display: inline-block;
}

.bodyFirstSection input[type='checkbox'] {
	position: relative;
	top: 12px;
	left: 20px;
	margin-right: 35px;
}

.bodyFirstSection span:last-child {
	margin-left: 20px;
}

.inputsContainer {
	width: 100%;
	overflow-x: auto !important;
	white-space: nowrap;
	margin: 10px 0;
	position: relative;
	right: 0;
	background-color: #eaedfa;
    border-radius: 4px;
}
.inputsContainer2{
    width: 100%;
    overflow-x: auto;
    height: 100%;
    min-height: 100px;
    max-height: 350px;
}

.scrollContainer {
	width: 100%;
  overflow-x: auto;
  height: 350px;
}

.scrollContainer2 {
	width: 100%;
  overflow-x: auto;
  height: 150px;
}

.myColWdith {
	max-width: 400px !important;
	display: inline-block;
}
.labelCheck label{
  width: 300px !important;
  white-space: pre-line;
}

.bodyFirstSection2 input[type='checkbox'] {
	position: relative;
	top: 0px;
	left: 20px;
	margin-right: 35px;
}

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
	.marginModaBodyForModel1 {
		margin: 30px;
	}
}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
	.marginModaBodyForModel1 {
		margin: 30px;
  }
  .myColWdith {
		max-width: 350px !important;
		display: inherit !important;
	}
}

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
	.marginModaBodyForModel1 {
		margin: 0px;
	}
	.myColWdith {
		max-width: 350px !important;
		display: inherit !important;
	}
}

/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
	.marginModaBodyForModel1 {
		margin: 0px;
	}
	.myColWdith {
		max-width: 350px !important;
		display: inherit !important;
	}
}
