.fondo {
  width: 100%;
  height: 700px;
}

.alto {
  height: 100%;
  width: 100%;
}

.imagenArbol,
.imagenInicial {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.fondoBlanco2,
.fondoBlanco,
.fondoIndice {
  width: 100%;
  height: 500px;
  position: relative;
}
.fondoBlanco2{
  height: auto;
  min-height: 400px;
  padding-bottom: 50px;
  padding-top: 50px;
}

.fondoIndice,
.cuadroVerde {
  background-color: #176937;
  position: relative;
}

.fondoMaria {
  width: 100%;
  height: 700px;
  color: #176937;
  position: relative;
}

.cuadro {
  width: 100%;
  color: #fff;
  padding: 75px;
}

.imagenArbol {
  background-image: url("../../../assets/images/svg/mano.jpg");
}

.titulo_indices {
  font-size: 60px;
  line-height: 75px;
}

.container_index,
.centrar,
.cuadroBanner {
  width: 100%;
  height: fit-content;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.cita {
  font-size: 30px;
  font-weight: bold;
  line-height: 40px;
}
.cita strong {
  font-size: 40px;
  font-weight: bold;
}

.fondo_traslucido {
  background-color: rgba(0, 0, 0, 0.55);
}

.margenCuadro {
  margin-top: 48px;
}
.imagenArbol,
.fondo_traslucido {
  height: 1000px;
}

.fondoMaria img {
  width: 80%;
}

.cuadroBanner,
.cuadroBanner img {
  width: 400px;
  left: 0;
  right: 0;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}

.cuadroBanner:hover{
    transform: scale(1.1);
}
.cuadroBanner:active{
    transform: scale(0.9);
}

.textos_html ol, .textos_html ul {
  list-style: inherit;
  margin-top: 10px;
}

.textos_html li {
  list-style: inherit;
  margin-top: 10px;
}

.imgTeam{
  width: 250px;
  height: 250px;
}

.texto_team{
  position: relative;
  right: -50%;
  padding-top: 5px;
}

.user{
  margin-bottom: 20px;
}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 1199.98px) {
  .titulo_indices {
    font-size: 45px;
    line-height: 60px;
  }
  .cita {
    font-size: 20px;
  }
  .cita strong {
    font-size: 30px;
  }
  .imagenArbol,
  .fondo_traslucido {
    height: 1100px;
  }
}
/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .titulo_indices {
    font-size: 30px;
    line-height: 45px;
  }
  .cita {
    font-size: 25px;
  }
  .cita strong {
    font-size: 35px;
  }
  .imagenArbol,
  .fondo_traslucido {
    height: 1200px;
  }

  .fondoMaria {
    height: 1200px;
  }
  .fondoBlanco2{
    min-height: 965px;
  }

  .cuadroBanner, .cuadroBanner img{
    width: 300px;
  }
  .texto_team{
    right: inherit;
    text-align: center;
  }
}
/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .margenCuadro {
    margin-top: 0px;
  }
  .imagenArbol,
  .fondo_traslucido {
    height: 1400px;
  }
  .fondoBlanco{
    height: 700px;
  }
  .fondoBlanco2{
    min-height: 1300px;
  }
  .alto2{
    height: 85%;
  }
}
/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .imagenArbol,
  .fondo_traslucido {
    height: 1700px;
  }
}
