.contenedorFooter{
    width: 100%;
    height: 900px;
    column-count: 4;
    column-fill: auto;
    margin-top: 30px;
    margin-bottom: 30px;
}

.contenedorFooter > div{
    margin-bottom: 25px;
}