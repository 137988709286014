.thumbanil img{
    width: 150px;
    height: 100px;
}

.list_group{
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-left: 15px !important;
}

.list_item{
    margin: 0px 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 0px solid #EAEDFA;
    cursor: pointer;
    margin-bottom: 0px;
    height: fit-content !important;
    width: 232px;
    background: transparent;
}
.list_item:hover{
    height: fit-content !important;
    /* background: rgba(83, 83, 83, 0.486); */
    transform: scale(1.1);
}

.list_item:active{
    height: fit-content !important;
    background: rgba(83, 83, 83, 0.226);
}

.list_item:last-child{
    border-bottom: 0;
}

.margen_reloj{
    margin-right: 5px;
}

.titulo_video{
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
}

.duracion_video{
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}


.row_video, .row_video:first-child{
    height: fit-content !important;
    max-height: inherit !important;
}

.videoContainer{
    position: relative;
    float: left;
    width: 40%;
    /* max-width: 200px; */
}

.textoVideoContainer{
    position: relative;
    float: left;
    width: 100px;
    /* max-width: 200px; */
}






/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.99px) { 
    
}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.99px) { 
    
    /* .videoContainer{
        float: none;
        width: 100%;
    }
    
    .textoVideoContainer{
        float: none;
        width: 100%;
    } */
    .thumbanil{
        width: fit-content;
        width: -moz-fit-content;
        position: relative;
        right: 0;
        left: 0;
        margin-left: auto;
        margin-right: auto;
    }
    .thumbanil img{
        width: 100px;
        height: 70px;
    }
    .contenedorTitulo{
        width: 85%;
        position: relative;
        right: 0;
        left: 0;
        margin-left: auto;
        margin-right: auto;
    }
    .list_item{
        width: 300px !important;
    }
}


/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.99px) { 
    .videoContainer{
        float: left !important;
        width: 40% !important;
        /* max-width: 200px !important; */
    }
    
    .textoVideoContainer{
        float: left !important;
        width: 60% !important;
        /* max-width: 200px !important; */
    }
    .thumbanil{
        width: fit-content !important;
        width: -moz-fit-content !important;
        position: relative !important;
        right: inherit !important;
        left: inherit !important;
        margin-left: inherit !important;
        margin-right: inherit !important;
    }
    .contenedorTitulo{
        width: 85% !important;
        position: relative !important;
        right: inherit !important;
        left: inherit !important;
        margin-left: inherit !important;
        margin-right: inherit !important;
    }
    .thumbanil img{
        width: 150px !important;
        height: 100px !important;
    }
    .list_item{
        width: 500px !important;
    }
}

/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.99px) { 
    .list_item{
        width: 400px !important;
    }
}