.sin_padding_l{
    padding-left: 0;
  }

  .sin_padding_r{
    padding-right: 0;
  }

  .color_azul{
    color: #00AEEF;
    
  }

  .texto_indicador{
    font-family: "Montserrat", sans-serif;
    font-size: 10px;
    line-height: 16.69px;
    font-weight: 500;
    color: #9095A4;
  }
