/*Nuevo diseño*/

.firstSectionFilterContainer {
	border: 1px solid #eaedfa;
	border-radius: 4px;
	padding: 0;
}

.noBorderRadius {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.toggleButtonContainer {
	width: 100%;
	background-color: #fafbff;
	/* padding: 2px 0 0 0; */
	border-left: 1px solid #eaedfa;
	border-radius: 0 4px 4px 0;
}

.toggleButtonContainer button {
	width: 100% !important;
	color: #00aeef;
	outline: none;
	box-shadow: none !important;
	height: 65px;
}

.toggleButtonContainer button i {
	margin-right: 10px;
}

.secondSectionFilterContainer {
	border-top: 1px solid #eaedfa;
	border-bottom: 1px solid #eaedfa;
	border-left: 1px solid #eaedfa;
	border-right: 1px solid #eaedfa;
	border-radius: 0 0 4px 4px;
	background-color: #fafbff;
	padding: 10px 0;
}

.mainSubFilterContainer {
	padding: 10px 0;
}

.tipoGraficaContainer {
  position: relative;
  top: 0px;
  margin: 0px 0 15px 0px;
  background: #FFF;
  border: 1px solid #ced4da;
  border-radius: 6px;
  height: 35px;
  padding-top: 3px;
}

.tipoGraficaContainer label {
	margin-right: 15px;
}

.filtersGrafica {
	margin-top: 30px;
}

.rowIndicador {
  margin: 8px 0;
  width: 100%;
}

.rowIndicador input[type='number'] {
	width: 70px;
	display: inline-block;
	margin: 0 5px 5px 0;
}

.checkPeriodo {
	margin-right: 10px;
}

.boxColor {
	width: 30px;
	height: 30px;
	border-radius: 4px;
}

.boxDesviacion {
	margin-top: 18px;
}

.lineWidthDesviacion {
	margin-top: 25px;
}

.labelInit + label::after {
	content: url('../../../assets/images/labelInit.png');
}

.labelMiddle + label::after {
	content: url('../../../assets/images/labelMiddle.png');
}

.labelEnd + label::after {
	content: url('../../../assets/images/labelEnd.png');
}

.buttonModel {
	border: 1px solid #eaedfa;
	color: #00aeef;
	width: 90% !important;
	margin: 0 auto;
}

.buttonModel i {
	margin-right: 10px;
}

.filterContainer {
	padding: 8px;
	margin-right: 5px;
	border: 1px solid #eaedfa;
	border-radius: 50px;
}

.filterContainer span {
	color: #00aeef;
	font-size: 14px;
}

.filterIconButton {
	margin-left: 10px;
	cursor: pointer;
}

.filterSelectedContainer,
.filterContainerOutside {
	height: 65px;
}

.filterContainerOutside {
	width: 100%;
	overflow-y: none !important;
	overflow-x: auto;
	white-space: nowrap;
	color: #00aeef;
	padding: 19px 0 0 15px;
}

.hidden {
	display: none;
}

.desviacionInput {
  float: left;

}
.desviacionDiv{
  float: left;
  width: 70px;
}


.contenedor_radios label {
    margin-left: 10px;
    top: 0px !important;
    position: relative;
}