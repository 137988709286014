.fondo {
    background-image: url("../../assets/images/fondoIndex.png");
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.indices {
    margin: auto;
    width: 86%;
    padding-top: 50px;
    padding-bottom: 50px;
}


/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) { 
	.fondo{
        padding-top: 33px;
    } 
}

/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
.fondo{
    padding-top: 33px;
}   
}
