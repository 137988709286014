/* Estilos para modelo #2 */

.toggle_button_container_model_one {
	width: 100%;
	background-color: #fafbff;
	padding: 8px 0 0 0;
	border: 1px solid #eaedfa;
	border-radius: 4px 0 0 4px;
}

.toggle_button_container_model_one i {
	margin-right: 10px;
}

.toggle_button_container_model_one,
.filters_selected_container_model_one,
.search_button_container_model_one,
.filter_container_outside_model_one {
	height: 60px;
}

.search_button_container_model_one {
	width: 100%;
	padding: 8px 0 0 0;
	border-top: 1px solid #eaedfa;
	border-right: 1px solid #eaedfa;
	border-bottom: 1px solid #eaedfa;
	border-radius: 0 4px 4px 0;
	background-color: #fafbff;
}

.toggle_button_container_model_one button,
.search_button_container_model_one button {
	color: #00aeef;
	outline: none;
	box-shadow: none !important;
}

.filters_selected_container_model_one {
	border-top: 1px solid #eaedfa;
	border-right: 1px solid #eaedfa;
	border-bottom: 1px solid #eaedfa;
}

.no_col_padding_model_one {
	padding: 0;
}

.filter_container_outside_model_one {
	width: 100%;
	overflow-y: none;
	overflow-x: auto;
	white-space: nowrap;
	color: #00aeef;
	padding-top: 15px;
}

.filters_container_model_one {
	width: 100%;
	background-color: #fafbff;
	border-left: 1px solid #eaedfa;
	border-right: 1px solid #eaedfa;
	border-bottom: 1px solid #eaedfa;
	border-radius: 0 0 4px 4px;
}

.filter_container_model_one {
	display: inline;
	padding: 5px 7px 7px 10px;
	margin: 6px 5px 0;
	border-radius: 50px;
}

.filter_container_model_one span {
	color: #00aeef;
}

.filter_container_model_one_alt {
	display: inline;
	padding: 5px 7px 7px 10px;
	margin: 6px 5px 0;
	border: 1px solid #eaedfa;
	border-radius: 50px;
}

.filter_container_model_one_alt span {
	color: #00aeef;
}

.search_input_container_model_one input {
	padding-left: 20px;
	height: 58px;
	border: 0;
	outline: none;
	box-shadow: none !important;
}

.filters_section_model_one {
	padding: 30px 0 20px 0;
}

.no_borders_filter_button_model_one {
	border-bottom-left-radius: 0;
}

.no_border_search_button_model_one {
	border-bottom-right-radius: 0;
}

.filter_icon_button_model_one {
	margin-left: 8px;
	cursor: pointer;
}

.filter_text_model_one {
	font-size: 13px;
}

.hidden {
	display: none;
}

.button_model_one {
	border: 1px solid #eaedfa;
	color: #00aeef;
	width: 90% !important;
	margin: 0 auto;
}

.button_model_one i {
	margin-right: 10px;
}

.autoCompleteContainer {
	position: relative;
	bottom: 15px;
	z-index: 5000 !important;
}

.autoCompleteContainer li:hover {
	cursor: pointer;
}

.autoCompleteContainer li:first-child {
	border-top: 0;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.filterContainer {
	padding: 8px;
	margin-right: 5px;
	border: 1px solid #eaedfa;
	border-radius: 50px;
}

.filterContainer span {
	color: #00aeef;
}

.filterIconButton {
	margin-left: 10px;
	cursor: pointer;
}

.filterSelectedContainer,
.selectedOptionContainer {
	height: 65px;
}

.selectedOptionContainer {
	width: 100%;
	overflow-y: none !important;
	overflow-x: auto;
	white-space: nowrap;
	color: #00aeef;
	padding: 18px 0 0 15px;
}
.errorContainer p {
	color: #e22929;
}

.button_model_one:disabled {
	background: #fafbff;
	color: rgb(79, 79, 79);
}

.padding_izquierdo {
	padding-left: 20px !important;
}

.fechaInicio,
.fechaFin {
	outline: none;
	box-shadow: none !important;
	border: 1px solid #eaedfa;
	border-radius: 4px;
	padding: 6px;
}

.fechaInicio {
	border-right: 1px solid #eaedfa;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.fechaFin {
	border-left: 1px solid #eaedfa;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}


.separacionRadios{
    margin-right: 50px;
    float: left;
}

.top{
	width: 112px;
	height: 48px;
	margin-right: 10px;
	margin-bottom: 10px;
	margin-top: 10px;
	text-align: center;
	float: left;
	border-radius: 6px;
}

.top_fecha {
	background: #4A4F54;
  }
:global(.top_1) {
	background: #00AEEF;
  }
  :global(.top_2) {
	background: #2874A6;
  }
  :global(.top_3) {
	background: #85C1E9;
  }
  :global(.top_4) {
	background: #1ABC9C;
  }
  :global(.top_5) {
	background:  #148F77;
  }
  :global(.top_6) {
	background: #76D7C4;
  }
  :global(.top_7) {
	background: #95A5A6;
  }
  :global(.top_8) {
	background: #717D7E;
  }
  :global(.top_9) {
	background: #BFC9CA;
  }
  :global(.top_10) {
	background: #34495E;
  }

  :global(.top_11) {
	background: #85929E;
  }

  :global(.top_12) {
	background: #c1acdc;
  }

  :global(.top_13) {
	background: #54357d;
  }

  :global(.top_14) {
	background: #C39BD3;
  }

  :global(.top_15) {
	background: #9B59B6;
  }

  :global(.top_16) {
	background: #3a3f54;
  }

  :global(.top_17) {
	background: #CD6155;
  }

  :global(.top_18) {
	background: #E6B0AA;
  }

  :global(.top_19) {
	background: #922B21;
  }

  :global(.top_20) {
	background: #E59866;
  }

  :global(.top_21) {
	background: #E67E22;
  }

  :global(.top_22) {
	background: #AF601A;
  }

  :global(.top_23) {
	background: #F39C12;
  }

  :global(.top_24) {
	background: #F1C40F;
  }

  :global(.top_25) {
	background: #239B56;
  }

  :global(.top_26) {
	background: #82E0AA;
  }

  :global(.top_27) {
	background: #117A65;
  }

  :global(.top_28) {
	background: #73C6B6;
  }

  :global(.top_29) {
	background: #2874A6;
  }

  :global(.top_30) {
	background: #A9CCE3;
  }

  :global(.top_31) {
	background: #797D7F;
  }

  :global(.top_32) {
	background: #D7DBDD;
  }

  :global(.top_33) {
	background: #76448A;
  }

  :global(.top_34) {
	background: #EBDEF0;
  }

  :global(.top_35) {
	background: #85C1E9;
  }


  .vertical_div{
    width: 112px;
    display: table;
  min-height: 48px;
}

.vertical{
    display: table-cell;
    vertical-align: middle;
	line-height: normal;
	position: relative;
}

.derecha_close{
	position: absolute;
	right: 13px;
}