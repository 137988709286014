.text_container{
    text-align: justify;
}

.main_subtitle h2{
    display: inline;
    margin-left: 20px;
}

.slash{
    font-size: 30px;
    font-weight: bold;
    color: #00aeef;
}

.list_container ul li{
    list-style: none;
    margin-top: 10px;
}

.list_container ul li:before {    
    font-family: "Font Awesome 5 Free" !important;
    font-weight: bold;
    content: '\f058';
    color: #00AEEF;
    margin-right: 10px;
} 

.imagenProceso{
    width: 100%;
}