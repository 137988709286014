.relativo{
    position: relative;
    margin-right: 1px;
}
.imagenes{
    width: 160px;
    height: 160px;
    float: left;
    position: relative;
}
.imagenes .image{
    display: grid;
}
.image{
    width: 156px;
    height: 156px;
}

.image {
    opacity: 1;
    display: block;
    transition: .5s ease;
    backface-visibility: hidden;
  }


.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    /* left: 70px; */
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    background: #00aeef;
  }

  .imagenes:hover .middle {
    opacity: 1;
    width: 156px;
    left: 78px;
    height: 96%;
    cursor: pointer;
    background: #001f2bb3;
  }

.icono{
    opacity: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    z-index: 1000;
    color: #ffffff;
}

.icon:before {
    color: #00aeef !important;
    background-color: #ffffff !important;
    border: 2px solid #00aeef !important;
    cursor: default !important;
    content: "";
    position: relative;
    z-index: 19;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    transition: all 0.2s;
    padding: 10px;
  }
  
  .icono_izquierdo{
height: 50px;
float: left;
padding-top: 20px;
margin-right: 12px;
  }

  .margen_abajo{
      margin-bottom: 10px;
  }


  .contenedor_mensaje{
      float: left;
      width: 240px;
  }