.cuadro{
    height: 260px;
    width: 320px;
    border: 1px solid #EAEDFA;
    position: relative;
    padding-top: 20px;
    border-radius: 6px;
    margin-bottom: 40px;
    float: left;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}



.cuadro_azul{
    width: 100%;
    height: 72px;
    background: #00aeef;
    position: absolute;
    bottom: 0;
    padding-left: 15px;
    padding-right: 15px;
    /* padding-top: 12px; */
    border-radius: 0 0 6px 6px;
    border: 1px solid #00aeef;
}


.texto{
    text-align: center;
    
}

.margin_15{
    margin-top: 5px;
}

.vertircal_div{
    width: 100%;
    display: table;
  height: 100%;
}

.vertical{
  display: table-cell;
  vertical-align: middle;
  line-height: normal;
}