.text_container{
    text-align: justify;
}

.main_subtitle h2{
    display: inline;
    margin-left: 20px;
}

.slash{
    font-size: 30px;
    font-weight: bold;
    color: #00aeef;
}

.btn {
    background: #00aeef;
    color: #ffffff !important;
    border: 1px solid rgba(0, 174, 239, 1);
    border-radius: 24px;
    height: 48px;
    width: 200px;
    font-size: 16px;
    line-height: 16.69px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    font-family: nunito;
    font-style: normal;
    font-weight: bold;
    white-space: nowrap;
    text-align: left;
  }
  
  .flecha {
    float: right;
    font-size: 23px;
    position: relative;
    bottom: 2px;
  }