.row {
  margin-bottom: 25px;
}

.titulo {
  position: relative;
}
.data {
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #8d949d;
  position: relative;
  width: 100%;
  margin-top: 10px;
  position: relative;
  display: inline-block;
}

.clave {
  width: 25%;
  float: left;
  position: relative;
}
.nombre {
  width: 75%;
  float: left;
  position: relative;
}

.documento {
  float: right;
  position: relative;
}

.link:hover {
  cursor: pointer !important;
  color: #00aeef !important;
}

.linkDocumento a{
  cursor: pointer !important;
}

