.fondo {
  width: 100%;
}

.alto {
  height: 100%;
  width: 100%;
}

.imagenArbol,
.imagenInicial {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.fondoBlanco,
.fondoIndice {
  width: 100%;
  height: 500px;
  position: relative;
}

.fondoBlanco {
  height: 500px;
}

.fondoIndice,
.cuadroVerde {
  background-color: #176937;
  position: relative;
}

.fondoMaria {
  width: 100%;
  height: 700px;
  color: #176937;
  position: relative;
}

.cuadro {
  width: 100%;
  color: #fff;
  padding: 75px;
}

.imagenArbol {
  background-image: url("../../../assets/images/svg/mano.jpg");
}

.titulo_indices {
  font-size: 60px;
  line-height: 75px;
}

.container_index,
.centrar,
.cuadroBanner {
  width: 100%;
  height: fit-content;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.cita {
  font-size: 30px;
  font-weight: bold;
  line-height: 40px;
}

.cita strong {
  font-size: 40px;
  font-weight: bold;
}

.fondo_traslucido {
  background-color: rgba(0, 0, 0, 0.55);
}

.margenCuadro {
  margin-top: 48px;
}

.imagenArbol,
.fondo_traslucido {
  height: 1000px;
}

.fondoMaria img {
  width: 80%;
}

.cuadroBanner,
.cuadroBanner img {
  width: 400px;
  left: 0;
  right: 0;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}

.cuadroBanner:hover {
  transform: scale(1.1);
}

.cuadroBanner:active {
  transform: scale(0.9);
}

.textos_html ol,
.textos_html ul {
  list-style: inherit;
  margin-top: 10px;
  padding-left: 35px;
}

.textos_html li {
  list-style: inherit;
  margin-top: 10px;
}

.objObjetivo {
  position: relative;
  width: 180px;
  min-height: 590px;
  float: left;
  cursor: pointer;
}

.objObjetivo img {
  width: 100%;
}

.number {
  border: 3px solid #176937;
  background-color: #fff;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  color: #176937;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.number span {
  font-size: 50px;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.sort {
  text-align: center;
  position: relative;
  top: 80px;
}

.sort i {
  color: #176937;
  font-size: 30px;
}

.text_objetivo {
  text-align: center;
  position: relative;
  top: 85px;
  padding: 5px;
  display: none;
}

.objObjetivo:hover .number {
  border: 3px solid #176937;
  background-color: #176937;
  color: #FFF;
}

.objObjetivo:hover .text_objetivo {
  display: inherit;
}

.caretRight {
  display: none;
}



/* // Medium devices (tablets, less than 992px) */
@media (max-width: 1199.99px) {
  .titulo_indices {
    font-size: 45px;
    line-height: 60px;
  }

  .cita {
    font-size: 20px;
  }

  .cita strong {
    font-size: 30px;
  }

  .imagenArbol,
  .fondo_traslucido {
    height: 1100px;
  }

  .objObjetivo {
    width: 145px;
  }
}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.99px) {
  .titulo_indices {
    font-size: 30px;
    line-height: 45px;
  }

  .cita {
    font-size: 25px;
  }

  .cita strong {
    font-size: 35px;
  }

  .imagenArbol,
  .fondo_traslucido {
    height: 1200px;
  }

  .fondoMaria {
    height: 1200px;
  }

  .objObjetivo {
    width: 100%;
    float: inherit;
    min-height: auto;
    text-align: left !important;
  }

  .objObjetivo img {
    width: 200px;
  }

  .number {
    top: 0;
    bottom: 0;
    margin: auto;
    right: inherit;
    left: 170px;

  }

  .sort {
    position: absolute;
    left: 272px;
    right: inherit;
    height: 27px;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .caretBottom {
    display: none;
  }

  .caretRight {
    display: inherit;
  }

  .text_objetivo {
    position: absolute;
    left: 300px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 250px;
    vertical-align: middle;
  }

  .text_objetivo>div {
    vertical-align: middle;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    height: min-content;
  }

  .cuadroBanner,
  .cuadroBanner img {
    width: 300px;
  }

}

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.99px) {
  .margenCuadro {
    margin-top: 0px;
  }

  .imagenArbol,
  .fondo_traslucido {
    height: 1400px;
  }
}

@media (max-width: 689.99px) {

  .objObjetivo {
    min-height: 400px;
    text-align: center !important;
  }

  .objObjetivo img {
    width: 200px;
    height: 200px;
  }

  .number {
    position: relative;
    top: inherit;
    bottom: 20px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;

  }

  .sort {
    top: inherit;
    bottom: 10px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    position: relative;

  }

  .caretBottom {
    display: inherit;
  }

  .caretRight {
    display: none;
  }

  .text_objetivo {
    position: relative;
    left: inherit;
    right: inherit;
    top: inherit;
    bottom: -45px;
    margin: auto;
    width: 100%;
    vertical-align: middle;
  }



}

/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.99px) {

  .imagenArbol,
  .fondo_traslucido {
    height: 1700px;
  }
}