.imgBackground{
    background-size: cover;
    background-repeat: no-repeat;
}

.margin{
    background-color: #092a45;
}



.imgBackground :global(.fondoImagenBack),
.imgBackground :global(.fondoAzul),
.imgBackground :global(.fondoBlanco) {
  background-color: #092a45;
  height: auto;
  min-height: 400px;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 30px;
}

.imgBackground :global(.fondoImagenBack) {
  background-image: url("../../../assets/images/reporteoBack.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 60px;
}
.imgBackground :global(.fondoBlanco) {
  background-color: #fff;
}

.imgBackground ol,
.imgBackground ul {
  list-style: inside;
}

.imgBackground li {
  margin-top: 10px;
}


.imgBackground :global(.hojaBlanca):before,
.imgBackground :global(.hojaAzul):before {
  content: "";
  display: inline-block;
  background-size: 100% 100%;
  width: 60px;
  height: 60px;
  position: relative;
  top: 12px;
}
.imgBackground :global(.hojaAzul):before {
  background-image: url("../../../assets/icons/sustentable/hoja-marino.png");
}
.imgBackground :global(.hojaBlanca):before {
  background-image: url("../../../assets/icons/sustentable/hoja-blanca.png");
}
