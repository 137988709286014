.link {
  color: inherit;
}

.link:hover .rigthIconContainer {
  background-color: rgb(0, 86, 179) !important;
}

.link:hover .infoContainer,
.link:hover .leftIconContainer {
  color: #000000 !important;
}

.link:active .rigthIconContainer {
  background-color: #00aeef !important;
}

.link:active .leftIconContainer {
  color: #00aeef;
}

.link:hover .boxContainer {
  /* background-color:  #EAEDFA !important; */
  border: 1px solid #00aeef;
}

.link:active .boxContainer {
  background: #eaedfa;
}

.boxContainer {
  border: 1px solid #eaedfa;
  border-radius: 4px;
  /* padding: 25px !important; */
  padding-left: 25px !important;
  padding-right: 30px !important;
  height: 120px;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 350px;
  float: left;
  display: table;
}

.infoContainer {
  padding: 0 5px 0 15px;
  height: fit-content;
  min-width: 205px;
}

.vertical_div {
  /* width: auto; */
  display: table;
  height: 100%;
  min-height: 100px;
}

.vertical {
  /* top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    height: fit-content; */
  display: table-cell;
  vertical-align: middle;
  line-height: normal;
}
.vertical2 {
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  height: fit-content;
}

.leftIconContainer i {
  font-size: 50px;
}

.rigthIconContainer {
  background-color: #00aeef !important;
  cursor: pointer !important;
  content: "";
  width: 48px;
  height: 48px;
  border-radius: 100%;
}

.rigthIconContainer i {
  position: relative;
  top: 15px;
  color: #fff;
  font-size: 20px;
}

.title {
  margin-bottom: 0 !important;
  width: 100%;
  max-width: 171px;
  line-height: 19px;
}

.subtitle {
  color: #9095a4;
}

.columna {
  float: left;
  width: fit-content;
}

/* // Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .boxContainer {
    transform: scale(0.7);
    position: relative;
    left: -25%;
  }
}
