.fondo {
	height: 700px;
	width: 100%;
	padding: 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
}

.fondo_blanco {
	background: #fff;
}

.contenedor {
	height: 100%;
	width: 100%;
	padding: 0 !important;
}

.row {
	display: inline-grid;
	position: relative;
}

.vertical {
	top: 0;
	bottom: 0;
	margin-top: auto;
	margin-bottom: auto;
	height: 700px;
}

.poligono {
	position: absolute;
	width: 125%;
	height: 0;
	border-top: 700px solid #00aeef;
	border-bottom: 0px solid #00aeef;
	border-right: 140px solid transparent;
	background: transparent;
	z-index: 0;
}
.contenido_poligono {
	z-index: 1;
	position: absolute;
	height: 100%;
	width: 100%;
}

.tab {
	position: relative;
	float: left;
	height: 100%;
	width: 80px;
	min-height: 700px;
	text-align: center;
	cursor: pointer;
	background: #00aeef;
	z-index: 1;
}
.tab:hover {
	transform: scale(1.1);
	z-index: 2;
}

.tabActivo {
	background: #037097;
}

.contenido_tab {
	position: relative;
	float: none;
	height: 100%;
}

.texto_vertical {
	writing-mode: vertical-lr;
	transform: rotate(180deg);
	text-transform: uppercase;
	position: absolute;
	bottom: 10px;
	width: 57px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
}

.informacion_container {
	position: relative;
	left: -75px;
	width: 100%;
	height: 100%;
}

.informacion_container iframe, .informacion_container .image {
	width: 115%;
	height: 400px;
	position: absolute;
	bottom: 0;
	top: 0;
	margin-top: auto;
	margin-bottom: auto;
}

.informacion_container2 {
	position: relative;
	left: -75px;
	width: 100%;
	height: 100%;
}

.informacion_container2 iframe {
	width: 110%;
	height: 80%;
	position: absolute;
	bottom: 0;
	top: 0;
	margin-top: auto;
	margin-bottom: auto;
}

.div_biblioteca {
	width: 250px;
	min-height: 50px;
	margin-left: 15px;
	margin-bottom: 30px;
	cursor: pointer;
}

.more {
	position: absolute;
	bottom: 5px;
	right: 20px;
	cursor: pointer;
	z-index: 1;
}

.more:hover {
	text-decoration: underline;
}
.more:active {
	text-decoration: none;
	transform: scale(0.9);
}

.scroll {
    margin-top: 30px;
	overflow-y: auto;
	overflow-x: hidden;
	width: 210px;
	height: 660px;
}

.image {
    width: 100% !important;
    background-size: contain;
    background-repeat: no-repeat !important;
    position: absolute !important;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    background-position: center;
}

/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.99px) {
}

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.99px) {
	.scroll {
		width: 90%;
		height: 450px;
	}
	.fondo {
		height: 1250px !important;
	}
	.vertical {
		height: 1100px !important;
	}
	.tab {
		position: relative !important;
		float: left !important;
		height: 25px !important;
		width: 100% !important;
		min-height: 59px !important;
		text-align: center !important;
		cursor: pointer !important;
		z-index: 1 !important;
	}

	.texto_vertical {
		writing-mode: inherit !important;
		transform: inherit !important;
		text-transform: uppercase !important;
		position: absolute !important;
		bottom: 10px !important;
		width: 100% !important;
		left: 0 !important;
		right: 0 !important;
		margin-left: auto !important;
		margin-right: auto !important;
		vertical-align: middle !important;
		height: 39px !important;
	}
	.poligono {
		position: absolute !important;
		width: 100% !important;
		height: 0 !important;
		border-top: 0px solid #00aeef !important;
		border-bottom: 0px solid #00aeef !important;
		border-right: 0px solid transparent !important;
		background: transparent !important;
		z-index: 0 !important;
		background: #00aeef !important;
		height: 1200px !important;
		width: 100% !important;
	}

	.informacion_container {
		position: relative !important;
		left: inherit !important;
		right: 0 !important;
		width: 100% !important;
		height: 0 !important;
		top: 30px !important;
		float: inherit !important;
	}

	.informacion_container iframe, .informacion_container .image {
		width: 90% !important;
		height: 400px !important;
		position: absolute !important;
		bottom: 0 !important;
		top: 0 !important;
		margin-top: auto !important;
		margin-bottom: auto !important;
		margin-right: auto;
		margin-left: auto;
		left: 0;
		right: 0;
	}

	.informacion_container2 {
		position: relative !important;
		left: inherit !important;
		right: 0 !important;
		width: 100% !important;
		height: 0 !important;
		top: -50px !important;
		float: inherit !important;
	}

	.informacion_container2 iframe {
		width: 90% !important;
		height: 400px !important;
		position: absolute !important;
		bottom: 0 !important;
		top: 0 !important;
		margin-top: auto !important;
		margin-bottom: auto !important;
		margin-right: auto;
		margin-left: auto;
		left: 0;
		right: 0;
	}

	.contenido_tab {
		position: relative !important;
		float: left !important;
		height: 70% !important;
		width: 100% !important;
		padding-top: 25px !important;
		left: 25px !important;
	}
	.tamaño_titulos {
		height: 750px;
	}
	.more{
		bottom: -420px !important;
	}
}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.99px) {
	.scroll {
		width: 90%;
		height: 500px;
	}
}

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.99px) {
	.scroll {
		width: 90%;
		height: 520px;
	}

	.fondo {
		height: 800px;
	}
	.vertical {
		height: 650px;
	}
	.tab {
		position: relative;
		float: left;
		height: 25px;
		width: 100%;
		min-height: 59px;
		text-align: center;
		cursor: pointer;
		z-index: 1;
	}

	.texto_vertical {
		writing-mode: inherit;
		transform: inherit;
		text-transform: uppercase;
		position: absolute;
		bottom: 10px;
		width: 100%;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		vertical-align: middle;
		height: 39px;
	}
	.poligono {
		position: absolute;
		width: 100%;
		height: 0;
		border-top: 0px solid #00aeef;
		border-bottom: 0px solid #00aeef;
		border-right: 0px solid transparent;
		background: transparent;
		z-index: 0;
		background: #00aeef;
		height: 800px;
		width: 100%;
	}

	.informacion_container {
		position: relative;
		left: inherit;
		right: 25px;
		width: 50%;
		height: 0;
		top: 105px;
		float: right;
	}

	.informacion_container iframe, .informacion_container .image {
		width: 100%;
		height: 400px;
		position: absolute;
		bottom: 0;
		top: 0;
		margin-top: auto;
		margin-bottom: auto;
	}

	.informacion_container2 {
		position: relative;
		left: inherit;
		right: 25px;
		width: 50%;
		height: 0;
		top: 60px;
		float: right;
	}

	.informacion_container2 iframe {
		width: 100%;
		height: 450px;
		position: absolute;
		bottom: 0;
		top: 0;
		margin-top: auto;
		margin-bottom: auto;
	}

	.contenido_tab {
		position: relative;
		float: left;
		height: 70%;
		width: 44%;
		padding-top: 25px;
		left: 25px;
	}

	.more{
		bottom: -450px;
	}
}
