.title {
  margin-bottom: 57px;
}

.text_container {
  text-align: justify;
}


.main_subtitle h2 {
  display: inline;
  margin-left: 20px;
}

.slash {
  font-size: 30px;
  font-weight: bold;
  color: #00aeef;
}
