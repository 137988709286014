.cartContainer {
  position: sticky;
  top: 135px;
  right: 10px;
  z-index: 4;
}

.cartTextBox {
  position: absolute;
  color: white;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85px;
  top: 70px;
  text-shadow: 1px 1px 5px black;
  padding: 6px 12px;
  background: rgba(77, 171, 233, .8);
  border-radius: 20px;
  cursor: pointer;
}

.cart {
  z-index: -99;
  border-radius: 50%;
  background-color: rgba(205, 205, 205, 0.5);
  position: absolute;
  color: rgba(77, 171, 233, 1);
  font-size: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 65px;
  text-shadow: 1px 1px 5px black;
  box-shadow: 0 0 0 3px rgba(76, 77, 78, 1);
  margin-right: 12px;
}

.cartNumber {
  color: #ef9234;
  font-weight: bold;
  font-size: 23px;
  position: absolute;
  left: 10px;
  right: 0;
  margin: auto;
  top: -4px;
  bottom: 0;
  width: 30px;
  text-align: center;
  height: 30px;
  cursor: pointer;
}

.close {
  cursor: pointer;
  color: #4c4d4e;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 20px;
  z-index: 6;
}

.close:hover {
  transform: scale(1.5);
}

.close:active {
  transform: scale(1);
  cursor: grabbing;
}

.cart:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.cartTextBox:hover ~ .cart {
  transform: scale(1.1);
  cursor: pointer;
}

.cart:active {
  transform: scale(1.2);
  cursor: grabbing;
}

.shopping_cart {
  max-height: 800px;
  position: absolute;
  width: 100%;
  max-width: 450px;
  background: #fff;
  top: 30px;
  margin: 0px 0 0 0;
  float: right;
  border-radius: 6px;
  box-shadow: 0px 0px 16px 5px #b5b9bd24;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.shopping_cart::-webkit-scrollbar {
  width: 4px !important;
  height: 6px;
}
.shopping_cart:after {
  bottom: 100%;
  left: 89%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #fff;
  border-width: 8px;
  margin-left: -8px;
}

.shopping_cart_right,
.cartRight {
  right: -80px;
}

.shopping_cart .productContainer {
  border-bottom: 1px solid #888888;
}

.productContainer,
.checkoutContainer {
  padding: 20px;
}

.deleteIcon {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.deleteIcon:active {
  cursor: grabbing;
}

.deleteIcon:hover {
  color: red;
}
.checkoutContainer {
  padding-right: 20px;
}
.botonCheckout {
  width: 100%;
  text-align: center;
  background-color: #249b00;
  border-radius: 6px;
  cursor: pointer;
}

.botonCheckout:hover {
  transform: scale(1.1);
}
.botonCheckout:active {
  transform: scale(1);
  cursor: grabbing;
}
.disabled {
  background-color: #a3a3a3;
  color: #4c4d4e;
  transform: scale(1) !important;
  cursor: not-allowed !important;
}

.forms {
  display: flex;
  align-items: center;
}

.total {
  position: absolute;
  right: 30px;
}

.cart_content_box {
  min-width: 424px;
  padding: 40px;
  position: relative;
}

.textQty {
  width: 70px;
}
.inputNumber {
  text-align: right;
  width: 55px;
}

.totalProduct {
  margin-left: 10px;
}

.imgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -20px;
}

.cartNumberAnimated {
  animation: count 1500ms 0ms infinite;
}

@keyframes count {
  50% {
    transform: scale(1.2);
  }
}

/* end animation */

/* // big devices (landscape phones, less than 1430px) */
@media (max-width: 1430px) {
  .shopping_cart_right,
  .cartRight {
    right: -20px;
  }
}
/* // medium devices (landscape phones, less than 992px) */
@media (max-width: 992px) {
  .shopping_cart_right,
  .cartRight {
    right: -70px;
  }
}
/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .shopping_cart_right,
  .cartRight {
    right: -20px;
  }
}
/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .shopping_cart_right,
  .cartRight {
    right: -10px;
  }

  .cart_content_box {
    min-width: 100%;
    padding: 0px;
    position: relative;
  }
  .deleteIcon {
    right: -10px;
  }
}
