.footer {
  background-color: #00aeef;
  height: 512px;
  padding: 1.7rem 1.7rem;
}

.footer2 {
  padding: 1.7rem 1.7rem;
  text-align: right;
}

.encabezado_footer {
  border-radius: 4px;
  /*     padding: 24px 21px 22px 26px; */
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
  width: 100%;
}

.titulo_footer {
  border-radius: 4px;
  /* padding: 24px 21px 22px 26px; */
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
  width: 100%;
}
.titulo_body {
  border-radius: 4px;
  /* padding: 24px 21px 22px 26px; */
  color: #ffffff;
  width: 100%;
}

.table_footer {
  width: 100%;
}

.tr_footer {
  border-radius: 4px;
  /* padding: 24px 21px 22px 26px; */
  color: #ffffff;
  width: 100%;
}
.tr_header {
  border-radius: 4px;
  /* padding: 24px 21px 22px 26px; */
  color: #ffffff;
  width: 100%;
}

.tr_header2 {
  border-radius: 4px;
  /* padding: 24px 21px 22px 26px; */
  color: #676f93;
  width: 100%;
  text-align: right;
}

.tr_body {
  border-radius: 4px;
  /* padding: 24px 21px 22px 26px; */
  width: 100%;
  color: #676f93;
}

.tr_bodyBackground {
  border-radius: 4px;
  /* padding: 24px 21px 22px 26px; */
  color: #676f93;
  background-color: #eaedfa;
}

.tr_footer:hover {
  background-color: #3581c2;
}

.tbody_100 {
  width: 100%;
  display: inline-block;
}

.tbody_70 {
  width: 70%;
  display: inline-block;
}

.tbody_30 {
  width: 30%;
  display: inline-block;
}

.tbody_25 {
  width: 25%;
  display: inline-block;
}

.tbody_20 {
  width: 20%;
  display: inline-block;
}

.tbody_15 {
  width: 15%;
  display: inline-block;
}

.tbody_10 {
  width: 10%;
  display: inline-block;
}

.table_wrapper {
  max-height: 500px;
  overflow: auto;
  background-color: #fafbff;
}
.table_wrapper_2 {
  overflow: auto;
  background-color: #fafbff;
}

.table_fixed {
  width: 100%;
  background-color: #eaedfa;
}

.thead tr:nth-child(1) th {
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
}
