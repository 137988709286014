.toggleBoxContainer {
  height: 140px;
}

.toggleBoxContainer {
  margin-bottom: 29px;
  border: 1px solid lightgrey;
  background: #fff;
}

.toggleBoxContainer {
  border-radius: 7px;
}

.toggleButtonContainer {
  border-radius: 4px;
}

.descriptionContainer {
  background-color: #fafbff;
  padding: 10px 15px 5px 50px;
  overflow-y: auto;
}

.descriptionContainer,
.imgContainer,
.titleContainer,
.toggleButtonContainer,
.textContainer {
  height: 137px;
  border-radius: 6px;
}

.imgContainer,
.titleContainer,
.toggleButtonContainer {
  position: relative;
  width: 100%;
  display: table;
  bottom: 138px;
  height: 140px;
}

.arrowContainer {
  position: absolute;
  width: 100%;
  bottom: 277px;
  left: 0px;
  border-radius: 0px 0px 6px 6px;
  background: #00aeef;
}

.divArrowContainer i,
.arrowContainer i {
  color: #fff !important;
}

.divArrowContainer {
  bottom: 137px;
}
.iconClass,
.titleContainer p,
.toggleButtonContainer i {
  display: table-cell;
  vertical-align: middle;
}

.imgClass {
  position: relative;
  top: 35px;
  width: 100%;
  max-width: 72px;
}

.toggleButtonContainer i {
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  left: -35px;
  position: relative;
}

.toggleButtonContainer .fondoIcono {
  cursor: pointer;
  font-size: 20px;
  color: #00aeef;
  left: 37px;
  top: 59.5px;
  border-radius: 50%;
  position: absolute;
  height: 20.5px;
  width: 20.5px;
  background: #00aeef;
  border: 1px solid #bdb2b2;
}

.toggleButtonBkgVerde .fondoIcono {
  color: #176937;
  background: #176937;
}

.imgContainer i {
  font-size: 50px;
}

.toggleButtonBkg {
  background-image: url("../../../assets/icons/bkgtb.svg");
  background-repeat: no-repeat;
}
.toggleButtonBkgVerde {
  background-image: url("../../../assets/icons/bkgtbVerde.svg");
  background-repeat: no-repeat;
}

.terminoSubtitle {
  margin-left: 20px;
}

.showInfo {
  opacity: 1;
  transition: all 1s ease-out;
}

.hideInfo {
  opacity: 0;
  transition: all 0.5s ease-out;
  visibility: hidden !important;
  /* display: contents; */
  /* display: none; */
}

.moveLeftButton {
  transform: translateX(-62rem);
  transition: all 0.5s ease-out;
}

.moveLeftButtonTwo {
  transform: translateX(-864px);
  transition: all 0.5s ease-out;
}

.moveRigthButton {
  transform: translateX(70px);
  transition: all 0.5s ease-in;
}
.padding_r {
  padding-right: 15px !important;
}

/* // Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .padding_r {
    padding-right: 5px !important;
    padding-left: 35px !important;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
  .titleContainer p {
    font-size: 16px !important;
    line-height: 23px !important;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (max-width: 992px) {
  .hideInfoRow {
    display: none;
  }

  .moveLeftButton {
    transform: translateX(-545px) !important;
    transition: all 0.5s ease-out;
  }
  .moveRigthButton {
    transform: translateX(2px);
    transition: all 0.5s ease-in;
  }

  .moveRigthButton {
    transform: translateX(0);
    transition: all 0.5s ease-in;
  }

  .descriptionContainer {
    padding: 10px 8px 50px 8px;
  }

  .imgContainer img {
    width: 50px;
    height: 50px;
  }

  .titleContainer p {
    font-size: 20px;
    /* font-weight: bold; */
  }
}

@media (max-width: 1292px) {
  .moveLeftButton {
    transform: translateX(-60rem);
    transition: all 0.5s ease-out;
  }
}

@media (max-width: 1255px) {
  .moveLeftButton {
    transform: translateX(-58rem);
    transition: all 0.5s ease-out;
  }
  .toggleButtonContainer i {
    cursor: pointer;
    font-size: 20px;
    color: #fff;
    left: -30px;
    position: relative;
  }
}
@media (max-width: 1248px) {
  .moveLeftButton {
    transform: translateX(-57.7rem);
    transition: all 0.5s ease-out;
  }

  /* // Extra large devices (large desktops, 1200px and up) */
  @media (max-width: 1200px) {
    .moveLeftButton {
      transform: translateX(-45rem);
      transition: all 0.5s ease-out;
    }
    .moveRigthButton {
      transform: translateX(18px);
      transition: all 0.5s ease-in;
    }
    .moveLeftButtonTwo {
      transform: translateX(-724px) !important;
    }

    .toggleButtonContainer i {
      cursor: pointer;
      font-size: 20px;
      color: #fff;
      left: -10px !important;
      position: relative;
    }
  }
}
