.main_title {
  font-size: 42px;
}

.main_subtitle {
  font-size: 30px;
  margin: 20px 0 40px 0;
}

.main_subtitle h2 {
  display: inline;
  margin-left: 20px;
}

.slash {
  font-size: 30px;
  font-weight: bold;
  color: #00aeef;
}

.text_container {
  text-align: justify;
}

.imgContainer {
  margin-top: 30px;
}

.fondo {
  background: #fafbff;
  padding-top: 30px;
  z-index: 0;
  margin-top: 5px;
}

.containerDiv {
  width: 100%;
  position: relative;
  cursor: pointer;
}
.containerDiv:hover {
  transform: scale(1.1);
  background: #ffffff;
  z-index: 1;
}

.container {
  margin-bottom: 15px;
}

.thumbanil img {
  width: 100%;
  height: auto;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #2798b0;
  background-image: url("../../../assets/images/fondoBiva.png");
}
.thumbanil div {
  width: 100%;
  height: 185px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #2798b0;
  background-image: url("../../../assets/images/fondoBiva.png");
}


/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .thumbanil div {
    height: 190px;
  }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.99px) {
  .thumbanil div {
    height: 118px;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.99px) {
  .thumbanil div {
    height: 107px;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.99px) {
  .thumbanil div {
    height: 152px;
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* Fin Responsivo */
