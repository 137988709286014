.btn{
    /* position: fixed; */
    right: 20px;
    bottom: 20px;
    z-index: 1020;
}

.btn-desaparece{
    display: none;
}

.icono{
    cursor: pointer;
    font-size: 50px;
    margin-right: auto;
    margin-left: auto;
    left: 0;
    right: 0;
    width: fit-content;
    cursor: pointer !important;

}

.btn:hover .icono{
    transform: scale(0.75);
}
.btn:active .icono{
    transform: scale(0.9);
}
