.contenedorTexto strong{
    color: #00aeef;
}

.fondoIndice{
    width: 100%;
}

.backGroundIndex { 
    background-color: #176937;
    padding-top: 60px;
    padding-bottom: 60px;
}


.sg a {
    color: #176937 !important;
    font-weight: bold;
}