.radio, .radio2{
    display: inline-block !important;
}

.radio label, .radio2 label{
    margin-left: 10px;
    top: 10px;
    position: relative;
}

.radio2{
    padding-left: 0px;
}

.imgCheck{
margin-right: 5px;
position: relative;
bottom: 1.5px;
}

.textoCheck{
    white-space: nowrap;
}

.checkCuadro{
    margin-top: 10px;
}