
.pagination {
  display: flex;
  list-style: none;
  height: 20px;
  margin-bottom: 10px;
  z-index: 3;
  position: relative;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  width: 350px;
  bottom: 0px;
  padding-inline-start: 0px;
  margin-top: 20px;
  font-family: 'Montserrat', sans-serif !important;
}
.pagination_circle{
  width: fit-content !important;
  width: -moz-fit-content !important;
}
.pagination :global(li:first-child) {
  border-left: 0px solid #fff;
  padding-top: 0px !important;
}

.pagination_inactivo {
  margin: 0px 12px 0px 12px;
}

.pagination_inactivo a {
  background: #00aeef;
  border-radius: 64px;
  font-size: 0px;
  width: 5px;
  height: 5px;
  border: 0px solid;
  padding: 4px;
  line-height: 44px;
  top: 10px;
  position: relative;
  height: 56px;
  width: 56px;
}

.pagination_activa {
  display: inline;
}

.pagination_inactivo .activo {
  z-index: 3 !important;
  color: #fff !important;
  background-color: #00aeef !important;
  border-color: #00aeef !important;
  cursor: default !important;
  /* margin-right: 10px !important; */
  /* margin-left: 10px !important; */
  position: relative;
  /* top: 10px !important; */
  border-radius: 5000px !important;
  padding: 12px;
  font-size: 16px !important;
  top: 9px;
  padding: 0px 0px 0px 0px;
  margin-left: 10px;
  margin-right: 10px;
}

.pagination_circle .pagination_inactivo .activo{
  margin-left: 7px;
  margin-right: 7px;
}

.pagination_circle .pagination_inactivo{
  margin: 0px 10px 0px 10px !important;
}

.pagination_circle .siguiente, .pagination_circle .anterior{
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.pagination_inactivo .activo:before {
  color: #fff !important;
  background-color: #00aeef !important;
  border-color: #00aeef !important;
  cursor: default !important;
  content: "";
  position: absolute;
  z-index: -1;
  width: 50px;
  height: 50px;
  left: 50%;
  margin-left: -24px;
  border-radius: 100%;
  transition: all 0.2s;
  top: -15px;
}
.anterior a,
.siguiente a {
  background: none;
  border-radius: inherit;
  margin: inherit;
  font-size: 18px;
  width: 5px;
  height: 5px;
  border: 0px solid;
  padding: inherit;
  line-height: 44px;
  top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  color: #00aeef;
}

.disable a i {
  color: #bdbdbf !important;
  cursor: no-drop;
}



/* tipo2 */


.tipo2 .pagination {
  display: flex;
  list-style: none;
  height: 60px;
  margin-bottom: 10px;
  z-index: 3;
  position: relative;
  left:  inherit !important;
  right: inherit !important;
  margin-right: inherit !important;
  margin-left: inherit !important;
  width: fit-content !important;
  width: -moz-fit-content !important;
  bottom: 0px;
  padding-inline-start: 0px;
  margin-top: 20px;
  float: right;
  font-family: 'Montserrat', sans-serif !important;
}
.tipo2 .pagination :global(li:first-child) {
  border-left: 0px solid #fff;
  padding-top: 0px !important;
}

.tipo2 .pagination_inactivo {
  margin: 0px 12px 0px 12px;
}

.tipo2 .pagination_inactivo a {
  background: inherit;
  border-radius: 6px;
  font-size: 16px;
  width: 50px !important;
  height: 50px !important;
  border: 0px solid;
  padding: inherit !important;
  line-height: inherit !important;
  top: 10px;
  position: relative;
  height: 56px;
  width: 56px;
  color: #00aeef !important;
}


.tipo2 .pagination_activa {
  display: inline;
}

.tipo2 .pagination_inactivo .activo {
  z-index: 3 !important;
  color: #fff !important;
  background-color: #00aeef !important;
  border-color: #00aeef !important;
  cursor: default !important;
  /* margin-right: 10px !important; */
  /* margin-left: 10px !important; */
  position: relative;
  /* top: 10px !important; */
  border-radius: 6px !important;
  padding: 12px;
  font-size: 16px !important;
  top: 9px;
  padding: 0px 0px 0px 0px;
  margin-left: 0px;
  margin-right: 0px;
}


.tipo2 .pagination_inactivo  {
  z-index: 3 !important;
  color: #00aeef !important;
  background-color: inherit !important;
  cursor: default !important;
  /* margin-right: 10px !important; */
  /* margin-left: 10px !important; */
  position: relative;
  /* top: 10px !important; */
  border-radius: 6px !important;
  padding: 12px;
  font-size: 16px !important;
  top: 9px;
  padding: 0px 0px 0px 0px;
  margin-left: 15px;
  margin-right: 30px;
}


.tipo2 .pagination_inactivo:before {
  color: #fff !important;
  background-color: transparent !important;
  border: 2px solid #EAEDFA !important;
  cursor: default !important;
  content: "";
  position: absolute;
  z-index: -1;
  width: 50px;
  height: 50px;
  left: 45%;
  margin-left: -24px;
  border-radius: 6px;
  transition: all 0.2s;
  top: -5px;
}

.tipo2 .pagination_inactivo .activo:before {
  color: #fff !important;
  background-color: #00aeef !important;
  border-color: #00aeef !important;
  cursor: default !important;
  content: "";
  position: absolute;
  z-index: -1;
  width: 50px;
  height: 50px;
  left: 40%;
  margin-left: -24px;
  border-radius: 6px;
  transition: all 0.2s;
  top: -15px;
}




.tipo2 .anterior:before,  .tipo2 .siguiente:before, .tipo2 .last:before, .tipo2  .first:before {
  color: inherit !important;
  background-color: inherit !important;
  border: 0px solid #00aeef;
  cursor: default !important;
  content: "";
  position: absolute;
  z-index: -1;
  width: inherit;
  height: inherit;
  left: inherit;
  margin-left: inherit;
  border-radius: inherit;
  transition: inherit;
  top: inherit;
}

.tipo2 .anterior, .tipo2 .siguiente, .tipo2 .first, .tipo2 .last {
  background: inherit !important;
  color: #00aeef !important;
  margin-right: 10px !important;

}

.tipo2 .anterior a,
.tipo2 .siguiente a, .tipo2 .first a,
.tipo2 .last a {
  background: none !important;
  border-radius: inherit;
  margin: inherit;
  font-size: 18px;
  width: 5px;
  height: 5px;
  border: 0px solid;
  padding: inherit;
  line-height: 44px;
  top: 7.5px;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  color: #00aeef !important;
}

.tipo2 .anterior a, .tipo2 .siguiente a{
  font-size: 29px !important;
  top: 0px !important;

}

.tipo2 .disable a i {
  color: #EAEDFA !important;
  cursor: no-drop;
}

.tipo2 .pagination_inactivo:first-child{
  margin-right: 0px !important;

}
.tipo2 .anterior {
  margin-left: 5px !important;
  margin-right: 15px !important;

}
.tipo2 .siguiente {
  margin-left: 15px !important;
  margin-right: 5px !important;

}
.tipo2 .pagination_inactivo:nth-last-child(3){
  margin-right: 15px !important;

}
.tipo2 .pagination_activa:nth-last-child(3){
  margin-right: 15px !important;

}

.tipo2 .pagination_inactivo:last-child{
  margin-left: 0px !important;

}

.tipo2 .pagination_inactivo:hover:before, .tipo2 .pagination_inactivo:hover a{
  background: #bdbdbf !important;
  color: #fff !important;
  border: 0px;
}
.tipo2 .anterior:hover a, .tipo2 .siguiente:hover a, .tipo2 .first:hover a, .tipo2 .last:hover a{
  background: inherit !important;
  color: #00aeef !important;
  border: 0px;
}

.tipo2 .pagination_activa:hover a{
  background: inherit !important;
  color: #fff !important;
  border: 2px;

}
.tipo2 .last, .tipo2  .first{
  border: 0px solid #fff;

}

.tipo2 .last:before, .tipo2  .first:before{
  border: 0px solid #fff;

}

.sustentable .pagination .pagination_inactivo a {
color: #176937 !important;
}

.sustentable .pagination .pagination_inactivo .activo {
  color: #fff !important;
  background-color: #176937 !important;
}

.sustentable .pagination .activo::before{
  background-color: #176937 !important;
}