.firstSectionFilterContainer {
  border: 1px solid #eaedfa;
  border-radius: 4px;
  padding: 0;
}

.noBorderRadius {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.toogleButtonContainer {
  width: 100%;
  background-color: #fafbff;
  border-right: 1px solid #eaedfa;
  border-radius: 4px 0 0 4px;
}

.toogleButtonContainer button {
  width: 100% !important;
  color: #00aeef;
  outline: none;
  box-shadow: none !important;
  height: 65px;
}

.toogleButtonContainer button i {
  margin-right: 10px;
}

.secondSectionFilterContainer {
  border-bottom: 1px solid #eaedfa;
  border-left: 1px solid #eaedfa;
  border-right: 1px solid #eaedfa;
  border-radius: 0 0 4px 4px;
  background-color: #fafbff;
  padding: 10px 0;
}

.filterSections {
  padding: 20px 0;
}

.fechaInicio,
.fechaFin {
  outline: none;
  box-shadow: none !important;
  border: 1px solid #eaedfa;
  border-radius: 4px;
  padding: 6px;
}

.fechaInicio {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.fechaFin {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

input[type="date"] {
  width: 100%;
}

.buttonModel {
  border: 1px solid #eaedfa;
  color: #00aeef;
  width: 90% !important;
  margin: 0 auto;
}

.buttonModel i {
  margin-right: 10px;
}

.hidden {
  display: none;
}

.buttonModel {
  border: 1px solid #eaedfa;
  color: #00aeef;
  width: 90% !important;
  margin: 0 auto;
}

.buttonModel i {
  margin-right: 10px;
}

.filterContainer {
  padding: 8px;
  margin-right: 5px;
  border: 1px solid #eaedfa;
  border-radius: 50px;
}

.filterContainer span {
  color: #00aeef;
}

.filterIconButton {
  margin-left: 10px;
  cursor: pointer;
}

.filterSelectedContainer,
.filterContainerOutside {
  height: 65px;
}

.filterContainerOutside {
  width: 100%;
  overflow-y: none !important;
  overflow-x: auto;
  white-space: nowrap;
  color: #00aeef;
  padding: 19px 0 0 15px;
}

.errorContainer p {
  color: #e22929;
}

.myLabel {
  display: block;
}

.formCheckInput {
  position: relative;
  top: 13px;
  left: 18px;
  margin-right: 25px;
}

.model_list li {
  cursor: pointer;
}

.no_col_padding {
  padding: 0;
}

.no_margin {
  margin: 0;
}

.paddingLeft15 {
  padding-left: 15px;
}

.leftCheck label {
  margin-bottom: 0px !important;
  cursor: pointer !important;
}
