.inputText {
  height: 50px;
  border: 3px solid #00aeef;
}

.textura {
  height: unset;
  height: -moz-max-content;
  background-size: cover;
  background: #4b4e53;
  position: absolute;
  width: 100%;
  z-index: 1;
  padding-top: 0;
  display: inline-block;
  position: fixed;
  width: 100%;
  height: 100%;
  background-size: 50%;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 100%;
  z-index: 1021;
  top: 125px;
}

.searchContainer {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #4b4e53f2;
  top: 0;
  z-index: 1021;
  overflow: auto;
  top: 125px;
}

.inputContainerSearch {
  padding-left: 20%;
  padding-right: 20%;
  position: relative;
  top: 20%;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
}

.moveInput {
  top: 7%;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
}

.resultContainer {
  position: relative;
  top: 12%;
  margin-bottom: 290px;
}

.close {
  position: fixed;
  right: 25px;
  top: 150px;
  cursor: pointer;
  z-index: 1022;
}

.close i {
  font-size: 30px;
  color: #fff;
}

.close i:hover {
  transform: scale(1.5);
}
.close i:active {
  transform: scale(1);
}

.clear {
  position: absolute;
  right: 20%;
  cursor: pointer;
}

.clear:hover {
  text-decoration: underline;
  transform: scale(1.2);
}

.clear:active {
  text-decoration: underline;
  transform: scale(1);
}

.botones {
  width: fit-content;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.botones span {
  position: absolute;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  width: min-content;
}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .textura {
    background-size: 70%;
  }
}

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .textura {
    background-size: 80%;
  }
  .botones {
    width: 283px;
  }
}

/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .textura {
    background-size: 85%;
  }
}
