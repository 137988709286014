.contenedor_principal{
    position: relative;
    width: 100%;
}
.boxContainer{
    border: 1px solid #EAEDFA;
    border-radius: 6px;
    background: #FFFFFF;
    width: 320px;
    height: 584px;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 10px;
    padding-top: 30px;
    position: relative;
    float: left;
}

.boxContainer2{
    border: 1px solid #EAEDFA;
    border-radius: 6px;
    background: #FFFFFF;
    width: 320px;
    height: 450px;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 10px;
    padding-top: 30px;
    position: relative;
    float: left;
}

.boxContainer3{
    border: 1px solid #EAEDFA;
    border-radius: 6px;
    background: #FFFFFF;
    width: 320px;
    height: 530px;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 10px;
    padding-top: 30px;
    position: relative;
    float: left;
}

.boxContainer4{
    border: 1px solid #EAEDFA;
    border-radius: 6px;
    background: #FFFFFF;
    width: 320px;
    height: 200px;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 10px;
    padding-top: 30px;
    position: relative;
    float: left;
}

.boxContainer5{
    border: 1px solid #EAEDFA;
    border-radius: 6px;
    background: #FFFFFF;
    width: 320px;
    height: 280px;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 10px;
    padding-top: 30px;
    position: relative;
    float: left;
}

.boxContainerIndice{
    border: 1px solid #EAEDFA;
    border-radius: 6px;
    background: #FFFFFF;
    width: 347px;
    height: 455px;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 10px;
    padding-left: 20px;
    padding-top: 30px;
    position: relative;
    float: left;
}

.boxContainer8{
    border: 1px solid #EAEDFA;
    border-radius: 6px;
    background: #FFFFFF;
    width: 320px;
    height: 775px;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 10px;
    padding-top: 30px;
    position: relative;
    float: left;
}

.titulo_grafica{
    z-index: 1;
}

.separacion_titulo{
    margin-bottom: 10px;
    white-space: nowrap;
}

.separacion{
    margin-bottom: 20px;
}

.descarga{
    width: 100%;
    height: 96px;
    background: #FAFBFF;
    border: 1px solid #EAEDFA;
    border-top: 3px solid #00AEEF;
    position: absolute;
    bottom: 0;
    left: 0px;
    border-radius: 0px 0px 6px 6px;
    cursor: pointer;
}

.descarga2{
    width: 100%;
    height: 48px;
    background: #FAFBFF;
    border: 1px solid #EAEDFA;
    border-top: 3px solid #00AEEF;
    position: absolute;
    bottom: 0;
    left: 0px;
    border-radius: 0px 0px 6px 6px;
    cursor: pointer;
}


.descarga:hover{
    transform: scale(1.03);
}
.descarga:active{
    transform: inherit;
}
.descarga2:hover{
    transform: scale(1.03);
}
.descarga2:active{
    transform: inherit;
}

.vertical_div{
    width: 100%;
    display: table;
  height: 100%;
  min-height: 100px;
}

.vertical{
    display: table-cell;
    vertical-align: middle;
    line-height: normal;
    padding: 10px;
}

.texto_descarga{
    width: 247px;
    float: left;
}
.boton_descarga{
    float: left;
}
.boton_descarga i:before{
    color: #00AEEF;
    font-size: 48px;
    position: relative;
    top: 10px;
}

.charts{
    position: absolute;
    bottom: 0px;
}


.buttonContainer{
    padding: 10px 10px 0 25px;
}

.buttonContainer{
    height: 48px;
    width: 100%;
}

.buttonContainer{
    color: #00aeef;
}

.buttonContainer a{
    color: #00aeef;
    text-decoration: none;
}

.buttonContainer a,
.buttonContainer span{
    height: 60px !important;
    width: 100%;
}

.buttonContainer span:hover{
    cursor: pointer;
}

.buttonContainer span i{
    margin-left: 10px;
    font-size: 14px;
}