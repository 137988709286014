.imagen {
  width: 100%;
  border: 2px solid #EAEDFA;

}

.titulo_div {
  position: relative;
  margin-top: 30px;
}

.slash{
    margin-right: 30px;
}

.descarga_div{
    margin-top: 80px;
    width: 320px;
    height: 120px;
    border: 1px solid #EAEDFA;
    padding-left: 26.14px;
    padding-right: 24px;
    padding-top: 33px;
    padding-bottom: 33px;
    border-radius: 6px;
    cursor: pointer;
}

.icono{
    font-size: 48px;
    color: #00aeef;
}

.icono2{
    font-size: 40px;
    color: #00aeef;
}

.icono_div{
    float: left;
}

.descarga_div_text{
    float: left;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 10px;
    padding-left: 5px;
}

.icono_download{
    padding-top: 5px;
float: right;
}


.regresar_div{
    width: 270px;
    height: 64px;
    background: #FAFBFF;
    border: 1px solid #EAEDFA;
    float: right;
    margin-top: 40px;
    margin-bottom: 25px;

    padding-top: 12px;
    padding-left: 5px;
    border-radius: 6px;

}

.icono_regresar_div{
    float: left;
    margin-right: 15px;
}
.texto_regresar_div{
    padding-top: 10px;
}

.centrar_div{
    width: 225px;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: auto;

}

.div_contenido{
    margin-bottom: 30px;
}

.div_contenido p{
    margin-top: 10px;
}
