.relativo {
  position: relative;
}
.margenImagenes{
  margin-right: 10px;
  margin-bottom: 10px;
}
.imagenes {
  width: 169px;
  height: 169px;
  padding-right: 25px;
  float: left;
  position: relative;
}

.imagenes a{
  height: 100%;
  display: table;
  position: relative;
}

.imagenes a .image{
  vertical-align: middle;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.imagenes_2 {
  width: 200px !important;
  height: auto !important;
  float: left;
  position: relative;
  margin-right: 30px;
  margin-bottom: 30px;
  text-align: center;
}

.videos {
  width: 200px;
  height: max-content;
  min-height: 292px;
  padding-bottom: 19px;
  float: left;
  position: relative;
  display: table-cell;
}

.video {
  width: 181px !important;
  height: 160px;
  float: left;
  position: relative;
}

.imagenes .image,
.videos .image, .imagenes_2 .image{
  display: grid;
}
.image {
  width: 169px;
  height: 169px;
  background-size: cover;
  background-repeat: no-repeat;
}
.imagenes_2 .image {
  width: 190px !important;
  height: auto !important;

}

.image_gris{
  /* filter: grayscale(1); */
}
.image {
  opacity: 1;
  display: block;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  background: #00aeef;
}

.middle_video{
  top: 77px !important;
}
.imagenes:hover .middle {
  opacity: 1;
  width: 169px;
  left: 84px;
  height: 100%;
  cursor: pointer;
  background: #001f2bb3;
}

.imagenes_2:hover .middle {
  opacity: 1;
  width: 156px;
  left: 79px;
  height: 96%;
  cursor: pointer;
  background: #001f2bb3;
}
.videos:hover .middle {
  opacity: 1;
  width: 181px;
  left: 90px;
  height: 155px;
  top: 77px;
  cursor: pointer;
  background: #001f2bb3;
}

.icono {
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  z-index: 1000;
  color: #ffffff;
}

.icon:before {
  color: #00aeef !important;
  background-color: #ffffff !important;
  border: 2px solid #00aeef !important;
  cursor: default !important;
  content: "";
  position: relative;
  z-index: 19;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  transition: all 0.2s;
  padding: 10px;
}


.titulo_video{
  width: 181px;
}

.noHover{
  display: none;
}
.imagenes_pequeñas{
  width: 125px !important;
  height: auto !important;
}

.link:hover .image{
  filter: none;
  transform: scale(1.3);
}





/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.99px) {
  .relativo {
    display: contents;
  }
}

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.99px) {
}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.99px) {

}
@media (min-width: 992px) and (max-width: 1199.99px) {

}

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.99px) {
}
