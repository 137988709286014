.card {
	width: 363.333px !important;
	height: 331px !important;
	margin-left: 5px;
	margin-right: 5px;
	margin-top: 4px;
	border-radius: 6px;
	border: 0px;
	position: relative;
}
.card:hover{
    transform: scale(1.02);
}
.fondoDifuminado {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 0;
}

.texto_titulo{
    color: #FFF;
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto !important;
    height: 15%;
}

.imagen {
	width: 100%;
	max-width: 200px;
	margin-top: -21px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
}

.boton {
	background: #00aeef !important;
}

.center {
	right: 0;
	left: 0;
	margin-left: auto;
	margin-right: auto;
	width: fit-content;
	width: -moz-fit-content;
}

.center button {
	margin-right: 0px !important;
}

.ribbon {
	position: absolute;
	left: -5px;
	top: -5px;
	z-index: 1;
	overflow: hidden;
	width: 75px;
	height: 75px;
	text-align: right;
}

.ribbon span {
	font-size: 10px;
	font-weight: bold;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	line-height: 20px;
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	width: 104px;
	display: block;
	background: #00aeef;
	box-shadow: 0 3px 5px -5px rgba(0, 0, 0, 1);
	position: absolute;
	top: 20px;
	left: -22px;
}
.ribbon  span::before {
	content: '';
	position: absolute;
	left: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid #002e72;
	border-right: 3px solid transparent;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #002e72;
}
.ribbon  span::after {
	content: '';
	position: absolute;
	right: 0px;
	top: 100%;
	z-index: -1;
	border-left: 3px solid transparent;
	border-right: 3px solid #002e72;
	border-bottom: 3px solid transparent;
	border-top: 3px solid #002e72;
}

.contenedor_botones {
	width: -moz-fit-content;
	width: fit-content;
	min-width: 609px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	bottom: 0;
}
.botonMas{
	position: absolute;
	bottom: 10px;
}

.imagenCard{
	position: absolute;
	bottom: 85px;
	width: 324px;
}
.imagenModal{
	max-width: 350px;
}

.sedeCard{
	position: absolute;
	bottom: 160px;
	width: 324px;
}

.contenedorSlider :global(.slick-track){
	left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}


/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.99px) {
	.contenedor_botones {
		min-width: fit-content !important;
		min-width: -moz-fit-content !important;
	}
	.contenedor_botones button {
		width: 188px !important;
		min-width: 188px !important;
		margin-bottom: 0;
		margin-top: 15px;
		float: none;
	}
}

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.99px) {
	.contenedor_botones {
		min-width: fit-content !important;
		min-width: -moz-fit-content !important;
	}
	.contenedor_botones button {
		width: 188px !important;
		min-width: 188px !important;
		margin-bottom: 0;
		margin-top: 15px;
		float: none;
	}
}

/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.99px) {
	.contenedor_botones {
		min-width: fit-content !important;
		min-width: -moz-fit-content !important;
	}
	.contenedor_botones button {
		width: 188px !important;
		min-width: 188px !important;
		margin-bottom: 0;
		margin-top: 15px;
		float: none;
	}
}

/* // Large devices (desktops, less than 1200px) */
@media (min-width: 992px) and (max-width: 1199.99px) {
	.contenedor_botones button {
		width: 188px !important;
		min-width: 188px !important;
		margin-bottom: 0;
		margin-top: 15px;
	}
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	.contenedor_botones button {
		width: 188px !important;
		min-width: 188px !important;
		margin-bottom: 0;
		margin-top: 15px;
	}
}
