.my_btn {
	background: #00aeef;
	color: #ffffff !important;
	border: 1px solid #00aeef;
	border-radius: 24px;
	height: 48px;
	min-width: 268px;
	width: fit-content;
	width: -moz-fit-content;
	font-size: 16px;
	line-height: 16.69px;
	padding-left: 18px;
	padding-right: 15px;
	/* padding-top: 8px; */
	font-family: 'Nunito', sans-serif;
	font-style: normal;
	font-weight: bold;
	white-space: nowrap;
	text-align: left;
	margin-bottom: 15px;
	margin-right: 15px;
	float: left;
	outline: none;
}
.my_btn:focus {
	outline: none;
}
.my_btn .icono_derecho {
	float: right;
	font-size: 23px;
	position: relative;
	bottom: 0px;
	color: #ffffff;
	font-family: 'Font Awesome 5 Free' !important;
}

.my_btn .icono_izquierdo {
	float: left;
	margin-right: 10px;
	font-size: 23px;
	position: relative;
	bottom: 2px;
	color: #ffffff;
	font-family: 'Font Awesome 5 Free' !important;
}

.botonRegresar .icono_izquierdo {
	margin-right: 20px;
}

.botonRegresar span {
	margin-right: 20px;
	float: right;
}

.my_btn span {
	color: #ffffff !important;
}

.btn_blanco {
	background: #ffffff;
}

.btn_blanco .icono_derecho,
.btn_blanco .icono_izquierdo,
.btn_blanco span {
	color: #00aeef !important;
}


.my_btn:hover {
	background: #ffffff;
}
.my_btn:hover span,
.my_btn:hover i {
	color: #00aeef !important;
}

.my_btn:active {
	background: #00aeef;
}

.my_btn:active span,
.my_btn:active i {
	color: #ffffff !important;
}

.btn_blanco:hover {
	background: #00aeef;
}

.btn_blanco:hover .icono_derecho,
.btn_blanco:hover .icono_izquierdo,
.btn_blanco:hover span {
	color: #ffffff !important;
}

.btn_blanco:active {
	background: #ffffff;
}

.btn_blanco:active .icono_derecho,
.btn_blanco:active .icono_izquierdo,
.btn_blanco:active span {
	color: #00aeef !important;
}


/* botones verdes */

.btn_verde {
	background: #176937;
	border: 1px solid #176937;
}

.btn_verde .icono_derecho,
.btn_verde .icono_izquierdo,
.btn_verde span {
	color: #fff !important;
}



.btn_verde:hover {
	background: #fff;
}

.btn_verde:hover .icono_derecho,
.btn_verde:hover .icono_izquierdo,
.btn_verde:hover span {
	color: #176937 !important;
}

.btn_verde:active {
	background: #ffffff;
}

.btn_verde:active .icono_derecho,
.btn_verde:active .icono_izquierdo,
.btn_verde:active span {
	color: #6fc08e !important;
}


/* end botones verdes */

/* botones blancos con verde */

.btn_blanco_verde {
	background: #fff;
	border: 1px solid #176937;
}

.btn_blanco_verde .icono_derecho,
.btn_blanco_verde .icono_izquierdo,
.btn_blanco_verde span {
	color: #176937 !important;
}



.btn_blanco_verde:hover {
	background: #176937;
}

.btn_blanco_verde:hover .icono_derecho,
.btn_blanco_verde:hover .icono_izquierdo,
.btn_blanco_verde:hover span {
	color: #fff !important;
}

.btn_blanco_verde:active {
	background: #176937;
}

.btn_blanco_verde:active .icono_derecho,
.btn_blanco_verde:active .icono_izquierdo,
.btn_blanco_verde:active span {
	color: #6fc08e !important;
}


/* end botones blanco con verde */



.btnConocerEmisoras {
	position: relative;
	bottom: 4px;
}

.vertical_div {
	display: inline-grid;
}

.vertical {
	display: table-cell;
	vertical-align: middle;
	line-height: normal;
	top: 0;
	bottom: 0;
	margin-top: auto;
	margin-bottom: auto;
	position: relative
}
