.fondo {
    height: 100%;
    width: 100%;
    position: fixed;
    background: transparent !important;
    top: 0;
    left: 0;
    z-index: 10000;
    background-size: 70px;
  }
  .fondo:before{
      position: relative;
      height: 100%;
      width: 100%;
      content: ' ';
      position: absolute;
      left: 0;
    }
    .centrado {
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        z-index: 10001;
        width: 50%;
        position: absolute;
      }
    
  .img{
    z-index: 10001;
    width: 200px;
  
  }
  .centrado_img {
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
  }
  
  .vertical_div{
      display: table;
  }
  
  .vertical{
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
      height: fit-content;
  }
  
  /* // Extra small devices (portrait phones, less than 576px) */
  @media (max-width: 575.98px) { 
    
        .centrado{
            width: 80%;
        }
  
        .img{
            width: 100%;
        }
        .centrado h2{
            font-size:20px;
        }
        .centrado h3{
          font-size:15px;
      }
  }
  
  /* // Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) and (max-width: 767.98px) {
    
        .centrado{
            width: 80%;
        }
        .img{
          width: 100%;
      }
      .centrado h2{
          font-size:25px;
      }
      .centrado h3{
        font-size:20px;
    }
   }
  
  /* // Medium devices (tablets, 768px and up) */
  @media (min-width: 768px) and (max-width: 991.98px) {
     
        .centrado{
            width: 80%;
        }
  }
  
  /* // Large devices (desktops, 992px and up) */
  @media (min-width: 992px) and (max-width: 1199.98px) { 
     
        .centrado{
            width: 80%;
        }
  }
  
  /* // Extra large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) { 

        .centrado{
            width: 80%;
        }
  }