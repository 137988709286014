.recuadro {
  width: 200px;
  height: 100px;
  background-color: #4472c4;
  position: relative;
  text-align: center;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textContainer {
  position: relative;
  display: inline-block;
  transform-origin: center;
}
.containerRecuadro {
  padding-top: 100px;
  position: relative;
  text-align: left;
}
.containerRecuadro img {
  width: 100%;
}
.cuadro1 {
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  margin: auto;
}
.cuadro2 {
  position: absolute;
  top: 220px;
  left: inherit;
  right: -23px;
  margin: auto;
}
.cuadro3 {
  position: absolute;
  top: 450px;
  left: inherit;
  right: -23px;
  margin: auto;
}
.cuadro4 {
  position: absolute;
  top: 600px;
  left: 0;
  right: 0;
  margin: auto;
}
.cuadro5 {
  position: absolute;
  top: 450px;
  left: inherit;
  left: -23px;
  margin: auto;
}
.cuadro6 {
  position: absolute;
  top: 220px;
  left: -23px;
  margin: auto;
}

.textoRecuadro {
  display: inline-block;
  vertical-align: middle;
}

.padding {
  width: 100%;
}

.contenedorTexto {
  width: 100%;
  position: relative !important;
  height: 100%;
}

.contenedorTexto > .texto {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: min-content;
  width: 80%;
}

.textos_html ol,
.textos_html ul {
  list-style: inherit;
  margin-top: 10px;
  padding-left: 35px;
}

.textos_html ul li {
  list-style: inherit;
  margin-top: 10px;
}
.textos_html ol li {
  list-style: auto;
  margin-top: 10px;
}

@media (max-width: 1199.98px) {
  .recuadro {
    width: 230px;
    height: 80px;
  }
  .recuadro span {
    font-size: 14px;
  }
  .cuadro2,
  .cuadro6 {
    top: 200px;
  }
  .cuadro3,
  .cuadro5 {
    top: 340px;
  }
  .cuadro4 {
    top: 450px;
  }
}

@media (max-width: 991.98px) {
  .recuadro {
    width: 170px;
    height: 60px;
  }
  .recuadro span {
    font-size: 10px;
  }
  .cuadro2,
  .cuadro6 {
    top: 165px;
  }
  .cuadro3,
  .cuadro5 {
    top: 260px;
  }
  .cuadro4 {
    top: 350px;
  }
}

@media (max-width: 767.98px) {
  .ocultarImagen {
    display: none;
  }

  .texto {
    height: auto !important;
  }
}
