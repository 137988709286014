.fondo,
.fondoImagen {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
}

.fondo_traslucido {
  background-color: rgba(0, 0, 0, 0.55);
}


.center {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  height: fit-content;
}

.texto_50 {
  font-size: 60px;
  line-height: 48px;
  font-weight: 500;
}

.imagenBackground {
  width: 100%;
}

.fondoImagen {
  height: inherit;
}

.logoContainer {
  height: 100%;
  width: 100%;
  position: absolute;
}

.logoContainer img {
  width: 250px;
  float: right;
  bottom: 15px;
  position: absolute;
  right: 15px;
}