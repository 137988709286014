.accionamos {
  font-size: 30px;
  font-family: "Nunito", sans-serif;
  color: #ffffff;
}
.margen_footer_cabecera{
margin-bottom: 15px;
}

.logo {
  width: 304px;
}

.sin_padding {
  padding: 0;
}

.botones_derecha {
  float: right;
}

.img_cencor{
  position: relative;
  width: 188px;
}

.avisoPolitica{
width: 171.7px;
text-align: center;
}
.derecha{
  position: absolute;
  float: right;
  right: 0;
}
.icon {
  position: relative;
  text-align: center;
  width: 0px;
  height: 0px;
  padding: 20px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  -moz-border-radius: 20px 20px 20px 20px;
  -webkit-border-radius: 20px 20px 20px 20px;
  -khtml-border-radius: 20px 20px 20px 20px;
  color: #ffffff;
}
.icon i {
  font-size: 21px;
  position: absolute;
  left: 9px;
  top: 10px;
}
.icon.social {
  float: left;
  margin: 0 10px 0 0;
  cursor: pointer;
  color: #ffffff;
  border: 1px solid #ffffff;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
}
.icon.social:hover {
  color: #ffffff;
  border: 1px solid #ffffff;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
}
.icon.social.fb i {
  left: 13px;
  top: 10px;
}
.icon.social.fb:hover {
  background: #3b5998;
}
.icon.social.tw i {
  left: 11px;
}
.icon.social.tw:hover {
  background: #55acee;
}
.icon.social.in i {
  left: 11px;
}
.icon.social.in:hover {
  background: #007bb5;
}
.icon.social.youtube i {
  left: 8px;
  top: 10px;
}
.icon.social.youtube:hover {
  background: #bb0000;
}
.icon.social.insta i {
  left: 10px;
}
.icon.social.insta:hover {
  background: #125688;
}
.icon.social.mail i {
  left: 10px;
}
.icon.social.mail:hover {
  background: #dd4b39;
}

/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.99px) {
  .avisoPolitica{
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }

  .derecha{
    position: relative;
    float: inherit;
    top: 0px;
    width: fit-content;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
}

	.div_logo,
	.div_accionamos {
	  text-align: center;
	  margin-top: 20px;	
	}
  
	.botones_derecha {
	  left: 0;
	  right: 0;
	  margin-left: auto;
	  margin-right: auto;
	  float: inherit;
	  width: fit-content;
	}
	.logo {
	  width: 80%;
	}
	.div_social {
	  left: 0;
	  right: 0;
	  margin-left: auto;
	  margin-right: auto;
	  float: inherit;
	  width: fit-content;
	  margin-top: 20px;
	}
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.99px) {
  .avisoPolitica{
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
  .div_logo,
  .div_accionamos {
	text-align: center;
	margin-top: 20px;	
  }

  .botones_derecha {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    float: inherit;
    width: fit-content;
  }
  .logo {
    width: 80%;
  }
  .div_social {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    float: inherit;
    width: fit-content;
    margin-top: 20px;
  }
  .derecha{
    position: relative;
    float: inherit;
    top: 0px;
    width: fit-content;
    right: 0;
    left: 0;
    margin-right: auto;
    margin-left: auto;
}

}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.99px) {
  .derecha{
    top: 10px;
    width: fit-content;
    right: 0;
}

  .div_logo,
  .div_accionamos {
	text-align: center;
	margin-top: 15px;
	
  }

  .botones_derecha {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    float: inherit;
    width: fit-content;
  }
  .logo {
    width: 80%;
  }
  .div_social {
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    float: inherit;
    width: fit-content;
    margin-top: 30px;
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.99px) { 
  .derecha{
      position: relative;
      float: inherit;
      top: 0px;
      width: fit-content;
      right: 0;
      left: 0;
      margin-right: auto;
      margin-left: auto;
  }

}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .derecha{
    position: absolute;
    float: right;
    top: -25px;
    width: fit-content;
    right: 0;
    margin-left: auto;
}

}





