.Main {
  margin: 0px 0;
  margin-top: 35px;
}

.ticker {
  margin-top: 10px;
  margin-bottom: 10px;
}

.blockScroll {
  overflow: inherit;
}

/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .Main {
    padding-top: 35px;
  }
}
