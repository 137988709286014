.btn_link {
  text-align: left;
  color: #f26522;
  text-decoration: none;
  background-color: #FFFFFF;
}

.tabsContainer {
  border: 1px solid #eaedfa;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 24px 21px 22px 26px;
}

.vertical_div {
  display: table;
  height: 100%;
  width: 100%;
}

.vertical_content {
  display: table-cell;
  vertical-align: middle;
  line-height: normal;
}