* {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

body {
	padding: 0;
	margin: 0;
}

.notfoundID {
	position: relative;
	height: 550px;
	width: 100%;
}

.notfoundID .notfound {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.notfound {
	max-width: 520px;
	width: 100%;
	line-height: 1.4;
	text-align: center;
	left: 0;
	right: 0;
	margin-right: auto;
	margin-left: auto;
}

.notfound .notfound_404 {
	position: relative;
	height: 200px;
	margin: 0px auto 20px;
	z-index: -1;
}

.notfound .notfound_404 h1 {
	font-family: 'Montserrat', sans-serif;
	font-size: 236px;
	width: 715px;
	font-weight: 200;
	margin: 0px;
	color: #00aeef;
	text-transform: uppercase;
	position: absolute;
	left: 50%;
	top: 40%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.notfound .notfound_404 h2 {
	font-family: 'Montserrat', sans-serif;
	font-size: 16px;
	font-weight: 400;
	text-transform: uppercase;
	color: #211b19;
	background: #fff;
	padding: 10px 5px;
	margin: auto;
	display: inline-block;
	position: absolute;
	bottom: 0px;
	left: 0;
	right: 0;
}

.notfound a {
	display: inline-block;
	padding: 13px 23px;
	-webkit-transition: 0.2s all;
	transition: 0.2s all;
}

@media only screen and (max-width: 767px) {
	.notfound .notfound_404 h1 {
		font-size: 148px;
		top: 22%;
	}
}

@media only screen and (max-width: 480px) {
	.notfound .notfound_404 {
		height: 148px;
		margin: 0px auto 10px;
	}
	.notfound .notfound_404 h1 {
		top: 22%;
		font-size: 86px;
	}
	.notfound .notfound_404 h2 {
		font-size: 16px;
	}
	.notfound a {
		padding: 7px 15px;
		font-size: 14px;
	}
}
