.fondo {
	background: #00aeef;
	height: 100px;
	width: 100%;
	padding-left: 70px;
	padding-right: 70px;
}

.izq {
	float: left;
	margin-right: 15px;
	height: 50px;
	position: relative;
	width: 215px;
	height: 100%;
}
.izq2 {
	float: left;
	margin-right: 15px;
	height: 50px;
	position: relative;
	width: 200px;
	height: 100%;
}
.boletinText {
	margin-right: 10px;
}

.suscribete {
	background: #fff;
	color: #00aeef;
	height: 50px;
	width: 200px;
	position: relative;
	top: 0;
	bottom: 0;
	margin-top: auto;
	margin-bottom: auto;
	position: absolute;
	cursor: pointer;
}

.suscribete:hover{
	transform: scale(1.02);
}

.suscribete span {
	position: absolute;
	margin-top: auto;
	margin-bottom: auto;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin-right: auto;
	margin-left: auto;
	height: 25px;
	width: 120px;
}

.textoAlineado {
	/* height: 50px; */
	position: absolute;
	margin-top: auto;
	margin-bottom: auto;
	top: 0;
	bottom: 0;
	height: 25px;
	/* width: 120px; */
}

.contactanos {
	position: absolute;
	right: 0px;
	width: 472px;
	height: 100%;
}
.contactanosContenedor {
	position: relative;
	width: 100%;
	height: 100%;
}

.contactanosAlineado {
	/* height: 50px; */
	position: absolute;
	margin-top: auto;
	margin-bottom: auto;
	top: 0;
	bottom: 0;
	height: 25px;
	width: auto;
	/* width: 120px; */
}




/* // Large devices (desktops, 992px and up) */
@media (max-width: 991.99px) {
	.contactanos {
		width: 300px;
		height: 100%;
	}
	.contactanosAlineado {
		height: 50px;
	}
}


@media (max-width: 855.99px) {
    .fondo {
        height: 150px;
        padding-left: 15px;
        padding-right: 15px;
    }
}

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.99px) {
    .contactanos, .izq, .izq2 {
		height: 50% !important;
    }
    .contactanos{
        position: relative;
        width: 100% !important;
    }

    .fondo {
        height: 150px;
        padding-left: 75px ;
        padding-right: 75px ;
    }

}

/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.99px) {
    .fondo {
        height: 180px;
        padding-left: 10px ;
        padding-right: 10px ;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .contactanos, .izq, .izq2 {
		height: 33% !important;
    }
}


