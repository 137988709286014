.main_title {
  font-size: 42px;
}

.main_subtitle {
  font-size: 30px;
  margin: 20px 0 40px 0;
}

.main_subtitle h2 {
  display: inline;
  margin-left: 20px;
}

.text_container {
  text-align: justify;
}

.text_container,
.imgContainer {
  margin-top: 30px;
}

.blueBack{
  background-color: #001232;
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (max-width: 575.98px) {
  .texto_introduccion button {
    width: 230px;
  }
  .texto_introduccion button span{
    font-size: 14px !important;
  }
}
