.titulo {
  color: #00aeef;
  font-family: "Montserrat", sans-serif;
  line-height: 24px;
  font-size: 16px;
  width: 100%;
}

.divDescarga {
  max-width: 213px;
  min-width: 210px;
  position: relative;
}

.descargaMultiple {
  margin-top: 10px;
}

.flechaDescarga {
  position: absolute;
  right: -5px;
  top: 2px;
}

.divDescarga,
.pointer {
  cursor: pointer !important;
}
.descargaMultiple{
  cursor:default !important;
}
.descargaMultiple:hover .flechaDescarga a{
  cursor: pointer !important;
  text-decoration: none !important;
  color: #0056b3;
}
.descargaMultiple:hover a{
  text-decoration: underline !important;
}
.active{
  color: #00aeef;
  font-weight: bold;
}

.btnFiles, .btnFiles:hover, .dropdown > .btnFiles {
  color: #9095a4 !important;
  background-color: inherit !important;
  border-color: inherit !important;
  border: none !important;
  padding: 0 !important;
  box-shadow: none !important;
}

.btnFiles:hover{
  transform: scale(1.09);
  color: #00aeef !important;
  font-weight: bold !important;
}

.btnFiles:active{
  transform: scale(1);
  font-weight: bold !important;
}
.texto_blanco :global(.color_gris), .blank{
  color: #FFF !important;
}
@media (max-width: 992px) {
  .flechaDescarga {
    position: absolute;
    right: -10px;
    top: 2px;
  }
}

