.contenedor_card {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-radius: 6px;
  margin-bottom: 20px;
}
.contenedor_card2,
.contenedor_card3,
.contenedor_card4 {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  margin-bottom: 0px;
}

.contenedor_card2:first-child {
  border-radius: 6px 6px 0 0;
}
.contenedor_card2 {
  margin-top: 50px;
  border-radius: 6px 6px 0 0;
  border-bottom: 0px !important;
}
.contenedor_card3 {
  border-radius: 0;
}

.contenedor_card4 {
  border-radius: 0 0 6px 6px;
}
.encabezado_card {
  padding: 7px 25px;
  min-height: 49px;
  width: 100%;
  background: #ffffff;
  border-radius: 6px 6px 0 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
}
.texto_encabezado {
  position: relative;
  top: 2px;
  margin-left: 10px;
}
.card_header {
  background: #ffffff;
}
.card_header:first-child {
  border: 0px solid rgba(0, 0, 0, 0.125) !important;
  border-radius: 6px 6px 6px 6px;
}
.card {
  margin-bottom: 25px;
  border: 0px solid rgba(0, 0, 0, 0.125) !important;
  margin-bottom: 0px;
  /* border-radius: 0; */
}

.titulo {
  color: #00aeef;
  font-family: "Montserrat", sans-serif;
  line-height: 24px;
  font-size: 16px;
  width: 100%;
}

.titulo2 {
  color: black;
  font-family: "Montserrat", sans-serif;
  line-height: 32px;
  font-size: 14px;
  width: 100%;
}

.titulo_texto16_17 {
  color: #00aeef;
  font-family: "Montserrat", sans-serif;
  line-height: 16.69px;
  font-size: 16px;
  width: 100%;
}
.btn_link {
  width: 100%;
  text-align: left;
  color: #00aeef;
  text-decoration: none;
  padding-right: 10px !important;
  text-decoration: none !important;
}
.btn_link2 {
  padding: 0.375rem 0rem !important;
}

.btn_link2 {
  width: 75%;
  text-align: left;
  color: #00aeef;
  text-decoration: none;
}

.btn_link_full{
  width: 100%;
}

.btn_link3{
  width: 100%;
  text-align: left;
  color: #00aeef;
  text-decoration: none;
  padding: 0.375rem 0rem !important;
}

.btn_link:hover {
  text-decoration: none;
}

.card_body {
  padding-top: 30px !important;
  padding-left: 35px !important;
  padding-right: 35px !important;
  padding-bottom: 30px !important;
  background: #fafbff;
  /* display: table-cell; */
  display: list-item;
  list-style: none;
  overflow: auto;
}

.card_body p,
.card_body_2 p {
  padding-bottom: 10px;
}

.card_body_2 {
  padding-top: 10px !important;
}

.card_body table,
.card_body table * {
  border: 0;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  /* padding-left: 15px; */
  margin-top: 6px;
  margin-bottom: 6px;
}

.card_body strong {
  color: #00aeef;
}

.card_body ul li {
  list-style: none;
}
.card_body ol li {
  list-style: decimal !important;
  margin-left: 30px;
}

.card_body ol ul li {
  list-style: none !important;
}

.card_body tr {
  border-bottom: 0px solid #dee2e6;
}
.card_body tr:last-child {
  border-bottom: 0px solid #dee2e6;
}
.card_body ul li:before {
  font-family: "Font Awesome\ 5 Free";
  content: "\f058";
  color: #00aeef;
  margin-right: 10px;
}

.card_body ul li li:before {
  font-family: "Font Awesome\ 5 Free";
  content: "";
  color: #00aeef;
  margin-right: 10px;
}

.card_body * {
  font-family: "Montserrat", sans-serif;
}

.accordion_toggle {
  float: right;
  position: absolute;
  top: 22px;
  right: 15px;
}

.accordion_toggle2 {
  top: 14px;
}
.accordion_toggle:before {
  font-family: "Font Awesome\ 5 Free";

  /* symbol for "collapsed" panels */
  content: "\f077" !important; /* adjust as needed, taken from bootstrap.css */
}

.titulo :global(.collapsed) .accordion_toggle:before {
  font-family: "Font Awesome\ 5 Free";
  /* symbol for "collapsed" panels */
  content: "\f078" !important; /* adjust as needed, taken from bootstrap.css */
}

.icon {
  margin-right: 25px;
}

.img{
  max-width: 60px;
}

.descripcionContactanos{
  left: 52px;
}

.titulo_2 {
  margin-left: 25px;
}

.btn {
  background: #00aeef;
  color: #ffffff !important;
  border: 1px solid #00aeef;
  border-radius: 24px;
  height: 48px;
  width: 268px;
  font-size: 16px;
  line-height: 16.69px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  font-family: nunito;
  font-style: normal;
  font-weight: bold;
  white-space: nowrap;
  text-align: left;
}

.flecha {
  float: right;
  font-size: 23px;
  position: relative;
  bottom: 2px;
}

.imagen_encabezado {
  width: 120px;
  height: 80px;
}

.tipo4 .imagen_encabezado {
  width: initial !important;
  height: 75px;
  margin-right: 15px;
}

.tipo5 {
  border-bottom: 2px solid rgba(0, 174, 239, 1) !important;
  display: flex;
}

.tipo5 .imagen_encabezado {
  width: initial !important;
  height: 75px;
  margin-right: 15px;
}

.texto_derecha_div_encabezado,
.texto_derecha_div_encabezado_en {
  position: absolute;
  right: 40px;
}

.texto_derecha_div_encabezado {
  float: right;
  width: 126px;
}
.texto_derecha_div_encabezado_en {
  float: right;
  width: 160px;
}
.texto_derecha_div_encabezado div span {
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
}

.texto_descarga {
  width: 100%;
  min-width: 100px;
  margin-top: 15px;
}
.margenes_boton {
  padding-top: 10px;
  padding-bottom: 10px;
}

.margenes_30_izq_der {
  padding-left: 30px;
  padding-right: 30px;
}

.radius6 {
  border-radius: 6px 6px 6px 6px !important;
  border-bottom: 0px !important;
}

.encabezado_card2 {
  padding: 7px 25px;
  min-height: 49px;
  width: 100%;
  background: #ffffff;
  border-radius: 6px 6px 0 0;
  border-bottom: 3px solid #00aeef !important;
}

.encabezado_card3 {
  padding: 7px 25px;
  min-height: 49px;
  width: 100%;
  background: #fafbff;
  border-radius: 6px 6px 0 0;
}

.encabezado_card4 {
  padding: 7px 25px;
  min-height: 49px;
  width: 100%;
  background: #00aeef;
  border-radius: 0 0 6px 6px;
}

.encabezado_card5 {
  padding: 7px 25px;
  min-height: 49px;
  width: 100%;
  background: #ffffff;
  border-radius: 6px 6px 0 0;
}

.encabezado_card6 {
  padding: 7px 25px;
  min-height: 49px;
  width: 100%;
  background: #fafbff;
  border-radius: 6px 6px 0 0;
  border-bottom: 3px solid #00aeef !important;
}

.encabezado_card7 {
    padding: 7px 25px;
    width: 100%;
    background: #ffffff;
    border-radius: 6px 6px 0 0;
}

.padding_card {
  padding: 7px 25px;
}

:global(.card-header) {
  padding: 7px 25px;
}

.padding_card_header {
  padding: 7px 12px !important;
}
.padding_card_header_2 {
  padding: 25px 25px !important;
}
.card_header2 {
  background: #fafbff;
}

.card_header3 {
  background: #ffffff;
  border-bottom: 3px solid #00aeef !important;
}
.card_header_paddings{
  padding: 7px 20px !important;
}
.card_noPadding{
  padding: 0px !important;
}
.card_header4 {
  background: #ffffff;
}
.cardDocs {
  padding: 7px 0px !important;
}
.titulo_3 {
  margin-left: 100px;
  display: inline;
}
.titulo_4 {
  margin-left: 150px;
}

.titulo_encabezado {
  width: 120px;
  float: left;
}
.titulo_encabezado2 {
  margin-left: 150px;
}
.titulo_5pct {
  display: inline-block;
  width: 5%;
}
.titulo_10pct {
  display: inline-block;
  width: 10%;
}
.titulo_25pct {
  display: inline-block;
  width: 25%;
}
.titulo_30pct {
  display: inline-block;
  width: 30%;
}
.titulo_33pct {
  display: inline-block;
  width: 33%;
}
.titulo_35pct {
  display: inline-block;
  width: 35%;
}
.titulo_40pct {
  display: inline-block;
  width: 40%;
}
.titulo_50pct {
  display: inline-block;
  width: 50%;
}
.titulo_60pct {
  display: inline-block;
  width: 60%;
}
.titulo_66pct {
  display: inline-block;
  width: 66%;
}
.titulo_75pct {
  display: inline-block;
  width: 75%;
}
.titulo_80pct {
  display: inline-block;
  width: 80%;
}
.titulo_85pct {
  display: inline-block;
  width: 85%;
}
.titulo_90pct {
  display: inline-block;
  width: 90%;
}
.titulo_100pct {
  display: inline-block;
  width: 100%;
}

.titulo_15pct {
  display: inline-block;
  width: 15%;
}

.titulo_20pct {
  display: inline-block;
  width: 20%;
}

.inlineBlock {
  display: inline-block;
}

.left_110 {
  left: 110px;
}
.left_125 {
  left: 125px;
}
.height_22 {
  height: 22px;
}

.leftCotizacion {
  width: fit-content;
  width: -moz-fit-content;
  float: left;
  margin-right: 10px;
  margin-top: 10px;
}

.fechaListado {
  width: auto;
  position: absolute;
  right: 15px;
}
.margin_bottom_label {
  margin-bottom: 10px;
}
.margin_bottom_row {
  margin-bottom: 15px;
}

.margin_top_20_descarga {
  margin-top: 20px !important;
}

.contenedor_download {
  height: 70px;
}

.icon_download {
  position: absolute;
  right: 20px;
}
.texto_download {
  position: absolute;
  width: 74px !important;
  right: 8px;
  bottom: 3px;
  text-align: center;
}

.descargaMultiple{
  margin-top: 10px;
}

.container_center{
  display: flex;
}

.sustentable .titulo,
.sustentable .btn_link,
.sustentable .btn_link2,
.sustentable .btn_link3,
.sustentable :global(.color_azul),
.sustentable i{
  color: #176937 !important;
}
.sustentable .card2, 
.sustentable .bottomAzul,
.sustentable .tipo5,
.sustentable .encabezado_card2,
.sustentable .encabezado_card6,
.sustentable .card_header3{
  border-bottom-color: #176937 !important;
}
@media all and (max-width: 768px) {
  .fechaListado {
    width: 100%;
    position: relative;
    right: 0;
  }
  .fechaListado span {
    margin-left: 8px;
  }
  .leftCotizacion {
    width: 45%;
    width: -moz-fit-content;
    float: left;
    margin-right: 10px;
    margin-top: 10px;
  }
}
.titulo_emisoraPDF {
  display: inline-block;
  width: 25%;
  color: gray;
}

.color_verde {
  color: #16c622;
}

.color_rojo {
  color: #e22929;
}

.color_amarillo {
  color: #f2bd0e;
}

.color_azul {
  color: #00aeef;
}

.color_gris {
  color: #9095a4;
}

.verticalTitle {
  vertical-align: middle;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  position: relative;
}

.imagenes_calificadoras {
  width: 150px;
  background-size: cover;
}

.verticalAlign_topText {
  vertical-align: top;
}

.contornoSerie {
  border: 1px solid #00aeef;
  width: 40px;
  height: 40px;
  text-align: center;
  float: left;
  border-radius: 6px;
  margin-right: 17px;
  position: relative;
  padding: 3px;
  margin-bottom: 10px;
}

.serieContenido {
  top: 0;
  bottom: 0;
  margin-bottom: auto;
  margin-top: auto;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 37px;
  text-align: center;
  height: max-content;
  height: -moz-max-content;
  line-height: 1.1;
}

.vertical_div {
  position: relative;
  display: table;
}

.vertical {
  display: table-cell;
  vertical-align: middle;
}

.paddingRight20 {
  padding-right: 20px;
}

.div_estatus {
  width: 120px;
}

.flechaDescarga {
  position: absolute;
  right: -5px;
  top: 2px;
}
.divDescarga {
  max-width: 213px;
  position: relative;
}
.rowDetail {
  border-bottom: 1px solid #00000020;
  margin-bottom: 5px;
  padding-bottom: 5px;
  padding-top: 10px;
  min-height: 82px;
}
.rowDetail:last-child{
  border-bottom: 0px;
}


.arrow_type_20{
  padding-top: 7px;
  float: left;
  position: absolute;
  left: 90px;
}
.rowDownload{
  height: max-content;
  height: -moz-max-content;
  height: -webkit-max-content;
}

.noWrapResponsive{
  white-space: nowrap;
}

.textCenter{
  text-align: center!important;
}
.textRight{
  text-align: right !important;
}
.numberRight{
  text-align: right!important;
}
.scroll_horizontal{
  overflow-x: auto;
}
.container_scroll{
  min-width: 991px !important;
}
.container_scroll_2000{
  min-width: 1708px !important;
}
.scroll_vertical_distribuciones{
  overflow-y: scroll;
  max-height: 550px;
}
.scroll_vertical_colocaciones{
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 630px;
  position: relative;
  width: 100%;
}
.scroll_vertical{
  overflow-y: auto;
  max-height: 165px;
}
.rowTable{
  width: max-content;
  display: flex;
}
.col70px{
  width: 70px;
  padding-right: 15px;
  margin-left: 15px;
}
.col100px{
  width: 100px;
  padding-right: 15px;
  margin-left: 15px;
}
.col145pxTitle{
  width: 250px;
  padding: 0 53px 0 53px;
}
.col145px{
  width: 185px;
  padding-left: 0px;
  padding-right: 10px;
}
.col150px{
  width: 140px;
  padding-right: 15px;
  margin-left: 15px;
}
.col200pxTitle{
  width: 210px;
}
.col183px{
  width: 183px;
  padding-left: 0px;
  padding-right: 28px;
}
.col200px{
  width: 235px;
  padding-left: 0px;
  padding-right: 28px;
}
.col18padding{
  padding-right: 16px !important;
}

.col235px{
  width: 235px;
  padding-right: 15px;
  margin-left: 15px;
}
.col200pxR{
  width: 220px;
}
.col205px{
  width: 205px;
  padding-right: 15px;
  margin-left: 15px;
}
.col225px{
  width: 225px;
  padding-right: 15px;
  margin-left: 15px;
}
.col250px{
  width: 250px;
  padding-right: 15px;
  margin-left: 15px;
}
.col300px{
  width: 300px;
  padding-right: 15px;
  margin-left: 15px;
}
.col305px{
  width: 305px;
  padding-right: 15px;
  margin-left: 15px;
}
.col350px{
  width: 350px;
  padding-right: 15px;
  margin-left: 15px;
}
.col420px{
  width: 423px;
  padding-right: 15px;
  margin-left: 15px;
}
.col525px{
  width: 530px;
  padding-right: 15px;
  margin-left: 15px;
}

.modoListado{
  position: inherit !important;
  padding-top: 15px;
}


.minHeightCell{
  min-height: 48px !important;
}

.margin_note{
  margin-left: 10px;
  margin-bottom: 20px;
}

.border_card_repeat_bottom, .border_card_repeat_top{
  width: 100.1%;
  height: 10px;
}
.border_card_repeat_bottom{
  border-radius: 0 0 6px 6px;
  border-bottom: 3px solid rgba(0, 0, 0, 0.125) !important;
  
}
.border_card_repeat_top{
  margin-top: 30px;
  border-radius: 6px 6px 0 0;

  border-top: 0px solid rgba(0, 0, 0, 0.125) !important;

}

.bottomAzul{
  border-bottom: 3px solid #00aeef !important;
  padding: 10px;
}

.border_bottom_redondeado{
  border-radius: 0 0 0 10px;;
}

@media (max-width: 575.99px) {
  .texto_derecha_div_encabezado,
  .texto_derecha_div_encabezado_en {
    position: relative;
    right: inherit;
  }
  .margin_top_20_descarga {
    margin-top: 0px !important;
  }
  
}

/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.99px) {
  .texto_derecha_div_encabezado,
  .texto_derecha_div_encabezado_en {
    position: relative;
    right: inherit;
  }
  .margin_top_20_descarga {
    margin-top: 0px !important;
  }
 
}

@media (min-width: 768px) {
  .margin_top_20_descarga {
    margin-top: 0px !important;
  }
}
@media (max-width: 992px) {
  .flechaDescarga {
    position: absolute;
    right: -10px;
    top: 2px;
  }

  .noWrapResponsive{
    white-space: inherit;
  }

  .textCenter,
  .numberRight{
    text-align: left!important;
  }
  
}

@media (max-width: 991.98px) {
  .textCenter,
  .numberRight{
    text-align: left!important;
  }
  .minHeightCell{
    min-height: inherit !important;
  }
}

@media (min-width: 992px) {
  .margin_top_20_descarga {
    margin-top: 20px !important;
  }
}
