.divImagen {
  width: 100%;
  overflow: hidden;
  background-position: center;
  background-repeat: no-repeat;
  height: 50vh;
  object-fit: cover;
}
.sinImagen{
  height: 0px;
}
.leyendaPortada {
  margin-top: 10px;
}
.contenedor {
  background-color: #fff;
  min-height: 350px;
  width: 100%;
  position: relative;
}

.contenedor .divImagen.wide {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
}

.contenedor .divImagen.tall {
  width: 100%;
  max-height: 100%;
  max-width: 100%;
}

.contenedor img {
  margin: 0 0 15px 15px;
}

.contenedor hr{
  margin-top: 15px;
  margin-bottom: 15px;
}

.contenedor h1,
.contenedor h2,
.contenedor h3,
.contenedor h4,
.contenedor h5,
.contenedor h6 {
  margin-bottom: 30px;
  margin-top: 30px;
  color: #00aeef;
  font-weight: bold;
  font-family: "Nunito", sans-serif;
}

.contenedor h1,
.titulo span {
  font-size: 40px;
}
.contenedor h2 {
  font-size: 35px;
}
.contenedor h3 {
  font-size: 30px;
}
.contenedor h4 {
  font-size: 25px;
}
.contenedor h5 {
  font-size: 20px;
}
.contenedor h6 {
  font-size: 18px;
}

.contenedor p,
.contenedor span {
  margin-bottom: 15px;
}
.contenedor ol,
.contenedor ul {
  margin-top: 15px;
  margin-bottom: 15px;
}
.contenedor ol {
  list-style-type: decimal;
  padding-left: 30px;
}
.contenedor ul {
  padding-left: 30px;
  list-style-type: disc;
}
.contenedor ul li,
.contenedor ol li {
  margin-bottom: 5px;
}

.contenedor a {
  color: #00aeef;
  font-weight: bold;
}
.contenedor strong {
  font-weight: bold;
}

.contenedor em {
  font-style: oblique;
}
.contenedor table h1,
.contenedor table h2,
.contenedor table h3,
.contenedor table h4,
.contenedor table h5,
.contenedor table h6 {
  margin: 0;
}
.contenedor td,
.contenedor th {
  border: 1px solid #ddd;
  padding: 8px;
}

.contenedor tr:nth-child(even) {
  background-color: #f2f2f2;
}

.contenedor tr:hover {
  background-color: #ddd;
}

.contenedor th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}

.contenedor code {
  color: #ffffff;
  background-color: #7c7e82;
  font-family: "Nunito", sans-serif;
  padding: 15px;
}

.contenedor pre {
  background-color: #7c7e82;
  padding: 15px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.contenedor pre code {
  padding: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.contenedor cite {
  font-style: italic;
  color: #73757a;
}

.contenedor cite:before {
  content: open-quote;
}
.contenedor cite:after {
  content: close-quote;
}

.contenedor :global(.portlet-msg-info) {
  background-color: #eef2fa;
  border-color: #89a7e0;
  color: #2e5aac;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}

.contenedor :global(.portlet-msg-alert) {
  background-color: #fff4ec;
  border-color: #ff8f39;
  color: #b95000;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}

.contenedor :global(.portlet-msg-error) {
  background-color: #feefef;
  border-color: #f48989;
  color: #da1414;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}
/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.99px) {
}
/* // Medium devices (tablets, less than 992px) */
@media (max-width: 991.99px) {
  
}
/* // Small devices (landscape phones, less than 768px) */
@media (max-width: 767.99px) {
 
}

/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.99px) {
 
}
