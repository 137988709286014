.grid{
    display: grid;
    grid-template-columns: repeat(3, 2fr);
}

/* // Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) { 
    .grid{
        display: grid;
        grid-template-columns: repeat(3, 2fr);
    }
 }
 
 /* // Medium devices (tablets, less than 992px) */
 @media (max-width: 991.98px) { 
     .grid{
         display: grid;
         grid-template-columns: repeat(2, 2fr);
     }
  }
  
  /* // Small devices (landscape phones, less than 768px) */
  @media (max-width: 767.98px) { 
      .grid{
          display: grid;
          grid-template-columns: repeat(1, 2fr);
      }
   }
/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) { 
    .grid{
        display: grid;
        grid-template-columns: repeat(1, 2fr);
    }
 }

