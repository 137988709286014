.width100{
    width: 100%;
}

.contenedorGrafica{
    width: 100%;
    border: 1px solid #EAEDFA;
    padding-bottom: 50px;
}

.botonCruce{
    width: 143px;
    height: 48px;
    border: 1px solid #EAEDFA;
    padding: 10px;
    text-align: center;
    float: left;
    margin-left: 15px;
    margin-bottom: 15px;
    border-radius: 6px;
    cursor: pointer;
}

.botonCruce:hover{
    transform: scale(1.1);
}
.botonCruce:active{
    transform: inherit;
}

.btnActivo{
    border: 1px solid #00AEEF;
    background: #00AEEF;
    color: #FFFFFF;
}

.btnActivo span{
    color: #FFFFFF;
}